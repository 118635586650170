import { useTheme } from "styled-components";
import { Tag } from "../MediaLibrary/mediaLibrary.model";
import { useContext, useState } from "react";

import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Modal, { ModalCard, WarningModal } from "../../components/Modal/Modal";
import Icon from "../../components/Icon/Icon";
import {
	ButtonContainer,
	EditModalContainer,
	InnerContainer,
} from "../../components/Library/Library";
import DropdownField from "../../components/FormFields/DropdownField";
import TextField from "../../components/FormFields/TextField";
import Button from "../../components/Button/Button";
import { deleteTag, editTag } from "./manageTags";
import { useParams } from "react-router-dom";
import { getCompany } from "../Company/manageCompany";
import DeleteTagWarning from "./DeleteTagWarning";
import { tagFieldRequired } from "../../components/Tags/TagSelection";
import { useAlert } from "../../components/Alert/Alerts";
import { getLocalTime } from "../../utils/timeUtilities";

const EditTagModal = (props: EditTagModalProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const { companyId } = useParams<{
		companyId?: string;
	}>();
	const parsedCompanyId = companyId
		? parseInt(companyId)
		: Number(getCompany() || "0");
	const [showDeleteWarning, setShowDeleteWarning] = useState(false);

	const formikProps = useFormik({
		initialValues: props.tag,
		onSubmit: async (value) => handleEditTag(value),
		validationSchema: Yup.object({
			tag: Yup.string()
				.trim()
				.required(tagFieldRequired)
				.min(1)
				.test("checkForDuplicates", "This tag already exists", (val) => {
					const isDuplicate = props.tagArr.some((tagObj) => tagObj.tag === val);

					return val === undefined
						? false
						: val === props.tag.tag // allow users to save the same tag name
						? true
						: !isDuplicate;
				}),
			// .max(60, "Max tag length is 60 characters"),
		}),
		enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
	});

	const handleEditTag = (value: Tag) => {
		if (value) {
			editTag(parsedCompanyId, value)
				.then((res) => {
					if (res.status === 204) {
						props.onHide();
						props.refreshTags();
						addNewAlert({
							type: "success",
							message: "Successfully modified tag",
						});
					}
				})
				.catch((err) =>
					addNewAlert({
						type: "error",
						message: err,
					})
				);
		} else {
			addNewAlert({
				type: "error",
				message: "Failed to edit media. Please try again later",
			});
		}
	};

	const handleDeleteTag = () => {
		deleteTag(parsedCompanyId, formikProps.values)
			.then(() => {
				props.onHide();
				props.refreshTags();
				addNewAlert({
					type: "success",
					message: "Successfully deleted tag",
				});
			})
			.catch((err) =>
				addNewAlert({
					type: "error",
					message: err,
				})
			);
	};

	return (
		<FormikProvider value={formikProps}>
			<DeleteTagWarning
				show={showDeleteWarning}
				setShow={setShowDeleteWarning}
				onConfirm={handleDeleteTag}
			/>
			<Modal show={props.tag !== null && !showDeleteWarning}>
				<ModalCard
					title="Edit Tag"
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							onClick={() => props.onHide()}
							width="35px"
							height="35px"
						/>
					}
					iconColor={theme.colorPrimary}
				>
					<EditModalContainer>
						<InnerContainer>
							<div className="flex flex-col gap-[.5rem]">
								<b className="mb-[1rem]">Tag name:</b>
								<TextField
									name="tag"
									hiddenlabel
									value={formikProps.values.tag}
								/>
							</div>

							<div className="flex flex-col gap-[.5rem]">
								<b className="mb-[1rem]">Metadata</b>

								{formikProps.values.createdDate && (
									<span>
										Created:{" "}
										{new Date(formikProps.values.createdDate).toLocaleString(
											"en-US",
											{
												timeZone:
													Intl.DateTimeFormat().resolvedOptions().timeZone,
											}
										)}
									</span>
								)}

								{formikProps.values.updatedDate && (
									<span>
										Edited on:{" "}
										{new Date(formikProps.values.updatedDate).toLocaleString(
											"en-US",
											{
												timeZone:
													Intl.DateTimeFormat().resolvedOptions().timeZone,
											}
										)}
									</span>
								)}

								{!formikProps.values.updatedDate &&
									!formikProps.values.createdDate && (
										<p>No Information Available</p>
									)}
							</div>

							<div className="flex flex-col gap-[.5rem]">
								<b className="mb-[1rem]">Used In</b>
								<span>
									{formikProps.values.companyCreditsCount} Company Credits
								</span>
								<span>
									{formikProps.values.individualCreditsCount} Individual Credits
								</span>
								<span>{formikProps.values.mediaCount} Media Assets</span>
							</div>
						</InnerContainer>

						<ButtonContainer>
							<Button
								className="button-light"
								icon="trash"
								iconSize="15px"
								iconColor={theme.colorActivation}
								onClick={() => {
									// check if tag is linked to any credits/media
									if (
										(formikProps.values.companyCreditsCount || 0) +
											(formikProps.values.individualCreditsCount || 0) +
											(formikProps.values.mediaCount || 0) >
										0
									) {
										setShowDeleteWarning(true);
									} else {
										handleDeleteTag();
									}
								}}
							>
								Delete
							</Button>

							<Button icon="check" onClick={formikProps.submitForm}>
								Save
							</Button>
						</ButtonContainer>
					</EditModalContainer>
				</ModalCard>
			</Modal>
		</FormikProvider>
	);
};

export default EditTagModal;

interface EditTagModalProps {
	tag: Tag;
	onHide(): void;
	refreshTags(): void;
	tagArr: Tag[];
}
