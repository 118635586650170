import styled, { useTheme, css } from "styled-components";
import { Fragment, useEffect, useState } from "react";
import Sort from "../../../components/Sort/Sort";
import TextField from "../../../components/FormFields/TextField";
import { useFormik, FormikProvider } from "formik";
import Button from "../../../components/Button/Button";
import Table, {
  SortDirection,
  TablePlaceholder,
} from "../../../components/Table/Table";
import {
  PaymentStatus,
  PaymentStatusOptions,
  TransactionType,
  TransactionTypeOptions,
} from "../../Checkout/OrderInterfaces";
import axios from "axios";
import { urlOrder } from "../../../endpoints";
import Pagination from "../../../utils/Pagination";
import { AdminPageControls, SearchFieldsContainer } from "./AdminSearch";
import StyledForm from "../../../components/Form/Form";
import { CSVLink } from "react-csv";
import {
  getStoredValue,
  setStoredValue,
} from "../../../components/LocalStorageStorage/LocalStorageStore";
import MultiDropdown from "../../../components/MultiDropdown/MultiDropdown";
import Loading from "../../../components/Loading/Loading";
import config from "../../../config";
import { CountryOptions } from "../../../data/CountryData";
import { commafy } from "../../../utils/commafy";
import {
  getLocalTime,
  getTimezoneOffset,
  unixToMS,
} from "../../../utils/timeUtilities";
import DateTimeField from "../../../components/FormFields/DateTimeField";

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colorActivation};
`;

const CsvExport = styled(CSVLink)<{ disabled?: boolean }>`
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `};
`;

let date = new Date();

const initialSearch = {
  id: undefined,
  companyName: undefined,
  paymentStatus: undefined,
  paymentMethod: undefined,
  companyCountry: undefined,
  orderAmount: undefined,
  startDate: undefined,
  endDate: undefined,
};

const getScrubbedRequest = (
  request: OrderSearchRequest
): OrderSearchRequest => {
  return {
    id: request.id ? Number(request.id) : undefined,
    companyName: request.companyName ? request.companyName : undefined,
    paymentStatus: request.paymentStatus ? request.paymentStatus : undefined,
    paymentMethod: request.paymentMethod ? request.paymentMethod : undefined,
    companyCountry: request.companyCountry ? request.companyCountry : undefined,
    orderAmount: request.orderAmount ? request.orderAmount : undefined,
    startDateTime: request.startDateTime
      ? unixToMS(request.startDateTime)
      : undefined,
    endDateTime: request.endDateTime
      ? unixToMS(request.endDateTime)
      : undefined,
  };
};

const OrderSearchTab = () => {
  const theme = useTheme();
  const [searchVal, setSearchVal] = useState<OrderSearchRequest>(
    getStoredValue("orderSearch") || initialSearch
  );
  const [searchResult, setSearchResult] = useState<OrderSearchResult[]>([]);
  const [isActive, setActive] = useState(false);
  const [page, setPage] = useState(1);
  const [totalAmountOfPages, settotalAmountOfPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const [csvOrders, setCsvOrders] = useState<any[]>([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSort, setActiveSort] = useState<string | null>(null);
  const [activeSortDirection, setActiveSortDirection] = useState<SortDirection>(
    SortDirection.Asc
  );

  const getPaymentStatusLabel = (value: number) => {
    return PaymentStatusOptions.find((option) => option.value === value)?.label;
  };

  async function PostSearch(request: OrderSearchRequest) {
    try {
      const newRequest = getScrubbedRequest(request);
      setErrors([]);
      setActive(false);
      const response = await axios.post(`${urlOrder}/search`, newRequest, {
        params: { page, recordsPerPage },
      });
      // console.log("Results: ", response);

      if (response.status === 200) {
        setSearchResult([]); // This cleans the results and forces a React re-render in cases of sorting.
        setSearchResult(response.data);
        const total = parseInt(response.headers["totalamountofrecords"]);
        setTotalRecords(total);
        settotalAmountOfPages(Math.ceil(total / recordsPerPage));
        setActive(true);
        setIsLoading(false);
      }

      setCsvLoading(true);

      // Get the timezone offset and append it to the query parameters
      const timezoneOffset = getTimezoneOffset();

      const getOrdersCSV = await axios.post(
        `${urlOrder}/search/export?timezoneOffset=${timezoneOffset}`,
        newRequest
      );

      if (getOrdersCSV.status === 200) {
        setCsvLoading(false); // enable export button after csv entries are retrieved
        setCsvOrders(getOrdersCSV.data);
      }
    } catch (error: any) {
      // console.log(error);
      //setErrors(error.response.data);
      setIsLoading(false);
    }
  }

  const fieldRequired = "This field is required";
  const formikProps = useFormik({
    initialValues: searchVal,
    onSubmit: async (value) => {
      setStoredValue("orderSearch", getScrubbedRequest(value));
      setPage(1);
      setTotalRecords(0);
      settotalAmountOfPages(1);
      await PostSearch(value);
      // console.log(value);
    },
    // validationSchema: Yup.object({
    // }),
  });

  const searchOrders = async (
    request: OrderSearchRequest,
    activeSort?: number,
    sortDirection?: SortDirection
  ) => {
    const newRequest = getScrubbedRequest(request);
    const sortField =
      activeSort !== undefined && activeSort !== null ? activeSort : undefined;

    const response = await axios.post(`${urlOrder}/search`, newRequest, {
      params: { page, recordsPerPage, sortField, sortDirection },
    });

    if (response.status === 200) {
      setSearchResult(response.data);
      const total = parseInt(response.headers["totalamountofrecords"]);
      setTotalRecords(total);
      settotalAmountOfPages(Math.ceil(total / recordsPerPage));
    }
  };

  useEffect(() => {
    if (searchResult.length > 1) {
      searchOrders(
        formikProps.values,
        activeSort !== null
          ? OrderSearchSortOptions[
              activeSort as keyof typeof OrderSearchSortOptions
            ]
          : undefined,
        activeSort !== null ? activeSortDirection : undefined
      );
    }
  }, [page, recordsPerPage]);

  useEffect(() => {
    if (activeSort !== null) {
      searchOrders(
        formikProps.values,
        OrderSearchSortOptions[
          activeSort as keyof typeof OrderSearchSortOptions
        ],
        activeSortDirection
      );
    }
  }, [activeSort, activeSortDirection]);

  return (
    <>
      <FormikProvider value={formikProps}>
        <SearchFieldsContainer col={3}>
          <TextField
            name="id"
            placeholder="Order Id"
            value={formikProps.values.id}
          />
          <TextField
            name="companyName"
            placeholder="Billing Company Name"
            value={formikProps.values.companyName}
          />
          <MultiDropdown
            placeholder="Payment Method"
            options={TransactionTypeOptions}
            selectedValues={(formikProps.values.paymentMethod || []).map(
              (value) => ({
                value,
                label: TransactionType[value],
              })
            )}
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "paymentMethod",
                selectedList.map((x) => Number(x.value))
              );
            }}
          />
          <MultiDropdown
            placeholder="Payment Status"
            options={PaymentStatusOptions}
            selectedValues={(formikProps.values.paymentStatus || []).map(
              (value) => ({
                value,
                label: getPaymentStatusLabel(value),
              })
            )}
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "paymentStatus",
                selectedList.map((x) => Number(x.value))
              );
            }}
          />
          <MultiDropdown
            placeholder="Company Country"
            options={CountryOptions}
            selectedValues={(formikProps.values.companyCountry || []).map(
              (label) => ({
                label,
                value: label,
              })
            )}
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "companyCountry",
                selectedList.map((x) => x.label)
              );
            }}
          />
          <TextField
            name="orderAmount"
            placeholder="Total Amount"
            value={formikProps.values.orderAmount}
          />
          <DateTimeField
            className="flex-1 !max-w-none"
            name="startDateTime"
            placeholder="Start Date Time"
            value={formikProps.values.startDateTime}
          />
          <DateTimeField
            className="flex-1 !max-w-none"
            name="endDateTime"
            placeholder="End Date Time"
            value={formikProps.values.endDateTime}
          />
        </SearchFieldsContainer>

        <div className="flex my-[1rem] gap-[1rem] justify-end flex-wrap">
          <Button
            className="button-light w-[150px]"
            type="button"
            onClick={() => {
              formikProps.setValues(initialSearch);
              setSearchVal(initialSearch);
              setSearchResult([]);
              setActive(false);
              setStoredValue("orderSearch", initialSearch);
              setActiveSort(null);
              setActiveSortDirection(SortDirection.Asc);
              setPage(1);
            }}
          >
            Reset Search
          </Button>

          <CsvExport
            className="no-underline"
            data={csvOrders}
            // headers={csvHeaders}
            filename={`Order Search Export ${getLocalTime(new Date())}.csv`}
            disabled={csvOrders.length < 1}
          >
            <Button
              className="button-light w-[150px]"
              disabled={csvOrders.length < 1}
            >
              <div className="flex items-center gap-[.5rem]">
                Export
                {csvLoading && (
                  <img
                    className="w-[16px]"
                    src={config.assets.loading.primary}
                  />
                )}
              </div>
            </Button>
          </CsvExport>

          <Button
            className="w-[150px]"
            type="submit"
            onClick={() => {
              setIsLoading(true);
              formikProps.submitForm();
            }}
          >
            Search
          </Button>
        </div>
      </FormikProvider>

      {/* top pagination controls */}
      {isActive && (
        <AdminPageControls
          totalRecords={totalRecords}
          totalAmountOfPages={totalAmountOfPages}
          currentPage={page}
          setPage={(newPage) => setPage(newPage)}
          setIsLoading={(isLoading) => setIsLoading(isLoading)}
        />
      )}

      {!isLoading &&
        (searchResult && searchResult.length > 0 ? (
          <Table
            isActive={isActive}
            dualScroll
            // generate string[] from enum
            columnLabels={Object.keys(OrderSearchSortOptions)
              .map((field) => field)
              .splice(
                Object.keys(OrderSearchSortOptions).length / 2,
                Object.keys(OrderSearchSortOptions).length - 1
              )}
            onClickSort={(sortLabel, sortDirection) => {
              setActiveSort(sortLabel);
              setActiveSortDirection(sortDirection);
            }}
            sortTriangleSize="14px"
            {...(activeSort !== null && {
              activeSort: {
                label: activeSort,
                direction: activeSortDirection,
              },
            })}
          >
            {searchResult.map((order: OrderSearchResult) => {
              return (
                <Fragment key={order.orderId}>
                  <div className="cell">
                    <p>
                      <StyledLink
                        href={`/edit-order/${order.orderId}`}
                        target="_blank"
                      >
                        {order.orderId}
                      </StyledLink>
                    </p>
                  </div>
                  <div className="cell">
                    <p>{order.orderDate}</p>
                  </div>
                  <div className="cell">
                    <p>{order.paymentDate}</p>
                  </div>
                  <div className="cell">
                    <p>{TransactionTypeOptions[order.paymentMethod].label}</p>
                  </div>
                  <div className="cell">
                    <p>${commafy(order.amount)}</p>
                  </div>
                  <div className="cell">
                    <p>{order.billingCompany}</p>
                  </div>
                  <div className="cell">
                    <p>{order.billingCity}</p>
                  </div>
                  <div className="cell">
                    <p>{order.billingCountry}</p>
                  </div>
                  <div className="cell">
                    <p>{PaymentStatusOptions[order.paymentStatus].label}</p>
                  </div>
                </Fragment>
              );
            })}
          </Table>
        ) : (
          <TablePlaceholder active={isActive}>
            Please add a search to see result table.
          </TablePlaceholder>
        ))}

      {isLoading && (
        <TablePlaceholder active={false}>
          <Loading fullScreen={false} showLogo={false} />
        </TablePlaceholder>
      )}

      {/* bottom pagination controls */}
      {isActive && (
        <AdminPageControls
          totalRecords={totalRecords}
          totalAmountOfPages={totalAmountOfPages}
          currentPage={page}
          setPage={(newPage) => setPage(newPage)}
          setIsLoading={(isLoading) => setIsLoading(isLoading)}
        />
      )}
    </>
  );
};

export default OrderSearchTab;

interface OrderSearchRequest {
  id?: number;
  companyName?: string;
  paymentStatus?: number[];
  paymentMethod?: number[];
  companyCountry?: string[];
  orderAmount?: number;
  startDateTime?: number;
  endDateTime?: number;
}

interface OrderSearchResult {
  orderId: number;
  orderDate: string;
  paymentDate?: string;
  paymentMethod: TransactionType;
  amount: number;
  billingCompany: string;
  billingCity: string;
  billingCountry: string;
  paymentStatus: PaymentStatus;
}
function useNavigate() {
  throw new Error("Function not implemented.");
}

enum OrderSearchSortOptions {
  "Order Id",
  "Order Date",
  "Payment Date",
  "Payment Method",
  "Amount",
  "Billing Company",
  "Billing City",
  "Billing Country",
  "Payment Status",
}
