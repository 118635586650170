import styled from "styled-components";
import { useEffect, useState } from "react";
import Button from "../../../../components/Button/Button";
import { useTheme } from "styled-components";
import FieldTemplateList from "../FieldTemplateList";
import { CardBody } from "../../../../components/DashboardCard/DashboardCard";

import {
	CreditFieldTemplate,
	DateFieldTemplate,
	ListFieldTemplate,
	TextFieldTemplate,
	MediaFieldTemplate,
	PhysicalComponentFieldTemplate,
	LinkFieldTemplate,
} from "../ProgramInterfaces";

import {
	getCreditFieldTemplates,
	getDateFieldTemplates,
	getListFieldTemplates,
	getTextFieldTemplates,
	getMediaFieldTemplates,
	getPhysicalComponentFieldTemplates,
	getLinkFieldTemplates,
} from "../manageFieldTemplates";

import HierarchyNav, {
	HierarchyCard,
} from "../../../../components/HierarchyNav/HierarchyNav";

const FieldTemplateTab = styled(CardBody)`
	display: flex;
	flex-direction: column;
	gap: 1.8125rem;
`;

const RenderTemplateTab = (props: RenderTemplateTabProps) => {
	const theme = useTheme();
	const [templates, setTemplates] = useState<
		| CreditFieldTemplate[]
		| DateFieldTemplate[]
		| ListFieldTemplate[]
		| TextFieldTemplate[]
		| MediaFieldTemplate[]
		| PhysicalComponentFieldTemplate[]
		| LinkFieldTemplate[]
	>([]);

	const [addTemplateButton, setAddTemplateButton] = useState("");

	useEffect(() => {
		switch (props.formFieldType) {
			case "creditFieldForm":
				getCreditFieldTemplates()
					.then((res) => {
						// console.log("get credit templates", res);
						setTemplates(res.data);
					})
					.catch((e) => {
						// console.log("getCreditFieldTemplates ERROR", e);
					});
				setAddTemplateButton("creditFieldForm");
				break;
			case "dateFieldForm":
				getDateFieldTemplates()
					.then((res) => {
						console.log("get date templates", res);
						setTemplates(res.data);
					})
					.catch((e) => {
						console.log("getDateFieldTemplates ERROR", e);
					});
				setAddTemplateButton("dateFieldForm");
				break;
			case "listForm":
				getListFieldTemplates()
					.then((res) => {
						// console.log("get list templates", res);
						setTemplates(res.data);
					})
					.catch((e) => {
						// console.log("getListFieldTemplates ERROR", e);
					});
				setAddTemplateButton("listForm");
				break;
			case "textFieldForm":
				getTextFieldTemplates()
					.then((res) => {
						// console.log("get text templates", res);
						setTemplates(res.data);
					})
					.catch((e) => {
						// console.log("getTextFieldTemplates ERROR", e);
					});
				setAddTemplateButton("textFieldForm");
				break;
			case "mediaFieldForm":
				getMediaFieldTemplates()
					.then((res) => {
						setTemplates(res.data);
					})
					.catch((e) => {
						// console.log("getMediaFieldTemplates ERROR", e);
					});
				setAddTemplateButton("mediaFieldForm");
				break;
			case "externalLinkForm":
				getLinkFieldTemplates()
					.then((res) => {
						// console.log("get link templates", res);
						setTemplates(res.data);
					})
					.catch((e) => {
						// console.log("getLinkFieldTemplates ERROR", e);
					});
				setAddTemplateButton("externalLinkForm");
				break;
			case "physicalEntriesForm":
				getPhysicalComponentFieldTemplates()
					.then((res) => {
						// console.log("get phsyical templates", res);
						setTemplates(res.data);
					})
					.catch((e) => {
						// console.log("getphsyicalFieldTemplates ERROR", e);
					});
				setAddTemplateButton("physicalEntriesForm");
				break;
		}
	}, []);

	return (
		<FieldTemplateTab>
			<Button
				className="ml-auto"
				iconLeft="plus"
				iconColor={theme.colorCopyLightLight}
				onClick={() => props.onClickNewField(addTemplateButton)}
			>
				Add New Template
			</Button>
			{templates && templates.length > 0 && (
				<HierarchyNav
					responsive={true}
					columns={[
						{ label: null, width: "150px" },
						{ label: "Created Date" },
						{ label: "Credit Type" },
						{ label: "Admin Help Text" },
						{ label: "Status" },
					]}
				>
					{(gridColumns: string, gridArea: string) =>
						templates.map((template, index) => (
							<HierarchyCard
								responsive={true}
								key={index}
								gridColumns={gridColumns}
								gridArea={gridArea}
							>
								<FieldTemplateList
									fieldName={template.name}
									createdDate={template.createdDate}
									adminHelpText={template.adminHelpText}
									isActive={template.isActive}
									openModal={() => props.onClickEditField(template)}
								/>
							</HierarchyCard>
						))
					}
				</HierarchyNav>
			)}
		</FieldTemplateTab>
	);
};

export default RenderTemplateTab;

interface RenderTemplateTabProps {
	formFieldType: string;
	onClickEditField(
		templateData:
			| CreditFieldTemplate
			| DateFieldTemplate
			| ListFieldTemplate
			| TextFieldTemplate
			| MediaFieldTemplate
			| PhysicalComponentFieldTemplate
			| LinkFieldTemplate
	): void;
	onClickNewField(formFieldType: string): void;
}
