import styled, { useTheme } from "styled-components";
import Button from "../../components/Button/Button";
import CheckoutEntryCard, {
  EntryCardProps,
} from "../../components/CheckoutEntryCard/CheckoutEntryCard";
import CompanyIcon from "../../assets/placeholders/placeholder.png";
import { useContext, useEffect, useState } from "react";
import {
  StickyCardBody,
  StickyHeaderBar,
  StickyContainer,
} from "../../components/StickyCard/StickyCard";
import RadioField from "../../components/FormFields/RadioField";
import { ListContainer } from "../../components/FormFields/RadioField";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import { PaymentProps } from "./Payment";
import { UserPageContainer } from "../../components/UserPageTemplate/UserPageTemplate";
import Icon from "../../components/Icon/Icon";
import { EntryModel } from "../Entries/EntryInterfacesComplete";
import {
  createOrderandPdf,
  getCheckoutEntries,
  getCheckoutEntryCards,
  getDeadlinePrice,
  getFinalPrice,
  removeFromCart,
  submitProject,
} from "./manageCheckout";
import { OrderLineItem, OrderModel, TransactionType } from "./OrderInterfaces";
import { element } from "prop-types";

import { useHistory } from "react-router-dom";
import { useSettings } from "../../hooks/UseSettings";
import { useCompany } from "../../hooks/UseCompany";
import { WarningModal } from "../../components/Modal/Modal";
import HierarchyDropDownModal from "../../components/HierarchyDropDownModal/HierarchyDropDownModal";
import { EntryAction } from "../../components/HierarchyDropDownModal/HierarchyDropDown";
import SubmissionLightbox from "./SubmisisonLightbox";
import { resolveTypeReferenceDirective } from "typescript";
import { CollapsibleContainer } from "../../components/Collapsible/Collapsible";
import { CheckoutEntryCardAlertContext } from "./CheckoutContext";
import config from "../../config";
import assetsConfig from "../../assetsConfig";
import { commafy } from "../../utils/commafy";
import { useAlert } from "../../components/Alert/Alerts";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

export const OrderSummary = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2.25rem 6rem;
  /* margin-bottom: 2rem; */
  background: ${({ theme }) => theme.colorBackgroundLight};
  box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
  z-index: 999;
`;

const LoadingSpinner = styled.img`
  width: 11%;
  display: inline-flex;
`;

export const CartTab = () => {
  const theme = useTheme();
  const history = useHistory();
  const { company, setCompany } = useCompany();
  const { addCounter } = useContext(CheckoutEntryCardAlertContext);

  // const orderTitles: any = {};
  const [transactionType, setTransactionType] = useState<TransactionType>(
    TransactionType.Credit
  );
  const { addNewAlert } = useAlert();

  const [orderAmount, setOrderAmount] = useState<number>(0);
  const [awardGroups, setAwardGroups] = useState<AwardGroups>({});
  const [orderTotal, setOrderTotal] = useState(0);
  const [itemText, setItemText] = useState("");
  const [itemCount, setItemCount] = useState(0);
  const [cartEntries, setCartEntries] = useState<EntryCardProps[]>([]);
  const { settings } = useSelector((state: RootState) => state.settings);
  const [entryChangeModal, setEntryChangeModal] = useState(false);
  const [submissionSubmitting, setSubmissionSubmitting] = useState(false);

  // all payments are disabled until payment threshold/override setting is retrieved
  const [isCreditPaymentDisabled, setIsCreditPaymentDisabled] = useState(true);
  const [isCheckPaymentDisabled, setIsCheckPaymentDisabled] = useState(true);
  const [isBankPaymentDisabled, setIsBankPaymentDisabled] = useState(true);

  const [entryIdToDuplicate, setEntryIdToDuplicate] = useState<null | number>(
    null
  );

  const [order, setOrder] = useState<OrderModel>({
    lineItems: [],
    statusId: 0,
    transactionType: transactionType,
    bankCity: "",
    bankName: "",
    cardInfo: {
      cardCode: "",
      cardNumber: "",
      expirationDate: "",
    },
    notes: "",
    orderAmount: orderAmount,
    companyAddress1: company.address1 || "",
    companyCity: company.city || "",
    companyCountry: company.country || "",
    companyName: company.name,
    companyPostalCode: company.postalCode || "",
    companyState: company.state || "",
    email: company.financeContactEmail || "",
    phone: company.financeContactPhone || "",
    companyAddress2: company.address2 || "",
    fullName: company.financeContactName || "",
  });

  function getOrderTotal(type: TransactionType) {
    if (type == TransactionType.Bank) {
      setOrderTotal(orderAmount + 25);
    } else {
      setOrderTotal(orderAmount);
    }
    setTransactionType(type);
  }

  useEffect(() => {
    if (transactionType == TransactionType.Bank) {
      setOrderTotal(orderAmount + 25);
    } else {
      setOrderTotal(orderAmount + 0);
    }
  }, [transactionType, orderAmount]);

  //   const [entriesCollapsed, setEntriesCollapsed] = useState<{
  //     [awardName: string]: boolean;
  //   }>({});

  //   useEffect(() => {
  //     setEntriesCollapsed(
  //       Object.keys(awardGroups).reduce((acc: any, element) => {
  //         acc[element] = false;
  //         return acc;
  //       }, {})
  //     );
  //   }, [awardGroups]);

  const [errors, setErrors] = useState<string[]>([]);

  async function PostCheckout(CheckoutData: any) {}

  const fieldRequired = "This field is required";
  const formikProps = useFormik({
    initialValues: {
      paymentMethod: "credit-card",
    },
    onSubmit: async (value) => {
      await PostCheckout(value);
      // console.log(value);
    },
    validationSchema: Yup.object({
      award: Yup.string().required(fieldRequired),
    }),
  });

  //   const handleAccordion = (clickedElement: string) => {
  //     const obj: any = {};
  //     Object.entries(entriesCollapsed).forEach(
  //       ([awardName, isCollapsed]) =>
  //         (obj[awardName] = clickedElement === awardName ? !isCollapsed : true)
  //     );
  //     setEntriesCollapsed(obj);
  //   };

  useEffect(() => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      companyAddress1: company.address1 || "",
      companyCity: company.city || "",
      companyCountry: company.country || "",
      companyName: company.name,
      companyPostalCode: company.postalCode || "",
      companyState: company.state || "",
      email: company.financeContactEmail || "",
      phone: company.financeContactPhone || "",
      companyAddress2: company.address2 || "",
      fullName: company.financeContactName || "",
    }));
  }, [company]);

  const handleGetCheckoutEntryCards = () => {
    return getCheckoutEntryCards().then((response) => {
      if (response.status == 200) {
        //console.log("Response: ", response);
        let tempAwardGroups: AwardGroups = {};
        let tempLineItems: LineItem[] = [];
        let tempOrderAmount = 0;
        if (response.data.length === 1) {
          setItemCount(response.data.length);
          if (!settings.featureFlags.Commerce) {
            setItemText(response.data.length + " Project Ready for Submission");
          } else {
            setItemText(response.data.length + " item in your cart");
          }
        } else {
          setItemCount(response.data.length);
          if (!settings.featureFlags.Commerce) {
            setItemText(
              response.data.length + " Projects Ready for Submission"
            );
          } else {
            setItemText(response.data.length + " items in your cart");
          }
        }
        setCartEntries(response.data);
        let orderLineItems: OrderLineItem[] = [];
        response.data.forEach((entry: EntryCardProps) => {
          let lineItem: LineItem = {
            awardName: entry.award!,
            totalLineAmount: getFinalPrice(
              getDeadlinePrice(entry.deadlines, entry.adjustment),
              entry.isCampaign!
            ),
            entry: entry,
          };
          let orderLineItem: OrderLineItem = {
            entryId: entry.id,
            totalLineAmount: getFinalPrice(
              getDeadlinePrice(entry.deadlines!, entry.adjustment),
              entry.isCampaign!
            ),
            entry: entry,
          };
          orderLineItems.push(orderLineItem);

          tempLineItems.push(lineItem);
          tempOrderAmount = tempOrderAmount + lineItem.totalLineAmount;

          setOrder((order) => {
            return {
              ...order,
              lineItems: orderLineItems,
            };
          });
        });
        const groups: AwardGroups = tempLineItems.reduce((groups, lineItem) => {
          const group = groups[lineItem.awardName] || [];
          group.push(lineItem);
          groups[lineItem.awardName] = group;
          return groups;
        }, tempAwardGroups);
        setAwardGroups(groups);
        setOrderAmount(tempOrderAmount);
        if (tempOrderAmount === 0 && response.data.length >= 1) {
          setTransactionType(TransactionType.Submission);
        }

        // else {
        //   formikProps.setFieldValue("paymentMethod", "");
        //   setTransactionType(TransactionType.Unassigned);
        // }

        return true;
      } else return false;
    });
  };

  const goToCheckout = async (entries: EntryCardProps[]) => {
    let closedEntries: boolean = false;
    entries.map((entry) => {
      if (entry.programEntriesClosed) {
        removeFromCart(entry.id).catch((e) => {
          if (!settings.featureFlags.Commerce) {
            addNewAlert({
              type: "error",
              message: "Unable to send to drafts",
            });
          } else {
            addNewAlert({
              type: "error",
              message: "Unable to remove from cart",
            });
          }
        });
        closedEntries = true;
      }
    });

    if (!closedEntries) {
      if (!settings.featureFlags.Commerce) {
        entries.map((entry) => {
          submitProject(entry.id).catch((e) => {
            addNewAlert({
              type: "error",
              message: "Unable to submit",
            });
          });
        });
      } else if (orderTotal === 0) {
        setSubmissionSubmitting(true);
        let updatedOrder = {
          ...order,
          transactionType: TransactionType.Submission,
        };

        setOrder(updatedOrder);

        try {
          // show confirmation modal once pdf is created
          const processedOrder = await createOrderandPdf(updatedOrder);

          // console.log("AAAAAAAAAAA createOrderandPdf", processedOrder);
          processedOrder.paymentDate = new Date(processedOrder.paymentDate);
          // console.log("processedOrder.data", processedOrder.data);

          if (!processedOrder.data.isError) {
            setOrder(processedOrder.data);
            setSubmissionSubmitting(false);
          } else {
            addNewAlert({
              type: "error",
              message: processedOrder.data.authMessage,
            });
          }
          // order.data.isError === false
          // 	? setOrder(order.data.order)
          // 	: setErrors(order.data.authMessage);
        } catch (err) {
          addNewAlert({ type: "error", message: String(err) });
        }
      } else {
        history.push({
          pathname: `/payment/${TransactionType[transactionType]}`,
          state: { orderTotal: orderTotal },
        });
      }
    } else {
      setEntryChangeModal(true);
    }
  };

  useEffect(() => {
    handleGetCheckoutEntryCards();
  }, [entryChangeModal, settings]);

  // toggles payment options depending on total order price and payment threshold
  useEffect(() => {
    // wait for settings to load
    if (settings && Object.values(settings).length > 0) {
      if (!settings.paymentThreshold || company.overridePaymentMethod) {
        setIsCreditPaymentDisabled(false);
        setIsBankPaymentDisabled(false);
        setIsCheckPaymentDisabled(false);
      } else {
        if (orderTotal < settings.paymentThreshold) {
          formikProps.setFieldValue("paymentMethod", "credit-card");
          setIsCreditPaymentDisabled(false);
          setIsBankPaymentDisabled(true);
          setIsCheckPaymentDisabled(true);
        } else {
          //   formikProps.setFieldValue("paymentMethod", "");
          setIsCreditPaymentDisabled(false);
          setIsBankPaymentDisabled(false);
          setIsCheckPaymentDisabled(false);
        }
      }
    }
  }, [orderTotal]);

  // update user menu counters
  useEffect(() => {
    addCounter();
  }, [awardGroups]);

  return (
    <>
      <WarningModal
        show={entryChangeModal}
        title="Entry Change"
        message="Some entries in your cart cannot be submitted because entries are closed. They have been removed from your cart. Please reach out if you believe this is an error using the Help button below."
        close={() => setEntryChangeModal(false)}
      >
        <Button
          className="w-[225px]"
          onClick={() => setEntryChangeModal(false)}
          icon="check"
        >
          OK
        </Button>
      </WarningModal>
      {order.id && <SubmissionLightbox order={order} show={order !== null} />}
      <HierarchyDropDownModal
        entryId={entryIdToDuplicate}
        setEntryId={setEntryIdToDuplicate}
        action={EntryAction.Duplicate}
      />
      <FormikProvider value={formikProps}>
        <h2>
          {!settings.featureFlags.Commerce ? "Ready to Submit" : "My Cart"}
        </h2>
        <p className="mb-[3rem] mt-[1rem]">
          {assetsConfig.copy.cartEntryDescription}
        </p>
        <OrderSummary>
          <div className="flex flex-col gap-[1rem]">
            <h2>
              {!settings.featureFlags.Commerce ? "Ready to Submit" : "My Cart"}
            </h2>
            <p>{itemText}</p>
          </div>
          <div className="flex gap-[3rem]">
            {orderTotal !== 0 && (
              <ListContainer rows={3} cols={1} name="paymentMethod">
                <RadioField
                  name="paymentMethod"
                  label="Credit Card Payment"
                  value="credit-card"
                  onClick={() => getOrderTotal(TransactionType.Credit)}
                  disabled={isCreditPaymentDisabled}
                />
                <RadioField
                  name="paymentMethod"
                  label="Check Payment"
                  value="check"
                  onClick={() => getOrderTotal(TransactionType.Check)}
                  disabled={isCheckPaymentDisabled}
                />
                <RadioField
                  name="paymentMethod"
                  label="Bank Transfer +$25.00 Fee"
                  value="bank"
                  onClick={() => getOrderTotal(TransactionType.Bank)}
                  disabled={isBankPaymentDisabled}
                />
              </ListContainer>
            )}

            <div className="flex flex-col mt-auto gap-[1rem]">
              {orderTotal !== 0 && (
                <h2 className="whitespace-nowrap">
                  Order Total: <b>${commafy(orderTotal)}</b>
                </h2>
              )}
              <Button
                disabled={
                  ((transactionType === TransactionType.Unassigned ||
                    itemCount === 0) &&
                    transactionType !== TransactionType.Submission) ||
                  submissionSubmitting
                }
                icon={submissionSubmitting ? "" : "caret"}
                iconRight={true}
                iconRotation="-90deg"
                iconColor={
                  transactionType === TransactionType.Unassigned &&
                  itemCount !== 0
                    ? theme.colorCopyLightLight
                    : undefined
                }
                onClick={() => {
                  if (!settings.featureFlags.Commerce) {
                    goToCheckout(cartEntries).then(() => {
                      history.push("/entries/entries-search");
                    });
                  } else {
                    goToCheckout(cartEntries);
                  }
                }}
                // to={`/payment/${TransactionType[transactionType]}`}
              >
                {submissionSubmitting ? (
                  <LoadingSpinner src={config.assets.loading.primary} />
                ) : orderTotal !== 0 ? (
                  "Proceed to Payment"
                ) : !settings.featureFlags.Commerce ? (
                  "Submit Projects"
                ) : (
                  "Submit Entries"
                )}
              </Button>
            </div>
          </div>
        </OrderSummary>
        <StickyContainer className="mb-[200px]" maxH={1000 * 3000 + "px"}>
          {Object.entries(awardGroups).flatMap(([awardName, lineItems], i) => {
            if (lineItems !== null && lineItems.length > 0) {
              return (
                <CollapsibleContainer
                  key={i}
                  className="mb-[2rem]"
                  title={awardName}
                  //   isCollapsed={entriesCollapsed[awardName]}
                  //   onClickCollapse={() => handleAccordion(awardName)}
                >
                  <StickyCardBody
                    //   key={i}
                    className="flex flex-col gap-[1.875rem]"
                  >
                    {lineItems.map((lineItem) => (
                      <CheckoutEntryCard
                        key={lineItem.entry.id}
                        entry={lineItem.entry}
                        awardName={lineItem.awardName}
                        showRemoveCartButton={true}
                        setEntryIdToDuplicate={setEntryIdToDuplicate}
                        onRemove={() => {
                          // wait for entry cards to update before showing alert
                          handleGetCheckoutEntryCards().then((res) => {
                            if (res) {
                              if (!settings.featureFlags.Commerce) {
                                addNewAlert({
                                  type: "success",
                                  message: "Successfully sent to drafts",
                                });
                              } else {
                                addNewAlert({
                                  type: "success",
                                  message: "Successfully removed from cart",
                                });
                              }
                            }
                          });
                        }}
                      />
                    ))}
                  </StickyCardBody>
                </CollapsibleContainer>
              );
            }
          })}
        </StickyContainer>
      </FormikProvider>
    </>
  );
};

export default CartTab;

interface AwardGroups {
  [key: string]: LineItem[];
}

interface LineItem {
  awardName: string;
  entry: EntryCardProps;
  totalLineAmount: number;
}
