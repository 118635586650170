import { AxiosResponse } from "axios";
import { FormikProvider, useFormik } from "formik";
import { Link } from "react-router-dom";
import {
	Fragment,
	useEffect,
	useLayoutEffect,
	useState,
	useContext,
} from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import {
	CardBody,
	CardContainer,
	CardHeader,
} from "../../../components/DashboardCard/DashboardCard";
import Loading from "../../../components/Loading/Loading";
import {
	getHierarchy,
	getOrder,
	updateOrder,
} from "../../Checkout/manageCheckout";
import {
	CheckStatus,
	CheckStatusOptions,
	OrderLineItem,
	OrderModel,
	PaymentStatus,
	PaymentStatusOptions,
	ReviewStatusOptions,
	TransactionType,
	TransactionTypeOptions,
} from "../../Checkout/OrderInterfaces";
import * as Yup from "yup";
import styled, { useTheme } from "styled-components";
import StyledForm from "../../../components/Form/Form";
import TextField from "../../../components/FormFields/TextField";
import Button from "../../../components/Button/Button";
import DropdownField from "../../../components/FormFields/DropdownField";
import { USStateOptions } from "../../../data/StateData";
import { CountryOptions } from "../../../data/CountryData";
import DownloadPDF from "../../../components/DownloadPDF/DownloadPDF";
import { getThumbnailSrc } from "../../MediaLibrary/manageMediaLibrary";
import {
	StickyCardBody,
	StickyContainer,
} from "../../../components/StickyCard/StickyCard";
import { CollapsibleContainer } from "../../../components/Collapsible/Collapsible";
import Icon from "../../../components/Icon/Icon";
import { EntryReviewContainer, EntryField } from "../../Entries/EntryForm";
import Placeholder from "../../../assets/placeholders/placeholder.png";

import Table from "../../../components/Table/Table";
import DownloadPhysicalEntryPDF from "./DownloadPhysicalEntryPDF";
import { programOptions } from "../../Entries/EntriesTestData";
import ProgramDisplayForm from "../Program/ProgramDisplayForm";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { commafy } from "../../../utils/commafy";
import { useAlert } from "../../../components/Alert/Alerts";
import { getLocalTime } from "../../../utils/timeUtilities";
import DateField from "../../../components/FormFields/DateField";

const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.colorActivation};
`;

const PaymentInfo = styled.div`
	padding: 2.25rem;
	border: solid 2px ${({ theme }) => theme.colorBorderLight};
	background: ${({ theme }) => theme.colorBackgroundLightLight};
`;

const ButtonContainer = styled.div`
	span {
		white-space: pre-wrap !important;
	}
`;

const EditOrder = () => {
	const { addNewAlert } = useAlert();
	const theme = useTheme();
	const { orderId } = useParams<{ orderId: string }>();
	const history = useHistory();
	const [order, setOrder] = useState<OrderModel>({
		companyAddress1: "",
		companyCity: "",
		companyCountry: "",
		companyName: "",
		companyPostalCode: "",
		companyState: "",
		email: "",
		fullName: "",
		lineItems: [],
		orderAmount: 0,
		phone: "",
		statusId: 0,
		transactionType: TransactionType.Credit,
		bankCity: "",
		bankName: "",
		companyAddress2: "",
		notes: "",
		hasPhysicalComponent: false,
	});

	async function UpdateOrder(value: OrderModel) {
		try {
			await updateOrder(value).then((response) => {
				if (response.status === 204) {
					// console.log("UpdateOrder Response: ", response);
					addNewAlert({ type: "success", message: "Order Updated" });
				}
			});
		} catch (error: any) {
			// console.log(error);
			addNewAlert({ type: "error", message: error.response.data });
		}
	}

	useEffect(() => {
		getOrder(parseInt(orderId))
			.then((response) => {
				if (response.status === 200) {
					setOrder(response.data);
				}
			})
			.catch((error) => {
				// console.log("getOrder ERROR", error);
				// setErrors((old) => [...old, error]);
			});
	}, []);

	const fieldRequired = "This field is required";
	const formikProps = useFormik({
		initialValues: order,
		enableReinitialize: true,
		onSubmit: async (value) => {
			await UpdateOrder(value);
			// console.log(value);
		},
		validationSchema: Yup.object({
			fullName: Yup.string().required(fieldRequired).firstLetterUppercase(),
			email: Yup.string().email().required(fieldRequired),
			phone: Yup.string().required(fieldRequired),
			companyName: Yup.string().required(fieldRequired),
			companyAddress1: Yup.string().required(fieldRequired),
			companyCity: Yup.string().required(fieldRequired),
			companyCountry: Yup.string().required(fieldRequired),
		}),
	});

	return (
		(formikProps && formikProps.values.id && (
			<CardContainer>
				<BrowserHeader title={`Order: [${formikProps.values.id}]`} />
				<CardHeader>
					<h2 className="card-title">Update Order</h2>
				</CardHeader>
				<CardBody>
					<FormikProvider value={formikProps}>
						<StickyContainer className="mb-[2rem] !relative !top-0 !min-h-min">
							<CollapsibleContainer
								className="mb-[2rem]"
								title="Payment Information"
							>
								<StickyCardBody className="!p-0">
									<PaymentInfo>
										<div className="flex gap-[3.5rem]">
											<StyledForm className="twoColumn mb-[1rem]" compact>
												<h2 className="font-semibold">
													Billing Contact Details
												</h2>
												<div className="col-w-100">
													<TextField
														name="fullName"
														placeholder="Full Name*"
														value={formikProps.values.fullName}
													/>
												</div>

												<TextField
													name="email"
													placeholder="Email Address*"
													value={formikProps.values.email}
												/>

												<TextField
													name="phone"
													placeholder="Phone Number*"
													value={formikProps.values.phone}
												/>
												<div className="col-w-100">
													<TextField
														name="companyName"
														placeholder="Company Name*"
														value={formikProps.values.companyName}
													/>
												</div>
												<div className="col-w-100">
													<TextField
														name="companyAddress1"
														placeholder="Address Line 1*"
														value={formikProps.values.companyAddress1}
													/>
												</div>
												<div className="col-w-100">
													<TextField
														name="companyAddress2"
														placeholder="Address Line 2"
														value={formikProps.values.companyAddress2}
													/>
												</div>

												<TextField
													name="companyCity"
													placeholder="Company City*"
													value={formikProps.values.companyCity}
												/>
												<DropdownField
													name="companyState"
													placeholder="Company State"
													options={USStateOptions}
													value={formikProps.values.companyState}
												/>
												<TextField
													name="companyPostalCode"
													placeholder="Company Zip Code"
													value={formikProps.values.companyPostalCode}
												/>
												<DropdownField
													name="companyCountry"
													placeholder="Company Country*"
													options={CountryOptions}
													value={formikProps.values.companyCountry}
												/>
												<div className="col-w-100">
													<TextField
														name="notes"
														placeholder="Invoice Notes"
														component="textarea"
														height="200px"
														value={formikProps.values.notes}
													/>
												</div>
												<DropdownField
													name="transactionType"
													placeholder="Payment Method"
													readOnly={true}
													options={TransactionTypeOptions}
													value={formikProps.values.transactionType}
												/>

												<DropdownField
													name="statusId"
													placeholder="Order Status"
													readOnly={true}
													options={PaymentStatusOptions}
													value={formikProps.values.statusId}
												/>
											</StyledForm>

											{order.transactionType === TransactionType.Check && (
												<StyledForm
													className="twoColumn mb-[1rem] h-fit"
													compact
												>
													<h2 className="font-semibold">Check Information</h2>
													<div className="col-w-100">
														<TextField
															name="checkNumber"
															placeholder="Check Number"
															value={formikProps.values.checkNumber}
														/>
													</div>

													<div className="col-w-100 !grid grid-cols-2 flex-wrap gap-[2rem]">
														<DropdownField
															name="checkStatus"
															placeholder="Check Status"
															options={CheckStatusOptions}
															value={formikProps.values.checkStatus}
														/>

														<DateField
															className="flex-1 !max-w-none"
															name="paymentDate"
															id="paymentDate1"
															placeholder="Payment Date"
															value={formikProps.values.paymentDate}
														/>
													</div>
												</StyledForm>
											)}

											{order.transactionType === TransactionType.Bank && (
												<StyledForm
													className="twoColumn mb-[1rem] h-fit"
													compact
												>
													<div className="col-w-100">
														<h2 className="font-semibold">Bank Information</h2>
													</div>

													<TextField
														name="bankName"
														placeholder="Bank Name"
														value={formikProps.values.bankName}
													/>

													<TextField
														name="bankCity"
														placeholder="Bank City"
														value={formikProps.values.bankCity}
													/>

													<DateField
														className="!max-w-none"
														name="paymentDate"
														id="paymentDate2"
														placeholder="Payment Date"
														value={formikProps.values.paymentDate}
													/>
												</StyledForm>
											)}
										</div>

										<ButtonContainer className="w-fit grid grid-cols-1 lg:grid-cols-2 justify-end gap-[1rem] mt-[1rem] ml-auto">
											<Button
												className="button-light"
												iconLeft="download"
												iconColor={theme.colorActivation}
												download={formikProps.values.invoicePDF}
											>
												Download Original Invoice
											</Button>
											<Button
												iconLeft="check"
												iconColor={theme.colorCopyLightLight}
												disabled={
													order.transactionType === TransactionType.Credit ||
													formikProps.values.statusId === PaymentStatus.Paid
												}
												onClick={() => {
													formikProps.setFieldValue(
														"statusId",
														PaymentStatus.Paid
													);
												}}
											>
												Confirm Payment
											</Button>
											{order.hasPhysicalComponent && (
												<DownloadPhysicalEntryPDF
													order={formikProps.values as OrderModel}
													title="Download Physical Component PDF"
													iconColor={theme.colorActivation}
												/>
											)}
											<DownloadPDF
												order={formikProps.values as OrderModel}
												title="Generate New Invoice"
											/>
											<Button
												onClick={formikProps.submitForm}
												disabled={
													formikProps.values.fullName === "" ||
													formikProps.values.email === "" ||
													formikProps.values.phone === "" ||
													formikProps.values.companyName === "" ||
													formikProps.values.companyAddress1 === "" ||
													formikProps.values.companyCity === "" ||
													formikProps.values.companyCountry === "" ||
													formikProps.isSubmitting
												}
											>
												Update Order
											</Button>
										</ButtonContainer>
									</PaymentInfo>
								</StickyCardBody>
							</CollapsibleContainer>
							<CollapsibleContainer
								className="mb-[2rem]"
								title="Order Metadata"
								isCollapsed
							>
								<StickyCardBody className="!p-0">
									<EntryReviewContainer className="p-[2.25rem]">
										<EntryField
											label="Date Created"
											value={
												formikProps.values.createdDate
													? getLocalTime(formikProps.values.createdDate)
													: ""
											}
											isRequired={false}
										/>
										<EntryField
											label="Date Last Modified"
											value={
												formikProps.values.updatedDate
													? getLocalTime(formikProps.values.updatedDate)
													: ""
											}
											isRequired={false}
										/>
										<EntryField
											label="Last Modified User"
											value={formikProps.values.modifiedByName}
											hardLink={`/edit-user/${formikProps.values.modifiedBy}`}
											isRequired={false}
										/>
										<EntryField
											label="Order Id"
											value={formikProps.values.id}
											isRequired={false}
										/>
										<EntryField
											label="Order Total"
											value={"$" + commafy(formikProps.values.orderAmount)}
											isRequired={false}
										/>
										<EntryField
											label="Order Date"
											value={
												formikProps.values.createdDate
													? getLocalTime(formikProps.values.createdDate)
													: ""
											}
											isRequired={false}
										/>
										<EntryField
											label="Entrant Company Name"
											value={formikProps.values.companyName}
											hardLink={`/edit-company/${formikProps.values.companyId}`}
											isRequired={false}
										/>
									</EntryReviewContainer>
								</StickyCardBody>
							</CollapsibleContainer>
							<CollapsibleContainer
								className="mb-[2rem]"
								title="Entries"
								isCollapsed
							>
								<StickyCardBody className="!p-0">
									{formikProps.values.lineItems &&
										formikProps.values.lineItems.length > 0 && (
											<Table
												isActive
												dualScroll
												columnLabels={[
													"Cover Image",
													"Entry Id",
													"Title",
													"Level 1",
													"Level 2",
													"Level 3",
													"Level 4",
													"Level 5",
													"Payment Status",
													"Review Status",
													"Execution Count",
												]}
											>
												{formikProps.values.lineItems.map(
													(lineItem: OrderLineItem, index) => {
														const entry = lineItem.entry;
														const hierarchy = getHierarchy(
															entry.executions || [],
															entry.ancestry || [],
															entry.isCampaign || false
														);

														return (
															<Fragment key={entry.id}>
																<div className="cell">
																	<img
																		src={
																			(entry.coverImage &&
																				getThumbnailSrc(entry.coverImage!)) ||
																			Placeholder
																		}
																		alt="Entry Cover Image"
																	/>
																</div>
																<div className="cell">
																	<p>
																		<StyledLink
																			to={`/admin/edit-entry?entryId=${entry.id}`}
																		>
																			{entry.id}
																		</StyledLink>
																	</p>
																</div>
																<div className="cell">
																	<p>
																		<StyledLink
																			to={`/admin/edit-entry?entryId=${entry.id}`}
																		>
																			{entry.title}
																		</StyledLink>
																	</p>
																</div>
																<div className="cell">
																	<p>{hierarchy.at(0) || ""}</p>
																</div>
																<div className="cell">
																	<p>{hierarchy.at(1) || ""}</p>
																</div>
																<div className="cell">
																	<p>{hierarchy.at(2) || ""}</p>
																</div>
																<div className="cell">
																	<p>{hierarchy.at(3) || ""}</p>
																</div>
																<div className="cell">
																	<p>{hierarchy.at(4) || ""}</p>
																</div>
																<div className="cell">
																	<p>
																		{
																			PaymentStatusOptions[
																				entry.paymentStatusId!
																			].label
																		}
																	</p>
																</div>
																<div className="cell">
																	<p>
																		{
																			ReviewStatusOptions[entry.reviewStatusId!]
																				.label
																		}
																	</p>
																</div>
																<div className="cell">
																	<p>
																		{(entry.executions &&
																			entry.executions.length) ||
																			"0"}
																	</p>
																</div>
															</Fragment>
														);
													}
												)}
											</Table>
										)}
								</StickyCardBody>
							</CollapsibleContainer>
						</StickyContainer>
					</FormikProvider>
				</CardBody>
			</CardContainer>
		)) || (
			<>
				<Loading />
			</>
		)
	);
};

export default EditOrder;

interface State {
	order: number;
}
