import styled, { css, useTheme } from "styled-components";
import TextField from "../FormFields/TextField";
import cn from "classnames";
import CheckboxField from "../FormFields/CheckboxField";
import Collapsible from "../Collapsible/Collapsible";
import { useContext, useEffect, useState } from "react";
import Icon from "../Icon/Icon";
import {
	MediaItem,
	MediaType,
} from "../../views/MediaLibrary/mediaLibrary.model.d";
import { KBtoMB } from "../../utils/KBtoMB";
import {
	getMediaSrc,
	getPlaceholder,
	getThumbnailSrc,
	ThumbnailSize,
} from "../../views/MediaLibrary/manageMediaLibrary";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack"; // https://github.com/wojtekmaj/react-pdf/issues/97
import { Direction } from "../AnimateReorder/AnimateReorder";
import MediaEnlargeModal from "../MediaEnlargeModal/MediaEnlargeModal";
import DocumentPlaceholder from "../../assets/placeholders/document-landscape.png";
import Image from "../Image/Image";
import { getClaims } from "../Auth/handleJWT";
import { updateHideMediaForWinnersGallery } from "../../views/Entries/manageEntry";
import Button from "../Button/Button";
import useDownloadMedia from "../../utils/useDownloadMedia";
import Download from "react-csv/components/Download";
import { DownloadMediaButton } from "../DownloadMedia/DownloadMedia";
import addEllipsisAfterLetterCount from "../../utils/addEllipsisAfterLetterCount";
import HideMediaButton from "../HideMediaButton/HideMediaButton";
import config from "../../config";
import { useAlert } from "../Alert/Alerts";
import { getLocalTime } from "../../utils/timeUtilities";
import Authorized from "../Auth/Authorized";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const SpinnerSrc = config.assets.loading.primary;

const StyledDocument = styled(Document)`
	.react-pdf__Page__canvas {
		width: 85px !important;
		height: 50px !important;
	}

	.react-pdf__Page__textContent,
	.react-pdf__Page__annotations {
		display: none !important;
	}
`;

const HiddenAnnotationsDoc = styled(Document)`
	.react-pdf__Page__annotations {
		display: none !important;
	}
`;

export const DragCard = styled.div<{
	className?: string;
	hideOnDrag?: boolean;
	disabled?: boolean; // disablesDrag
	readOnly?: boolean;
}>`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.75rem;

	border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
	background: ${({ theme }) => theme.colorBackgroundLight};
	/* max-height: 500px; */
	cursor: move;

	${(p) =>
		p.disabled &&
		css`
			cursor: not-allowed;
		`}

	${(p) =>
		p.readOnly &&
		css`
			cursor: default;
		`}


  ${(p) =>
		p.hideOnDrag &&
		css`
			display: none;
		`}

  .editable-credit-fields {
		display: none;
	}

	&.dropped {
		.credit-info {
			flex: 1;
			width: 100% !important;
		}

		.media-file-name,
		.credit-field p {
			max-width: unset;
		}

		.credit-field p {
			white-space: pre-wrap;
		}

		.drag-arrows {
			display: none;
		}
	}

	.credit-info {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		width: 100%;

		.light {
			color: ${({ theme }) => theme.colorCopyLight};
		}

		p.font-semibold {
			font-size: ${({ theme }) => theme.xxSmallSize};
		}

		p:not(.font-semibold) {
			font-size: ${({ theme }) => theme.xxxSmallSize};
		}

		.credit-field {
			flex: 1;
		}
	}

	.drag-icons {
		display: flex;
		align-items: center;

		.drag-arrows {
			margin-right: 1.25rem;
		}
	}

	.triangle,
	.caret-down,
	.close-icon {
		display: none;
		cursor: pointer;
	}

	.icon-container {
		display: none;
	}

	p {
		max-width: 215px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		line-height: 1rem;

		&.media-type,
		&.upload-date {
			font-size: ${({ theme }) => theme.xxSmallSize};
		}

		&.media-type {
			width: 110px;
		}

		&.media-file-name {
			max-width: 300px;
			font-size: ${({ theme }) => theme.xSmallSize};
			font-weight: ${({ theme }) => theme.fontSemiBold};
			white-space: pre-wrap;
			overflow-wrap: anywhere;
		}
	}

	.checkbox-field {
		display: flex;
		justify-content: center;
		margin-left: auto;
		input {
			margin-right: 0;
		}
	}

	&.moveDown {
		transition: transform 150ms ease;
		transform: translateY(75px);
	}

	&.moveUp {
		transition: transform 150ms ease;
		transform: translateY(-75px);
	}
`;

export const TagsContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.625rem;

	.tag-bubble {
		text-align: center;
		padding: 5.25px 30.25px;
		border-radius: 999px;
		border: 1px solid ${({ theme }) => theme.colorBorder};
	}

	p {
		width: initial;
	}
`;

export const DragMediaAccordion = (props: DragMediaAccordionProps) => {
	const { settings, loading } = useSelector(
		(state: RootState) => state.settings
	);
	  
	const theme = useTheme();
	const [collapse, setCollapse] = useState(true);
	const mediaObj: MediaItem = props.mediaObj;
	// determine if the user viewing the entry is an admin
	const claims = getClaims();
	const isAdmin = claims.some(
		(claim) => claim.name === "role" && claim.value === "admin"
	);

	const { addNewAlert } = useAlert();
	const { downloadFile, isDownloading } = useDownloadMedia();

	const dragStartHandler = (
		e: React.DragEvent<HTMLElement>,
		checkedMediaArr?: MediaItem[]
	) => {
		e.stopPropagation();
		// e.preventDefault();
		const id: string = e.currentTarget.getAttribute("data-media-id")!;

		e.dataTransfer.setData(props.dropTarget, String(id));

		// set data to array of checked media
		if (checkedMediaArr && checkedMediaArr.length > 0) {
			const transferData = {
				dropTarget: props.dropTarget,
				media: checkedMediaArr,
			};
			e.dataTransfer.setData("dropData", JSON.stringify(transferData));
			// set data as array of dragged media types e.g. [1,0] = [Image, Video]
			e.dataTransfer.setData(
				`{"mediatypesarr": [${checkedMediaArr.map((media) => media.type)}]}`,
				"unused data"
			);
		} else {
			// set data to single dropped media object
			const transferData = {
				dropTarget: props.dropTarget,
				media: props.mediaObj,
			};
			e.dataTransfer.setData("dropData", JSON.stringify(transferData));
			//set public dataTransfer so it's visible before dropping: https://stackoverflow.com/questions/11065803/determine-what-is-being-dragged-from-dragenter-dragover-events
			e.dataTransfer.setData(
				`{"mediatype": ${props.mediaObj.type}}`,
				"unused data"
			);
		}

		e.dataTransfer.effectAllowed = "copy";
	};

	return (
		<DragCard
			className={cn(`drag-card media`, props.className, {
				"": collapse,
			})}
			draggable
			onDragStart={(e) => {
				// props.onDrag && console.log("props on drag", props.onDrag());
				dragStartHandler(e, props.onDrag && props.onDrag());
			}}
			data-media-id={mediaObj.id}
		>
			<div className="drag-icons">
				{!props.isMobile && (
					<Icon
						className="drag-arrows"
						icon="drag-arrows"
						color={theme.colorPrimary}
					/>
				)}

				<div className="w-[85px] h-[50px] mr-[1rem] overflow-hidden">
					<Image
						className="object-cover"
						src={getThumbnailSrc(mediaObj)}
						placeholderSrc={getPlaceholder(mediaObj.type)}
						alt={mediaObj.fileName}
						refetchOnError
						lazy
					/>
				</div>
				<div className="flex flex-col flex-1">
					<p className="credit-field media-file-name pb-[1px]">
						{props.fileEllipsis
							? addEllipsisAfterLetterCount(mediaObj.fileName, 80)
							: mediaObj.fileName}
					</p>
					<div className="flex">
						<p className="credit-field media-type">
							{MediaType[mediaObj.type]}
						</p>
						{mediaObj.createdDate && (
							<p className="credit-field upload-date">
								{getLocalTime(mediaObj.createdDate)}
							</p>
						)}
					</div>
				</div>

				<div className="icon-container ml-auto">
					{isAdmin && (
						<Authorized
							settings={settings}
							loading={loading}
							feature="Display Hide Media"
							authorized={
								<HideMediaButton
									className="mr-[.25rem]"
									hideForWinnersGallery={mediaObj.hideForWinnersGallery}
									mediaId={mediaObj.id!}
								/>
							}
						/>
					)}
					<Icon
						icon="expandCaret"
						className="caret-down"
						color={theme.colorPrimary}
						height="20px"
						width="20px"
						rotation={collapse ? "0" : "180deg"}
						onClick={() => setCollapse(!collapse)}
					/>
					<DownloadMediaButton
						fileName={mediaObj.fileName}
						path={mediaObj.path}
					/>
					<div className="flex flex-col gap-[.25rem]">
						<Icon
							className="triangle"
							icon="triangle"
							color={theme.colorPrimary}
							height="15px"
							width="15px"
							rotation="180deg"
							onClick={() => props.setIndex && props.setIndex(Direction.Up)}
							// readonly={props.readOnly}
						/>

						<Icon
							className="triangle"
							icon="triangle"
							color={theme.colorPrimary}
							height="15px"
							width="15px"
							onClick={() => props.setIndex && props.setIndex(Direction.Down)}
							// readonly={props.readOnly}
						/>
					</div>
					<Icon
						icon="closeLarge"
						color={theme.colorPrimary}
						className="close-icon"
						onClick={() => props.onRemoveMedia && props.onRemoveMedia()}
					/>
				</div>
			</div>

			<div className="credit-info !w-fit">
				<div className="editable-credit-fields w-full">
					<Collapsible
						className="flex flex-col gap-[1rem] w-full items-center"
						isCollapsed={collapse}
					>
						<MediaEnlargeModal media={mediaObj} />

						<div className="grid grid-cols-2 gap-[1rem] w-fit mt-[1rem]">
							{/* <p>File type: </p>
              <p>{MediaType[mediaObj.type]}</p>
              <p>Uploaded on:</p>
              <p>
                {mediaObj.createdDate &&
                  getLocalTime(mediaObj.createdDate)}
              </p>{" "}
              */}
							<p>File size:</p>
							<p>{KBtoMB(Number(mediaObj.size))}</p>
							{/* <p>Dimensions:</p>
              <p>
                {mediaObj.width} x {mediaObj.height}
              </p> */}
						</div>
						{/* <hr className="h-[2px] w-[500px]" /> */}
						{/* <TagsContainer>
              <p className="w-unset">Tags</p>
              {mediaObj.tags.map((tag: string) => {
                return (
                  <div className="tag-bubble">
                    <p>{tag}</p>
                  </div>
                );
              })}
            </TagsContainer> */}
					</Collapsible>
				</div>

				{props.allowMultiSelect && (
					<CheckboxField
						className="checkbox-field"
						label="Allow multi select"
						name={`multiSelect${mediaObj.id!}`}
						checked={mediaObj.isCheckedForDrag}
						onChange={() => props.onCheck && props.onCheck()}
						nonFormik
					/>
				)}
			</div>
		</DragCard>
	);
};

interface DragMediaAccordionProps {
	dropTarget: string;
	// mediaArr: Array<MediaItem>; // list of all media
	mediaObj: MediaItem; // specific media object
	onRemoveMedia?: Function;
	onCheck?(): void;
	allowMultiSelect?: boolean;
	onDrag?(): MediaItem[];
	setIndex?(direction: Direction): void; // called to change the display order of a dropped media
	className?: string;
	fileEllipsis?: number;
	isMobile?: boolean; // replaces drag/drop with multiselect
}
