import axios from "axios";
import { useCallback, useContext, useEffect, useReducer, useRef } from "react";
import { getClaims } from "../components/Auth/handleJWT";
import { urlMedia } from "../endpoints";
import { MediaType, Tag } from "../views/MediaLibrary/mediaLibrary.model.d";
import assetsConfig from "../assetsConfig";
import { useAlert } from "../components/Alert/Alerts";

const GLOBAL_FILE_SIZE_LIMIT = 1.5e9; // global file size limit

// file size limiits for each file type (in bytes)
export const FILE_TYPE_LIMIT = {
	[MediaType.Audio]: 1e8, // 100 MB
	[MediaType.Document]: 1e8, // 100 MB
	[MediaType.Image]: 1e8, // 100 MB
	[MediaType.Video]: 1.5e9, // 1.5 GB
};

export enum DocumentMimeType {
	Pdf = "application/pdf",
	Docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	Xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	Pptx = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
}

export const DOCUMENT_EXT = [".pdf", ".docx", ".xlsx", ".pptx"];

const docTypeMapping = (ext: string): string | null => {
	switch (ext) {
		case DOCUMENT_EXT[0]:
			return DocumentMimeType.Pdf;
		case DOCUMENT_EXT[1]:
			return DocumentMimeType.Docx;
		case DOCUMENT_EXT[2]:
			return DocumentMimeType.Xlsx;
		case DOCUMENT_EXT[3]:
			return DocumentMimeType.Pptx;
		default:
			return null;
	}
};

// check if mimetype string includes the document type
const hasDocMimeType = (acceptedDocTypes?: string) => {
	let hasPdfMimeType = false;
	let hasDocxMimeType = false;
	let hasXlsxMimeType = false;
	let hasPptxMime = false;

	if (acceptedDocTypes) {
		const pdfMime = docTypeMapping(DOCUMENT_EXT[0]);
		const docxMime = docTypeMapping(DOCUMENT_EXT[1]);
		const xlsxMime = docTypeMapping(DOCUMENT_EXT[2]);
		const PptxMime = docTypeMapping(DOCUMENT_EXT[3]);

		if (pdfMime && acceptedDocTypes.includes(pdfMime)) hasPdfMimeType = true;
		if (docxMime && acceptedDocTypes.includes(docxMime)) hasDocxMimeType = true;
		if (xlsxMime && acceptedDocTypes.includes(xlsxMime)) hasXlsxMimeType = true;
		if (PptxMime && acceptedDocTypes.includes(PptxMime)) hasPptxMime = true;
	}

	return { hasPdfMimeType, hasDocxMimeType, hasXlsxMimeType, hasPptxMime };
};

export const isDocTypeAllowed = (
	filePath: string,
	acceptedDocTypes?: string
): boolean => {
	const { hasPdfMimeType, hasDocxMimeType, hasXlsxMimeType, hasPptxMime } =
		hasDocMimeType(acceptedDocTypes);

	if (!acceptedDocTypes) return false;

	if (filePath.endsWith(DOCUMENT_EXT[0])) {
		return hasPdfMimeType;
	} else if (filePath.endsWith(DOCUMENT_EXT[1])) {
		return hasDocxMimeType;
	} else if (filePath.endsWith(DOCUMENT_EXT[2])) {
		return hasXlsxMimeType;
	} else if (filePath.endsWith(DOCUMENT_EXT[3])) {
		return hasPptxMime;
	} else return false;
};

export const ALLOWED_MEDIA_TYPES = [
	"image/png",
	"image/jpeg",
	"video/mp4",
	"video/quicktime",
	"audio/mpeg",
	"text/csv",
	DocumentMimeType.Pdf,
	DocumentMimeType.Docx,
	DocumentMimeType.Xlsx,
	DocumentMimeType.Pptx,
];

const fileTypeMapping = (mimeType: string): string => {
	switch (mimeType) {
		case ALLOWED_MEDIA_TYPES[0]:
			return "PNG";
		case ALLOWED_MEDIA_TYPES[1]:
			return "JPEG";
		case ALLOWED_MEDIA_TYPES[2]:
			return "MP4";
		case ALLOWED_MEDIA_TYPES[3]:
			return "QUICKTIME";
		case ALLOWED_MEDIA_TYPES[4]:
			return "MPEG";
		case ALLOWED_MEDIA_TYPES[5]:
			return "CSV";
		case ALLOWED_MEDIA_TYPES[6]:
			return "PDF";
		case ALLOWED_MEDIA_TYPES[7]:
			return "DOCX";
		case ALLOWED_MEDIA_TYPES[8]:
			return "XLSX";
		case ALLOWED_MEDIA_TYPES[9]:
			return "PPTX";
		default:
			return mimeType;
	}
};

export const mimeTypeMapper = (allowMediaType: string) => {
	const mimeTypeArr = allowMediaType.split(",");
	const readableFileTypes = mimeTypeArr.reduce((acc, val, currentIndex) => {
		{
			let str = fileTypeMapping(val.trim());
			if (currentIndex !== mimeTypeArr.length - 1) str += ", ";
			return acc + str;
		}
	}, "");

	return readableFileTypes;
};

// show specific document/media types in a user friendly string
export const displayMediaType = (
	mediaType: MediaType,
	acceptedDocTypes?: string
): string => {
	if (mediaType === MediaType.Document) {
		if (acceptedDocTypes) {
			let acceptedDocs = [];
			if (acceptedDocTypes.includes(DocumentMimeType.Pdf))
				acceptedDocs.push("PDF");
			if (acceptedDocTypes.includes(DocumentMimeType.Docx))
				acceptedDocs.push("DOCX");
			if (acceptedDocTypes.includes(DocumentMimeType.Xlsx))
				acceptedDocs.push("XLSX");
			if (acceptedDocTypes.includes(DocumentMimeType.Pptx))
				acceptedDocs.push("PPTX");
			if (acceptedDocs.length === 0) acceptedDocs.push("PDF");

			return acceptedDocs.join(", ");
		} else {
			return "PDF";
		}
	} else return MediaType[mediaType];
};

// export const FILE_SIZE_MESSAGE = `Files cannot exceed the ${FILE_SIZE_LIMIT} GB upload limit`;
export const FILE_UPLOAD_COUNT_MESSAGE = (max: number) =>
	`Maximum upload limit for this field: ${max} file(s)`;
export const FILE_TYPE_MESSAGE =
	"Please only upload jpg, png, mp4, mov, m4v, mp3, pdf, docx, xlsx, pptx, or csv files";

export const MEDIA_UPLOAD_ERR = `There was a problem uploading your media, please contact ${assetsConfig.urls.contactEmail}`;

// https://jsmanifest.com/uploading-files-in-react-while-keeping-ui-completely-in-sync/

export const api = {
	uploadFile(file: File, companyId?: number, tags?: Tag[]) {
		// start formdata
		const formData = new FormData();
		formData.append("File", file);
		formData.append("FileName", file.name);
		formData.append("Size", file.size.toString());

		if (companyId) {
			formData.append("CompanyId", String(companyId));
		} else {
			const claims = getClaims();
			const companyIdFromClaim =
				claims.find((claim) => claim.name === "company")?.value || "";
			formData.append("CompanyId", String(companyIdFromClaim));
		}

		if (tags && tags.length) {
			tags.forEach((tag, index) => {
				formData.append(`Tags[${index}].id`, tag.id.toString());
				formData.append(`Tags[${index}].companyId`, tag.companyId.toString());
				formData.append(`Tags[${index}].tag`, tag.tag);
			});
		}

		// get and assign type
		switch (file.type) {
			case "image/jpeg":
			case "image/png":
				formData.append("Type", MediaType.Image.toString());
				// TODO: [NEMO-262] Get dimensions for images
				break;
			case "video/mp4":
			case "video/quicktime":
				formData.append("Type", MediaType.Video.toString());
				// TODO: [NEMO-263] Get dimensions for videos
				// TODO: [NEMO-264] Get duration for video
				break;
			case DocumentMimeType.Pdf:
			case DocumentMimeType.Docx:
			case DocumentMimeType.Xlsx:
			case DocumentMimeType.Pptx:
				formData.append("Type", MediaType.Document.toString());
				break;
			case "audio/mpeg":
				formData.append("Type", MediaType.Audio.toString());
				// TODO: [NEMO-265] Get duration for audio
				break;

			default:
		}

		const headers = {
			"Content-Type": "multipart/form-data",
		};
		// console.log("FileName", file.name);
		return axios.post(`${urlMedia}`, formData, {
			headers: headers,
		});
	},
};

const logUploadedFile = (num: number, color = "green") => {
	const msg = `%cUploaded ${num} files.`;
	const style = `color:${color};font-weight:bold;`;
	// console.log(msg, style);
};

// Constants
const LOADED = "LOADED";
const INIT = "INIT";
const PENDING = "PENDING";
const FILES_UPLOADED = "FILES_UPLOADED";
const UPLOAD_ERROR = "UPLOAD_ERROR";

interface UploadState {
	files: File[];
	pending: File[];
	next: File;
	prev: File;
	uploading: boolean;
	uploaded: any;
	status: string;
}

const initialState = {
	files: [],
	pending: [],
	next: null,
	uploading: false,
	uploaded: {},
	status: "idle",
};

type Action =
	| (UploadState & { type: "load" })
	| (UploadState & { type: "submit"; results: {} })
	| (UploadState & { type: "next"; error: string })
	| (UploadState & { type: "file-uploaded"; prev: { id: any; file: any } })
	| (UploadState & { type: "files-uploaded" })
	| (UploadState & { type: "set-upload-error"; error: string });

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case "load":
			return { ...state, files: action.files, status: LOADED };
		case "submit":
			return { ...state, uploading: true, pending: state.files, status: INIT };
		case "next":
			return {
				...state,
				next: action.next,
				status: PENDING,
			};
		case "file-uploaded":
			return {
				...state,
				next: null,
				pending: action.pending,
				uploaded: {
					...state.uploaded,
					[action.prev.id]: action.prev.file,
				},
			};
		case "files-uploaded":
			return { ...state, uploading: false, status: FILES_UPLOADED };
		case "set-upload-error":
			return { ...state, uploadError: action.error, status: UPLOAD_ERROR };
		case "reset":
			return initialState;
		default:
			return state;
	}
};

export const isFileCountExceeded = (
	fileCount?: number,
	maxFileCount?: number
) => {
	return maxFileCount !== undefined && fileCount !== undefined
		? fileCount > maxFileCount
		: false;
};

const fileLimitMessage = (fileType: string, fileSize: number) => {
	let err = null;

	switch (fileType) {
		case "image/jpeg":
		case "image/png":
			if (fileSize > FILE_TYPE_LIMIT[MediaType.Image]) {
				err = `Images cannot exceed the ${
					FILE_TYPE_LIMIT[MediaType.Image] / 1e6
				} MB upload limit`;
			}
			break;
		case "video/mp4":
			if (fileSize > FILE_TYPE_LIMIT[MediaType.Video]) {
				err = `Videos cannot exceed the ${
					FILE_TYPE_LIMIT[MediaType.Video] / 1e9
				} GB upload limit`;
			}
			break;
		case "video/quicktime":
			if (fileSize > FILE_TYPE_LIMIT[MediaType.Video]) {
				err = `Videos cannot exceed the ${
					FILE_TYPE_LIMIT[MediaType.Video] / 1e9
				} GB upload limit`;
			}
			break;
		case DocumentMimeType.Pdf:
		case DocumentMimeType.Docx:
		case DocumentMimeType.Xlsx:
		case DocumentMimeType.Pptx:
			if (fileSize > FILE_TYPE_LIMIT[MediaType.Document]) {
				err = `Documents cannot exceed the ${
					FILE_TYPE_LIMIT[MediaType.Document] / 1e6
				} MB upload limit`;
			}
			break;
		case "audio/mpeg":
			if (fileSize > FILE_TYPE_LIMIT[MediaType.Image]) {
				err = `Audio files cannot exceed the ${
					FILE_TYPE_LIMIT[MediaType.Image] / 1e6
				} MB upload limit`;
			}
			break;
		default:
			// for other file types, e.g. CSV
			if (fileSize > GLOBAL_FILE_SIZE_LIMIT) {
				err = `Files cannot exceed the ${
					GLOBAL_FILE_SIZE_LIMIT / 1e9
				} GB upload limit`;
			}
	}

	return err;
};

const useFileHandlers = (props?: IUseFileHandlerProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { addNewAlert } = useAlert();

	const reset = () => {
		dispatch({ type: "reset" });
	};

	const onSubmit = useCallback(
		(e) => {
			e.preventDefault();
			if (state.files.length) {
				dispatch({ type: "submit" });
			} else {
				window.alert("You don't have any files loaded.");
			}
		},
		[state.files.length]
	);

	const onChange = (
		// e: React.ChangeEvent<HTMLFormElement>
		filesArr: File[],
		fileCountLimit?: number,
		allowMediaType?: string // user readable string
	) => {
		let allowUpload = true;

		if (filesArr.length) {
			const arrFiles: File[] = Array.from(filesArr);

			// check file COUNT limit
			if (isFileCountExceeded(arrFiles.length, fileCountLimit)) {
				addNewAlert({
					type: "error",
					message: FILE_UPLOAD_COUNT_MESSAGE(fileCountLimit!),
				});
				dispatch({ type: "reset" });
				return [];
			}

			const files = arrFiles.flatMap((file, index) => {
				const src = window.URL.createObjectURL(file);
				// check each file for the file SIZE limit
				// if (file.size > FILE_SIZE_LIMIT) {

				const err = fileLimitMessage(file.type, file.size);
				if (err) {
					allowUpload = false;
					addNewAlert({
						type: "error",
						message: err!,
					});
					return [];
				}
				// check if file type is allowed at all in the app
				else if (!ALLOWED_MEDIA_TYPES.includes(file.type)) {
					allowUpload = false;
					addNewAlert({
						type: "error",
						message: FILE_TYPE_MESSAGE,
					});
					return [];
				}
				// check if file type meets field req
				else if (
					allowMediaType !== undefined &&
					!allowMediaType.includes(file.type)
				) {
					allowUpload = false;
					addNewAlert({
						type: "error",
						message: `This field only accepts ${mimeTypeMapper(
							allowMediaType
						)} files`,
					});
					return [];
				} else {
					return { file, id: index, src };
				}
			});

			// if any of the files fail the file size limit, return empty []
			if (allowUpload) {
				console.log("files", files);
				dispatch({ type: "load", files });
				return files;
			} else {
				dispatch({ type: "reset" });
				return [];
			}
		} else {
			dispatch({ type: "reset" });
			return [];
		}
	};

	// Sets the next file when it detects that its ready to go
	useEffect(() => {
		if (state.pending.length && state.next == null) {
			const next = state.pending[0];
			dispatch({ type: "next", next });
		}
	}, [state.next, state.pending]);

	const countRef = useRef(0);

	// Processes the next pending thumbnail when ready
	useEffect(() => {
		if (state.pending.length && state.next) {
			const { next } = state;
			api
				.uploadFile(next, props?.companyId)
				.then((res) => {
					const prev = next;
					logUploadedFile(++countRef.current);
					const pending = state.pending.slice(1);
					dispatch({ type: "file-uploaded", prev, pending });
				})
				.catch((error) => {
					addNewAlert({
						type: "error",
						message: MEDIA_UPLOAD_ERR,
					});
					console.error(error);
					dispatch({ type: "set-upload-error", error });
				});
		}
	}, [state]);

	// Ends the upload process
	useEffect(() => {
		if (!state.pending.length && state.uploading) {
			dispatch({ type: "files-uploaded" });
		}
	}, [state.pending.length, state.uploading]);

	return {
		...state,
		onSubmit,
		onChange,
		reset,
	};
};

type IUseFileHandlerProps = {
	companyId?: number;
};

export default useFileHandlers;
