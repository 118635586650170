import useFileHandlers, {
	ALLOWED_MEDIA_TYPES,
	DocumentMimeType,
	MEDIA_UPLOAD_ERR,
	mimeTypeMapper,
} from "../../hooks/useFileHandlers";
import styled, { useTheme, css } from "styled-components";
import { IconContext } from "react-icons";
import React, { useRef, useEffect, useState, useContext } from "react";
import { FileDrop } from "react-file-drop";
import Icon from "../Icon/Icon";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack"; // https://github.com/wojtekmaj/react-pdf/issues/97
import { api } from "../../hooks/useFileHandlers";
import TextField from "./TextField";
import { FieldError } from "./FieldTemplate";
import VideoPlaceholder from "../../assets/placeholders/video.png";
import AudioPlaceholder from "../../assets/placeholders/audio.png";
import DocumentPlaceholder from "../../assets/placeholders/document-landscape.png";
import { MediaType } from "../../views/MediaLibrary/mediaLibrary.model.d";

import { bytesToGB } from "../../utils/KBtoMB";
import axios, { AxiosError } from "axios";
import { boolean } from "yup";
import config from "../../config";
import assetsConfig from "../../assetsConfig";
import { useAlert } from "../Alert/Alerts";

const CheckeredBackgroundImage = assetsConfig.placeholders.imageBackground;

export const LoadingScreen = styled.div<{ show: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999999999999;
	background: rgba(0, 0, 0, 0.97);
	display: none;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	${(p) =>
		p.show &&
		css`
			display: flex;
		`}
`;

export const StyledFileUploadNew = styled.div<{
	borderNone?: boolean;
	height?: string;
	fill?: string;
	disabled?: boolean;
}>`
	width: 100%;
	cursor: ${(p) => (p.disabled ? "default" : "pointer")};

	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};

	${(p) =>
		p.fill &&
		css`
			background: ${p.fill};
		`}
	.upload-icon, .search-icon {
		color: ${({ theme }) => theme.colorCopyLight};
		font-size: ${({ theme }) => theme.h1Size};
		margin: 1rem auto;
	}
	.background_image {
		background-image: url(${CheckeredBackgroundImage});
		height: 100%;
		background-size: contain;
		border: 1px solid ${({ theme }) => theme.colorBorderLight} !important;
	}

	.file-drop {
		/* relatively position the container bc the contents are absolute */
		position: relative;
		height: ${(p) => (p.height ? p.height : `194px`)};
		width: 100%;
		border: ${(p) =>
			p.borderNone ? `1px solid transparent` : `1px dashed lightgrey`};
		padding: 20px;
		background: ${(p) => (p.fill ? p.fill : "transparent")};
		overflow: hidden;
	}

	.file-drop > .file-drop-target {
		/* basic styles */
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: 2px;

		/* horizontally and vertically center all content */
		display: flex;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;

		flex-direction: column;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;

		align-items: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;

		justify-content: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;

		align-content: center;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;

		text-align: center;
	}

	.file-drop > .file-drop-target.file-drop-dragging-over-frame {
		/* overlay a black mask when dragging over the frame */
		border: none;
		/* background: ${({ theme }) => theme.colorPrimary}; */
		box-shadow: none;
		z-index: 50;
		opacity: 1;

		/* typography */
		/* color: white; */
	}

	.file-drop > .file-drop-target.file-drop-dragging-over-target {
		/* activate background color when we are dragging over the target */
		p {
			color: ${({ theme }) => theme.colorCopyLightLight};
		}

		.search-icon svg g {
			stroke: ${({ theme }) => theme.colorCopyLightLight};
		}

		background: ${({ theme }) => theme.colorActivation};

		.upload-icon {
			svg {
				polygon {
					fill: ${({ theme }) => theme.colorCopyLightLight};
					stroke: ${({ theme }) => theme.colorCopyLightLight};
				}

				g {
					stroke: ${({ theme }) => theme.colorCopyLightLight};
				}
			}
			/* color: ${({ theme }) => theme.colorCopyLightLight}; */
		}
	}
`;

export const ProgressBar = styled.div`
	position: relative;
	width: 100%;
	height: 15px;
	border-radius: 999px;
	margin-top: 1rem;
	background: ${({ theme }) => theme.colorBackgroundLight};

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 15px;

		border-radius: 999px;
		background: ${({ theme }) => theme.colorActivation};
		animation: fillBar 350ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
	}

	@keyframes fillBar {
		from {
			width: 0;
		}

		to {
			width: 100%;
		}
	}
`;

const FileUpload = (props: FileUploadProps) => {
	const theme = useTheme();
	const {
		files,
		pending,
		next,
		uploading,
		uploaded,
		status,
		onSubmit,
		onChange,
		reset,
	} = useFileHandlers({ companyId: props.companyId });
	const { addNewAlert } = useAlert();

	// set default to allow all files
	const [acceptedFiles, setAcceptedFiles] = useState(
		ALLOWED_MEDIA_TYPES.join(", ")
	);

	const fileInputRef = useRef<any>(null);
	const [showLoading, setShowLoading] = useState(false);
	const [fileValue, setFileValue] = useState("");

	useEffect(() => {
		if (typeof props.allowedMediaType === "string") {
			if (props.allowedMediaType === "csv") {
				setAcceptedFiles("text/csv");
			} else setAcceptedFiles(props.allowedMediaType);
		} else {
			switch (props.allowedMediaType) {
				case MediaType.Document:
					setAcceptedFiles(
						`${DocumentMimeType.Pdf}, ${DocumentMimeType.Docx}, ${DocumentMimeType.Xlsx}, ${DocumentMimeType.Pptx}`
					);
					break;
				case MediaType.Image:
					setAcceptedFiles("image/png, image/jpeg");
					break;
				case MediaType.Audio:
					setAcceptedFiles("audio/mpeg");
					break;
				case MediaType.Video:
					setAcceptedFiles("video/mp4, video/quicktime");
					break;
			}
		}
	}, [props.allowedMediaType]);

	useEffect(() => {
		if (props.uploadToMediaLib && files.length > 0) {
			setShowLoading(true);
			let uploadFilePromises: any[] = [];

			files.forEach((fileObj: FileObj, i: number) => {
				uploadFilePromises.push(api.uploadFile(fileObj.file, props.companyId));
			});

			// return a single promise containing each file upload

			Promise.allSettled(uploadFilePromises)
				.then((results) => {
					let anyRejected = results.some(
						(result) => result.status === "rejected"
					);
					let anySuccessfull = results.some(
						(result) => result.status === "fulfilled"
					);
					let duplicatedFiles = false;

					if (anyRejected) {
						let errorMessage = MEDIA_UPLOAD_ERR;

						for (const rejectedPromise of results) {
							if (
								rejectedPromise.status === "rejected" &&
								axios.isAxiosError(rejectedPromise.reason)
							) {
								if (rejectedPromise.reason.response?.status === 409) {
									duplicatedFiles = true;
									break;
								}
							}
						}

						if (duplicatedFiles) {
							errorMessage = "Some file(s) already exists!";
						}

						addNewAlert({
							type: "error",
							message: errorMessage,
						});

						if (!anySuccessfull) {
							setShowLoading(false);
							reset();
						}
					}

					if (anySuccessfull) {
						props.onUploadSuccess && props.onUploadSuccess(!duplicatedFiles);
						setShowLoading(false);
						reset();
					}
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, [props.uploadToMediaLib]);

	const removeFile = (id: number) => {
		const filesAfterDelete = files.filter(
			(fileObj: FileObj) => fileObj.id !== id
		);

		const newFiles = Object.values(filesAfterDelete as FileObj[]).map(
			(fileObj: FileObj) => fileObj.file
		);

		// console.log("new files AFTER delete", newFiles);
		onChange(newFiles); // set new files
	};

	const getPlaceholder = (fileType: string) => {
		let placeholder = "";
		switch (fileType) {
			case "video/mp4":
				placeholder = VideoPlaceholder;
				break;
			case "video/quicktime":
				placeholder = VideoPlaceholder;
				break;
			case "video":
				placeholder = VideoPlaceholder;
				break;
			case "audio/mpeg":
				placeholder = AudioPlaceholder;
				break;
			case "text/csv":
			case DocumentMimeType.Pdf:
			case DocumentMimeType.Docx:
			case DocumentMimeType.Xlsx:
			case DocumentMimeType.Pptx:
				placeholder = DocumentPlaceholder;
				break;
		}

		return placeholder;
	};

	useEffect(() => {
		if (props.value && !props.value.initial) {
			fetch(props.value.src)
				.then((r) => r.blob())
				.then((blobFile) => {
					if (props.value) {
						const newFile = new File([blobFile], props.value.fileName, {
							type: props.value?.fileType,
						});

						onChange([newFile]);
					}
				});
		}
	}, [props.value]);

	useEffect(() => {
		if (!files || files.length === 0) {
			return;
		}

		if (props.onChange) {
			props.onChange(files);

			if (props?.resetAfterOnChange) {
				reset();
			}
		}
	}, [files]);

	return (
		<>
			<LoadingScreen show={showLoading}>
				<h1 className="text-colorCopyLightLight mb-[1rem]">
					Uploading Media...
				</h1>
				<img src={config.assets.loading.secondary} alt="loading..." />
			</LoadingScreen>

			<StyledFileUploadNew
				className={props.className ? props.className : ""}
				borderNone={props.borderNone || (files.length > 0 && props.imgOnly)}
				height={props.height}
				fill={props.fill}
				id={props.id}
				disabled={props.disabled}
			>
				<FileDrop
					{...(!props.disabled && {
						onDrop: (droppedFiles, e) => {
							if (!droppedFiles) {
								return;
							}

							const newFiles = [...droppedFiles];

							const existingFiles =
								files &&
								files.length > 0 &&
								Object.values(files as FileObj[]).map(
									(fileObj: FileObj) => fileObj.file
								);

							if (existingFiles) {
								const combinedFiles = [...existingFiles, ...newFiles];
								onChange(combinedFiles);
							} else {
								onChange(newFiles);
							}
						},
					})}
					// onDrop={(droppedFiles, e) => onChange(droppedFiles)}
					onTargetClick={() => fileInputRef.current.click()}
					onDragOver={(e) => {
						let isAllowedLocalFileDrag = props.allowedMediaType ? false : true;
						const localFileType = e.dataTransfer.items[0].type;
						const numberOfFiles = e.dataTransfer.items.length;

						switch (props.allowedMediaType) {
							case MediaType.Document:
								if (
									localFileType === DocumentMimeType.Pdf ||
									localFileType === DocumentMimeType.Docx ||
									localFileType === DocumentMimeType.Xlsx ||
									localFileType === DocumentMimeType.Pptx
								)
									isAllowedLocalFileDrag = true;
								break;
							case MediaType.Image:
								if (
									localFileType === "image/png" ||
									localFileType === "image/jpeg"
								)
									isAllowedLocalFileDrag = true;

								break;
							case MediaType.Audio:
								if (localFileType === "audio/mpeg")
									isAllowedLocalFileDrag = true;
								break;
							case MediaType.Video:
								if (
									localFileType === "video/mp4" ||
									localFileType === "video/quicktime"
								)
									isAllowedLocalFileDrag = true;
								break;
							case "csv":
								if (localFileType === "text/csv") isAllowedLocalFileDrag = true;
								break;
							case DocumentMimeType.Pdf:
								if (localFileType === DocumentMimeType.Pdf)
									isAllowedLocalFileDrag = true;
								break;
							case DocumentMimeType.Docx:
								if (localFileType === DocumentMimeType.Docx)
									isAllowedLocalFileDrag = true;
								break;
							case DocumentMimeType.Xlsx:
								if (localFileType === DocumentMimeType.Xlsx)
									isAllowedLocalFileDrag = true;
								break;
							case DocumentMimeType.Pptx:
								if (localFileType === DocumentMimeType.Pptx)
									isAllowedLocalFileDrag = true;
								break;
							default:
								isAllowedLocalFileDrag = false;
						}

						isAllowedLocalFileDrag = props.multiple
							? isAllowedLocalFileDrag
							: numberOfFiles === 1 && isAllowedLocalFileDrag;

						if (props.multiple === undefined && numberOfFiles > 1) {
							addNewAlert({
								type: "error",
								message: "Please upload only one file",
							});
						}

						e.dataTransfer.dropEffect = isAllowedLocalFileDrag
							? "copy"
							: "none";
					}}
				>
					{props.children}
					{props.imgOnly &&
					(files[0] || (props.value && props.value.fileType === "image")) ? (
						<img
							className="w-[100%] object-cover  absolute background_image"
							src={files[0] ? files[0].src : props.value?.src}
							alt="uploaded file"
						/>
					) : (
						!props.whileLoading && (
							<>
								<Icon
									className="upload-icon"
									icon="upload"
									color={theme.colorCopyLight}
								/>
								<p>
									Drag & Drop or Click to Upload{" "}
									{props.fileTypeName ? "CSV" : "Media"}
								</p>
							</>
						)
					)}
					<input
						className="hidden"
						type="file"
						accept={acceptedFiles}
						name="img-loader-input"
						multiple={props.multiple}
						onClick={() => {
							setFileValue("");
						}}
						value={fileValue}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							if (e.target.files && Object.values(e.target.files).length > 0) {
								// returns false if there aren't any files uploaded
								const existingFiles =
									files &&
									files.length > 0 &&
									Object.values(files as FileObj[]).map(
										(fileObj: FileObj) => fileObj.file
									);

								const newFiles = Object.values(e.target.files);

								// console.log("old files", existingFiles);
								// console.log("new files", newFiles);
								onChange([
									...(existingFiles ? [...existingFiles] : []), // conditionally return existing files
									...newFiles,
								]);
							}
						}}
						ref={fileInputRef}
						disabled={props.disabled}
					/>
				</FileDrop>

				{!props.hideUpload &&
					!props.imgOnly &&
					files.length > 0 &&
					files.map((fileObj: FileObj, index: number) => {
						return (
							<div
								className="flex my-[1rem] justify-center items-center"
								key={index}
							>
								{fileObj.file.type === "application/pdf" ? (
									<Document
										className={`border border-solid border-${theme.colorBorderLight}`}
										file={fileObj.src}
									>
										<Page height={300} pageNumber={1} />
									</Document>
								) : (
									// render image preview
									<img
										className="w-[50%] max-h-[150px] object-contain"
										src={
											fileObj.file.type.includes("image")
												? fileObj.src
												: getPlaceholder(fileObj.file.type)
										}
										alt="uploaded file"
									/>
								)}

								<div className="w-[50%] p-[2rem]">
									<div className="flex justify-between items-center">
										<p className="break-all">{fileObj.file.name}</p>
										{/* <TextField
            name={`fileName.${fileObj.id}`}
            placeholder="Filename*"
            value={fileObj.file.name}
          /> */}
										<Icon
											icon="close"
											color={theme.colorPrimary}
											onClick={() => {
												removeFile(fileObj.id);
												// reset()
											}}
										/>
									</div>
									<ProgressBar key={fileObj.file.name} />
								</div>
							</div>
						);
					})}

				{!props.imgOnly && props.value && files.length === 0 && (
					<div className="flex my-[1rem] justify-center items-center">
						{props.value.fileType === "application/pdf" ? (
							<Document
								className={`border border-solid border-${theme.colorBorderLight}`}
								file={props.value.src}
							>
								<Page height={300} pageNumber={1} />
							</Document>
						) : (
							<img
								className="w-[50%] max-h-[150px] object-contain"
								src={
									props.value.fileType === "video"
										? VideoPlaceholder
										: props.value.src
								}
								alt="uploaded file"
							/>
						)}
						<div className="w-[50%] p-[2rem]">
							<div className="flex justify-between items-center">
								<p className="break-all">{props.value.fileName}</p>

								<Icon
									icon="close"
									color={theme.colorPrimary}
									onClick={() => {
										reset();
										props.remove && props.remove();
									}}
								/>
							</div>

							<ProgressBar key={props.value.fileName} />
						</div>
					</div>
				)}
			</StyledFileUploadNew>
		</>
	);
};

export default FileUpload;

interface FileUploadProps {
	className?: string;
	borderNone?: boolean;
	height?: string;
	id: string;
	fill?: string;
	onChange?: (files: FileObj[]) => void;
	imgOnly?: boolean; // only show image preview when uploaded
	value?: FileUploadValue;
	disabled?: boolean; //disable clicking/dragging functionality
	allowedMediaType?: MediaType | string;
	fileTypeName?: string;
	uploadToMediaLib?: boolean;
	onUploadSuccess?(successUpload: boolean): void;
	onUploadError?(): void;
	remove?(): void;
	companyId?: number;
	multiple?: boolean;
	hideUpload?: boolean;
	children?: React.ReactNode;
	whileLoading?: boolean;
	resetAfterOnChange?: boolean;
}

export interface FileUploadValue {
	fileType: "image" | "application/pdf" | "video";
	fileName: string;
	src: string;
	initial?: boolean;
}

interface FileObj {
	file: File;
	id: number;
	src: string;
}
