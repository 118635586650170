import { FunctionComponent, ReactElement, SVGProps } from "react";
import assetsConfig from "./assetsConfig";
import { DefaultTheme } from "styled-components";

let config: Config = {
	assets: assetsConfig,
};

export default config!;

export interface Config {
	assets: AssetsConfig;
}

export interface Logos {
	local: SvgIcon;
	localPng: string;
	primary: string;
	primaryWhite: string;
	secondary: string;
}

export interface Loading {
	primary: string;
	secondary: string;
	tertiary: string;
	quaternary: string;
}

export interface Backgrounds {
	primary: FunctionComponent<
		SVGProps<SVGSVGElement> & { title?: string | undefined }
	>;
}

export interface Placeholders {
	// user: string;
	// company: string;
	image: string;
	imageBackground: string;
}

export interface Fonts {
	pdf: {
		fontFamily: string;
		fonts: Array<{
			src: string;
			fontWeight: number;
			fontStyle?: string;
		}>;
	};
}

type SvgIcon = ReactElement;
export interface AssetsConfig {
	companyName: string;
	companyNameShort: string;
	companyNameLong: string;
	holdingCompanyName: string;
	agencyNetworkCompanyName: string;
	logos: Logos;
	icons: {
		[iconName: string]: SvgIcon;
	};
	loading: Loading;
	backgrounds: Backgrounds;
	placeholders: Placeholders;
	fonts: Fonts;
	urls: { [key: string]: string };
	copy: { [key: string]: string };
	labels: {
		[key: string]: {
			singular: string;
			plural: string;
		};
	};
	colors: DefaultTheme;
	settings: {
		showHelpWidget: boolean;
	};
}
