import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Button from "../../components/Button/Button";
import TextField from "../../components/FormFields/TextField";
import Lightbox from "../../components/Lightbox/Lightbox";
import Modal from "../../components/Modal/Modal";

import { LoginModalCard } from "./Login";
import { PasswordValidation } from "../../components/FormFields/PasswordValidateField";
import { CustomBG } from "./Login";
import { useContext, useState } from "react";
import PasswordValidateField from "../../components/FormFields/PasswordValidateField";
import { urlResetPassword } from "../../endpoints";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import config from "../../config";
import { useAlert } from "../../components/Alert/Alerts";

const ResetPassword = () => {
  const { addNewAlert } = useAlert();
  const history = useHistory();
  const location = useLocation();
  const params: any = new URLSearchParams(location.search);

  const LoadingSpinner = styled.img`
    width: 15%;
    display: inline-flex;
  `;

  const formikProps = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object({
      ...PasswordValidation,
    }),
    onSubmit: async () => {
      const newPassword: any = {
        email: params.get("email"),
        newPassword: formikProps.values.password,
        token: params.get("token"),
      };

      const retryLimit = 5;
      let retryCount = 0;
      let success = false;

      while (retryCount < retryLimit && !success) {
        try {
          await axios.post(`${urlResetPassword}`, newPassword);
          history.push({
            pathname: "/login",
            state: { alert: "Successfully reset password." },
          });
          success = true; // Exit loop if successful
        } catch (err) {
          retryCount += 1;

          if (retryCount >= retryLimit) {
            addNewAlert({
              type: "error",
              message:
                "Failed to reset password. Please generate a new password reset link.",
            });
            formikProps.setSubmitting(false);
          }
        }
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Lightbox className="flex-col items-center mx-auto h-fit self-center !min-w-[500px] !max-w-[600px]">
      <Modal show={true}>
        <LoginModalCard className="!h-[550px]">
          <div>
            <h2 className="mb-[.5rem]">Change Password</h2>
            <p>Please create a new password below.</p>
          </div>

          <FormikProvider value={formikProps}>
            <PasswordValidateField
              name="password"
              placeholder="Password*"
              popUpBottom
            />
            <TextField
              className="mb-[1rem]"
              type="password"
              name="passwordConfirm"
              placeholder="Confirm Password*"
            />
            <Button
              type="submit"
              onClick={() => formikProps.submitForm()}
              disabled={formikProps.isSubmitting}
            >
              {formikProps.isSubmitting ? (
                <LoadingSpinner src={config.assets.loading.primary} />
              ) : (
                "Update Password"
              )}
            </Button>
          </FormikProvider>
        </LoginModalCard>
      </Modal>
    </Lightbox>
  );
};

export default ResetPassword;
