import { DateTime } from "luxon";
import { DATE_FORMAT } from "../components/FormFields/DateField";

// converts unix timestamp (number) to JS Date Object
export const unixToJS = (unix: number) => {
	const unixInMS = unixToMS(unix);
	const dateTime = DateTime.fromMillis(unixInMS);
	if (dateTime.isValid) {
		return dateTime.toJSDate();
	} else return null;
};

// ensures unix timestamp (number) is in Milliseconds
export const unixToMS = (unix: number) => {
	const isMilliseconds = unix.toString().length === 13;
	const unixInMilliseconds = isMilliseconds ? unix : unix * 1000;
	return unixInMilliseconds;
};

// converts JS Date Object to unix timestamp (number)
export const jsToUnix = (date: Date | null) => {
	if (date !== null && !DateTime.fromJSDate(date).invalidReason) {
		return DateTime.fromJSDate(date).toMillis();
	} else return null;
};

// converts date (string) to JS Date Object
export const dateStringToJS = (date: string) => {
	if (!DateTime.fromFormat(date, DATE_FORMAT).invalidReason) {
		return DateTime.fromFormat(date, DATE_FORMAT).toJSDate();
	} else return null;
};

// ignores timezones or daylight saving conversions for direct string comparison
export const getRawDateString = (date: Date) => {
	const m = date.getMonth() + 1;

	// add leading zeros
	const month = String(m).length < 2 ? "0" + String(m) : m;
	const day =
		String(date.getDate()).length < 2
			? "0" + String(date.getDate())
			: date.getDate();

	return date.getFullYear() + "/" + month + "/" + day;
};

// const isNumeric = (n: any) => {
// 	return !isNaN(parseFloat(n)) && isFinite(n);
// };

// export const isValidTimeStamp = (timestamp: any) => {
// 	const removeSpaces = timestamp.replaceAll(" ", "");
// 	// 13 digits for valid unix timestamp in milliseconds
// 	// 10 digits for valid unix timestamp in seconds
// 	const newTimestamp =
// 		!isNaN(removeSpaces) &&
// 		(removeSpaces.length === 10 || removeSpaces.length === 13)
// 			? new Date(Number(removeSpaces)).getTime()
// 			: false;
// 	return isNumeric(newTimestamp);
// };

export const getLuxonDateTime = (
	date: Date | number | string
): DateTime | null => {
	// convert the input date (Date | number | string) and return a luxon DateTime Object
	if (date instanceof Date) {
		// JS Date object
		if (DateTime.fromJSDate(date).invalidReason) {
			return null;
		} else return DateTime.fromJSDate(date);
	}
	// unix timestamp
	else if (typeof date === "number") {
		// unix timestamp
		const unixInMS = unixToMS(date);
		const dt = DateTime.fromMillis(unixInMS);
		return dt.isValid ? dt : null;
	}
	// STRING format
	else if (typeof date === "string") {
		const isDateTimeString = containsTime(date);

		// DATETIME string
		if (isDateTimeString) {
			// convert UTC DateTime string to user's local timezone
			const localTime = DateTime.fromISO(date, { zone: "UTC" }).toLocal();

			// check for valid DATETIME format
			if (localTime.invalidReason) {
				return null;
			} else return localTime;
		}
		// DATE string
		else {
			const convertedDateTime = DateTime.fromFormat(date, DATE_FORMAT);

			// check for valid DATE format
			if (convertedDateTime.invalidReason) {
				return null;
			} else return convertedDateTime;
		}
	} else return null;
};

function containsTime(dateString: string) {
	return dateString.includes("T") || dateString.includes(":");
}

// display datetime in user's system timezone
export const getLocalTime = (
	date: Date | number | string,
	displayTime = true,
	defaultFormat: "short" | "long" = "short"
) => {
	// const options = {
	// 	weekday: "long",
	// 	year: "numeric",
	// 	month: "long",
	// 	day: "numeric",
	// };

	const isDateTimeString = typeof date === "string" && containsTime(date);

	let useCustomFormat =
		(!displayTime || !isDateTimeString) && defaultFormat === "short"; // 1983/10/14

	const format = () => {
		if (displayTime && isDateTimeString) {
			if (defaultFormat === "short") {
				return DateTime.DATETIME_SHORT; // 1983/10/14, 9:30 AM
			} else return DateTime.DATETIME_FULL; // October 14, 1983, 9:30 AM EDT
		} else {
			if (defaultFormat === "short") {
				return DateTime.DATE_SHORT; // 10/14/1983
			} else return DateTime.DATE_FULL; // October 14, 1983
		}
	};

	const luxonDt = getLuxonDateTime(date);

	return luxonDt
		? useCustomFormat
			? String(luxonDt.toFormat(DATE_FORMAT))
			: String(luxonDt.toLocaleString(format()))
		: null;
};

export const getTimezoneOffset = () => {
	const date = new Date();
	const offsetInMinutes = date.getTimezoneOffset();
	const offsetInHours = offsetInMinutes / 60;
	return offsetInHours;
};
