import { useTheme } from "styled-components";
import Button from "../../components/Button/Button";
import CheckoutEntryCard, {
  EntryCardProps,
} from "../../components/CheckoutEntryCard/CheckoutEntryCard";
import CompanyIcon from "../../assets/placeholders/placeholder.png";
import { useState, useEffect, useContext } from "react";

import {
  StickyCardBody,
  StickyContainer,
} from "../../components/StickyCard/StickyCard";
import Icon from "../../components/Icon/Icon";
import { OrderModel } from "./OrderInterfaces";
import { getDraftEntries } from "../Entries/manageEntry";
import {
  deleteEntry,
  getCheckoutEntries,
  getDraftEntryCards,
} from "./manageCheckout";
import { EntryModel, ExecutionModel } from "../Entries/EntryInterfacesComplete";
import { Ancestor, ProgramDeadline } from "../Admin/Program/ProgramInterfaces";
import { ChangeWarning } from "../Entries/EntryWarnings";

import Modal, { ModalCard } from "../../components/Modal/Modal";
import HierarchyDropDown, {
  EntryAction,
} from "../../components/HierarchyDropDownModal/HierarchyDropDown";
import HierarchyDropDownModal from "../../components/HierarchyDropDownModal/HierarchyDropDownModal";
import Loading from "../../components/Loading/Loading";
import { CollapsibleContainer } from "../../components/Collapsible/Collapsible";
import { CheckoutEntryCardAlertContext } from "./CheckoutContext";
import assetsConfig from "../../assetsConfig";
import { useAlert } from "../../components/Alert/Alerts";
import { useSettings } from "../../hooks/UseSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const DraftEntriesTab = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { addNewAlert } = useAlert();
  const theme = useTheme();
  const [draftEntries, setDraftEntries] = useState<EntryCardProps[]>([]);
  const [awardGroups, setAwardGroups] = useState<AwardGroups>({});
  const { addCounter } = useContext(CheckoutEntryCardAlertContext);
  const { settings } = useSelector((state: RootState) => state.settings);

  //   const [entriesCollapsed, setEntriesCollapsed] = useState<{
  //     [awardName: string]: boolean;
  //   }>({});

  const getDraftEntries = () => {
    return getDraftEntryCards().then((response) => {
      setIsLoading(false);
      if (response.status == 200) {
        // console.log("Draft Entry Response: ", response);
        let tempAwardGroups: AwardGroups = {};
        let tempLineItems: LineItem[] = [];
        let tempOrderAmount = 0;
        response.data.forEach((entry: EntryCardProps) => {
          let lineItem: LineItem = {
            awardName: entry.award!,
            entry: entry,
          };
          tempLineItems.push(lineItem);
        });
        const groups: AwardGroups = tempLineItems.reduce((groups, lineItem) => {
          const group = groups[lineItem.awardName] || [];
          group.push(lineItem);
          groups[lineItem.awardName] = group;
          return groups;
        }, tempAwardGroups);
        setAwardGroups(groups);
        return true;
      } else return false;
    });
  };

  //   useEffect(() => {
  //     setEntriesCollapsed(
  //       Object.keys(awardGroups).reduce((acc: any, element) => {
  //         acc[element] = false;
  //         return acc;
  //       }, {})
  //     );
  //   }, [awardGroups]);

  useEffect(() => {
    getDraftEntries();
  }, [awardGroups.lineItems]);

  //   const handleAccordion = (clickedElement: string) => {
  //     const obj: any = {};
  //     Object.entries(entriesCollapsed).forEach(
  //       ([awardName, isCollapsed]) =>
  //         (obj[awardName] = clickedElement === awardName ? !isCollapsed : true)
  //     );
  //     setEntriesCollapsed(obj);
  //   };

  const [showDeleteEntry, setShowDeleteEntry] = useState(false);
  const [deleteEntryId, setDeleteEntryId] = useState<null | number>(null);
  const [entryIdToDuplicate, setEntryIdToDuplicate] = useState<null | number>(
    null
  );

  // update user menu counters
  useEffect(() => {
    addCounter();
  }, [awardGroups]);

  return (
    <div>
      <ChangeWarning
        show={showDeleteEntry}
        title="Delete Entry"
        message="Do you want to delete this entry? This cannot be undone."
        yesMessage="Yes, Delete"
        onHideModal={() => setShowDeleteEntry(false)}
        onConfirm={() => {
          const tempDeleteId = deleteEntryId;
          deleteEntryId &&
            deleteEntry(deleteEntryId)
              .then(() => {
                addNewAlert({
                  type: "success",
                  message: `Successfully deleted entry ID: ${tempDeleteId}`,
                });
                setDeleteEntryId(null); // clear delete id after deleting entry
                getDraftEntries();
              })
              .catch((e) => {
                addNewAlert({
                  type: "error",
                  message: `Failed to delete entry ID: ${tempDeleteId}`,
                });
              });
        }}
      />

      <HierarchyDropDownModal
        entryId={entryIdToDuplicate}
        setEntryId={setEntryIdToDuplicate}
        refreshEntries={getDraftEntries}
        action={EntryAction.Duplicate}
      />

      <h2>My Drafts</h2>
      <p className="mb-[3rem] mt-[1rem]">
        {assetsConfig.copy.draftEntryDescription}
      </p>
      <StickyContainer maxH={1000 * 3000 + "px"} className="mb-[200px]">
        {isLoading && (
          <Loading
            text={"Loading " + assetsConfig.labels.entry.plural}
            fullScreen={false}
            showLogo={false}
          />
        )}
        {Object.entries(awardGroups).flatMap(([awardName, lineItems], i) => {
          if (lineItems !== null && lineItems.length > 0) {
            return (
              <CollapsibleContainer
                className="mb-[2rem]"
                key={i}
                title={awardName}
                // isCollapsed={entriesCollapsed[awardName]}
                // onClickCollapse={() => handleAccordion(awardName)}
              >
                <StickyCardBody key={i} className="flex flex-col gap-[1rem]">
                  {lineItems.map((lineItem) => (
                    <CheckoutEntryCard
                      key={lineItem.entry.id}
                      awardName={lineItem.awardName}
                      entry={lineItem.entry}
                      showDeletePopup={(id) => {
                        setShowDeleteEntry(true);
                        setDeleteEntryId(id);
                      }}
                      setEntryIdToDuplicate={setEntryIdToDuplicate}
                      showAddCartButton={true}
                      onRemove={() => {
                        // wait for entry cards to update before showing alert
                        getDraftEntries().then((res) => {
                          if (res)
                            if (!settings.featureFlags.Commerce) {
                              addNewAlert({
                                type: "success",
                                message: "Successfully submitted",
                              });
                            } else {
                              addNewAlert({
                                type: "success",
                                message: "Successfully added to cart",
                              });
                            }
                        });
                      }}
                    />
                  ))}
                </StickyCardBody>
              </CollapsibleContainer>
            );
          }
        })}
      </StickyContainer>
    </div>
  );
};

export default DraftEntriesTab;

interface AwardGroups {
  [key: string]: LineItem[];
}

export interface LineItem {
  awardName: string;
  entry: EntryCardProps;
  buttonDisabled?: boolean;
  totalLineAmount?: number;
}
