import styled from "styled-components";
import {
	MissingText,
	entryDetailObj,
} from "../../components/GalleryCard/GalleryCard";
import { GalleryBody, StyledGalleryCard } from "../Judging/GalleryCard";
import addEllipsisAfterLetterCount from "../../utils/addEllipsisAfterLetterCount";
import useTextWrap from "../../hooks/useTextWrap";

export const StyledReviewGalleryCard = styled(StyledGalleryCard)`
	.GalleryCoverImg {
		width: 350px;
		height: auto;
		object-fit: cover;
		aspect-ratio: 16 / 9;
	}

	.title {
		font-size: 1.75rem;
		font-family: ${({ theme }) => theme.fontHeading};
		white-space: normal;
		font-weight: 600;
	}
`;

const EntryReviewGalleryCard = (props: EntryReviewGalleryCardProps) => {
	const { wrapText } = useTextWrap();

	return (
		<StyledReviewGalleryCard>
			<img
				className="GalleryCoverImg"
				src={
					props.entryCoverImageSrc.val ||
					"/static/media/placeholder.eee5087461ff40bd9fc9.png"
				}
				alt="Cover Image"
			/>
			<GalleryBody style={{ filter: "none" }}>
				<div className="section flex flex-col flex-1">
					{wrapText(
						<p className="subtext truncate mb-[.25rem]">
							{props.entryBrand.val ? (
								addEllipsisAfterLetterCount(
									props.entryBrand.val,
									props.brandEllipse || 100
								)
							) : (
								<MissingText textLabel="Brand" />
							)}
						</p>
					)}
					{wrapText(
						<h3 className="font-medium title">
							{props.entryTitle.val ? (
								addEllipsisAfterLetterCount(
									props.entryTitle.val,
									props.titleEllipse || 100
								)
							) : (
								<MissingText textLabel="Entry Title" />
							)}
						</h3>
					)}
					<div className="flex gap-[.5rem] mt-auto">
						<p className={`font-semibold text-colorCopyLight`}>Medium:</p>
						<p>
							{props.medium.val ? (
								props.medium.val
							) : (
								<MissingText textLabel="Medium" />
							)}
						</p>
					</div>
				</div>
			</GalleryBody>
		</StyledReviewGalleryCard>
	);
};

interface EntryReviewGalleryCardProps {
	titleEllipse?: number;
	brandEllipse?: number;
	entryTitle: entryDetailObj;
	entryBrand: entryDetailObj;
	entryCoverImageSrc: entryDetailObj;
	medium: entryDetailObj;
}

export default EntryReviewGalleryCard;
