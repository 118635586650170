import styled, { css, useTheme } from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { CardContainer } from "../../../components/DashboardCard/DashboardCard";
import { CardHeader } from "../../../components/DashboardCard/DashboardCard";
import { NavTabs, NavTab } from "../../../components/NavTabs/NavTabs";
import { NavTabPanel } from "../../../components/NavTabs/NavTabs";
import { ConfigNavTabs } from "../Program/ProgramConfig";
import Sort, { SortContainer } from "../../../components/Sort/Sort";
import TextField from "../../../components/FormFields/TextField";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import DropdownField from "../../../components/FormFields/DropdownField";
import Button from "../../../components/Button/Button";
import EntrySearchTab from "./EntrySearchTab";
import OrderSearchTab from "./OrderSearchTab";
import { CardBody } from "../../../components/DashboardCard/DashboardCard";
import { ProgramHierarchyContainer } from "../Program/ProgramRootAdminNav";
import assetsConfig, { lightTheme } from "../../../assetsConfig";
import axios from "axios";
import { urlEntries, urlUserAdmin } from "../../../endpoints";
import { NumberLocale } from "yup/lib/locale";
import { PaymentStatus } from "../../Checkout/OrderInterfaces";
import { ProgramModel } from "../Program/ProgramInterfaces";
import { Link, useParams } from "react-router-dom";
import CompanySearchTab from "./CompanySearchTab";
import UserSearchTab from "./UserSearchTab";
import Pagination from "../../../utils/Pagination";
import CompanyCreditsSearchTab from "./CompanyCreditsSearchTab";
import IndividualCreditsSearchTab from "./IndividualCreditsSearchTab";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import JurorsSearchTab from "./JurorsSearchTab";
import MediaSearchTab from "./MediaSearchTab";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

// const CustomSort = styled(Sort);

export const SearchFieldsContainer = styled.div<{ col?: number }>`
  /* https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/ */

  /**
   * User input values.
   */
  --grid-layout-gap: 1rem;
  --grid-column-count: 3; /* This gets overridden by an inline style. */
  --grid-item--min-width: 200px; /* This gets overridden by an inline style. */

  --grid-column-count: ${(p) => p.col && p.col};
  /**
   * Calculated values.
   */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
`;

export const AdminPageControls = (props: AdminPageControlProps) => {
  return (
    <div className="flex items-center gap-[2rem] justify-end my-[1rem]">
      <h3 className="text-center">Total Results: {props.totalRecords}</h3>
      {props.totalAmountOfPages > 1 && (
        <Pagination
          currentPage={props.currentPage}
          totalAmountOfPages={props.totalAmountOfPages}
          onChange={(newPage) => props.setPage(newPage)}
          setIsLoading={(isLoading) => {
            props.setIsLoading(isLoading);
          }}
        />
      )}
    </div>
  );
};

const AdminSearch = (props: { tab: string }) => {
  const { settings } = useSelector((state: RootState) => state.settings);

  // Memoize tabs and tabNames to prevent unnecessary recalculations
  const tabs = useMemo(
    () =>
      [
        "entries",
        settings.featureFlags.Commerce ? "orders" : null,
        "users",
        !settings.isDemo ? "companies" : null,
        "company-credits",
        "individual-credits",
        settings.featureFlags["Judging Fields"] ? "jurors" : null,
        "media",
      ].filter((x): x is string => !!x),
    [settings.featureFlags, settings.isDemo]
  );

  const tabNames = useMemo(
    () =>
      [
        "Entries",
        settings.featureFlags.Commerce ? "Orders" : null,
        "Users",
        !settings.isDemo ? "Companies" : null,
        "Company Credits",
        "Individual Credits",
        settings.featureFlags["Judging Fields"] ? "Jurors" : null,
        "Media",
      ].filter((x): x is string => !!x),
    [settings.featureFlags, settings.isDemo]
  );

  const defaultTab = tabs.indexOf(props.tab || "entries");
  const [tabIndex, setTabIndex] = useState(defaultTab);

  useEffect(() => {
    setTabIndex(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tabIndex]);

  return (
    <CardContainer>
      <BrowserHeader title={`Library: ${tabNames[tabIndex]}`} />
      <CardHeader>
        <h2 className="card-title">{settings.isDemo ? "Search" : "Library"}</h2>
      </CardHeader>
      <NavTabs
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <ConfigNavTabs>
          <NavTab>
            <Link className="no-underline" to="/library/entries">
              {assetsConfig.labels.entry.plural}
            </Link>
          </NavTab>

          {settings.featureFlags.Commerce && (
            <NavTab>
              <Link className="no-underline" to="/library/orders">
                Orders
              </Link>
            </NavTab>
          )}
          <NavTab>
            <Link className="no-underline" to="/library/users">
              Users
            </Link>
          </NavTab>

          {!settings.isDemo && (
            <NavTab>
              <Link className="no-underline" to="/library/companies">
                {assetsConfig.labels.company.plural}
              </Link>
            </NavTab>
          )}

          <NavTab>
            <Link className="no-underline" to="/library/company-credits">
              Company Credits
            </Link>
          </NavTab>
          <NavTab>
            <Link className="no-underline" to="/library/individual-credits">
              Individual Credits
            </Link>
          </NavTab>
          {settings.featureFlags["Judging Fields"] && (
            <NavTab>
              <Link className="no-underline" to="/library/jurors">
                Jurors
              </Link>
            </NavTab>
          )}
          <NavTab>
            <Link className="no-underline" to="/library/media">
              Media
            </Link>
          </NavTab>
        </ConfigNavTabs>
        <NavTabPanel>
          <CardBody>
            <EntrySearchTab />
          </CardBody>
        </NavTabPanel>
        {settings.featureFlags.Commerce && (
          <NavTabPanel>
            <CardBody>
              <OrderSearchTab />
            </CardBody>
          </NavTabPanel>
        )}
        <NavTabPanel>
          <CardBody>
            <UserSearchTab />
          </CardBody>
        </NavTabPanel>
        {!settings.isDemo && (
          <NavTabPanel>
            <CardBody>
              <CompanySearchTab />
            </CardBody>
          </NavTabPanel>
        )}
        <NavTabPanel>
          <CardBody>
            <CompanyCreditsSearchTab />
          </CardBody>
        </NavTabPanel>
        <NavTabPanel>
          <CardBody>
            <IndividualCreditsSearchTab />
          </CardBody>
        </NavTabPanel>
        {settings.featureFlags["Judging Fields"] && (
          <NavTabPanel>
            <CardBody>
              <JurorsSearchTab />
            </CardBody>
          </NavTabPanel>
        )}
        <NavTabPanel>
          <CardBody>
            <MediaSearchTab />
          </CardBody>
        </NavTabPanel>
      </NavTabs>
    </CardContainer>
  );
};

export default AdminSearch;

interface AdminPageControlProps {
  totalRecords: number;
  totalAmountOfPages: number;
  currentPage: number;
  setPage(newPage: number): void;
  setIsLoading(isLoading: boolean): void;
}
