import {
	EntryListField,
	ListType,
} from "../../Admin/Program/ProgramInterfaces";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import RadioField, {
	ListContainer,
} from "../../../components/FormFields/RadioField";
import DropdownField from "../../../components/FormFields/DropdownField";
import { CreditType } from "../../Admin/Program/ProgramInterfaces";
import { FieldArray, useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { ValidateContext } from "../EntryForm";
import {
	AUTOSAVE_MESSAGE,
	DynamicEntryField,
	EntryError,
} from "../DynamicFields";
import { fieldRequired } from "../../../components/FieldModal/FieldModal";
import { NonFormikRadio } from "../../../components/FormFields/RadioField";
import { updateEntryField } from "../manageEntry";
import { SuccessMessageWrapper } from "../../../components/FormFields/FieldWrapper";
import MultiDropdown from "../../../components/MultiDropdown/MultiDropdown";

const EntryListFieldForm = (props: EntryListFieldProps) => {
	const { values, setFieldValue, errors } = useFormikContext<any>();
	const listOptions = props.field.programField.listOptions.split(";");
	const asterisk =
		props.field.programField.minQuantity &&
		props.field.programField.minQuantity >= 1
			? "*"
			: "";

	const validate = useContext(ValidateContext);

	// if no checkboxes are checked
	const isNotCheckedErr =
		validate &&
		!props.field.selectedOption &&
		props.field.programField.minQuantity &&
		props.field.programField.minQuantity > 0;

	// if less than minimum checkboxes are checked
	const isMinCheckedErr =
		validate &&
		props.field.selectedOption &&
		props.field.programField.minQuantity &&
		props.field.selectedOption.length < props.field.programField.minQuantity;

	// show err is user selects less than minimum allowed
	const multiSelectMinError =
		isNotCheckedErr || isMinCheckedErr
			? `A minimum of ${props.field.programField.minQuantity} options must be selected`
			: undefined;

	// show err is user selects more than maximum allowed
	const multiSelectMaxErr =
		validate &&
		props.field.selectedOption &&
		props.field.programField.maxQuantity &&
		props.field.selectedOption.length > props.field.programField.maxQuantity
			? `A maximum of ${props.field.programField.maxQuantity} selections are allowed`
			: undefined;

	const singleSelectError =
		validate &&
		(!props.field.selectedOption ||
			props.field.selectedOption?.length === 0 ||
			props.field.selectedOption?.reduce((prev, curr) => `${prev}${curr}`) ==
				"") &&
		props.field.programField.minQuantity > 0
			? fieldRequired
			: undefined;

	const handleBlur = async (index: number) => {
		if (!props.readOnly) {
			const linkField = props.field;
			await updateEntryField(linkField);
		}
	};

	return (
		<div className="col-w-100" id={`wrapper-div-${props.name}`}>
			{!props.isAdmin && (
				<>
					<h3 className="subtitle font-semibold">
						{props.field.programField.title}
						{asterisk}
					</h3>
					<p className="whitespace-pre-wrap">
						{props.field.programField.entrantHelpText}
					</p>
				</>
			)}

			{(props.field.programField.listType === ListType.CheckBox && (
				<SuccessMessageWrapper>
					{(success, setSuccess) => (
						<ListContainer
							name={`${props.name}.selectedOption`}
							success={success}
							error={multiSelectMinError || multiSelectMaxErr}
						>
							<FieldArray name={`${props.name}.selectedOption`}>
								{({ insert, remove, push }) =>
									listOptions &&
									listOptions.length > 0 &&
									listOptions.map((option, i) => {
										return (
											<CheckboxField
												key={i}
												name={`${props.name}.selectedOption`}
												nonFormik
												checked={
													props.field.selectedOption &&
													props.field.selectedOption.includes(option)
												}
												onChange={(isChecked) => {
													isChecked
														? push(option)
														: remove(
																props.field.selectedOption.indexOf(option)
														  );
													// Update the entry field after modifying the selected options
													const updatedField = {
														...props.field,
														selectedOption: isChecked
															? [...(props.field.selectedOption || []), option]
															: props.field.selectedOption.filter(
																	(opt) => opt !== option
															  ),
													};
													if (props.saveOnChange) {
														updateEntryField(updatedField).then(() =>
															setSuccess(AUTOSAVE_MESSAGE)
														);
													}
												}}
												placeholder={option}
												disabled={props.readOnly}
											/>
										);
									})
								}
							</FieldArray>
						</ListContainer>
					)}
				</SuccessMessageWrapper>
			)) ||
				(props.field.programField.listType === ListType.Radio && (
					<SuccessMessageWrapper>
						{(success, setSuccess) => (
							<ListContainer
								name={`${props.name}.selectedOption`}
								success={success}
								error={singleSelectError}
							>
								<FieldArray name={`${props.name}.selectedOption`}>
									{({ insert, remove, push }) =>
										listOptions &&
										listOptions.length > 0 &&
										listOptions.map((option, index) => {
											return (
												<NonFormikRadio
													key={index}
													label={option}
													name={`${props.name}.selectedOption`}
													checked={
														props.field.selectedOption &&
														props.field.selectedOption.includes(option)
													}
													onClick={() => {
														setFieldValue(`${props.name}.selectedOption`, [
															option,
														]);
														// Update the entry field after modifying the selected option
														const updatedField = {
															...props.field,
															selectedOption: [option],
														};
														if (props.saveOnChange) {
															updateEntryField(updatedField).then(() =>
																setSuccess(AUTOSAVE_MESSAGE)
															);
														}
													}}
													// value={
													//   (props.field.selectedOption &&
													//     props.field.selectedOption.includes(option)) ||
													//   false
													// }
													// value={option}
													// nonFormik
													disabled={props.readOnly}
												/>
											);
										})
									}
								</FieldArray>
							</ListContainer>
						)}
					</SuccessMessageWrapper>
				)) ||
				(props.field.programField.listType === ListType.SingleDropDown && (
					<SuccessMessageWrapper>
						{(success, setSuccess) => (
							<DropdownField
								placeholder={props.field.programField.title}
								name={`${props.name}.selectedOption`}
								options={listOptions.map((o) => {
									return { value: o };
								})}
								value={
									(props.field.selectedOption &&
										props.field.selectedOption.length === 1 &&
										props.field.selectedOption[0]) ||
									null
								}
								onChange={(e) => {
									setFieldValue(`${props.name}.selectedOption`, [
										e.target.value,
									]);
									// Update the entry field after modifying the selected option
									const updatedField = {
										...props.field,
										selectedOption: [e.target.value],
									};
									if (props.saveOnChange) {
										updateEntryField(updatedField).then(() =>
											setSuccess(AUTOSAVE_MESSAGE)
										);
									}
								}}
								nonFormik
								success={success}
								error={singleSelectError}
								readOnly={props.readOnly}
							/>
						)}
					</SuccessMessageWrapper>
				)) || (
					<SuccessMessageWrapper>
						{(success, setSuccess) => (
							<MultiDropdown
								placeholder={props.field.programField.title}
								options={listOptions.map((o) => {
									return { value: o };
								})}
								selectedValues={
									props.field.selectedOption
										? props.field.selectedOption.flatMap((value) => {
												const correspondingOption = listOptions.find(
													(option) => option === value
												);
												return correspondingOption
													? [
															{
																value: correspondingOption,
																label: correspondingOption,
															},
													  ]
													: [];
										  })
										: []
								}
								onChange={(selectedOptions) => {
									const strArr = selectedOptions.map((option) => option.value);
									setFieldValue(`${props.name}.selectedOption`, strArr);
									// Update the entry field after modifying the selected option
									const updatedField = {
										...props.field,
										selectedOption: strArr,
									};
									if (props.saveOnChange) {
										updateEntryField(updatedField).then(() =>
											setSuccess(AUTOSAVE_MESSAGE)
										);
									}
								}}
								disabled={props.readOnly}
								success={success}
								error={multiSelectMinError || multiSelectMaxErr}
							/>
						)}
					</SuccessMessageWrapper>
				)}
		</div>
	);
};

export default EntryListFieldForm;

interface EntryListFieldProps extends DynamicEntryField {
	field: EntryListField;
	name: string;
	saveOnChange?: boolean;
}
