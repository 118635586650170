import axios from "axios";
import { useState, useEffect } from "react";
import {
  NotificationActionTypes,
  UserNotification,
} from "../components/Notification/types";
import {
  fetchNotificationsSuccess,
  addNotification,
  updateNotification,
  removeNotification,
  setFallback,
} from "../components/Notification/actions";
import { urlNotification } from "../endpoints";
import { getClaims } from "../components/Auth/handleJWT";
import { claim } from "../components/Auth/auth.models";
import {
  Notification,
  NotificationAction,
  useNotificationHub,
} from "./useNotificationHub";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

export const useNotifications = (claims: claim[]) => {
  const dispatch: Dispatch<NotificationActionTypes> = useDispatch();

  const userId = claims.find((claim) => claim.name === "userId")?.value;

  const { connection, joinGroup, leaveGroup, isConnected } =
    useNotificationHub(claims);

  async function getNotifications(userId: string) {
    try {
      const response = await axios.get<Array<UserNotification>>(
        `${urlNotification}/recipient/${userId}`
      );

      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      // Handle error
    }
  }

  // Fetch initial notifications from the server
  useEffect(() => {
    if (!userId) {
      return;
    }

    const getNotificationsAsync = async () => {
      let results = await getNotifications(userId);
      if (results) {
        dispatch(fetchNotificationsSuccess(results));
      } else {
        dispatch(setFallback());
      }
    };
    getNotificationsAsync();
  }, [dispatch, userId]);

  useEffect(() => {
    if (connection && userId) {
      const groupName = `Notification-${userId}`;
      joinGroup(groupName, connection);
    }
  }, [connection, userId]);

  // Handle incoming SignalR notifications
  useEffect(() => {
    if (isConnected && connection) {
      // Set up SignalR connection to handle incoming notifications
      connection.on("ReceiveNotification", (notification: Notification) => {
        // Handle "Add" action for new notifications
        if (notification.action === NotificationAction.Add) {
          const newNotification = notification.data as UserNotification; // Cast notification data
          dispatch(addNotification(newNotification)); // Dispatch action to Redux store
        }
        // Handle "Update" action for existing notifications
        else if (notification.action === NotificationAction.Update) {
          const updatedNotification = notification.data as UserNotification; // Cast notification data
          dispatch(updateNotification(updatedNotification)); // Dispatch action to Redux store
        }
        // Handle "Delete" action for removing notifications
        else if (notification.action === NotificationAction.Delete) {
          const deletedNotificationId = (notification.data as { id: number })
            .id; // Get the ID of the notification to be deleted
          dispatch(removeNotification(deletedNotificationId)); // Dispatch action to Redux store
        }
      });
    }
  }, [connection, isConnected, dispatch]);

  return;
};
