import { FieldArray, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import MultiSelectDropdownField from "../../../../components/FormFields/MultiSelectDropdownField";
import Button from "../../../../components/Button/Button";
import styled, { useTheme } from "styled-components";
import HierarchyMultiselect, {
	SearchHierarchySelection,
} from "./HierarchyMultiselect";
import axios from "axios";
import { urlPrograms } from "../../../../endpoints";
import MultiDropdown from "../../../../components/MultiDropdown/MultiDropdown";
import { DropdownOptions } from "../../../../components/FormFields/DropdownField";
import Icon from "../../../../components/Icon/Icon";
import MultiSelectRow from "./MultiSelectRow";
import { NonFormikToggleSwitch } from "../../../../components/FormFields/ToggleSwitchField";

const FieldContainer = styled.div`
	flex-wrap: wrap;
	padding-top: 1rem;
	margin-bottom: 1rem;
	border-top: 2px solid ${({ theme }) => theme.colorBorderLight};
	& > * {
		flex: 1;
		min-width: 250px;
		max-width: 350px;
	}
`;

// map through object and return an array of positive and negative hierarchy IDs
const collapseHierarchy = (data: any[]): SearchObj[] => {
	const resultArray: SearchObj[] = [];

	// Process each row in the data array
	data.forEach((row) => {
		const result: SearchObj = {
			positive: [],
			positiveIsExecution: false,
			negative: [],
			negativeIsExecution: false,
		};

		// Reverse the array to start processing from the bottom-most nodes
		const reversedData = [...row].reverse();

		// Process the reversed array
		const item = reversedData[0];

		//console.log("item", item);
		// If it's an array, handle the child elements
		if (Array.isArray(item) && item.length) {
			//console.log("Array:", item);
			item.forEach((child) => {
				// If the child is negative, add it to negative
				if (child.isNegative) {
					result.negative.push(child.id);
				} else {
					// If the child is positive, add to positive
					result.positive.push(child.id);
				}
			});

			// if this item is negative, the imediate parent is the only positive
			const isNegative = item.some((x) => x.isNegative);
			if (isNegative) {
				result.positive = [reversedData[1].id];
				result.negativeIsExecution = item.some((x) => x.isExecution);
			} else {
				result.positiveIsExecution = item.some((x) => x.isExecution);
			}
		} else if (item) {
			if (item.isNegative) {
				result.negative = [item.id];
				result.positive = [reversedData[1].id];
				result.negativeIsExecution = item.isExecution;
			} else {
				// If the item is positive, add to positive
				result.positive = [item.id];
				result.positiveIsExecution = item.isExecution;
			}
		}
		resultArray.push(result);
	});

	return resultArray;
};

const AdvancedSearchFields = (props: AdvancedSearchFieldsProps) => {
	const theme = useTheme();
	const { setSearchVal, onResetSearch } = props;
	const [rootLevelPrograms, setRootLevelPrograms] = useState<DropdownOptions[]>(
		[]
	);

	const formikProps = useFormik<HierarchySearch>({
		initialValues: {
			hierarchySearch: [[]],
		},
		onSubmit: async (value) => {},
		enableReinitialize: true,
	});

	useEffect(() => {
		async function fetchOptions() {
			const response = await axios.get(`${urlPrograms}/GetRootProgramOptions`);
			setRootLevelPrograms(response.data);
		}

		fetchOptions();
	}, []);

	useEffect(() => {
		props.setSearchVal(collapseHierarchy(formikProps.values.hierarchySearch));
	}, [formikProps.values]);

	useEffect(() => {
		formikProps.setFieldValue("hierarchySearch", [[]]);
	}, [onResetSearch]);

	return (
		<FormikProvider
			key={String(onResetSearch)} // rerender entire form when reset search is clicked
			value={formikProps}
		>
			<div className="w-full flex flex-col gap-[1rem]">
				<FieldArray name="hierarchySearch">
					{({ push, remove }) => (
						<>
							{formikProps.values.hierarchySearch &&
								formikProps.values.hierarchySearch.map((query, index) => (
									<FieldContainer key={index} className="flex gap-[1rem]">
										{rootLevelPrograms && rootLevelPrograms.length > 0 && (
											<>
												<MultiSelectRow
													name={`hierarchySearch[${index}]`}
													rootLevelPrograms={rootLevelPrograms}
												/>
											</>
										)}

										<Icon
											className="!flex-none !min-w-0 w-[20px] ml-auto self-center"
											icon="close"
											color={theme.colorPrimary}
											onClick={() => remove(index)}
										/>
									</FieldContainer>
								))}

							<Button
								type="button"
								className="w-[200px] button-light"
								icon="plus"
								iconColor={theme.colorActivation}
								onClick={() => push([])}
							>
								Add a new row
							</Button>
						</>
					)}
				</FieldArray>
			</div>
		</FormikProvider>
	);
};

export default AdvancedSearchFields;

interface AdvancedSearchFieldsProps {
	setSearchVal(search: SearchObj[]): void; // pass search values back up
	onResetSearch: boolean;
}

export interface SearchObj {
	positive: number[];
	positiveIsExecution: boolean;
	negative: number[];
	negativeIsExecution: boolean;
}

export interface HierarchySearch {
	hierarchySearch: SearchRow[];
}

type SearchRow = SearchColumn[];
type SearchColumn = SearchHierarchySelection[] | SearchHierarchySelection;
