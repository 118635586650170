import { useEffect, useState } from "react";

import DropdownField from "../../../../components/FormFields/DropdownField";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import Button from "../../../../components/Button/Button";
import { JobTitleOptions } from "../../../../data/JobTitleData";
import {
	CompanyTypeOptions,
	CreditType,
} from "../../Program/ProgramInterfaces";
import styled, { useTheme } from "styled-components";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { CreditSortType, creditSortOptions } from "./SortCreditLibrary";
import { isSubStringInString } from "../../../../utils/isSubstringInString";
import { CompanyCredit, IndividualCredit } from "../CreditInterfaces";
import { filterByTags } from "../../../MediaLibrary/MediaSort";
import TagFilter from "../../Media/TagFilter";
import { Tag } from "../../../MediaLibrary/mediaLibrary.model";
import ToggleSwitchField from "../../../../components/FormFields/ToggleSwitchField";
import { SortContainer } from "../../../../components/Library/Library";

const CreditLibrarySortContainer = styled(SortContainer)`
	.tag-filter {
		flex-basis: auto !important;
	}

	@media only screen and (max-width: 1500px) {
		.tag-filter {
			order: 1;
		}
		.active-toggle {
			order: 2;
			margin-left: auto;
		}
	}
`;

export enum FilterCondition {
	And,
	Or,
}

export const filterCredits = (
	credits: any[],
	filter: CreditFilter,
	isCompanyCredit: boolean,
	// "AND" requires all search conditions to match
	// "OR" returns results if any of the search conditions match
	filterCondition: FilterCondition
) => {
	const isFilterActive =
		filter.creditName ||
		(!isCompanyCredit && filter.jobTitle) ||
		(isCompanyCredit && filter.companyType) ||
		filter.tags.length > 0;

	let creditsCopy = [...credits];

	let searchProps: string[] = [];
	if (isCompanyCredit) {
		searchProps = ["name", "city", "country"];
	} else {
		searchProps = [
			"fullName",
			"companyName",
			"companyCity",
			"country",
			"email",
		];
	}

	if (filter.onlyShowActive) {
		creditsCopy = creditsCopy.filter((credit) => credit.isActive);
	}

	// if filter is active, filter/sort
	if (isFilterActive) {
		if (filterCondition === FilterCondition.And) {
			// credit search
			if (filter.creditName !== null && filter.creditName !== "") {
				creditsCopy = creditsCopy.filter((credit) =>
					searchProps.some((searchProp) =>
						isSubStringInString(credit[searchProp], filter.creditName!)
					)
				);
			}

			// job title search
			if (
				!isCompanyCredit &&
				filter.jobTitle !== null &&
				filter.jobTitle !== ""
			) {
				creditsCopy = creditsCopy.filter((credit) =>
					isSubStringInString(credit.jobTitle, filter.jobTitle!)
				);
			}

			// company type search
			if (
				isCompanyCredit &&
				filter.companyType !== null &&
				filter.companyType !== ""
			) {
				creditsCopy = creditsCopy.filter((credit) =>
					isSubStringInString(credit.companyType, filter.companyType!)
				);
			}

			// tag filter
			if (filter.tags.length > 0) {
				creditsCopy = filterByTags(creditsCopy, filter.tags);
			}
		} // credit search and tag filter
		else
			creditsCopy = creditsCopy.filter((credit) => {
				let isMatch = false;

				// credit search
				if (filter.creditName !== null && filter.creditName !== "") {
					searchProps.forEach((searchProp) => {
						if (isSubStringInString(credit[searchProp], filter.creditName!)) {
							isMatch = true;
						}
					});
				}

				// job title search
				if (
					!isCompanyCredit &&
					filter.jobTitle !== null &&
					filter.jobTitle !== ""
				) {
					if (isSubStringInString(credit.jobTitle, filter.jobTitle!)) {
						isMatch = true;
					}
				}

				// company type search
				if (
					isCompanyCredit &&
					filter.companyType !== null &&
					filter.companyType !== ""
				) {
					if (isSubStringInString(credit.companyType, filter.companyType!)) {
						isMatch = true;
					}
				}

				// tag filter
				if (filter.tags.length > 0 && credit.tags && credit.tags.length > 0) {
					const isTagMatch = filter.tags.every((searchTag) => {
						return credit.tags?.some((creditTag: Tag) =>
							creditTag.tag.toUpperCase().includes(searchTag.toUpperCase())
						);
					});

					if (isTagMatch) {
						isMatch = true;
					}
				}

				return isMatch;
			});
	}

	// date sort
	if (filter.dateSort !== null) {
		creditsCopy = creditsCopy.sort((a, b) => {
			const timeA = new Date(a.createdDate).getTime();
			const timeB = new Date(b.createdDate).getTime();
			return filter.dateSort === CreditSortType.UploadDateAsc
				? timeA - timeB
				: timeB - timeA;
		});
	}

	return creditsCopy;
};

const FilterCreditLibrary = (props: FilterCreditLibraryProps) => {
	const theme = useTheme();
	const isCompanyCredit = props.creditType === CreditType.Company;
	const [filter, setFilter] = useState<CreditFilter>({
		jobTitle: null,
		companyType: null,
		dateAdded: null,
		tags: [],
		creditName: null,
		onlyShowActive: false,
		dateSort: null,
	});

	const formikProps = useFormik({
		initialValues: {},
		onSubmit: async (value) => {},
		validationSchema: Yup.object({}),
		enableReinitialize: true,
	});

	const handleFilter = () => {
		let creditsCopy = filterCredits(
			props.credits,
			filter,
			isCompanyCredit,
			FilterCondition.And
		);

		props.setCredits(creditsCopy);
	};

	useEffect(() => {
		handleFilter();
	}, [filter, props.credits]);

	return (
		<FormikProvider value={formikProps}>
			<CreditLibrarySortContainer>
				<DropdownField
					className="min-w-[280px] flex-1"
					name="dateSort"
					placeholder="Sort By"
					options={creditSortOptions}
					onChange={(e) =>
						e.target.value !== "" &&
						setFilter({ ...filter, dateSort: Number(e.target.value) })
					}
				/>
				{!isCompanyCredit && (
					<SearchBar
						className="min-w-[280px] flex-1"
						searchPlaceholder="Search by Job Title"
						onKeyUp={(e) =>
							setFilter({ ...filter, jobTitle: e.currentTarget.value })
						}
						searchIconBG={theme.colorBackgroundLightLight}
						showBorder={false}
					/>
				)}
				{isCompanyCredit && (
					<SearchBar
						className="min-w-[280px] flex-1"
						searchPlaceholder="Search by Company Type"
						onKeyUp={(e) =>
							setFilter({ ...filter, companyType: e.currentTarget.value })
						}
						searchIconBG={theme.colorBackgroundLightLight}
						showBorder={false}
					/>
				)}

				<SearchBar
					className="xl:min-w-[400px] xl:flex-1 w-full "
					searchPlaceholder={
						isCompanyCredit
							? "Search by Name, City, or Country"
							: "Search by Name and Company Details"
					}
					onKeyUp={(e) =>
						setFilter({ ...filter, creditName: e.currentTarget.value })
					}
					searchIconBG={theme.colorBackgroundLightLight}
					showBorder={false}
				/>

				<ToggleSwitchField
					className="active-toggle"
					id="isActive"
					name="isActive"
					checked={filter.onlyShowActive}
					label={"Show Only Active Credits"}
					small
					onClick={() =>
						setFilter({
							...filter,
							onlyShowActive: !filter.onlyShowActive,
						})
					}
				/>

				<TagFilter
					className="tag-filter"
					onSearch={(tags) => setFilter({ ...filter, tags: tags })}
				/>

				{/* <div className="flex w-full items-start">
          <TagFilter
            onSearch={(tags) => setFilter({ ...filter, tags: tags })}
          />
        </div> */}
			</CreditLibrarySortContainer>
		</FormikProvider>
	);
};

export default FilterCreditLibrary;

interface FilterCreditLibraryProps {
	credits: any[];
	setCredits(credits: any[]): void;
	creditType: CreditType;
}

export interface CreditFilter {
	jobTitle: string | null;
	companyType: string | null;
	dateAdded: Date | null;
	tags: string[];
	creditName: string | null;
	onlyShowActive: boolean;
	dateSort: null | CreditSortType;
}
