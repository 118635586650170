import styled, { css, useTheme } from "styled-components";
import Button from "../Button/Button";
import ToggleSwitchField from "../FormFields/ToggleSwitchField";
import CheckboxField from "../FormFields/CheckboxField";
import TextField from "../FormFields/TextField";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { ModalContainer } from "../Modal/Modal";
import {
	createCreditFieldTemplate,
	createDateFieldTemplate,
	createLinkFieldTemplate,
	createListFieldTemplate,
	createPhysicalComponentFieldTemplate,
	createTextFieldTemplate,
	createMediaFieldTemplate,
	updateCreditFieldTemplate,
	updateDateFieldTemplate,
	updateLinkFieldTemplate,
	updateListFieldTemplate,
	updateMediaFieldTemplate,
	updatePhysicalComponentFieldTemplate,
	updateTextFieldTemplate,
} from "../../views/Admin/Program/manageFieldTemplates";
import {
	DateType,
	CreditType,
	ListType,
	TextType,
} from "../../views/Admin/Program/ProgramInterfaces";
import { MediaType } from "../../views/MediaLibrary/mediaLibrary.model.d";
import { CreditFieldTemplate } from "../../views/Admin/Program/ProgramInterfaces";
import { FieldTemplate } from "../../views/Admin/Program/ProgramInterfaces";
import { useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { GiConsoleController } from "react-icons/gi";
import Icon from "../Icon/Icon";

import { useAlert } from "../Alert/Alerts";

export const fieldRequired = "This field is required";

export const fieldTemplateValidation = {
	name: Yup.string().required(fieldRequired),
	title: Yup.string().required(fieldRequired),
	adminHelpText: Yup.string().required(fieldRequired),
	entrantHelpText: Yup.string().required(fieldRequired),
	jurorHelpText: Yup.string().required(fieldRequired),
};

export const newFieldInitialVal = {
	name: "",
	title: "",
	adminHelpText: "",
	entrantHelpText: "",
	jurorHelpText: "",
};

export const FieldModal = (props: FieldModalProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();

	const { values, setFieldValue, submitForm, validateForm } =
		useFormikContext<any>();

	async function PostNewField(newFieldData: any) {
		submitForm(); // submit form to run validation
		validateForm().then((res) => {
			// console.log("validateForm response", res);
			// empty obj = no errors
			if (Object.keys(res).length === 0) {
				try {
					if (props.isUpdateForm) {
						const successfulUpdate = "Successfully updated field";

						switch (props.formType) {
							case "link":
								// console.log("UPDATE linkCompTemplateData", values);
								updateLinkFieldTemplate(values, () => values)
									.then(() => {
										props.hideModal();
										addNewAlert({
											type: "success",
											message: successfulUpdate,
										});
									})
									.catch((err) => {
										addNewAlert({
											type: "error",
											message: err.response.status,
										});
									});
								break;
							case "physical":
								// console.log("UPDATE physicalCompTemplateData", values);
								updatePhysicalComponentFieldTemplate(values, () => values)
									.then(() => {
										props.hideModal();
										addNewAlert({
											type: "success",
											message: successfulUpdate,
										});
									})
									.catch((err) => {
										addNewAlert({
											type: "error",
											message: err.response.status,
										});
									});
								break;
							case "text":
								// console.log("UPDATE textCompTemplateData", values);
								updateTextFieldTemplate(values, () => values)
									.then(() => {
										props.hideModal();
										addNewAlert({
											type: "success",
											message: successfulUpdate,
										});
									})
									.catch((err) => {
										addNewAlert({
											type: "error",
											message: err.response.status,
										});
									});
								break;
							case "date":
								// console.log("UPDATE dateTemplateData", values);
								updateDateFieldTemplate(values, () => values)
									.then(() => {
										props.hideModal();
										addNewAlert({
											type: "success",
											message: successfulUpdate,
										});
									})
									.catch((err) => {
										addNewAlert({
											type: "error",
											message: err.response.status,
										});
									});
								break;
							case "list":
								const listFormVal = { ...values };
								// console.log("UPDATE listTemplateData", listFormVal);
								updateListFieldTemplate(listFormVal, () => listFormVal)
									.then(() => {
										props.hideModal();
										addNewAlert({
											type: "success",
											message: successfulUpdate,
										});
									})
									.catch((err) => {
										addNewAlert({
											type: "error",
											message: err.response.status,
										});
									});
								break;
							case "media":
								// console.log("UPDATE mediaTemplateData", values);
								updateMediaFieldTemplate(values, () => values)
									.then(() => {
										props.hideModal();
										addNewAlert({
											type: "success",
											message: successfulUpdate,
										});
									})
									.catch((err) => {
										addNewAlert({
											type: "error",
											message: err.response.status,
										});
									});
								break;
							case "credit":
								// console.log("UPDATE creditTemplateData", values);
								updateCreditFieldTemplate(values, () => values)
									.then(() => {
										props.hideModal();
										addNewAlert({
											type: "success",
											message: successfulUpdate,
										});
									})
									.catch((err) => {
										addNewAlert({
											type: "error",
											message: err.response.status,
										});
									});
								break;
						}
					} else {
						const successfulNewField = "Successfully created new field";
						// create new field template
						switch (props.formType) {
							case "link":
								// console.log("linkTemplateData", values);
								createLinkFieldTemplate(values).then(() => {
									props.hideModal();
									addNewAlert({
										type: "success",
										message: successfulNewField,
									});
								});
								break;
							case "physical":
								// console.log("physicalTemplateData", values);
								createPhysicalComponentFieldTemplate(values).then(() => {
									props.hideModal();
									addNewAlert({
										type: "success",
										message: successfulNewField,
									});
								});
								break;
							case "text":
								// console.log("textFieldTempateData", values);
								createTextFieldTemplate(values).then(() => {
									props.hideModal();
									addNewAlert({
										type: "success",
										message: successfulNewField,
									});
								});
								break;
							case "date":
								// console.log("dateFieldTemplateData", values);
								createDateFieldTemplate(values).then(() => {
									props.hideModal();
									addNewAlert({
										type: "success",
										message: successfulNewField,
									});
								});
								break;
							case "list":
								const listFormVal = { ...values };
								// console.log("listieldTemplateData", listFormVal);
								createListFieldTemplate(listFormVal).then(() => {
									props.hideModal();
									addNewAlert({
										type: "success",
										message: successfulNewField,
									});
								});
								break;
							case "media":
								// console.log("mediaFieldTemplateData", values);
								createMediaFieldTemplate(values).then(() => {
									props.hideModal();
									addNewAlert({
										type: "success",
										message: successfulNewField,
									});
								});
								break;
							case "credit":
								// console.log("creditTemplateData", values);
								createCreditFieldTemplate(values).then(() => {
									props.hideModal();
									addNewAlert({
										type: "success",
										message: successfulNewField,
									});
								});
								break;
						}
					}
				} catch (e) {
					// console.log(e);
				}
			}
		});
	}

	return (
		<ModalContainer className={props.className ? props.className : ""}>
			<Icon
				icon="close"
				color={theme.colorPrimary}
				className="m-[1.5rem] ml-auto cursor-pointer"
				onClick={() => props.hideModal()}
				width="25px"
				height="25px"
			/>

			<div className="inner-container">
				<h2 className="text-[1.25rem]">
					{props.isUpdateForm ? "Update" : "Create"} {props.formTitle}
				</h2>

				<div className="flex gap-[1rem] items-center">
					<p>Active Field?</p>
					<ToggleSwitchField
						id="isActive"
						name="isActive"
						checked={values.isActive}
					/>
				</div>

				<TextField
					className="flex-1"
					name="name"
					placeholder="Field Name"
					value={values.name}
					readOnly={props.isUpdateForm}
				/>

				<TextField
					className="flex-1"
					name="title"
					placeholder="Field Title"
					value={values.title}
				/>

				<TextField
					className="flex-1"
					name="adminHelpText"
					placeholder="Admin Help Text"
					value={values.adminHelpText}
					component="textarea"
				/>

				<TextField
					className="flex-1"
					name="entrantHelpText"
					placeholder="Entrant Help Text"
					value={values.entrantHelpText}
					component="textarea"
				/>

				<TextField
					className="flex-1"
					name="jurorHelpText"
					placeholder="Juror Help Text"
					value={values.jurorHelpText}
					component="textarea"
				/>

				<div className="flex gap-[1rem]">
					<p>Displays for:</p>
					<CheckboxField
						name="displayForJurors"
						placeholder="Jurors"
						checked={values.displayForJurors}
					/>
					<CheckboxField
						name="displayForWinnersGallery"
						placeholder="Winners Gallery"
						checked={values.displayForWinnersGallery}
					/>
				</div>
				<div className="flex gap-[1rem]">
					{" "}
					<TextField
						className="min-w-[50%]"
						placeholder="Field Weight"
						name="fieldWeight"
						type="number"
						value={values.fieldWeight}
					/>
				</div>

				<hr className="mt-[1.5rem]" />

				{/* dynamic fields */}
				{props.children && props.children}

				<Button
					className="button-activation"
					onClick={() => PostNewField(values)}
				>
					{props.isUpdateForm ? "Save Configuration" : "Add New Template"}
				</Button>
			</div>
		</ModalContainer>
	);
};

export default FieldModal;

interface FieldModalProps {
	className?: string;
	formTitle: string;
	// formData?: FieldTemplate; // data is passed in when editing field template
	formType:
		| "link"
		| "physical"
		| "text"
		| "date"
		| "list"
		| "media"
		| "credit"
		| "entryType";
	hideModal: () => void;
	children?: React.ReactNode;
	isUpdateForm: boolean; // IS update form when data is passed in, otherwise it's a new form field
}

export interface DefaultFieldProps {
	hideModal: () => void;
	isFieldsUnlockable: boolean;
	isNewField?: boolean;
}
