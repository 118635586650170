import { Fragment, useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { ModalCard } from "../Modal/Modal";
import Icon from "../Icon/Icon";
import Modal from "../Modal/Modal";
import {
	MediaItem,
	MediaType,
} from "../../views/MediaLibrary/mediaLibrary.model.d";
import VideoPlaceholder from "../../assets/placeholders/video-landscape.png";
import AudioPlaceholder from "../../assets/placeholders/audio-landscape.png";
import ImagePlaceholder from "../../assets/placeholders/image-landscape.png";
import DocumentPlaceholder from "../../assets/placeholders/document-landscape.png";
import Image from "../Image/Image";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
	MediaContainer,
	RenderMediaPreview,
	ThumbnailSize,
	getMediaSrc,
	getThumbnailSrc,
} from "../../views/MediaLibrary/manageMediaLibrary";
import ImageComponent from "../Image/Image";
import { StyledLightBox } from "../../views/Judging/EntryDetail/MediaLightbox";
import Button from "../Button/Button";

export const VIDEO_EXTENSIONS = ["mp4", "mov", "m4p", "m4v"];

export const ImgOverlay = styled.div<{ show: boolean }>`
	position: relative;

	.expand-icon,
	.pdf-link {
		display: none;
	}

	${(p) =>
		p.show &&
		css`
			cursor: zoom-in;

			&:hover {
				.expand-icon {
					display: block;
					position: absolute;
					right: 1rem;
					bottom: 1rem;
				}

				.pdf-link {
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%);
				}

				img,
				.react-pdf__Document {
					transition: all 150ms ease;
					filter: brightness(15%);
				}
			}
		`}
`;

export const isVideoAvailable = (url: string) => {
	// check if url is valid video
	return VIDEO_EXTENSIONS.some((extension) =>
		url.toLowerCase().includes(extension)
	);
};

const MediaPlayerOverlay = styled.div`
	position: relative;

	& > img {
		filter: brightness(0.5);
	}
	.play-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		z-index: 99;
	}
`;

const MediaEnlargeModal = (props: MediaEnlargeModalProps) => {
	const theme = useTheme();
	const [show, setShow] = useState(false);

	const renderMediaThumbnail = () => {
		switch (props.media.type) {
			case MediaType.Image:
				return (
					<Image
						className="my-[1rem] max-w-[500px] max-h-[400px]"
						src={getThumbnailSrc(props.media.path)}
						placeholderSrc={ImagePlaceholder}
						alt={props.media.fileName}
						onClick={() => setShow(true)}
						refetchOnError
					/>
				);
			case MediaType.Document:
				return (
					<Image
						className="my-[1rem] max-w-[500px] max-h-[400px]"
						src={getThumbnailSrc(props.media)}
						placeholderSrc={DocumentPlaceholder}
						alt={props.media.fileName}
						onClick={() => setShow(true)}
						refetchOnError
					/>
				);
			case MediaType.Audio:
				return (
					<Image
						className="my-[1rem] max-w-[500px] max-h-[400px]"
						src={AudioPlaceholder}
						alt={props.media.fileName}
						onClick={() => setShow(true)}
						refetchOnError
					/>
				);
			case MediaType.Video:
				return (
					<MediaPlayerOverlay
						className="my-[1rem] max-w-[500px] max-h-[400px]"
						onClick={() => setShow(true)}
					>
						<Image
							className="w-full h-full"
							src={getThumbnailSrc(props.media)}
							placeholderSrc={VideoPlaceholder}
							alt={props.media.fileName}
							refetchOnError
						/>
						<Icon
							className="play-icon"
							icon="play"
							width="40px"
							height="40px"
							color="rgba(255,255,255,.75)"
						/>
					</MediaPlayerOverlay>
				);
		}
	};

	return props.media.type === MediaType.Document ? (
		<ImgOverlay
			show
			onClick={() => window.open(getMediaSrc(props.media), "_blank")}
		>
			{renderMediaThumbnail()}
			<Button className="pdf-link">Open Document In New Tab</Button>
		</ImgOverlay>
	) : (
		<>
			<Modal show={show}>
				<ModalCard
					className="!min-h-[500px]"
					title={props.media.fileName || "Preview"}
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							onClick={() => setShow(false)}
							width="35px"
							height="35px"
						/>
					}
					iconColor={theme.colorPrimary}
				>
					<div className="flex justify-center items-center flex-1">
						{show && <RenderMediaPreview mediaItem={props.media} hideOverlay />}
					</div>
				</ModalCard>
			</Modal>
			<ImgOverlay show>
				{renderMediaThumbnail()}

				<Icon
					className="expand-icon"
					icon="expand-arrows"
					color={theme.colorPrimary}
					width="25px"
					height="25px"
					onClick={() => setShow(true)}
				/>
			</ImgOverlay>
		</>
	);
};

// full screen media modal using path
export const ImgEnlargeModal = (props: ImgEnlargeModalProps) => {
	const theme = useTheme();
	const [show, setShow] = useState(false);

	const getFullsizeImage = props.path.replaceAll("-small", "-xlarge");

	useEffect(() => {
		show
			? (document.body.style.overflow = "hidden")
			: (document.body.style.overflow = "auto");
	}, [show]);

	return (
		<>
			<StyledLightBox show={show}>
				<Icon
					className="ml-auto m-[1rem]"
					icon="close"
					color={theme.colorCopyLightLight}
					onClick={() => setShow(false)}
					width="50px"
					height="50px"
				/>
				<div className={`overflow-y-auto p-[2rem]`}>
					<ImageComponent
						className="!h-auto"
						src={getFullsizeImage}
						placeholderSrc={props.path || ImagePlaceholder}
						alt={props.fileName}
						lazy
					/>
				</div>
			</StyledLightBox>
			<ImgOverlay className="aspect-video" show>
				<Image
					// className="my-[1rem] max-w-[500px] max-h-[400px]"
					// className="!object-contain"
					src={props.path}
					placeholderSrc={ImagePlaceholder}
					alt={props.fileName || "Preview"}
					onClick={() => setShow(true)}
					refetchOnError
				/>
				<Icon
					className="expand-icon"
					icon="expand-arrows"
					color={theme.colorPrimary}
					width="20px"
					height="20px"
					onClick={() => setShow(true)}
				/>
			</ImgOverlay>
		</>
	);
};

export default MediaEnlargeModal;

interface MediaEnlargeModalProps {
	media: MediaItem;
}

interface ImgEnlargeModalProps {
	path: string;
	fileName: string;
}
