import { useContext, useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import * as AiIcons from "react-icons/ai";
import Icon from "../Icon/Icon";
import StickyCard from "../StickyCard/StickyCard";
import Button from "../Button/Button";
import { ModalContext } from "../../App";
import { CSSTransition } from "react-transition-group";
import { DynamicTransitionDuration } from "../../globalStyles";

const ModalBG = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;

  &.modal-bg-enter {
    background: ${({ theme }) => theme.colorBackgroundDarkDark};
    backdrop-filter: blur(0);

    & > * {
      opacity: 0;
    }
  }
  &.modal-bg-enter-active,
  &.modal-bg-enter-done {
    background: ${({ theme }) => theme.colorBackdropDark};
    backdrop-filter: blur(6px);
    transition: all ${DynamicTransitionDuration} ease;

    & > * {
      opacity: 1;
      transition: opacity calc(${DynamicTransitionDuration} * 2) ease;
    }
  }
  &.modal-bg-exit {
    background: ${({ theme }) => theme.colorBackdropDark};
    backdrop-filter: blur(6px);

    & > * {
      opacity: 1;
    }
  }
  &.modal-bg-exit-active {
    background: ${({ theme }) => theme.colorBackgroundDarkDark};
    backdrop-filter: blur(0);
    transition: all 150ms ease;

    & > * {
      opacity: 0;
      transition: opacity calc(${DynamicTransitionDuration} * 2) ease;
    }
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colorBackgroundLightLight};
  min-width: 600px;

  @media only screen and (max-width: ${({ theme }) => theme.md}) {
    min-width: 90vw;
  }

  max-width: 90vw;

  .inner-container {
    display: flex;
    flex-direction: column;
    padding: 0 2.625rem;
    padding-bottom: 3rem;
    margin-top: 7px;
    gap: 3rem;
  }
`;
const FadeInModal = (props: {
  className?: string;
  show: boolean;
  children: React.ReactNode;
}) => {
  const container = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  // hide scrollbar
  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
    if (props.show) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = scrollbarWidth + "px";
    }
    return () => {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "";
    };
  }, [show]);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <CSSTransition
      in={show}
      timeout={150}
      unmountOnExit
      classNames="modal-bg"
      nodeRef={container}
    >
      <ModalBG className={props.className} ref={container}>
        {props.children}
      </ModalBG>
    </CSSTransition>
  );
};

const Modal = (props: ModalProps) => {
  return (
    <FadeInModal show={props.show}>
      <div
        className={`${
          props.className
            ? props.className
            : "overflow-y-auto overflow-x-hidden"
        } max-h-[90vh]`}
      >
        {props.children}
      </div>
    </FadeInModal>
  );
};

export const WarningModal = (props: WarningModalProps) => {
  const theme = useTheme();
  return (
    <FadeInModal show={props.show}>
      <ModalContainer className="relative flex items-center gap-[2rem] pb-[3rem] p-[1.5rem] md:p-[3rem]">
        <div className="flex flex-col items-center w-full">
          <Icon
            icon="close"
            color={theme.colorPrimary}
            className="ml-auto cursor-pointer"
            width="35px"
            height="35px"
            onClick={props.close}
          />
          {props.icon ? (
            props.icon
          ) : (
            <Icon
              icon="invalid"
              color={theme.colorPrimary}
              width="100px"
              height="100px"
            />
          )}
          <h1>{props.title}</h1>
        </div>
        {typeof props.message === "string" ? (
          <p className="text-center max-w-[500px]">{props.message}</p>
        ) : (
          props.message
        )}

        <div className="flex gap-[1.25rem]">{props.children}</div>
      </ModalContainer>
    </FadeInModal>
  );
};

// a new modal that uses a global context
export const NewModal = (props: NewModalProps) => {
  const theme = useTheme();
  const { setModal } = useContext(ModalContext);

  const handleClose = () => {
    setModal(null);
    props.onClickClose();
  };

  return (
    <FadeInModal show>
      <ModalContainer className="relative flex items-center gap-[2rem] pb-[3rem] p-[1.5rem] md:p-[3rem]">
        <div className="flex flex-col items-center w-full">
          <Icon
            icon="close"
            color={theme.colorPrimary}
            className="ml-auto cursor-pointer"
            width="35px"
            height="35px"
            onClick={() => handleClose()}
          />

          <Icon
            className={props.headerIcon === "invalid" ? "" : "mb-[1rem]"}
            icon={props.headerIcon}
            color={theme.colorPrimary}
            width={props.headerIconSize ? props.headerIconSize : "100px"}
            height={props.headerIconSize ? props.headerIconSize : "100px"}
          />

          <h1>{props.title}</h1>
        </div>
        {typeof props.message === "string" ? (
          <p className="text-center max-w-[500px]">{props.message}</p>
        ) : (
          props.message
        )}

        <div className="flex gap-[1.25rem]">
          {props.onConfirm ? (
            <>
              <Button
                className="button-light"
                icon="close"
                iconColor={theme.colorCopyDarkDark}
                iconSize="18px"
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button
                icon="check"
                onClick={() => {
                  props.onConfirm!();
                  setModal(null);
                }}
              >
                {props.confirmText ? props.confirmText : "Confirm"}
              </Button>
            </>
          ) : (
            <Button
              className="absolute left-0 bottom-0 w-full mt-auto"
              icon="check"
              onClick={() => handleClose()}
            >
              OK
            </Button>
          )}
        </div>
      </ModalContainer>
    </FadeInModal>
  );
};

export const ModalCard = styled(StickyCard)<ModalCardProps>`
  position: relative;
  top: 0;
  width: 780px;

  @media only screen and (max-width: ${({ theme }) => theme.md}) {
    width: 100%;
    max-width: 90vw;
  }
`;

export default Modal;

interface ModalProps {
  className?: string;
  show: boolean;
  children: React.ReactNode;
}

interface WarningModalProps {
  show: boolean;
  children: React.ReactNode;
  title: string;
  message: string | React.ReactNode;
  close(): void;
  icon?: React.ReactNode;
}

interface ModalCardProps {
  onClick?(e: any): void;
}

export interface NewModalProps {
  title: string;
  message: string | React.ReactNode;
  headerIcon: string;
  headerIconSize?: string;
  onClickClose(): void;
  onConfirm?(): void;
  confirmText?: string;
}
