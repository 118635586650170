import styled, { css, useTheme } from "styled-components";
import Button from "../Button/Button";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import parse from "html-react-parser";
import { roundTime } from "../../utils/getCountdownTimer";
import {
	ProgramDisplay,
	SubprogramCardModel,
} from "../../views/Admin/Program/ProgramInterfaces";
import { getCountdownTimer } from "../../utils/getCountdownTimer";
import { ProgramModel } from "../../views/Admin/Program/ProgramInterfaces";
import { DynamicTransitionDuration } from "../../globalStyles";
import { DisplayDate } from "../../views/Admin/Program/ProgramInterfaces";
import {
	ProgramCardContainer,
	ProgramHeader,
	ProgramDescription,
} from "../ProgramCard/ProgramCard";
import { StringLiteral } from "typescript";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getResizedVideoSrcAsync } from "../../views/MediaLibrary/manageMediaLibrary";

const MediaContainer = styled.div<{
	hasImage?: boolean;
	hasVideo?: boolean;
}>`
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	.program-img,
	.welcome-video {
		flex: 1;
		width: 100%;
		object-fit: cover;
		aspect-ratio: 16 / 9;
		min-width: 320px;
	}

	// if there's both  a video AND program img
	${(p) =>
		p.hasImage &&
		p.hasVideo &&
		css`
			padding: 2.4375rem;
			gap: 2.4375rem;
			max-width: 100%;
		`}

	// if there's either a video or program img
	${(p) =>
		(p.hasImage || p.hasVideo) &&
		!(p.hasImage && p.hasVideo) &&
		css`
			@media only screen and (min-width: ${({ theme }) => theme.xl}) {
				max-width: 40%;
			}
		`}
`;

const ParentProgramCardContainer = styled(ProgramCardContainer)<{
	hasImage?: boolean;
	hasVideo?: boolean;
}>`
	min-height: 0;
	max-width: 100%;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;

	// if there's either a video or program img
	${(p) =>
		(p.hasImage || p.hasVideo) &&
		!(p.hasImage && p.hasVideo) &&
		css`
			@media only screen and (min-width: ${({ theme }) => theme.xl}) {
				flex-wrap: nowrap;
			}
		`}

	&,
	${ProgramHeader}, ${ProgramDescription} {
		background: ${({ theme }) => theme.colorBackgroundMedium};
	}

	.program-description {
		margin-bottom: 0;
	}
`;

const ParentProgramCard = (props: ParentProgramCardProps) => {
	// State to store the welcome video URL after it is fetched
	const [welcomeVideo, setWelcomeVideo] = useState<string | null>(null);

	// Effect to fetch the resized video URL when the component mounts
	useEffect(() => {
		const fetchResizedVideo = async () => {
			if (props.welcomeVideo) {
				const videoSrc = await getResizedVideoSrcAsync(props.welcomeVideo);
				setWelcomeVideo(videoSrc);
			} else setWelcomeVideo(null);
		};
		fetchResizedVideo();
	}, [props.welcomeVideo]);

	return (
		<ParentProgramCardContainer
			className={`${props.className ? props.className : ""}`}
			hasImage={props.image !== undefined && props.imageDisplaysForEntrants}
			hasVideo={welcomeVideo !== null}
		>
			<MediaContainer
				hasImage={props.image !== undefined && props.imageDisplaysForEntrants}
				hasVideo={welcomeVideo !== null}
			>
				{welcomeVideo && (
					<video
						className="welcome-video"
						controls
						key={welcomeVideo + props.programId}
					>
						<source src={welcomeVideo} type="video/mp4" />
						<source src={welcomeVideo} type="video/quicktime" />
						Your browser does not support the video tag.
					</video>
				)}

				{props.image && props.imageDisplaysForEntrants && (
					<img className="program-img" src={props.image} alt="Program" />
				)}
			</MediaContainer>

			<div className="flex flex-col w-full">
				<ProgramHeader>
					<h1>{props.name}</h1>
				</ProgramHeader>
				{props.description && (
					<ProgramDescription>
						<div className="program-description">
							{parse(props.description)}
						</div>
					</ProgramDescription>
				)}
			</div>
		</ParentProgramCardContainer>
	);
};

export default ParentProgramCard;

interface ParentProgramCardProps extends ParentProgramCardModel {
	className?: string;
}

export interface ParentProgramCardModel {
	programId: number;
	name: string;
	description: string;
	welcomeVideo?: string;
	image?: string;
	imageDisplaysForEntrants?: boolean;
}
