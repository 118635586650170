import { useState, useEffect, useRef, ImgHTMLAttributes } from "react";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
	src: string;
	alt: string;
	// should image be rendered only when it is visible in the viewport
	lazy?: boolean;
	// src of the placeholder image
	placeholderSrc?: string;
	// should image be refetched if it fails to load
	refetchOnError?: boolean;
	// max number of times to refetch image - default 5
	maxRefetches?: number;
	// interval between refetches in ms - default 3s
	refetchInterval?: number;
}

function Image({
	src,
	alt,
	lazy,
	placeholderSrc,
	refetchOnError,
	maxRefetches = 5,
	refetchInterval = 3000,
	...rest
}: ImageProps) {
	const [isVisible, setIsVisible] = useState(!lazy);
	const imgRef = useRef<HTMLImageElement>(null);
	const [adjustedSrc, setAdjustedSrc] = useState(src || placeholderSrc);
	const [refetchCount, setRefetchCount] = useState(0);

	// trigger refetch if image fails to load
	const handleError = () => {
		// show placeholder image instead of broken image icon
		setAdjustedSrc(placeholderSrc);

		// refetch image if refetchOnError is true and maxRefetches has not been reached
		if (refetchOnError && refetchCount < maxRefetches) {
			// increment refetchCount
			setRefetchCount(refetchCount + 1);

			// refetch image after refetchInterval
			setTimeout(() => {
				setAdjustedSrc(src);
			}, refetchInterval);
		}
	};

	useEffect(() => {
		if (lazy) {
			const observer = new IntersectionObserver((entries) => {
				const [entry] = entries;
				setIsVisible(entry.isIntersecting);
			});

			if (imgRef.current) {
				observer.observe(imgRef.current);
			}

			return () => {
				if (imgRef.current) {
					observer.unobserve(imgRef.current);
				}
			};
		}
	}, [lazy]);

	return (
		<img
			className={`${rest.className ? rest.className : ""} ${
				adjustedSrc === placeholderSrc ? "object-cover" : "object-contain"
			}`}
			ref={imgRef}
			src={isVisible ? adjustedSrc : placeholderSrc}
			onError={handleError}
			alt={alt}
			{...rest}
		/>
	);
}

export default Image;
