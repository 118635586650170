import { useTheme } from "styled-components";
import "react-datepicker/src/stylesheets/datepicker.scss";
import { useState, useRef } from "react";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { FocusBorder, Label } from "../../components/FormFields/FieldTemplate";
import Icon from "../../components/Icon/Icon";
import FieldWrapper from "../../components/FormFields/FieldWrapper";
import { DateTime } from "luxon";
import { fieldRequired } from "../FieldModal/FieldModal";
import { DATE_FORMAT, DatePickerWrapper, StyledDatePicker } from "./DateField";
import {
	getLocalTime,
	getLuxonDateTime,
	jsToUnix,
	unixToJS,
} from "../../utils/timeUtilities";
// import { FormikProvider, useFormik } from "formik";

// global date time format, should be used consistenly in front/backend
export const DATETIME_FORMAT = "yyyy/MM/dd h:mm a";

const DateTimeField = (props: DateTimeFieldProps) => {
	const theme = useTheme();
	const { values, setFieldValue } = useFormikContext<any>();
	const [date, setDate] = useState<Date | null>(null);
	const [fieldError, setFieldError] = useState<string | null>(null);
	const [focused, setFocused] = useState(false);
	const dateIconRef = useRef<any>(null);
	const offsetNameShort = DateTime.now().offsetNameShort as string;

	// show time by default
	const showTimeSelect =
		props.showTimeSelect === undefined ? true : props.showTimeSelect;

	const min = props.min ? new Date(props.min) : undefined;
	const max = props.max ? new Date(props.max) : undefined;

	const minDateErr = (min: Date) =>
		`Date cannot be before ${getLocalTime(min, showTimeSelect, "long")}`;
	const maxDateErr = (max: Date) =>
		`Date cannot be after ${getLocalTime(max, showTimeSelect, "long")}`;

	const handleChange = (date: Date | null) => {
		props.onChange && props.onChange(jsToUnix(date));

		if (!props.nonFormik) {
			// set formik value
			setFieldValue(props.name, jsToUnix(date));
		}

		// handle date within component without formik
		setDate(date);
	};

	useEffect(() => {
		const isValid = props.value !== undefined && props.value !== null;

		if (isValid) {
			setDate(unixToJS(props.value!));

			if (!props.nonFormik) {
				setFieldValue(props.name, props.value);
			}
		} else setDate(null);
	}, [props.value]);

	useEffect(() => {
		if (props.error) {
			// custom field error
			setFieldError(props.error);
		} else if (props.isRequired && !date) {
			// missing req field
			setFieldError(fieldRequired);
		} else if (min && date && date < min) {
			// min date
			setFieldError(minDateErr(min));
		} else if (max && date && date > max) {
			// max date
			setFieldError(maxDateErr(max));
		} else {
			// clear error
			setFieldError(null);
		}
	}, [props.error, min, max, date]);

	return (
		<FieldWrapper
			className={props.className}
			name={props.name}
			success={props.success}
			error={fieldError || undefined}
		>
			{(success, error) => (
				<DatePickerWrapper
					hiddenlabel={false}
					className={`datepicker-wrapper ${focused ? "focused" : ""} ${
						date !== null ? "hasValue" : ""
					} ${props.className ? props.className : ""}`}
					onClick={() => dateIconRef.current.onInputClick()}
					readOnly={props.readOnly}
					disabled={props.disabled}
					success={success}
					error={error}
					timeZone={
						showTimeSelect && offsetNameShort ? offsetNameShort : undefined
					}
				>
					<Label htmlFor={props.name}>
						{props.placeholder ? props.placeholder : "Click to Select a Date"}
					</Label>

					<StyledDatePicker
						dateFormat={showTimeSelect ? DATETIME_FORMAT : DATE_FORMAT}
						selected={date}
						onChange={(date: Date | null) => handleChange(date)}
						ref={dateIconRef}
						showTimeSelect={showTimeSelect}
						onFocus={() => setFocused(true)}
						onBlur={() => setFocused(false)}
						disabled={props.readOnly || props.disabled} // disables click
					/>
					<Icon
						className="absolute right-[16px] top-[50%] translate-y-[-50%] pointer-events-none"
						icon="calendar"
						color={theme.colorCopyLight}
						width="15px"
					/>
					<FocusBorder />
				</DatePickerWrapper>
			)}
		</FieldWrapper>
	);
};

export default DateTimeField;

interface DateTimeFieldProps {
	name: string;
	className?: string;
	value?: number; // unix timestamp in seconds
	min?: number | Date;
	max?: number | Date;
	isRequired?: boolean;
	onChange?(date: number | null): void; // unix timestamp in seconds
	placeholder?: string;
	showTimeSelect?: boolean;
	nonFormik?: boolean;
	readOnly?: boolean;
	disabled?: boolean; // darker shade of readOnly
	success?: string;
	error?: string;
}
