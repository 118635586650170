import styled, { DefaultTheme, css, useTheme } from "styled-components";
import { Circle } from "../../components/ProgressCircle/ProgressCircle";
import Button, { adjustColor } from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { useEffect, useState, useContext } from "react";
import { WarningModal } from "../../components/Modal/Modal";
import {
	GalleryBatchCard,
	GalleryEntryCard,
	GalleryQuickVote,
	VoteOption,
} from "../Admin/Judging/JudgingInterfaces";
import { getThumbnailSrc } from "../MediaLibrary/manageMediaLibrary";
import {
	EntrySetAdminCard,
	JurorViewCard,
} from "../Admin/Judging/JudgingInterfaces";
import { ActiveBar } from "./JudgingGalleryNav";
import useAbstainModal from "./useAbstainModal";
import { Link } from "react-router-dom";
import TextSize from "../../components/TextSize/TextSize";

export const voteButtonColor = (
	voteOptionName: string,
	theme: DefaultTheme
) => {
	switch (voteOptionName) {
		case "In":
			return theme.colorIn;
		case "Out":
			return theme.colorOut;
		case "1":
			return theme.colorAwardOne;
		case "2":
			return theme.colorAwardTwo;
		case "3":
			return theme.colorAwardThree;
		case "4":
			return theme.colorAwardFour;
		case "5":
			return theme.colorAwardFive;
		case "6":
			return theme.colorAwardSix;
		case "7":
			return theme.colorAwardSeven;
		case "8":
			return theme.colorAwardEight;
		case "9":
			return theme.colorAwardNine;
		case "10":
			return theme.colorAwardTen;
		case "Gold":
			return theme.colorAwardGold;
		case "Silver":
			return theme.colorAwardSilver;
		case "Bronze":
			return theme.colorAwardBronze;
		case "Shortlist":
			return theme.colorShortlist;
		case "Grand":
			return theme.colorGrand;
		case "No Grand":
			return theme.colorNoGrand;
		case "Winner":
			return theme.colorGrand;
		case "No Winner":
			return theme.colorNoGrand;
		case "Score":
			return theme.colorActivation;
		case "Unvoted":
			return theme.colorAbstain;
		default:
			return theme.colorAbstain;
	}
};

export const AbstainVoteCSS = css`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 0.5rem 0;
	background: ${({ theme }) => theme.colorBackgroundLight};
	cursor: pointer;
	font-weight: ${({ theme }) => theme.fontSemiBold};
	text-align: center;
`;

const QuickVote = styled.div<{ show: boolean }>`
	position: absolute;
	display: flex;
	flex-direction: column;
	width: calc(100% - 2rem);
	height: calc(100% - 2rem);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	z-index: 9999;

	${(p) =>
		!p.show &&
		css`
			display: none;
		`};

	.header {
		display: flex;
		justify-content: flex-end;
		padding: 0.25rem 0;
		background: ${({ theme }) => theme.colorBackgroundDarkDark};
	}
	.body {
		display: flex;
		flex-direction: column;
		flex: 1;

		& > {
			img {
				height: 125px;
			}
		}

		.inner-body {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			flex: 1;
			gap: 1rem;
			padding: 1rem;
			/* padding-bottom: 0; */
			height: 100%;
		}

		.abstain {
			${AbstainVoteCSS};
		}
	}
`;

const QuickVoteButton = styled(Button)<{
	background: string;
}>`
	min-width: unset !important;
	padding: 0.5rem 1.25rem;
	/* width: 84px; */
	width: 120px;
	height: 45px;
	font-weight: ${({ theme }) => theme.fontSemiBold};
	color: ${({ theme }) => theme.colorCopyLightLight};
	background: ${(p) => p.background};

	span {
		font-size: ${({ theme }) => theme.xSmallSize};
	}

	&:hover {
		background: ${(p) => adjustColor(p.background)};
	}
`;

export const OneToTenQuickVoteButton = styled(Button)<{
	background: string;
}>`
	min-width: unset !important;
	font-weight: ${({ theme }) => theme.fontSemiBold};
	color: ${({ theme }) => theme.colorBackgroundMedium};
	background: ${(p) => p.background};
	width: 40px;
	height: 40px;
	padding: 0.5rem 0.5rem;

	span {
		font-size: ${({ theme }) => theme.xSmallSize};
	}

	&:hover {
		background: ${(p) => adjustColor(p.background)};
	}
`;

export const GalleryBody = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 1.5rem;
	border-left: 2px solid ${({ theme }) => theme.colorBorderLight};
	border-right: 2px solid ${({ theme }) => theme.colorBorderLight};
`;

const GalleryBottomBar = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-top: auto;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};
	padding: 1.5rem;
`;

export const StyledGalleryCard = styled.div<StyledGalleryCardProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	min-height: 350px;
	max-width: 350px;
	width: 100%;
	background: ${({ theme }) => theme.colorBackgroundLight};
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};

	@media only screen and (min-width: ${({ theme }) => theme.sm}) {
		min-height: 490px;
	}

	${(p) =>
		p.tint &&
		css`
			.tint {
				display: block;
			}
		`};

	.cover-img {
		width: 350px;
		height: 197px;
		object-fit: cover;
	}

	.quickVoteDetails {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 0.5rem 0;
		background: ${({ theme }) => theme.colorBackgroundDarkDark};

		p {
			font-weight: ${({ theme }) => theme.fontSemiBold};
			color: ${({ theme }) => theme.colorCopyLightLight};
			transition: opacity 0.3s ease-in-out;

			&:hover {
				opacity: 0.85;
			}
		}
	}

	.tint {
		display: none;
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.25);
		z-index: 99;
	}

	.overlay {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		background: rgba(0, 0, 0, 0.5);
		transition: 150ms ease;
		width: 100%;
		height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		z-index: 999;

		${ActiveBar} {
			bottom: 0;
		}

		.overlay-button {
			width: 170px;
		}

		.button-vote-in,
		.button-vote-out,
		.button-vote-abstain {
			cursor: default;

			min-width: unset;
			width: 80%;
		}

		.vote-buttons {
			visibility: hidden !important;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			transition: 150ms ease;
			opacity: 0;
		}

		@media only screen and (min-width: ${({ theme }) => theme.sm}) {
			.button-vote-in,
			.button-vote-out,
			.button-vote-abstain {
				width: 170px;
			}
		}
	}

	${(p) =>
		p.tint &&
		css`
			.overlay {
				opacity: 1;
			}
		`};

	.overlay:hover {
		.vote-buttons {
			opacity: 1;
			visibility: visible !important;
		}
	}

	&:hover {
		.overlay {
			opacity: 1;
			${ActiveBar} {
				width: 100%;
			}
		}
		${GalleryBody}, ${GalleryBottomBar}, .cover-img {
			filter: blur(1px);
		}
	}

	img {
		width: 100%;
		height: 150px;
	}
	.section {
		.heading {
			font-size: 1.75rem;
			font-family: ${({ theme }) => theme.fontHeading};
			font-weight: 600;
			line-height: 1.75rem;
		}
	}
`;

export const RenderVoteSelectionButton = (props: {
	className?: string;
	voteOption: VoteOption;
	disabled?: boolean;
	onClick?(): void;
}) => {
	const theme = useTheme();
	const isOneToTen = !isNaN(parseFloat(props.voteOption.name));

	if (isOneToTen) {
		return (
			<OneToTenQuickVoteButton
				disabled={props.disabled}
				className={`${props.className ? props.className : ""}`}
				onClick={() => props.onClick && props.onClick()}
				background={voteButtonColor(props.voteOption.name, theme)}
			>
				{props.voteOption.name}
			</OneToTenQuickVoteButton>
		);
	} else
		return (
			<QuickVoteButton
				disabled={props.disabled}
				className={`max-w-[170px] ${props.className ? props.className : ""}`}
				onClick={() => props.onClick && props.onClick()}
				background={voteButtonColor(props.voteOption.name, theme)}
			>
				{props.voteOption.name}
			</QuickVoteButton>
		);
};

export const SelectedVoteButton = styled(Button)<{ background: string }>`
	width: 170px;
	height: 55px;
	cursor: default;
	background: ${(p) => p.background};

	&:hover {
		background: ${(p) => p.background};
	}
`;

// for the grand round, abstain vote displays as no grand
export const showNoGrandForAbstain = (vote: GalleryQuickVote) => {
	const isGrandRound =
		vote && vote.voteOption ? vote.voteOption.roundTypeId === 4 : false;

	const isWinnerRound =
		vote && vote.voteOption ? vote.voteOption.roundTypeId === 5 : false;

	if (isGrandRound || isWinnerRound) {
		return vote && vote.voteOption
			? vote.voteOption.name === "Grand" || vote.voteOption.name === "Winner"
				? vote.voteOption.name
				: "X"
			: null;
	}
	return vote && vote.voteOption ? vote.voteOption.name : null;
};

const PhysicalComponentFlag = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 35px;
	padding: 0.5rem;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};
	color: ${({ theme }) => theme.colorCopyLightLight};
`;

const GalleryCard = (props: GalleryEntryCardProps) => {
	const theme = useTheme();
	const { showAbstainModal } = useAbstainModal();
	const [showVoteOptions, setShowVoteOptions] = useState(false);
	const [vote, setVote] = useState<null | GalleryQuickVote>(null);
	const [entryLocked, setEntryLocked] = useState(true);
	const [hasVoted, setHasVoted] = useState(false);

	useEffect(() => {
		if (props.vote.voteOption) {
			setHasVoted(true);
		}

		setVote(props.vote);
	}, [props.vote]);

	useEffect(() => {
		if (props.entrySetCard.id !== 0) {
			setEntryLocked(
				props.entrySetCard.entriesLocked || props.isLocked || props.juryLocked
			);
		}
	}, [props.entrySetCard.entriesLocked, props.isLocked, props.juryLocked]);

	const isOneToTen =
		vote && vote.voteOption
			? !isNaN(parseFloat(vote.voteOption.name))
			: undefined;

	return (
		<StyledGalleryCard tint={hasVoted || showVoteOptions || false}>
			{props.hasPhysicalComponent && props.showPhysicalComponents && (
				<PhysicalComponentFlag>
					<Icon
						className="mr-[1rem]"
						icon="cube"
						color={theme.colorBackgroundLightLight}
						width="18px"
						height="18px"
					/>
					<span className="font-bold">Physical Component Present</span>
				</PhysicalComponentFlag>
			)}

			<div className="tint"></div>
			<div className="overlay">
				{vote && vote.voteOption && (
					<SelectedVoteButton
						className={
							isOneToTen ? "!text-colorBackgroundMedium font-semibold" : ""
						}
						background={voteButtonColor(vote.voteOption.name, theme)}
					>
						{showNoGrandForAbstain(vote)}
					</SelectedVoteButton>
				)}
				<div className="vote-buttons">
					<Button
						className="overlay-button"
						to={`/judge/${props.juryId}/${props.entrySetId}/${props.batchId}/${props.entryId}/${props.vote.id}`}
					>
						Details & Vote
					</Button>
					{!props.isFeedbackRequired && (
						<Button
							className="overlay-button button-light"
							disabled={entryLocked}
							icon={entryLocked ? "lock" : undefined}
							onClick={() => setShowVoteOptions(true)}
						>
							{entryLocked
								? "Voting Locked"
								: hasVoted
								? "Change Vote"
								: "Quick Vote"}
						</Button>
					)}
				</div>
				<ActiveBar leftPosition="0" />
			</div>

			<QuickVote show={showVoteOptions}>
				<div className="header">
					<Icon
						icon="close"
						className="px-[0.25rem]"
						color={theme.colorCopyLightLight}
						onClick={() => setShowVoteOptions(false)}
					/>
				</div>
				<div className="body">
					<div>
						<img
							className="object-cover"
							src={
								(props.coverImage && getThumbnailSrc(props.coverImage!)) || ""
							}
							alt="Cover Image"
						/>
					</div>

					<div className="inner-body">
						{props.voteOptions?.flatMap((voteOption, i) => {
							if (!isNaN(parseFloat(voteOption.name))) {
								return (
									<OneToTenQuickVoteButton
										key={`${props.vote.id}.quickVote.${i}`}
										onClick={() => {
											props.updateVote(
												props.vote.id,
												props.entrySetId,
												props.batchId,
												props.roundId,
												voteOption.id,
												props.roundType,
												props.galleryCards
											);
											setShowVoteOptions(false);
										}}
										background={voteButtonColor(voteOption.name, theme)}
										/* icon={
                        props.vote.voteOption?.name === voteOption.name
                          ? "check"
                          : undefined
                      } */
									>
										{voteOption.name}
									</OneToTenQuickVoteButton>
								);
							}
							// hide no grand votes, abstain votes will be shown in the bottom as a different button
							else if (
								voteOption.name !== "Abstain" &&
								voteOption.name !== "No Grand" &&
								voteOption.name !== "No Winner"
							) {
								return (
									<QuickVoteButton
										key={`${props.vote.id}.quickVote.${i}`}
										onClick={() => {
											props.updateVote(
												props.vote.id,
												props.entrySetId,
												props.batchId,
												props.roundId,
												voteOption.id,
												props.roundType,
												props.galleryCards
											);

											setShowVoteOptions(false);
										}}
										background={adjustColor(
											voteButtonColor(voteOption.name, theme),
											-20
										)}
										icon={
											props.vote.voteOption?.name === voteOption.name
												? "check"
												: undefined
										}
									>
										{voteOption.name}
									</QuickVoteButton>
								);
							} else return [];
						})}
					</div>

					{/* show abstain vote button for non-grand/winner rounds */}
					{props.vote.roundTypeId !== 4 &&
						props.vote.roundTypeId !== 5 &&
						props.voteOptions?.flatMap((voteOption, i) => {
							if (voteOption.name === "Abstain") {
								return (
									<>
										<div
											key={`${props.vote.id}.abstain.${i}`}
											className="abstain"
											onClick={() => {
												if (localStorage.getItem("showed_abstain_modal")) {
													// Cast abstain vote without showing the modal
													props.updateVote(
														props.vote.id,
														props.entrySetId,
														props.batchId,
														props.roundId,
														voteOption.id,
														props.roundType,
														props.galleryCards
													);
												} else {
													// Show abstain modal before casting the vote
													showAbstainModal(() =>
														props.updateVote(
															props.vote.id,
															props.entrySetId,
															props.batchId,
															props.roundId,
															voteOption.id,
															props.roundType,
															props.galleryCards
														)
													);
												}

												setShowVoteOptions(false);
											}}
										>
											Abstain from Voting
										</div>
									</>
								);
							} else return [];
						})}
				</div>
				<Link
					className="no-underline"
					to={`/judge/${props.juryId}/${props.entrySetId}/${props.batchId}/${props.entryId}/${props.vote.id}`}
				>
					<div className="quickVoteDetails">
						<p>View Details & Vote</p>
					</div>
				</Link>
			</QuickVote>
			<img
				className="cover-img"
				src={(props.coverImage && getThumbnailSrc(props.coverImage!)) || ""}
				alt="Cover Image"
			/>

			<GalleryBody>
				<div className="section">
					<TextSize
						characterCount={props.brand ? props.brand.length : 0}
						breakpoints={[
							{
								characterCount: 40,
								fontSize: Number(theme.pSize.replaceAll("px", "")) - 2,
							},
						]}
					>
						<p className="subtext mb-[.25rem]">{props.brand}</p>
					</TextSize>

					<TextSize
						characterCount={props.title ? props.title.length : 0}
						breakpoints={[
							{
								characterCount: 40,
								fontSize: 1.75 * Number(theme.pSize.replaceAll("px", "")) - 2,
							},
						]}
					>
						<p className="font-medium heading">{props.title}</p>
					</TextSize>

					{/* <p className="subtext mb-[.25rem]">{props.brand}</p> */}
					{/* <h3 className="font-medium heading">{props.title}</h3> */}
				</div>
			</GalleryBody>
			<GalleryBottomBar>
				<Icon
					icon="eye"
					width="20px"
					height="20px"
					color={theme.colorPrimary}
				/>
				<p className="text-colorCopyLightLight font-bold ml-[.5rem]">
					Details & Vote
				</p>
			</GalleryBottomBar>
		</StyledGalleryCard>
	);
};

export default GalleryCard;

interface GalleryEntryCardProps extends GalleryEntryCard {
	voteOptions: VoteOption[];
	entrySetCard: EntrySetAdminCard;
	roundType: string;
	juryLocked: boolean;
	isFeedbackRequired: boolean;
	updateVote: (
		voteId: number,
		entrySetId: number,
		batchId: number,
		roundId: number,
		voteOptionId?: number,
		roundType?: string,
		galleryCards?: Array<GalleryBatchCard>
	) => void;
	vote: GalleryQuickVote;
	galleryCards: Array<GalleryBatchCard>;
	showPhysicalComponents: boolean;
}

interface StyledGalleryCardProps {
	tint?: boolean; // Make tint optional
}
