import styled, { useTheme } from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import { useState, useRef } from "react";
import { css } from "styled-components";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import {
	FormField,
	FocusBorder,
	Label,
} from "../../components/FormFields/FieldTemplate";
import Icon from "../../components/Icon/Icon";
import FieldWrapper from "../../components/FormFields/FieldWrapper";
import { DateTime } from "luxon";
import { fieldRequired } from "../FieldModal/FieldModal";
import { dateStringToJS, getRawDateString } from "../../utils/timeUtilities";

// global date format, should be used consistenly in front/backend
export const DATE_FORMAT = "yyyy/MM/dd";

export const DatePickerWrapper = styled(FormField)<{
	zIndex?: number;
	timeZone?: string;
}>`
	max-width: 220px;

	.react-datepicker {
		display: flex;
	}

	// time container
	.react-datepicker__time-container {
		width: auto;
		.react-datepicker__header--time {
			height: 59px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		${(p) =>
			p.timeZone &&
			css`
				.react-datepicker-time__header::after {
					content: ${`" (${p.timeZone ? p.timeZone : ""})"`};
				}
			`}
	}

	.react-datepicker-popper {
		${(p) =>
			p.zIndex
				? css`
						z-index: ${p.zIndex};
				  `
				: css`
						z-index: 999999;
				  `}
	}

	.react-datepicker__triangle {
		left: -15px !important;
	}
`;

export const StyledDatePicker = styled(DatePicker)`
	padding: 1.25rem 1rem 0.375rem;
	width: 100%;
	height: 56px;
	background: transparent;
	z-index: 0;

	&:focus {
		outline: none;
	}
`;

const DateField = (props: DateFieldProps) => {
	const theme = useTheme();
	const { values, setFieldValue } = useFormikContext<any>();
	const [date, setDate] = useState<string | null>(null);
	const [fieldError, setFieldError] = useState<string | null>(null);
	const [focused, setFocused] = useState(false);
	const dateIconRef = useRef<any>(null);
	const { min, max } = props;

	const minDateErr = (min: string) => `Date cannot be before ${min}`;
	const maxDateErr = (max: string) => `Date cannot be after ${max}`;

	const handleChange = (date: Date | null) => {
		const rawDateString = date === null ? null : getRawDateString(date);

		setDate(rawDateString);
		props.onChange && props.onChange(rawDateString);
		!props.nonFormik && setFieldValue(props.name, rawDateString);
	};

	useEffect(() => {
		const isValidDateString =
			props.value !== undefined &&
			props.value !== null &&
			!DateTime.fromFormat(props.value, DATE_FORMAT).invalidReason;
		if (isValidDateString) {
			setDate(props.value!);

			if (!props.nonFormik) {
				setFieldValue(props.name, props.value);
			}
		} else {
			setDate(null);
		}
	}, [props.value]);

	useEffect(() => {
		if (props.error) {
			// custom field error
			setFieldError(props.error);
		} else if (props.isRequired && !date) {
			// missing req field
			setFieldError(fieldRequired);
		} else if (date && min && date < min) {
			// min date
			setFieldError(minDateErr(min));
		} else if (date && max && date > max) {
			// max date
			setFieldError(maxDateErr(max));
		} else {
			// clear error
			setFieldError(null);
		}
	}, [props.error, min, max, date]);

	return (
		<FieldWrapper
			id={props.id}
			className={props.className}
			name={props.name}
			success={props.success}
			error={fieldError || undefined}
		>
			{(success, error) => (
				<DatePickerWrapper
					hiddenlabel={false}
					className={`datepicker-wrapper ${focused ? "focused" : ""} ${
						date !== null ? "hasValue" : ""
					} ${props.className ? props.className : ""}`}
					onClick={() => dateIconRef.current.onInputClick()}
					readOnly={props.readOnly}
					disabled={props.disabled}
					success={success}
					error={error}
				>
					<Label htmlFor={props.name}>
						{props.placeholder ? props.placeholder : "Click to Select a Date"}
					</Label>

					<StyledDatePicker
						dateFormat={DATE_FORMAT}
						// selected={date ? new Date(date) : null}
						selected={date ? dateStringToJS(date) : null}
						onChange={(date: Date) => handleChange(date)}
						ref={dateIconRef}
						onFocus={() => setFocused(true)}
						onBlur={() => setFocused(false)}
						disabled={props.readOnly || props.disabled} // disables click
					/>
					<Icon
						className="absolute right-[16px] top-[50%] translate-y-[-50%] pointer-events-none"
						icon="calendar"
						color={theme.colorCopyLight}
						width="15px"
					/>
					<FocusBorder />
				</DatePickerWrapper>
			)}
		</FieldWrapper>
	);
};

export default DateField;

interface DateFieldProps {
	name: string;
	className?: string;
	id?: string;
	value?: string;
	min?: string;
	max?: string;
	isRequired?: boolean;
	onChange?(date: string | null): void;
	placeholder?: string;
	nonFormik?: boolean;
	readOnly?: boolean;
	disabled?: boolean; // darker shade of readOnly
	success?: string;
	error?: string;
}
