import {
  ADD_NOTIFICATION,
  FETCH_NOTIFICATIONS_SUCCESS,
  NotificationActionTypes,
  REMOVE_NOTIFICATION,
  SET_FALLBACK,
  UPDATE_NOTIFICATION,
  UserNotification,
} from "./types";

export interface NotificationState {
  notifications: UserNotification[];
}

export const initialState: NotificationState = { notifications: [] };

const notificationsReducer = (
  state = initialState,
  action: NotificationActionTypes
): NotificationState => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case SET_FALLBACK:
      return {
        ...state,
        notifications: [],
      };
    case ADD_NOTIFICATION:
      // Check if the notification already exists in the state
      const existingNotification = state.notifications.find(
        (notif) => notif.id === action.payload.id
      );

      if (existingNotification) {
        // If the notification already exists, return the current state without changes
        return state;
      }

      // Otherwise, add the new notification and sort by `createdDate`
      return {
        ...state,
        notifications: [...state.notifications, action.payload].sort(
          (a, b) =>
            new Date(b.createdDate!).getTime() -
            new Date(a.createdDate!).getTime()
        ),
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((notif) =>
          notif.id === action.payload.id ? action.payload : notif
        ),
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notif) => notif.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default notificationsReducer;
