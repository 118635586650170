import { FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import Button from "../../../../components/Button/Button";
import CheckboxField from "../../../../components/FormFields/CheckboxField";
import DropdownField from "../../../../components/FormFields/DropdownField";
import Icon from "../../../../components/Icon/Icon";
import Modal, { ModalCard } from "../../../../components/Modal/Modal";
import StickyCard, {
  StickyCardBody,
  StickyContainer,
} from "../../../../components/StickyCard/StickyCard";
import Collapsible from "../../../../components/Collapsible/Collapsible";
import {
  BatchSelectionCard,
  EntrySetSelectionCard,
  useAdminLiveToolsHub,
} from "../../../../hooks/useAdminLiveToolsHub";
import {
  getEntryDetailsForJuror,
  getEntryNotes,
} from "../../../Entries/manageEntry";
import {
  EntryDetailsModel,
  EntryNote,
} from "../../../Judging/EntryDetail/EntryDetail";
import {
  GalleryBatchCard,
  GalleryEntryCard,
  LiveToolsMediaType,
  VoteOption,
} from "../JudgingInterfaces";
import { getVoteOptions } from "../manageJudging";
import { calculateEntryAwardIndicator } from "./AwardSummaryBox";
import EntryDetailPane from "./EntryDetailPane";
import EntryGridHeader from "./EntryGridHeader";
import EntryView, { EntrySortOptions, ViewType } from "./EntryView";
import JuryHeader from "./JuryHeader";
import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";

const BatchContainer = styled(StickyContainer)`
  top: 0 !important;
  height: 60vh !important;
  max-height: 100%;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colorBoxShadow};

  @media only screen and (min-width: ${({ theme }) => theme.lg}) {
    overflow-y: auto;
  }
`;

const InActiveDiv = styled.div`
  margin-left: 1rem;
  color: ${({ theme }) => theme.colorDanger};
`;

const ViewToggle = styled.span<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  ${(p) =>
    p.color &&
    css`
      .view-type {
        color: ${p.color};
      }
    `}
`;

const LiveToolsLanding = () => {
  const theme = useTheme();
  const {
    settings,
    batches,
    juryId,
    roundEntries,
    debug,
    roundOptions,
    mediaState,
    updateEntriesLocked,
    updateHighlightedRoundEntryId,
    updateLiveToolsSettings,
    juryCard,
    connection,
    updateVote,
    updateEntryLocked,
    updateMedia,
    scrollToTop,
  } = useAdminLiveToolsHub();
  const [showFilter, setShowFilter] = useState(
    settings.selectedRoundStructureId ? true : false
  );
  const [selectedRoundStructureId, setSelectedRoundStructureId] = useState(
    settings.selectedRoundStructureId
  );
  const [selectedBatches, setSelectedBatches] = useState<BatchSelectionCard[]>(
    []
  );
  const [entrySetSelections, setEntrySetSelections] = useState<
    Array<EntrySetSelectionCard>
  >([]);
  const [entryDisplayTitlePreview, setEntryDisplayTitlePreview] = useState(
    settings.entryDisplayTitlePreview
  );
  const [entryDetails, setEntryDetails] = useState<EntryDetailsModel | null>(
    null
  );
  const [entryNotes, setEntryNotes] = useState<Array<EntryNote>>([]);
  const [isActiveRound, setIsActiveRound] = useState(false);
  const [selectedRoundEntry, setSelectedRoundEntry] =
    useState<GalleryEntryCard | null>(null);
  const [clickedCard, setClickedCard] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [voteOptions, setVoteOptions] = useState<Array<VoteOption>>([]);

  const formikProps = useFormik({
    initialValues: {
      settings: { ...settings },
      entrySetSelections: entrySetSelections,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      debug && console.log("values: ", values);

      updateLiveToolsSettings({
        ...values.settings,
        selectedBatches: selectedBatches,
        entryDisplayTitlePreview: entryDisplayTitlePreview,
        selectedRoundStructureId: selectedRoundStructureId,
      });
      //setShowFilter(false);
    },
  });

  function calculateScoreIndicator(roundEntry: GalleryEntryCard) {
    return calculateEntryAwardIndicator(
      roundEntry,
      settings.roundStructure?.name || "",
      voteOptions,
      false,
      false
    );
  }

  function updateEntrySetSelections(roundStructureId: number) {
    if (connection && roundStructureId) {
      debug && console.log("updateEntrySetSelections", roundStructureId);
      connection
        .invoke("GetEntrySetSelectionCards", Number(juryId), roundStructureId)
        .then((cards) => {
          debug && console.log("GetEntrySetSelectionCards", cards);
          setEntrySetSelections(cards);
        });
    }
  }

  function getEntryDisplayTitlePreview(
    batches: BatchSelectionCard[],
    juryName: string
  ): string {
    if (batches.length === 0) {
      return juryName;
    }

    if (batches.length === 1) {
      return batches[0].label.split(" > ").slice(1).join(" > ").trim();
    }

    const splitLabels = batches.map((batch) => batch.label.split(" > "));
    let sharedFragment = "";
    let currentDepth = 1;

    while (true) {
      const hierarchyNamesAtCurrentDepth = splitLabels.map(
        (splitLabel) => splitLabel[currentDepth]
      );
      const firstHierarchyName = hierarchyNamesAtCurrentDepth[0];

      if (!firstHierarchyName) {
        break;
      }

      if (
        hierarchyNamesAtCurrentDepth.every(
          (name) => name === firstHierarchyName
        )
      ) {
        sharedFragment += (sharedFragment ? " > " : "") + firstHierarchyName;
        currentDepth++;
      } else {
        break;
      }
    }

    return sharedFragment ? sharedFragment : juryName;
  }

  // toggle show/hide checkbox for batches
  const toggleBatches = (batches: BatchSelectionCard[], isChecked: boolean) => {
    if (isChecked) {
      setSelectedBatches((prevSelectedBatches) => [
        ...prevSelectedBatches,
        ...batches.filter(
          (batch) =>
            prevSelectedBatches.findIndex((x) => x.id === batch.id) === -1
        ),
      ]);
    } else {
      setSelectedBatches((prevSelectedBatches) =>
        prevSelectedBatches.filter(
          (batch) => batches.findIndex((x) => x.id === batch.id) === -1
        )
      );
    }
  };

  useEffect(() => {
    if (selectedBatches && settings.juryName) {
      debug &&
        console.log("selected changed", selectedBatches, settings.juryName);

      const newTitle = getEntryDisplayTitlePreview(
        selectedBatches,
        settings.juryName
      );
      if (newTitle !== entryDisplayTitlePreview) {
        setEntryDisplayTitlePreview(newTitle);
      }
    }
  }, [selectedBatches, settings.juryName]);

  useEffect(() => {
    if (selectedRoundStructureId) {
      debug &&
        console.log(
          "selectedRoundStructureId Changed",
          settings.selectedRoundStructureId,
          selectedRoundStructureId
        );
      updateEntrySetSelections(Number(selectedRoundStructureId));
    }
  }, [selectedRoundStructureId]);

  useEffect(() => {
    if (entrySetSelections) {
      setIsActiveRound(
        entrySetSelections.length > 0
          ? entrySetSelections.every((x) => x.isActive)
          : false
      );
    }
  }, [entrySetSelections]);

  useEffect(() => {
    if (!settings.isActive) {
      updateMedia({
        ...mediaState,
        mediaType: LiveToolsMediaType.PauseSlide,
        awardLevel: undefined,
        brand: undefined,
        title: undefined,
        filePath: undefined,
        text: undefined,
        isPlaying: undefined,
      });
    }
  }, [settings.isActive]);

  useEffect(() => {
    if (selectedRoundEntry && (batches.length > 0 || roundEntries.length > 0)) {
      const roundEntry =
        batches.length > 0
          ? batches
              .flatMap((x) => x.roundEntries)
              .find((x) => x.id == selectedRoundEntry.id)
          : roundEntries.find((x) => x.id == selectedRoundEntry.id);

      if (
        roundEntry &&
        JSON.stringify(roundEntry) !== JSON.stringify(selectedRoundEntry)
      ) {
        debug && console.log("Found Selected Entry: ", roundEntry);
        setSelectedRoundEntry(roundEntry);
      } else if (!roundEntry) {
        setSelectedRoundEntry(null);
        setEntryDetails(null);
        setEntryNotes([]);
      }
    }
  }, [selectedRoundEntry, batches, roundEntries]);

  useEffect(() => {
    if (selectedRoundEntry && selectedRoundEntry.entryId !== entryDetails?.id) {
      // pass entry details to the entry detail pane
      getEntryDetailsForJuror(
        selectedRoundEntry.entryId,
        selectedRoundEntry.juryId
      )
        .then((response) => {
          if (response.status === 200) {
            setEntryDetails(response.data);
          } else {
            setEntryDetails(null);
          }
        })
        .catch((error) => {
          setEntryDetails(null);
          // Alert here?
        });

      getEntryNotes(Number(selectedRoundEntry.entryId)).then((response) => {
        if (response.status === 200) {
          setEntryNotes(response.data);
        }
      });
    }
  }, [selectedRoundEntry, entryDetails]);

  useEffect(() => {
    if (settings.selectedRoundStructureId) {
      debug &&
        console.log(
          "settings.selectedRoundStructureId Changed",
          settings.selectedRoundStructureId,
          selectedRoundStructureId
        );
      setSelectedRoundStructureId(settings.selectedRoundStructureId);
      getVoteOptions(settings.selectedRoundStructureId).then((response) => {
        if (response.status === 200) {
          setVoteOptions(response.data);
        }
      });
    }
  }, [settings.selectedRoundStructureId]);

  useEffect(() => {
    debug && console.log("set selected Batches", settings.selectedBatches);

    setSelectedBatches(settings.selectedBatches);
  }, [settings.selectedBatches]);

  return (
    <FormikProvider value={formikProps}>
      <BrowserHeader title={`Live Tools: ${juryCard.name}`} />
      <Modal show={showFilter}>
        <ModalCard
          className="!max-h-[90vh] !w-[90vw] overflow-hidden"
          title="Live Tools Filter & Sort"
          headerIcons={
            <Icon
              icon="close"
              color={theme.colorPrimary}
              onClick={() => setShowFilter(false)}
              width="35px"
              height="35px"
            />
          }
          iconColor={theme.colorPrimary}
        >
          <div className="flex flex-col overflow-auto lg:flex-row p-[1.5625rem_2.125rem] md:p-[1.5625rem_3.125rem] gap-[1.5625rem]">
            <div className="flex order-2 lg:order-1 flex-col gap-[1rem] flex-1">
              <h2>Batch Selection</h2>

              <span>
                <b>Scoreboard Title:</b>{" "}
                {entryDisplayTitlePreview
                  ? entryDisplayTitlePreview
                  : "Please select an entry set or batch"}
              </span>
              <BatchContainer>
                {formikProps.values.entrySetSelections &&
                  formikProps.values.entrySetSelections.length > 0 &&
                  formikProps.values.entrySetSelections
                    .sort((a, b) => a.id - b.id)
                    .map((eSet, i) => {
                      return (
                        <StickyCard
                          id={eSet.id.toString()}
                          key={eSet.id}
                          className="!max-h-fit"
                          titleElement={
                            <div className="flex items-center gap-[.5rem] text-[1rem]">
                              <CheckboxField
                                name={`checkbox.showEntrySet.${eSet.id}`}
                                label="show entry set"
                                checked={eSet.batches.every(
                                  (x) =>
                                    selectedBatches.findIndex(
                                      (b) => b.id === x.id
                                    ) > -1
                                )}
                                onChange={(isChecked) =>
                                  toggleBatches(eSet.batches, isChecked)
                                }
                                // partially checked if at least one batch (but not all) is checked
                                partialCheck={
                                  eSet.batches.some(
                                    (x) =>
                                      selectedBatches.findIndex(
                                        (b) => b.id === x.id
                                      ) > -1
                                  ) &&
                                  !eSet.batches.every(
                                    (x) =>
                                      selectedBatches.findIndex(
                                        (b) => b.id === x.id
                                      ) > -1
                                  )
                                }
                                nonFormik
                              />
                              {eSet.name}
                              {!eSet.isActive ? (
                                <InActiveDiv>(InActive Round)</InActiveDiv>
                              ) : (
                                ""
                              )}
                            </div>
                          }
                          headerIcons={[
                            <Icon
                              icon="caret"
                              color={theme.colorPrimary}
                              width="20px"
                              height="20px"
                              rotation={
                                !eSet.accordionCollapsed ? "180deg" : "0deg"
                              }
                              onClick={() =>
                                formikProps.setFieldValue(
                                  `entrySetSelections[${i}].accordionCollapsed`,
                                  !eSet.accordionCollapsed
                                )
                              }
                            />,
                          ]}
                        >
                          <Collapsible isCollapsed={!eSet.accordionCollapsed}>
                            <StickyCardBody className="flex flex-col !p-0">
                              {eSet.batches.map((batch, batchIndex) => {
                                return (
                                  <div
                                    className="flex p-[.5rem_2rem] items-center"
                                    key={batch.id}
                                  >
                                    <CheckboxField
                                      name={`checkbox.showBatch.${batch.id}`}
                                      label="show batch"
                                      onChange={(isChecked) =>
                                        toggleBatches([batch], isChecked)
                                      }
                                      checked={
                                        selectedBatches.findIndex(
                                          (b) => b.id === batch.id
                                        ) > -1
                                      }
                                      nonFormik
                                    />
                                    <p className="mr-[.5rem] text-xSmallSize">
                                      {batch.label
                                        .split(">")
                                        .slice(1)
                                        .join(">")
                                        .trim()}
                                    </p>
                                    <p className="ml-auto whitespace-nowrap text-xSmallSize">
                                      {batch.entryCount} entries
                                    </p>
                                  </div>
                                );
                              })}
                            </StickyCardBody>
                          </Collapsible>
                        </StickyCard>
                      );
                    })}
              </BatchContainer>
            </div>
            <div className="flex order-1 lg:order-2 flex-col gap-[1rem]">
              <div className="flex items-center gap-[1rem]">
                <ViewToggle
                  onClick={() =>
                    formikProps.setFieldValue(
                      `settings.displayConfiguration`,
                      ViewType.List
                    )
                  }
                  color={
                    formikProps.values.settings.displayConfiguration ===
                    ViewType.List
                      ? theme.colorPrimary
                      : theme.colorCopyLight
                  }
                >
                  <Icon
                    icon="list"
                    color={
                      formikProps.values.settings.displayConfiguration ===
                      ViewType.List
                        ? theme.colorPrimary
                        : theme.colorCopyLight
                    }
                    width="25px"
                    height="25px"
                  />
                  <p className="view-type">List View</p>
                </ViewToggle>

                <ViewToggle
                  onClick={() =>
                    formikProps.setFieldValue(
                      `settings.displayConfiguration`,
                      ViewType.Grid
                    )
                  }
                  color={
                    formikProps.values.settings.displayConfiguration ===
                    ViewType.Grid
                      ? theme.colorPrimary
                      : theme.colorCopyLight
                  }
                >
                  <Icon
                    icon="grid"
                    color={
                      formikProps.values.settings.displayConfiguration ===
                      ViewType.Grid
                        ? theme.colorPrimary
                        : theme.colorCopyLight
                    }
                    width="20px"
                    height="20px"
                  />
                  <p className="view-type">Grid View</p>
                </ViewToggle>
              </div>

              <DropdownField
                className="mt-[1rem]"
                name="settings.selectedRoundStructureId"
                options={roundOptions}
                placeholder="Round"
                value={selectedRoundStructureId}
                onChange={(event) => {
                  setSelectedRoundStructureId(
                    Number(event.currentTarget.value)
                  );
                  // clear all selected batches
                  toggleBatches(selectedBatches, false);
                }}
              />
              <DropdownField
                name="settings.sort"
                options={[
                  {
                    value: EntrySortOptions.PreviousRoundScoreDescending,
                    label: "Prev. Round Score",
                  },
                  {
                    value: EntrySortOptions.PreviousRoundScoreAscending,
                    label: "Prev. Round Score (Reversed)",
                  },
                  {
                    value: EntrySortOptions.CurrentScoreDescending,
                    label: "Current Score",
                  },
                  {
                    value: EntrySortOptions.CurrentScoreAscending,
                    label: "Current Score (Reversed)",
                  },
                  {
                    value: EntrySortOptions.EntryBrandAscending,
                    label: "Entry  Brand",
                  },
                  {
                    value: EntrySortOptions.EntryBrandDescending,
                    label: "Entry  Brand (Reversed)",
                  },
                  {
                    value: EntrySortOptions.EntryTitleAscending,
                    label: "Entry Title",
                  },
                  {
                    value: EntrySortOptions.EntryTitleDescending,
                    label: "Entry Title (Reversed)",
                  },
                ]}
                placeholder="Sort"
                hideEmptyOption
                value={formikProps.values.settings.sort}
              />
              <CheckboxField
                name="settings.includeBatchDivisions"
                placeholder="Include Batch Divisions"
                checked={formikProps.values.settings.includeBatchDivisions}
              />
              <CheckboxField
                name="settings.showProgressIndicators"
                placeholder="Show Progress Indicators"
                checked={formikProps.values.settings.showProgressIndicators}
              />
              <CheckboxField
                name="settings.showPhysicalComponents"
                placeholder="Show Physical Components"
                checked={formikProps.values.settings.showPhysicalComponents}
              />
              <Button
                className="mt-auto"
                onClick={() => {
                  formikProps.handleSubmit();
                  setShowFilter(false);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </ModalCard>
      </Modal>
      <JuryHeader
        juryCard={juryCard}
        roundName={
          (roundOptions &&
            roundOptions
              .find((x) => x.value === settings.selectedRoundStructureId)
              ?.label?.toString()) ||
          ""
        }
        updateLiveToolsSettings={updateLiveToolsSettings}
        settings={settings}
        updateMedia={updateMedia}
        liveMediaState={mediaState}
        selectedBatches={settings.selectedBatches}
      />
      <EntryGridHeader
        settings={settings}
        updateEntriesLocked={updateEntriesLocked}
        onClickFilter={() => setShowFilter(true)}
      />
      <EntryView
        onClickCard={(roundEntry) => {
          setClickedCard(!clickedCard);

          if (roundEntry) {
            setSelectedRoundEntry(roundEntry);
          }
        }}
        settings={settings}
        updateHighlightedRoundEntryId={updateHighlightedRoundEntryId}
        batches={batches}
        roundEntries={roundEntries}
        searchVal={searchVal}
      />
      <EntryDetailPane
        settings={settings}
        entryDetails={entryDetails}
        entryNotes={entryNotes}
        roundEntry={selectedRoundEntry}
        updateVote={updateVote}
        updateEntryLocked={updateEntryLocked}
        updateHighlightedRoundEntryId={updateHighlightedRoundEntryId}
        clickedEntry={clickedCard}
        resetClickedEntry={() => setClickedCard(false)}
        isActiveRound={isActiveRound}
        onSearch={(search) => setSearchVal(search)}
        voteOptions={voteOptions}
        calculateScoreIndicator={calculateScoreIndicator}
        updateMedia={updateMedia}
        mediaState={mediaState}
        scrollToTop={scrollToTop}
        juryCard={juryCard}
      />
    </FormikProvider>
  );
};

export default LiveToolsLanding;

// used exclusively for the live tools page, includes props to toggle hide/show/collapsed states for each entry set and batch
export interface LiveToolsEntrySets {
  [eSetId: string]: {
    entrySetTitle: string;
    // showEntrySet: boolean;
    accordionCollapsed: boolean;
    batches: {
      [batchId: string]: LiveToolsBatch;
    };
  };
}

interface LiveToolsBatch extends GalleryBatchCard {
  batchAncestry: string;
  entryCount: number;
  show: boolean;
}
