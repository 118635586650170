import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import {
	FieldError,
	FieldSuccess,
	SUCCESS_MESSAGE_DURATION,
} from "./FieldTemplate";
import Icon from "../Icon/Icon";
import { useTheme } from "styled-components";
import { useFormikContext } from "formik";

// HOC to reset success message after 2s
export const SuccessMessageWrapper = (props: {
	children: (
		success: string | undefined,
		setSuccess: React.Dispatch<React.SetStateAction<string | undefined>>
	) => React.ReactNode;
}) => {
	const [success, setSuccess] = useState<string | undefined>();
	const [timerId, setTimerId] = useState<any>(null);

	// prevents success message from flickering when user autosaves too fast
	useEffect(() => {
		// Clear the previous timer if it exists
		if (timerId) {
			clearTimeout(timerId);
		}

		// Start a new timer only if success is not undefined
		if (success !== undefined) {
			const newTimerId = setTimeout(() => {
				setSuccess(undefined);
			}, SUCCESS_MESSAGE_DURATION);

			// Store the new timer ID in the state
			setTimerId(newTimerId);
		}

		// Clean up the timer when the component unmounts or success changes to undefined
		return () => {
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, [success]);

	return <>{props.children(success, setSuccess)}</>;
};

// field container used to display error and success messages
const FieldWrapper = (props: FieldWrapperProps) => {
	const theme = useTheme();
	const { errors } = useFormikContext<any>();

	// use formik or custom defined error
	const error = errors[props.name]
		? String(errors[props.name])
		: props.error
		? props.error
		: undefined;

	const [success, setSuccess] = useState<string | undefined>(props.success);

	// hide success message after short delay
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setSuccess(undefined);
	// 	}, SUCCESS_MESSAGE_DURATION);
	// }, [success]);

	useEffect(() => {
		setSuccess(props.success);
	}, [props.success]);

	return (
		<div
			className={`relative ${props.className ? props.className : ""} ${
				props.error || (errors && errors[props.name]) ? "mb-[30px]" : ""
			}`}
			id={props.id}
			{...(props.attributes &&
				Object.values(props.attributes).length > 0 &&
				props.attributes)}
			{...(props.onClick && {
				onClick: (e) => props.onClick!(e),
			})}
		>
			{props.children(success, error)}
			{/* show custom error or formik error */}
			{success === undefined &&
				(props.error ? (
					<FieldError>{props.error}</FieldError>
				) : (
					errors &&
					errors[props.name] && <FieldError>{errors[props.name]}</FieldError>
				))}

			{!props.hideSuccessMessage && (
				<CSSTransition
					in={success !== undefined}
					timeout={SUCCESS_MESSAGE_DURATION}
					unmountOnExit
					classNames="success-message"
				>
					<FieldSuccess
						position={
							props.successMessagePosition
								? props.successMessagePosition
								: undefined
						}
					>
						<Icon
							icon="check"
							color={theme.colorSuccess}
							width="1rem"
							height="1rem"
						/>
						{success && <span>{success}</span>}
					</FieldSuccess>
				</CSSTransition>
			)}
		</div>
	);
};

export default FieldWrapper;

interface FieldWrapperProps {
	className?: string;
	id?: string;
	// accessibility attributes
	attributes?: {
		[key: string]: string;
	};
	name: string;
	readOnly?: boolean;
	children: (
		success: string | undefined,
		error: string | undefined
	) => React.ReactNode;
	success?: string;
	error?: string;
	onClick?(e: React.MouseEvent<HTMLDivElement>): void;
	hideSuccessMessage?: boolean;
	successMessagePosition?: "relative" | "absolute";
}
