import { useFormik, FormikProvider } from "formik";
import { useTheme } from "styled-components";
import assetsConfig from "../../assetsConfig";
import * as Yup from "yup";
import Button from "../Button/Button";
import TextField from "../FormFields/TextField";
import Icon from "../Icon/Icon";
import Modal, { ModalCard, ModalContainer } from "../Modal/Modal";
import { CompanyModel } from "../../views/Company/company.model";
import { createCompany } from "../../views/Company/manageCompany";

export const NewCompanyModal = (props: NewCompanyModalProps) => {
  const theme = useTheme();

  const fieldRequired = "This field is required";

  const blankCompanyModel: CompanyModel = {
    name: "",
  };

  async function PostAddCompany(AddCompany: any) {
    try {
      const newCompany: CompanyModel = {
        name: AddCompany.name,
      };

      const response = await createCompany(newCompany);
      if (response?.status == 201) {
        if (response.data && response.data.id) {
          window.open(`/edit-company/${response.data.id}`);
        }
        props.onClickHide();
        formikProps.resetForm();
      }
    } catch (error: any) {
      //console.log(error);
      // setErrors(error.response.data);
    }
  }

  const formikProps = useFormik({
    initialValues: blankCompanyModel,
    enableReinitialize: true,
    onSubmit: (values) => {
      PostAddCompany(values);
    },
    validationSchema: Yup.object({
      name: Yup.string().required(fieldRequired),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Modal show={props.show}>
      <ModalCard
        className="!min-h-[250px] !w-[500px]"
        title={`Add ${assetsConfig.labels.company.singular}`}
        headerIcons={
          <Icon
            icon="close"
            color={theme.colorCopyLightLight}
            onClick={props.onClickHide}
          />
        }
        iconColor={theme.colorCopyLightLight}
      >
        <div className="flex flex-col py-[1.5625rem] px-[1.5625rem] gap-[1.5625rem]">
          <FormikProvider value={formikProps}>
            <TextField
              name="name"
              placeholder={`${assetsConfig.labels.company.singular} Name`}
            />
            <Button
              className="w-auto ml-auto"
              iconLeft="plus"
              iconColor={theme.colorCopyLightLight}
              onClick={formikProps.submitForm}
              type="submit"
            >
              Add
            </Button>
          </FormikProvider>
        </div>
      </ModalCard>
    </Modal>
  );
};

interface NewCompanyModalProps {
  show: boolean;
  onClickHide(): void;
}
