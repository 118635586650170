import { resolve } from "path";
import { useCallback, useReducer, useEffect, useRef, useState } from "react";
import styled, { css, useTheme } from "styled-components";
import Icon from "../components/Icon/Icon";
import Loading from "../components/Loading/Loading";
import classNames from "classnames";

export const StyledSelectableContainer = styled.div<{
	selected: boolean;
	completed: boolean;
	errored: boolean;
	table: boolean;
}>`
	position: relative;
	box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colorBoxShadow};
	cursor: pointer;
	min-height: 2rem;

	${(p) =>
		p.table &&
		css`
			box-shadow: none;
		`}

	.children {
		height: 100%;
	}

	${(p) =>
		p.selected &&
		css`
		outline: 2px solid ${
			p.completed
				? p.theme.colorSuccess
				: p.errored
				? p.theme.colorDanger
				: p.theme.colorActivation
		};

  & .selected-container {
	// table = media list
	// no table = credit list
	${
		p.errored && p.table
			? css`
					position: relative;
					top: 0;
					left: 0;
			  `
			: css`
					position: absolute;
					top: 10px;
					left: 10px;
			  `
	};

	right: 10px;
	padding: 1rem;
    z-index: 100;
    display: flex;
    flex-direction: row;
    gap: 1rem;

	&.selected-error {
		background: ${p.theme.colorBackgroundLight};
	}

    & .icon {
      border-radius: 100%;
      padding 5px;
      height: 25px;
      background: ${
				p.completed
					? p.theme.colorSuccess
					: p.errored
					? p.theme.colorDanger
					: p.theme.colorActivation
			};
    }
    & .errored {
      font-weight: bold;
      color: ${p.theme.colorDanger};
    }
  }

   .children  {
    filter: brightness(0.75); 
	background: ${p.theme.colorBackgroundLightLight};
  }

  `}
`;

export const SelectableContainer = <T extends HasId>(
	props: SelectableContainerProps<T>
) => {
	const theme = useTheme();
	const [completed, setCompleted] = useState(false);
	const [selected, setSelected] = useState(false);
	const [errored, setErrored] = useState<ErroredItem>();
	const [processing, setProcessing] = useState(false);

	useEffect(() => {
		setCompleted(props.state.completed.some((e) => e.id === props.item.id));
		setSelected(props.state.items.some((e) => e.id === props.item.id));
		setErrored(props.state.errored.find((e) => e.item.id === props.item.id));
		setProcessing(props.state.pending.some((e) => e.id === props.item.id));
	}, [props.item, props.state]);

	useEffect(() => {}, [completed]);

	return (
		<StyledSelectableContainer
			table={props.table ? true : false}
			className={props.className}
			selected={selected}
			completed={completed}
			errored={errored ? true : false}
			key={props.key ? props.key : ""}
			onClick={props.onClick && props.onClick}
		>
			{processing ? (
				<Loading size="35px" fullScreen={false} showLogo={false} />
			) : (
				<div className="children">{props.children && props.children}</div>
			)}

			{selected && (
				<div
					className={`selected-container ${errored ? "selected-error" : ""}`}
				>
					<Icon
						className="icon"
						icon={errored ? "close" : "check"}
						color={theme.colorCopyLightLight}
						width="15px"
						height="15px"
					/>
					{errored && <p className="errored">{errored.error}</p>}
				</div>
			)}
		</StyledSelectableContainer>
	);
};

interface SelectableContainerProps<T extends HasId> {
	item: T;
	state: TaskState<T>;
	onClick?: ((...args: any[]) => void) | (() => void);
	children?: React.ReactNode;
	key?: any;
	className?: string;
	table?: boolean;
}

interface HasId {
	id?: any;
}

export const BottomBar = styled.div<{ show: boolean }>`
	display: flex;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};
	color: ${({ theme }) => theme.colorCopyLightLight};
	justify-content: center;

	/* fix to the bottom */
	position: fixed;
	left: 0;
	bottom: -75px;
	width: 100%;
	z-index: 9999;
	height: 75px;
	/* padding: 1rem; */
	flex-wrap: wrap;
	transition: all 150ms ease;

	${(p) =>
		p.show &&
		`
  bottom: 0;
  `}

	@media (min-width: ${({ theme }) => theme.lg}) {
		flex-wrap: nowrap;
	}
	align-items: center;

	.button-transparent {
		height: 100%;
		border-left: 2px solid ${({ theme }) => theme.colorBorder};

		&:last-of-type {
			border-right: 2px solid ${({ theme }) => theme.colorBorder};
		}

		flex-wrap: nowrap;
		flex-direction: row;

		@media only screen and (max-width: 500px) {
			min-width: 95px;
		}
	}

	.bar-text {
		width: 130px;
		color: ${({ theme }) => theme.colorCopyLightLight};
	}
`;

// Constants
export const LOADED = "LOADED";
export const INIT = "INIT";
export const PENDING = "PENDING";
export const TASKS_COMPLETED = "TASKS_COMPLETED";
export const TASK_ERROR = "TASK_ERROR";

export interface TaskHandlerReturnType<T> extends TaskState<T> {
	onSubmit: (task: (item: T) => Promise<T> | Promise<any>, items?: T[]) => void;
	loadItems: (items: T[]) => void;
	reset: () => void;
	handleSelectItem: (item: T) => void;
}

export interface TaskState<T> {
	items: T[];
	pending: T[];
	next: T | null;
	prev: T | null;
	task?: any;
	processing: boolean;
	completed: T[];
	status: string;
	errored: ErroredItem[];
}

interface ErroredItem {
	item: any;
	error: string;
}

type Action<T> =
	| { type: "load"; items: T[] }
	| { type: "submit"; items?: T[] }
	| { type: "next"; next: T }
	| { type: "task-completed"; pending: T[]; prev: T }
	| { type: "tasks-completed" }
	| { type: "task-error"; error: string; prev: T; pending: T[] }
	| { type: "reset"; initialState: TaskState<T> };

/**
 * useTaskHandler is a custom hook to manage and process an array of tasks.
 *
 * @returns {TaskHandlerReturnType<T>} An object with the following properties:
 *  - items: An array of the original tasks.
 *  - pending: An array of tasks that are yet to be processed.
 *  - next: The task that is currently being processed.
 *  - prev: The task that was last processed.
 *  - task: The current task function.
 *  - processing: A boolean indicating whether the tasks are currently being processed.
 *  - completed: An array of tasks that have been successfully processed.
 *  - status: A string representing the current status of the tasks ('idle', 'loaded', 'init', 'pending', 'tasks_completed', 'task_error').
 *  - errored: An array of tasks that resulted in an error when processed. Each item in the array is an object containing the original task and the error that occurred.
 *  - onSubmit: A function that accepts an array of items and a task function, then starts processing the tasks.
 *  - loadItems: A function that loads an array of tasks into the hook.
 *  - reset: A function that resets the state to its initial state.
 */
const useTaskHandler = <T extends HasId>(): TaskHandlerReturnType<T> => {
	const initialState: TaskState<T> = {
		items: [],
		pending: [],
		next: null,
		prev: null,
		processing: false,
		completed: [],
		status: "idle",
		errored: [],
	};

	const reducer = <T,>(
		state: TaskState<T>,
		action: Action<T>
	): TaskState<T> => {
		switch (action.type) {
			case "load":
				return {
					...state,
					items: action.items,
					processing: false,
					pending: [],
					completed: [],
					errored: [],
					next: null,
					prev: null,
					status: LOADED,
				};
			case "submit":
				return {
					...state,
					processing: true,
					pending: action.items ? action.items : state.items,
					items: action.items ? action.items : state.items,
					completed: [],
					errored: [],
					next: null,
					prev: null,
					status: INIT,
				};
			case "next":
				return {
					...state,
					next: action.next,
					status: PENDING,
				};
			case "task-completed":
				return {
					...state,
					next: null,
					pending: action.pending,
					completed: [...state.completed, action.prev],
				};
			case "tasks-completed":
				return { ...state, processing: false, status: TASKS_COMPLETED };
			case "task-error":
				return {
					...state,
					next: null,
					errored: [
						...state.errored,
						{ item: action.prev, error: action.error },
					],
					pending: action.pending,
				};
			case "reset":
				return action.initialState;
			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);
	const [taskFunction, setTaskFunction] = useState<(item: T) => Promise<T>>();

	const onSubmit = useCallback(
		(task: any, items?: T[]) => {
			if (state.items.length || (items && items.length)) {
				setTaskFunction(() => task);
				dispatch({ type: "submit", items: items });
			} else {
				window.alert("You don't have any tasks loaded.");
			}
		},
		[state.items.length]
	);

	const loadItems = (items: T[]) => {
		dispatch({ type: "load", items: items });
	};

	const reset = () => {
		dispatch({ type: "reset", initialState });
	};

	const handleSelectItem = (item: T) => {
		const items = state.items as T[];

		const newItems = items.some((stateItem) => item.id === stateItem.id)
			? items.filter((stateItem) => item.id !== stateItem.id)
			: [...items, item];

		dispatch({ type: "load", items: newItems });
	};

	// Sets the next task when it detects that its ready to go
	useEffect(() => {
		if (state.pending.length && state.next == null) {
			const next = state.pending[0];
			dispatch({ type: "next", next });
		}
	}, [state.next, state.pending]);

	// Processes the next pending task when ready
	useEffect(() => {
		if (
			state.pending.length &&
			state.next &&
			taskFunction &&
			typeof taskFunction === "function"
		) {
			const { next } = state;
			taskFunction(next as T)
				.then(() => {
					const prev = next;
					const pending = state.pending.slice(1);
					dispatch({ type: "task-completed", prev, pending });
				})
				.catch((error) => {
					console.log(error);
					const prev = next;
					const pending = state.pending.slice(1);
					dispatch({ type: "task-error", error, prev, pending });
				});
		}
	}, [state.next, state.pending, taskFunction]);

	// Ends the task process
	useEffect(() => {
		if (!state.pending.length && state.processing) {
			dispatch({ type: "tasks-completed" });
		}
	}, [state.pending.length, state.processing]);

	return {
		...(state as TaskState<T>),
		onSubmit,
		loadItems,
		reset,
		handleSelectItem,
	};
};

export default useTaskHandler;
