import styled, { useTheme } from "styled-components";
import Modal from "../../components/Modal/Modal";
import * as AiIcons from "react-icons/ai";
import StyledForm from "../../components/Form/Form";
import TextField from "../../components/FormFields/TextField";
import { ModalContainer } from "../../components/Modal/Modal";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { OrderModel } from "./OrderInterfaces";
import { TransactionType } from "./OrderInterfaces";
import { FormikProvider, useFormik } from "formik";
import Icon from "../../components/Icon/Icon";
import assetsConfig from "../../assetsConfig";
import PrimaryLogo from "../../components/PrimaryLogo/PrimaryLogo";
import { getLocalTime } from "../../utils/timeUtilities";

const OrderInfoContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.3125rem;

	.text-medium {
		display: flex;
		align-items: center;
		font-size: 1.25rem;
	}

	.text-regular {
		margin-right: 0.5rem;
		font-size: 1.375rem;
	}
`;

const IndentedDiv = styled.div`
	margin-left: 40px;
`;

const SubmissionLightbox = (props: SubmissionLightboxProps) => {
	const history = useHistory();
	const theme = useTheme();
	const formikProps = useFormik({
		initialValues: props.order,
		onSubmit: () => {},
	});
	// console.log("Props: ", props);
	// console.log("Date ", props.order.paymentDate);
	return (
		<Modal show={props.show}>
			<ModalContainer>
				<FormikProvider value={formikProps}>
					<Icon
						className="m-[1.5rem] ml-auto"
						icon="close"
						color={theme.colorPrimary}
						width="30px"
						height="30px"
						onClick={() => history.push("/entries/orders")}
					/>
					<div className="inner-container">
						<PrimaryLogo
							className="mb-[2rem] mx-auto"
							color={theme.colorLogoDarkDark}
							width="250px"
						/>
						<h2 className="font-semibold">Thank You For Your Submission</h2>
						<p>
							You're almost done! Please follow the instructions below to
							complete your entry:
						</p>
						<hr />

						<OrderInfoContainer>
							<h2 className="font-semibold">
								{props.order.companyName || "company Name"}
							</h2>
							<p className="text-medium">
								Order Date:{" "}
								{props.order.createdDate &&
									getLocalTime(props.order.createdDate!)}
							</p>
							<h2 className="font-semibold">Order ID #{props.order.id}</h2>
							<p
								className="text-medium underline text-colorActivation cursor-pointer"
								onClick={() =>
									window.open(`${props.order.invoicePDF}`, "_blank")
								}
							>
								<Icon
									className="mr-[.5rem]"
									icon="download"
									color={theme.colorActivation}
									width="15px"
									height="15px"
								/>
								Download Invoice
								{!props.order.invoicePDF && (
									<p className="text-colorDanger">no pdf</p>
								)}
							</p>

							<h2>
								<span className="text-regular">Order Total</span>$
								{props.order.orderAmount}
							</h2>
						</OrderInfoContainer>

						<hr />

						<h2>{TransactionType[props.order.transactionType]} Information</h2>

						{props.order.transactionType == TransactionType.Check && (
							<>
								<p>
									Take note of your Order ID: <b>"{props.order.id}"</b> and your
									Billing Name: <b>"{props.order.companyName}"</b> and include
									it in the note section of your check.
								</p>
								<p>
									Make your check payable to:
									<br />
									<IndentedDiv>{assetsConfig.companyNameLong}</IndentedDiv>
								</p>
								<p>
									Mail your check to: <br />
									<IndentedDiv>
										{assetsConfig.companyNameLong} <br />
										104 West 27th Street <br />
										10th Floor <br />
										New York, NY 10001 <br />
										USA
									</IndentedDiv>
								</p>
								<p>
									Please Remember: <br />
									<IndentedDiv>
										<ul>
											<li>
												Reference your Order ID and your Billing Name on your
												check in the notes section.
											</li>
											<li>Checks must be in U.S. Dollars only.</li>
											<li>Include a copy of the invoice in the envelope.</li>
										</ul>
									</IndentedDiv>
								</p>
							</>
						)}

						{props.order.transactionType == TransactionType.Bank && (
							<>
								<p>
									Take note of your Order ID: <b>"{props.order.id}"</b> and your
									Billing Name: <b>"{props.order.companyName}"</b> and include
									it in the note section of your check.
								</p>
								<p>
									Name on Account: <br />
									<IndentedDiv>{assetsConfig.companyNameLong}</IndentedDiv>
								</p>
								<p>
									Bank Information: <br />
									<IndentedDiv>
										JP Morgan AChase <br />
										New York, NY <br />
										USA <br />
										<br />
										Account #: 222935725 <br />
										Routing #: 021000021 <br />
										Swift Code: CHASUS33
									</IndentedDiv>
								</p>
								<p>
									Please Remember: <br />
									<IndentedDiv>
										<ul>
											<li>
												Reference your Order ID and your Company Name on your
												Bank Transfer in the notes section.
											</li>
											<li>Checks must be in U.S. Dollars only.</li>
										</ul>
									</IndentedDiv>
								</p>
								<div className="col-w-100">
									<TextField
										name="bankName"
										placeholder="Bank Name"
										value={props.order.bankName}
										nonFormik={true}
										readOnly
									/>
								</div>
								<div className="col-w-100">
									<TextField
										name="bankCity"
										placeholder="Bank City"
										value={props.order.bankCity}
										nonFormik={true}
										readOnly
									/>
								</div>
							</>
						)}

						{props.order.transactionType == TransactionType.Bank && <></>}

						<div className="col-w-100">
							<TextField
								name="notes"
								placeholder="Invoice Notes"
								value={props.order.notes}
								component="textarea"
								height="200px"
								nonFormik={true}
								readOnly
							/>
						</div>
					</div>
				</FormikProvider>
			</ModalContainer>
		</Modal>
	);
};

export default SubmissionLightbox;

interface SubmissionLightboxProps {
	show: boolean;
	order: OrderModel;
}

// type SubmissionLightboxProps = Lightbox & order;

// interface Lightbox {
//   show: boolean;
//   //   hideModal(): void;
// }
