import { FieldArray, useFormikContext } from "formik";
import {
	EntryMediaField,
	EntryMediaJoin,
} from "../../Admin/Program/ProgramInterfaces";
import {
	MediaItem,
	MediaType,
	Tag,
} from "../../MediaLibrary/mediaLibrary.model.d";
import { AUTOSAVE_MESSAGE, DynamicEntryField } from "../DynamicFields";
import { useContext } from "react";

import { updateEntryField } from "../manageEntry";
import { SuccessMessageWrapper } from "../../../components/FormFields/FieldWrapper";
import DraggableMediaList from "../../../components/Draggable/DraggableMedia";
import { useAlert } from "../../../components/Alert/Alerts";
import { displayMediaType } from "../../../hooks/useFileHandlers";

const EntryMediaFieldForm = (props: EntryMediaFieldProps) => {
	const { addNewAlert } = useAlert();
	const { values, setFieldValue } = useFormikContext<any>();

	const AddToMediaField = (
		uploaded: any,
		mediaField: EntryMediaField,
		name: string,
		libraryChanged: boolean = false
	) => {
		let joins: EntryMediaJoin[] = [];
		// push a new EntryMediaJoin to field for each uploaded media item
		if (Array.isArray(uploaded)) {
			joins = joins = uploaded
				.filter(
					(item) =>
						!mediaField.mediaItems.some(
							(join) => join.mediaItem.fileName === item.fileName
						)
				)
				.map((item) => ({
					fieldId: mediaField.id,
					id: 0,
					mediaId: item.id,
					mediaItem: item,
					weight: mediaField.mediaItems.length,
				}));
		} else {
			if (
				mediaField.mediaItems.some(
					(join) => join.mediaItem.fileName === uploaded.fileName
				)
			) {
				// Return a resolved void Promise here if the media already exists
				return Promise.resolve();
			}
			joins.push({
				fieldId: mediaField.id,
				id: 0,
				mediaId: uploaded.id,
				mediaItem: uploaded,
				weight: mediaField.mediaItems.length,
			});
		}
		const newMediafield = {
			...mediaField,
			mediaItems: [...mediaField.mediaItems, ...joins],
		};
		return updateMediaField(newMediafield, name);
		if (libraryChanged) {
		}
	};

	const updateMediaField = (newMediaField: EntryMediaField, name: string) => {
		return updateEntryField(newMediaField)
			.then((resp) => {
				if (resp.status === 200) {
					setFieldValue(`${name}`, resp.data);
				} else {
					addNewAlert({
						type: "error",
						message: "Failed to save.",
					});
				}
			})
			.catch((error: any) => {
				addNewAlert({
					type: "error",
					message: "Failed to save.",
				});
			});
	};

	const validateOnDrop = (item: MediaItem) => {
		// if the item does not have the required type, don't add
		if (item.type !== props.field.programField.mediaType) {
			return false;
		}
		// if max quantity has been reached, don't add
		if (
			props.field.mediaItems.length === props.field.programField.maxQuantity
		) {
			// TODO: Probably set an Alert here for max media items added?
			return false;
		}

		// else MediaItem can be added
		return true;
	};

	const minQuantity =
		props.field.programField.minQuantity &&
		props.field.programField.minQuantity >= 1
			? props.field.programField.minQuantity
			: undefined;
	const maxQuantity =
		props.field.programField.maxQuantity &&
		props.field.programField.maxQuantity !== 0
			? props.field.programField.maxQuantity
			: undefined;

	const asterisk = minQuantity ? "*" : "";

	return (
		<div className="col-w-100" id={`wrapper-div-${props.name}`}>
			{!props.isAdmin && (
				<>
					<h3 className="subtitle font-semibold">
						{props.field.programField.title}
						{asterisk}
					</h3>
					<p className="whitespace-pre-wrap">
						{props.field.programField.entrantHelpText}
					</p>
				</>
			)}

			<FieldArray name={`${props.name}.mediaItems`}>
				{({ insert, remove, push }) => {
					const add = (media: MediaItem) => {
						push({
							fieldId: props.field.id,
							id: 0,
							mediaId: media.id,
							mediaItem: media,
							hideForWinnersGallery: false,
						});
					};
					const mediaField = props.field as EntryMediaField;
					return (
						<>
							{!props.isAdmin && (
								<span>
									{displayMediaType(
										props.field.programField.mediaType,
										props.field.programField.acceptedDocTypes
									)}

									{(minQuantity || maxQuantity) && (
										<>
											{" - "}
											{minQuantity && (
												<span className="italic">
													Minimum: {props.field.programField.minQuantity}
												</span>
											)}
											{minQuantity && maxQuantity && (
												<span className="top-[-2px]"> || </span>
											)}
											{maxQuantity && (
												<span className="italic">
													Maximum: {props.field.programField.maxQuantity}
												</span>
											)}
										</>
									)}
								</span>
							)}
							<SuccessMessageWrapper>
								{(success, setSuccess) => (
									<DraggableMediaList
										name={props.name}
										list={
											(props.field.mediaItems &&
												props.field.mediaItems.length > 0 &&
												props.field.mediaItems.map((m) => {
													return {
														...m.mediaItem,
														id: m.mediaItem.id!,
														joinId: m.id,
														hideForWinnersGallery:
															m.mediaItem.hideForWinnersGallery,
														weight: m.weight,
													};
												})) ||
											[]
										}
										mediaType={props.field.programField.mediaType}
										acceptedDocTypes={props.field.programField.acceptedDocTypes}
										tags={props.tags}
										min={props.field.programField.minQuantity}
										max={props.field.programField.maxQuantity}
										disabled={props.readOnly}
										success={success}
										companyId={props.companyId}
										onAdd={(addedMedia) =>
											AddToMediaField(
												addedMedia,
												mediaField,
												props.name,
												false
											).then(() => setSuccess(AUTOSAVE_MESSAGE))
										}
										onRemove={(mediaId) => {
											const newMediaField: EntryMediaField = {
												...mediaField,
												mediaItems: mediaField.mediaItems.filter(
													(mediaItem) => mediaItem.mediaId !== mediaId
												),
											};
											return updateMediaField(newMediaField, props.name).then(
												() => setSuccess(AUTOSAVE_MESSAGE)
											);
										}}
										onReorder={(newMedia) => {
											setSuccess(AUTOSAVE_MESSAGE);
											return Promise.resolve();
										}}
										onUploadSuccess={(uploaded) => {
											// push a new EntryMediaJoin to field for each uploaded media item
											AddToMediaField(
												uploaded,
												mediaField,
												props.name,
												true
											).then(() => setSuccess(AUTOSAVE_MESSAGE));
											props.refreshMedia && props.refreshMedia();
										}}
										showLocalFileUpload
										dynamicHeight
									/>
								)}
							</SuccessMessageWrapper>
						</>
					);
				}}
			</FieldArray>
		</div>
	);
};

export default EntryMediaFieldForm;

interface EntryMediaFieldProps extends DynamicEntryField {
	field: EntryMediaField;
	name: string;
	refreshMedia?(): void;
	companyId?: number;
	tags: Tag[];
}
