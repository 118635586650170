import { useEffect, useRef, useState } from "react";
import { useQuill } from "react-quilljs";
// import Quill, { DeltaStatic, Sources } from "quill";
// import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.snow.css"; // Add css for snow theme
import { useFormikContext } from "formik";
import styled, { css } from "styled-components";
import FieldWrapper from "./FieldWrapper";
import { defaultBoxShadow } from "../../assetsConfig";
import DOMPurify from "dompurify";

const RichTextCharLimit = (props: {
	currentLength: number;
	maxLength?: number;
}) => {
	const { currentLength, maxLength } = props;

	if (maxLength && currentLength) {
		const warning = currentLength >= maxLength * 0.85 ? "text-colorDanger" : "";

		return currentLength ? (
			<span className={`absolute right-[.5rem] bottom-[-28px] ${warning}`}>
				{currentLength}/{maxLength}
			</span>
		) : (
			<></>
		);
	} else return <></>;
};

const StyledRichTextField = styled.div<{ height?: string; readOnly?: boolean }>`
	box-shadow: ${defaultBoxShadow};
	font-family: ${({ theme }) => theme.fontCopy} !important;
	font-size: ${({ theme }) => theme.pSize} !important;
	background: ${({ theme }) => theme.colorBackgroundLightLight};

	ul,
	ol,
	li {
		font-family: ${({ theme }) => theme.fontCopy} !important;
		font-size: ${({ theme }) => theme.pSize} !important;
	}

	ul,
	ol {
		padding-left: 0;
	}

	.ql-formats {
		margin-right: 0 !important;
	}

	.ql-snow {
		border: 0 !important;
	}

	${(p) =>
		p.readOnly &&
		css`
			background: ${({ theme }) => theme.colorFieldReadOnly};
		`}

	.ql-editor {
		&.ql-editor.ql-blank::before {
			font-family: ${({ theme }) => theme.fontCopy} !important;
			color: ${({ theme }) => theme.colorCopyLight} !important;
			font-style: normal;
			font-size: ${({ theme }) => theme.pSize};
		}
		${(p) =>
			p.height &&
			css`
				height: ${p.height};
			`}
	}
`;

const EditorToolbar = () => {
	return (
		<div id="toolbar">
			{/* <span className="ql-formats">
				<select className="ql-font" defaultValue="arial">
					<option value="arial">Arial</option>
					<option value="comic-sans">Comic Sans</option>
					<option value="courier-new">Courier New</option>
					<option value="georgia">Georgia</option>
					<option value="helvetica">Helvetica</option>
					<option value="lucida">Lucida</option>
				</select>
				<select className="ql-size" defaultValue="medium">
					<option value="extra-small">Size 1</option>
					<option value="small">Size 2</option>
					<option value="medium">Size 3</option>
					<option value="large">Size 4</option>
				</select>
				<select className="ql-header" defaultValue="3">
					<option value="1">Heading</option>
					<option value="2">Subheading</option>
					<option value="3">Normal</option>
				</select>
			</span> */}
			<span className="ql-formats">
				<button className="ql-bold" />
				<button className="ql-italic" />
				<button className="ql-list" value="ordered" />
				<button className="ql-list" value="bullet" />
				<button className="ql-link" />

				{/* <button className="ql-underline" />
				<button className="ql-strike" /> */}
			</span>
			{/* <span className="ql-formats">
        <button className="ql-indent" value="-1" />
				<button className="ql-indent" value="+1" />
      </span> */}

			{/* <span className="ql-formats">
				<button className="ql-script" value="super" />
				<button className="ql-script" value="sub" />
				<button className="ql-blockquote" />
				<button className="ql-direction" />
			</span>
			<span className="ql-formats">
				<select className="ql-align" />
				<select className="ql-color" />
				<select className="ql-background" />
			</span>
			<span className="ql-formats">
				<button className="ql-link" />
				<button className="ql-image" />
				<button className="ql-video" />
			</span>
			<span className="ql-formats">
				<button className="ql-formula" />
				<button className="ql-code-block" />
				<button className="ql-clean" />
			</span> */}
			{/* <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span> */}
		</div>
	);
};

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
	<svg viewBox="0 0 18 18">
		<polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
		<path
			className="ql-stroke"
			d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
		/>
	</svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
	<svg viewBox="0 0 18 18">
		<polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
		<path
			className="ql-stroke"
			d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
		/>
	</svg>
);

// traverse formik obj to get values like campaignFields.8.text
const traverseFormikVal = (obj: any, path: string) => {
	if (typeof obj !== "object" || obj === null) {
		return undefined;
	}

	const keys = path.split("."); // Split the string into an array

	let value = obj; // Start with the main object
	for (const key of keys) {
		if (
			value === undefined ||
			!Object.prototype.hasOwnProperty.call(value, key)
		) {
			return undefined;
		}
		value = value[key]; // Traverse the object using the keys
	}
	return value;
};

const RichTextField = (props: RichTextFieldProps) => {
	const { setFieldValue, errors } = useFormikContext<any>();
	const [charCount, setCharCount] = useState<number>(0);
	const [isTouched, setIsTouched] = useState(false);

	const toolbarOptions = [
		"bold",
		"italic",
		{ list: "ordered" },
		{ list: "bullet" },
		"link",
	];

	const modules = {
		toolbar: props.readOnly ? false : toolbarOptions,
		history: {
			delay: 500,
			maxStack: 100,
			userOnly: true,
		},
	};
	const formats = [
		// "header",
		// "font",
		// "size",
		"bold",
		"italic",
		// "underline",
		// "align",
		// "strike",
		// "script",
		// "blockquote",
		// "background",
		"list",
		"list",
		// "indent",
		"link",
		// "image",
		// "color",
		// "code-block",
	];

	const { quill, quillRef } = useQuill({
		modules: modules,
		formats: formats,
	});

	useEffect(() => {
		if (quill) {
			quill.on("text-change", (delta, oldDelta, source) => {
				setIsTouched(true);

				// update word count on text change
				const text = quill.getText();
				const len = text.replace(/<(.|\n)*?>/g, "").trim().length;
				setCharCount(len);

				// trim text after character limit
				if (props.maxLength && len > props.maxLength) {
					quill.deleteText(props.maxLength, len + 1);
				}

				if (len === 0) {
					setFieldValue(props.name, "");
				} else {
					const sanitizedHtmlContent = DOMPurify.sanitize(quill.root.innerHTML);
					setFieldValue(props.name, sanitizedHtmlContent);
				}
			});

			// trigger onblur event and pass sanitized html up
			quill.on("selection-change", (range, oldRange, source) => {
				const onblur = range === null && oldRange !== null;
				if (onblur) {
					const sanitizedHtmlContent = DOMPurify.sanitize(quill.root.innerHTML);
					!props.readOnly && props.onBlur && props.onBlur(sanitizedHtmlContent);
				}
			});
		}
	}, [quill, props.readOnly]);

	useEffect(() => {
		if (quill) {
			quill.enable(!props.readOnly);
		}
	}, [quill, props.readOnly]);

	// populate initial value
	useEffect(() => {
		if (quill && props.value && !isTouched) {
			// set touched to true so the value doesn't repopulate
			setIsTouched(true);

			const sanitizedHtmlContent = DOMPurify.sanitize(props.value);

			// set formik value
			setFieldValue(props.name, sanitizedHtmlContent);

			const delta = quill.clipboard.convert({ html: sanitizedHtmlContent });
			quill.setContents(delta, "silent");

			// set initial word count
			const text = quill.getText();
			const len = text.replace(/<(.|\n)*?>/g, "").trim().length;
			setCharCount(len);
		}
	}, [quill, props.value, isTouched]);

	return (
		<FieldWrapper
			className={props.className}
			name={props.name}
			success={props.success}
			error={props.error}
		>
			{(success, error) => (
				<>
					<StyledRichTextField
						{...(props.height && {
							height: props.height,
						})}
						readOnly={props.readOnly}
					>
						<div ref={quillRef} />
					</StyledRichTextField>
					<RichTextCharLimit
						currentLength={charCount}
						maxLength={props.maxLength}
					/>
					{/* <div ref={counterRef} /> */}
				</>
			)}
		</FieldWrapper>
	);
};

RichTextField.displayName = "Editor";

export default RichTextField;

interface RichTextFieldProps {
	className?: string;
	placeholder?: string;
	name: string;
	height?: string;
	value?: string;
	success?: string;
	error?: string;
	readOnly?: boolean;
	onBlur?(html: string): void;
	maxLength?: number;
}
