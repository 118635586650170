import styled, { useTheme, css } from "styled-components";
import Icon from "../../../components/Icon/Icon";
import classNames from "classnames";
import { useState, useEffect, useRef } from "react";
import { MediaItem, MediaType } from "../../MediaLibrary/mediaLibrary.model.d";
import {
	checkImage,
	getMediaSrc,
	getPlaceholder,
	getThumbnailSrc,
	ThumbnailSize,
} from "../../MediaLibrary/manageMediaLibrary";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack"; // https://github.com/wojtekmaj/react-pdf/issues/97
import VideoPlaceholder from "../../../assets/placeholders/video-landscape.png";
import AudioPlaceholder from "../../../assets/placeholders/audio-landscape.png";
import ImagePlaceholder from "../../../assets/placeholders/image-landscape.png";
import DocumentPlaceholder from "../../../assets/placeholders/document-landscape.png";
import { isVideoAvailable } from "../../../components/MediaEnlargeModal/MediaEnlargeModal";
import { Execution, MediaField } from "./EntryDetail";
import Button from "../../../components/Button/Button";
import Collapsible from "../../../components/Collapsible/Collapsible";
import MediaCarousel from "../../../components/MediaCarousel/MediaCarousel";
import convertRange from "../../../utils/convertRange";
import Image from "../../../components/Image/Image";

const ThumbnailContainer = styled.div`
	position: relative;
	width: 160px;
	height: 90px;
	overflow-y: hidden;
	/* display: flex;
  justify-content: center;
  align-items: center; */

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const StyledImgHover = styled.div`
	position: relative;
	height: 100%;

	.zoom-button {
		position: absolute;
		z-index: 9999;
		bottom: 0;
		right: 1rem;
		padding: 1rem 2rem;
		width: 160px;
	}
	/* .original-image {
    height: 100%;
  } */

	.zoomed-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
	}
`;

const ZoomOnHoverContainer = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;

	.zoom-button {
		position: absolute;
		z-index: 9999;
		bottom: 0;
		right: 1rem;
		padding: 1rem 2rem;
		width: 160px;
	}

	img {
		width: auto;
		margin: 0 auto;
		object-fit: contain;
	}
`;

// https://codepen.io/robertkirsz/pen/ZvorjB
const ZoomedImageWrapper = styled.div<{ isZoomed: boolean }>`
	position: relative;

	figure {
		width: fit-content;
		height: 100%;
		background-repeat: no-repeat;
		margin: 0 auto;

		img {
			display: block;
			width: 100%;
		}
	}

	${(p) =>
		p.isZoomed &&
		css`
			figure {
				/* :hover  */
				cursor: zoom-in;
				width: 100%;
				height: 100%;
				background-size: 200%;
				img {
					opacity: 0;
				}
			}
		`};
`;

// const ZoomOnHover = (props: {
//   image: React.ReactNode;
//   imgSrc: string;
//   isZoom?: boolean;
// }) => {
//   const [isZoomed, setIsZoomed] = useState(false);
//   const [bgPosition, setBgPosition] = useState("0% 0%");

//   const handleMouseMove = (e: any) => {
//     const padding = 5; // user can pan 5% past image borders
//     const { left, top, width, height } = e.target.getBoundingClientRect();
//     let x = ((e.clientX - left) / width) * 100;
//     let y = ((e.clientY - top) / height) * 100;

//     x = convertRange(x, [0, 100], [0 - padding, 100 + padding]);
//     y = convertRange(y, [0, 100], [0 - padding, 100 + padding]);
//     setBgPosition(`${x}% ${y}%`);
//   };

//   useEffect(() => {
//     props.isZoom !== undefined && setIsZoomed(props.isZoom);
//   }, [props.isZoom]);

//   return (
//     <StyledImgHover>
//       {props.isZoom === undefined && (
//         <Button
//           className="zoom-button w-[160px]"
//           icon="search"
//           onClick={() => setIsZoomed(!isZoomed)}
//         >
//           {isZoomed ? "Zoom Out" : "Zoom In"}
//         </Button>
//       )}

//       <ZoomedImageWrapper className="zoomed-image" isZoomed={isZoomed}>
//         <figure
//           onMouseMove={(e) => handleMouseMove(e)}
//           style={
//             isZoomed
//               ? {
//                   backgroundImage: `url(${props.imgSrc})`,
//                   backgroundPosition: bgPosition,
//                 }
//               : {}
//           }
//         >
//           {props.image}
//         </figure>
//       </ZoomedImageWrapper>
//     </StyledImgHover>
//   );
// };

export const ZoomOnHover = (props: {
	id: string;
	image: React.ReactNode;
	isZoom?: boolean;
	position?: ZoomPosition;
	getPosition?(position: ZoomPosition): void;
	isZoomEnabled?: boolean;
}) => {
	const [isZoomed, setIsZoomed] = useState(false);
	const zoomContainerRef = useRef<any>(null);

	useEffect(() => {
		props.isZoom !== undefined && setIsZoomed(props.isZoom);
	}, [props.isZoom]);

	function handleMouseMove(e: React.MouseEvent) {
		// get our pieces
		const outer = e.currentTarget as HTMLDivElement;
		const inner = e.currentTarget.querySelector("img");

		if (inner) {
			// if the image is too small, don't "un-zoom"
			const innerWidth = inner.naturalWidth;
			const innerHeight = inner.naturalHeight;
			const outerWidth = outer.offsetWidth;
			const outerHeight = outer.offsetHeight;
			if (innerWidth < outerWidth && innerHeight < outerHeight) {
				return;
			}

			const clientX = e.clientX;
			const clientY = e.clientY;

			// scale the image
			const scaleX = innerWidth / outerWidth;
			const scaleY = innerHeight / outerHeight;
			const scale = Math.max(scaleX, scaleY);

			// get current mouse position
			const { left, top } = e.currentTarget.getBoundingClientRect();
			const mouseOuterX = clientX - left;
			const mouseOuterY = clientY - top;

			// get percentages
			const mousePercentX = mouseOuterX / outerWidth;
			const mousePercentY = mouseOuterY / outerHeight;

			// get difference between full width and container
			const sizeDifferenceX = innerWidth - outerWidth;
			const sizeDifferenceY = innerHeight - outerHeight;

			// position the image
			const innerPositionX =
				sizeDifferenceX / 2 - sizeDifferenceX * mousePercentX;
			const innerPositionY =
				sizeDifferenceY / 2 - sizeDifferenceY * mousePercentY;

			// lift x/y position back up
			props.getPosition &&
				props.getPosition({
					x: parseFloat(mousePercentX.toFixed(5)),
					y: parseFloat(mousePercentY.toFixed(5)),
				});

			if (!props.isZoomEnabled) {
				return;
			}

			inner.style.transform =
				"translate(" +
				innerPositionX +
				"px, " +
				innerPositionY +
				"px) " +
				"scale(" +
				scale +
				", " +
				scale +
				")";

			e.preventDefault();
		}
	}

	// zoom an image to given position
	function setPosition(element: HTMLDivElement, position: ZoomPosition) {
		// get our pieces
		const outer = element as HTMLDivElement;
		const inner = element.querySelector("img");

		if (inner) {
			// if the image is too small, don't "un-zoom"
			const innerWidth = inner.naturalWidth;
			const innerHeight = inner.naturalHeight;
			const outerWidth = outer.offsetWidth;
			const outerHeight = outer.offsetHeight;
			if (innerWidth < outerWidth && innerHeight < outerHeight) {
				return;
			}

			// add easing transition for smooth zooming in/out
			inner.style.transition = "transform 1000ms ease-in-out";

			// scale the image
			const scaleX = innerWidth / outerWidth;
			const scaleY = innerHeight / outerHeight;
			const scale = Math.max(scaleX, scaleY);

			// displays the image at x/y zoom if position is defined
			const mousePercentX = position.x;
			const mousePercentY = position.y;

			// get difference between full width and container
			const sizeDifferenceX = innerWidth - outerWidth;
			const sizeDifferenceY = innerHeight - outerHeight;

			// position the image
			const innerPositionX =
				sizeDifferenceX / 2 - sizeDifferenceX * mousePercentX;
			const innerPositionY =
				sizeDifferenceY / 2 - sizeDifferenceY * mousePercentY;

			inner.style.transform =
				"translate(" +
				innerPositionX +
				"px, " +
				innerPositionY +
				"px) " +
				"scale(" +
				scale +
				", " +
				scale +
				")";
		}
	}

	function resetPosition(element: HTMLDivElement) {
		const inner = element.querySelector("img");

		if (inner) {
			inner.style.transition = "transform 1000ms ease-in-out";
			inner.style.transform = "";
		}
	}

	useEffect(() => {
		props.isZoom !== undefined && setIsZoomed(props.isZoom);
	}, [props.isZoom]);

	useEffect(() => {
		if (zoomContainerRef.current) {
			if (props.position && props.position.x && props.position.y) {
				setPosition(zoomContainerRef.current, props.position);
			} else {
				resetPosition(zoomContainerRef.current);
			}
		}
	}, [props.position]);

	return (
		<ZoomOnHoverContainer
			key={`${props.id}.${String(isZoomed)}`} // refresh zoom state
			// only track mouse movement if zoom is active
			{...(isZoomed &&
				!props.position && {
					onMouseMove: (e) => handleMouseMove(e),
				})}
			ref={zoomContainerRef}
		>
			{props.isZoom === undefined && (
				<Button
					className="zoom-button w-[160px]"
					icon="search"
					onClick={() => setIsZoomed(!isZoomed)}
				>
					{isZoomed ? "Zoom Out" : "Zoom In"}
				</Button>
			)}
			{props.image}
		</ZoomOnHoverContainer>
	);
};

const DocumentOverlay = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.tint {
		position: absolute;
		width: 100%;
		height: 100vh;
		background: rgba(0, 0, 0, 0.75);
		z-index: 9999;
		transition: all 150ms ease;
	}

	.hover-button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const CenteredPlayIcon = styled.div<{ selected: boolean }>`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	background: rgba(0, 0, 0, 0.4);
	cursor: pointer;

	${(p) =>
		p.selected &&
		`
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        animation: borderAnimation 150ms forwards
        
    }
      
    `}

	@keyframes borderAnimation {
		from {
			border: 5px solid transparent;
		}
		to {
			border: 5px solid ${({ theme }) => theme.colorPrimary};
		}
	}
`;

const ThumbnailPlayIcon = (props: { mediaType: MediaType }) => {
	const theme = useTheme();
	const color = theme.colorSelected;
	const size = "40px";

	switch (props.mediaType) {
		case MediaType.Audio:
			return (
				<Icon icon="audio-play" color={color} width={size} height={size} />
			);
		case MediaType.Document:
			return <Icon icon="doc-play" color={color} width={size} height={size} />;
		case MediaType.Image:
			return <Icon icon="eye" color={color} width={size} height={size} />;
		case MediaType.Video:
			return (
				<Icon icon="video-play" color={color} width="30px" height="30px" />
			);
		default:
			return <Icon icon="eye" color={color} width={size} height={size} />;
	}
};

export const RenderMediaThumbnail = (props: RenderThumbnailProps) => {
	const [thumbNailSrc, setThumbNailSrc] = useState(props.media.path);
	let thumbnail = <></>;
	useEffect(() => {
		setThumbNailSrc(getThumbnailSrc(props.media, ThumbnailSize.SMALL));
	}, [props.media]);

	switch (props.media.type) {
		case MediaType.Image:
			thumbnail = (
				<img
					className="select-none cursor-pointer"
					src={thumbNailSrc}
					alt={props.media.fileName}
					loading="lazy"
					draggable={false}
					onClick={() => props.onClick(props.media)}
					onError={() => setThumbNailSrc(getPlaceholder(props.media.type))}
				/>
			);
			break;
		case MediaType.Document:
			thumbnail = (
				// <Document
				// 	className={`border border-solid border-colorBorderLight select-none cursor-pointer`}
				// 	file={getMediaSrc(props.media)}
				// >
				// 	<Page width={158} height={89} pageNumber={1} />
				// </Document>

				<Image
					key={thumbNailSrc}
					src={thumbNailSrc}
					placeholder={DocumentPlaceholder}
					alt={props.media.fileName}
					refetchOnError
					lazy
				/>
			);
			break;
		case MediaType.Audio:
			thumbnail = (
				<img
					className="select-none cursor-pointer"
					src={AudioPlaceholder}
					alt={props.media.fileName}
					loading="lazy"
				/>
			);
			break;
		case MediaType.Video:
			thumbnail = (
				<img
					className="select-none cursor-pointer"
					src={thumbNailSrc}
					alt={props.media.fileName}
					loading="lazy"
					onError={() => setThumbNailSrc(getPlaceholder(props.media.type))}
				/>
			);
			break;
	}
	const theme = useTheme();

	return (
		<ThumbnailContainer
			draggable={false}
			onClick={() => props.onClick(props.media)}
		>
			{props.showIcons && (
				<div className="absolute right-1 gap-[0.5em] flex p-[0.5em]">
					{/* Need to change this icon */}
					<Icon
						icon="upload"
						color={theme.colorButtonLight}
						width="20px"
						height="20px"
						className="pointer"
					/>
					<Icon
						icon="expand-arrows"
						color={theme.colorButtonLight}
						width="20px"
						height="20px"
						className="pointer"
					/>
				</div>
			)}
			<CenteredPlayIcon selected={props.selected}>
				<ThumbnailPlayIcon mediaType={props.media.type} />
			</CenteredPlayIcon>

			{thumbnail}
		</ThumbnailContainer>
	);
};

export const RenderMediaPlayer = (props: {
	media: MediaItem | null;
	pauseMedia?: boolean;
	isZoom?: boolean;
	isZoomEnabled?: boolean;
	//   setVideoTime?(time: number): void;
	//     videoTime?: number;
}) => {
	const [numPages, setNumPages] = useState<number | null>(null);
	const media = props.media;
	const vidRef = useRef<any>(null);
	const audioRef = useRef<any>(null);
	//   const [vidTime, setVidTime] = useState<number>(props.videoTime || 0);

	useEffect(() => {
		if (vidRef && vidRef.current) {
			vidRef.current.pause();
		}
		if (audioRef && audioRef.current) {
			audioRef.current.pause();
		}
	}, [props.pauseMedia]);

	if (media) {
		const mediaSrc = getMediaSrc(media, ThumbnailSize.X_LARGE);
		switch (media.type) {
			case MediaType.Image:
				return (
					<ZoomOnHover
						id={media.id || ""}
						image={
							<img
								key={media.id}
								id={media.id}
								className="h-full object-contain rendered-img"
								src={mediaSrc || ImagePlaceholder}
								alt={media.fileName}
								loading="lazy"
							/>
						}
						isZoom={props.isZoom}
						isZoomEnabled={props.isZoomEnabled}
						// imgSrc={mediaSrc || ImagePlaceholder}
					/>
				);

			case MediaType.Document:
				return (
					<DocumentOverlay>
						<Document
							key={media.id}
							className={`document flex flex-col items-center mx-[1rem] border-2 border-solid border-colorBorderLight`}
							file={mediaSrc}
							onLoadSuccess={({ numPages }) => setNumPages(numPages)}
						>
							{/* {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page pageNumber={page} />
              ))} */}
							<Page pageNumber={1} />
						</Document>

						<div className="tint">
							<Button
								className="hover-button"
								onClick={() => window.open(mediaSrc, "_blank")}
							>
								Open Document In New Tab
							</Button>
						</div>
					</DocumentOverlay>
				);

			case MediaType.Audio:
				return (
					<div className="flex flex-col h-full">
						<img
							key={media.id}
							className="object-contain max-h-[80%]"
							src={AudioPlaceholder}
							alt="Audio Placeholder"
						/>

						<audio
							className="mx-auto mt-[1rem] w-[80%]"
							controls
							src={mediaSrc}
							ref={audioRef}
						/>
					</div>
				);
			case MediaType.Video:
				if (isVideoAvailable(mediaSrc)) {
					return (
						<video
							key={media.id}
							className="w-full h-full"
							controls
							ref={vidRef}
							//   onTimeUpdate={
							//     (e) => setVidTime(e.currentTarget.currentTime)
							//     // console.log("change", e.currentTarget.currentTime)
							//   }
						>
							<source src={mediaSrc} type="video/mp4" />
							<source src={mediaSrc} type="video/quicktime" />
							Your browser does not support the video tag.
						</video>
					);
				} else {
					return (
						<div className="flex justify-center items-center h-full">
							An error occurred with the video url. The video preview is
							unavailable.
						</div>
					);
				}

			default:
				return <></>;
		}
	} else
		return (
			<div className="flex items-center justify-center w-full h-full bg-colorBackgroundDarkDark">
				<h3 className="text-colorCopyLightLight">Media Preview</h3>
			</div>
		);
};

export const CarouselIcon = (props: { direction: "left" | "right" }) => {
	const theme = useTheme();
	return {
		className: "flex justify-center items-center w-[30px] h-[30px] self-center",
		children: (
			<Icon
				icon="caret"
				width="18px"
				height="18px"
				color={theme.colorCopyLightLight}
				rotation={props.direction === "left" ? "90deg" : "-90deg"}
			/>
		),
	};
};

export const StyledLightBox = styled.div<{ show: boolean }>`
	position: fixed;
	display: flex;
	flex-direction: column;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};

	${(p) =>
		!p.show &&
		`
      display: none;
    `};

	.rendered-img {
		object-fit: contain;
	}
`;

const MediaLightBox = (props: MediaLightBoxProps) => {
	const theme = useTheme();
	const [activeMediaPreview, setActiveMediaPreview] =
		useState<MediaItem | null>(null);
	const [activeExecutionIndex, setActiveExecutionIndex] = useState<
		number | null
	>(null);
	const [isSliderCollapsed, setIsSliderCollapsed] = useState(false);

	useEffect(() => {
		props.show
			? (document.body.style.overflow = "hidden")
			: (document.body.style.overflow = "auto");
	}, [props.show]);

	useEffect(() => {
		setActiveMediaPreview(props.selectedMediaPreview);
	}, [props.selectedMediaPreview]);

	const carouselItems = props.executions?.flatMap(
		(execution: Execution, executionIndex: number) => {
			const mediaItems = execution.mediaFields.flatMap(
				(field, mediaFieldIndex) =>
					field.media.map((media, i) => {
						// return field/media index that is the last media item in the last media field
						const lastFieldMediaIndex = () => {
							for (let x = execution.mediaFields.length - 1; x >= 0; x--) {
								const mediaLength = execution.mediaFields[x].media.length;
								const hasMedia = mediaLength > 0;

								if (hasMedia) {
									return {
										fieldIndex: x,
										mediaIndex: mediaLength - 1,
									};
								}
							}
						};

						const lastItemInExecution =
							props.executions && props.executions.length > 1
								? // should not be the last execution
								  executionIndex !== props.executions.length - 1 &&
								  mediaFieldIndex === lastFieldMediaIndex()?.fieldIndex &&
								  i === lastFieldMediaIndex()?.mediaIndex
								: false;

						return (
							<div
								className={classNames(
									"flex-col items-start relative inline-flex",
									{
										// divider is not a child of the carousel but we still want to show it in between executions
										"w-[176px]": !lastItemInExecution,
										"w-[211px]": lastItemInExecution,
									}
								)}
								key={`${executionIndex}-${mediaFieldIndex}-${i}`}
							>
								<RenderMediaThumbnail
									media={media}
									onClick={(media) => {
										setActiveMediaPreview(media);
										setActiveExecutionIndex(executionIndex);
									}}
									selected={
										activeMediaPreview
											? media.id === activeMediaPreview.id
											: false
									}
								/>
								{lastItemInExecution && (
									// render divider on the right side of the last item in the execution
									<span className="text-colorCopyLightLight w-[2px] h-full border-colorCopyLight border-l-[2px] mx-[.5rem] absolute right-[17px] top-0"></span>
								)}

								<b className="mt-[.5rem] ml-[.5rem] text-[0.875rem] text-colorCopyLightLight">
									{field.title}
								</b>
							</div>
						);
					})
			);

			return mediaItems;
		}
	);
	const handleLightBoxClose = () => {
		setIsSliderCollapsed(false);
		props.onClickHide();
	};

	// find execution index of selected media
	useEffect(() => {
		if (props.show && props.executions && activeMediaPreview) {
			const activeExeIndex = props.executions.findIndex((exe) =>
				exe.mediaFields.some((mediaField) =>
					mediaField.media.some((media) => media.id === activeMediaPreview.id)
				)
			);
			if (activeExeIndex !== -1) {
				setActiveExecutionIndex(activeExeIndex);
			}
		}
	}, [props.show]);

	return (
		<StyledLightBox show={props.show || false}>
			<Icon
				className="ml-auto m-[1rem]"
				icon="close"
				color={theme.colorCopyLightLight}
				onClick={handleLightBoxClose}
				width="50px"
				height="50px"
			/>
			<div
				className={`h-full overflow-y-auto mt-auto ${
					props.hideCarousel ? "mb-[3rem]" : ""
				} ${
					activeMediaPreview?.type === MediaType.Document
						? "!overflow-hidden"
						: ""
				} `}
			>
				<RenderMediaPlayer
					media={activeMediaPreview}
					pauseMedia={props.show}
					isZoomEnabled={props.isZoomEnabled}
				/>
			</div>
			{!props.hideCarousel && (
				<>
					<hr />
					{/* <p className="text-colorCopyLight w-[80%] mx-auto mt-[1rem]">
        {mediaCount} items
      </p> */}
					<Icon
						className="ml-auto m-[1rem]"
						icon="caret"
						color={theme.colorPrimary}
						width="25px"
						height="25px"
						rotation={isSliderCollapsed ? "180deg" : "0deg"}
						onClick={() => setIsSliderCollapsed(!isSliderCollapsed)}
					/>

					<Collapsible isCollapsed={isSliderCollapsed}>
						<div className="flex items-center gap-[1rem] w-[90%] mx-auto mb-2">
							{props.executions &&
								activeExecutionIndex !== null &&
								props.executions[activeExecutionIndex].title && (
									<b className="text-colorCopyLightLight">
										{props.executions[activeExecutionIndex].title}
									</b>
								)}
							{/* <p className="text-colorCopyLight">
            Entry {activeExecutionIndex !== null ? activeExecutionIndex + 1 : 1}{" "}
            of {props.executions!.length}
          </p> */}
						</div>

						<div className="mx-auto w-[90%] h-[250px]">
							<MediaCarousel isVisible={props.show}>
								{carouselItems}
							</MediaCarousel>
						</div>
					</Collapsible>
				</>
			)}
		</StyledLightBox>
	);
};

export default MediaLightBox;

interface MediaLightBoxProps {
	show: boolean;
	onClickHide(): void;
	hideCarousel?: boolean;
	className?: string;
	executions?: Execution[];
	activeExecutionIndex?: number;
	selectedMediaPreview: MediaItem | null;
	isZoomEnabled?: boolean;
	//   videoTime?: number;
}

interface RenderThumbnailProps {
	media: MediaItem;
	onClick(media: MediaItem): void;
	selected: boolean;
	showIcons?: boolean;
}

export interface ZoomPosition {
	x: number;
	y: number;
}
