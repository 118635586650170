import styled, { useTheme } from "styled-components";
import { Dispatch, SetStateAction } from "react";
import { getThumbnailSrc } from "../../views/MediaLibrary/manageMediaLibrary";
import Placeholder from "../../assets/placeholders/placeholder.png";
import Icon from "../Icon/Icon";
import { entryStatus } from "../../views/Checkout/PreviousEntry/PrevEntriesSortFilter";
import { Link } from "react-router-dom";
import useTextWrap from "../../hooks/useTextWrap";
import assetsConfig from "../../assetsConfig";
import { useSettings } from "../../hooks/UseSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getLocalTime } from "../../utils/timeUtilities";
import Authorized from "../Auth/Authorized";

const EntryCardContainer = styled.div`
  display: flex;
  gap: 3rem;
  padding: 1.5rem;
  border: 2px solid ${({ theme }) => theme.colorBorderLight};
  background: ${({ theme }) => theme.colorBackgroundLightLight};
  flex-wrap: wrap;
  /* justify-content: space-between; */
  .info {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .complete,
    .incomplete {
      font-weight: bold;
    }

    .complete {
      color: ${({ theme }) => theme.colorActivation};
    }

    .incomplete {
      color: ${({ theme }) => theme.colorDanger};
    }

    .icon-text {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .additionalFees {
    position: relative;
    &::before {
      position: absolute;
      content: "+";
      left: -15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const showTwoDecimals = (num: number) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

// sublevel lines to the campaign execution cards
export const DottedLines = styled.div`
  position: absolute;
  left: 5%;
  top: 0;
  width: 5%;
  height: 50%;
  border-left: 2px dashed ${({ theme }) => theme.colorBorderLight};
  border-bottom: 2px dashed ${({ theme }) => theme.colorBorderLight};
`;

const EntrantEntrySearchCard = (props: EntrantEntrySearchCardProps) => {
  const { settings, loading } = useSelector(
    (state: RootState) => state.settings
  );
  const theme = useTheme();
  const { wrapText } = useTextWrap();

  return (
    <EntryCardContainer>
      <div className="min-w-[200px]">
        <div className="pt-[56.25%] relative">
          <img
            src={
              (props.entry.coverImage &&
                getThumbnailSrc(props.entry.coverImage!)) ||
              Placeholder
            }
            className="absolute top-0 left-0 w-full h-full object-cover"
            alt="Entry Cover"
          />
        </div>
      </div>
      <div className="info">
        {wrapText(
          <p
            className={`break-all  md:w-[300px] xl:w-[300px] xxl:w-[400px] font-semibold ${
              props.entry.title ? "" : "text-colorDanger italic"
            }`}
          >
            {props.entry.title || "Missing Entry Title"}
          </p>
        )}
        {wrapText(
          <p
            className={`break-all  md:w-[300px] xl:w-[300px] xxl:w-[400px] font-semibold ${
              props.entry.brand ? "" : "text-colorDanger italic"
            }`}
          >
            {props.entry.brand || "Missing Entry Brand"}
          </p>
        )}
        {!settings.isDemo && props.entry.createdDate && (
          <p className="italic">{getLocalTime(props.entry.createdDate)}</p>
        )}

        {settings.isDemo && props.entry.updatedDate && (
          <p className="italic">
            Last Updated: {getLocalTime(props.entry.updatedDate)}
          </p>
        )}

        <p>
          Status:{" "}
          <span
            className={`${
              props.entry.reviewStatusId === 3
                ? "incomplete"
                : [0, 2].includes(props.entry.reviewStatusId)
                ? "text-colorDanger font-bold"
                : "complete"
            }`}
          >
            {entryStatus[props.entry.reviewStatusId].label}
          </span>
        </p>
      </div>
      {!settings.isDemo && (
        <div className="info !gap-0  lg:min-w-[270px]">
          {props.entry.hierarchy &&
            props.entry.hierarchy.map((program, i) => {
              return (
                <p
                  className={`whitespace-nowrap ${
                    i === 0 ? "font-semibold" : ""
                  }`}
                  key={i}
                >
                  {program}
                </p>
              );
            })}
        </div>
      )}

      <div className="md:w-[180px] flex flex-wrap gap-[2rem]  ">
        <div className="info">
          <Link
            className="icon-text"
            to={`/edit-entry/?entryId=${props.entry.id}`}
            target="_blank"
          >
            <Icon
              icon="edit"
              color={theme.colorPrimary}
              width="15px"
              height="15px"
            />
            {assetsConfig.labels.entry.singular} ID: {props.entry.id}
          </Link>

          {props.entry.orderId && (
            <Link to={`/entries/orders`} target="_blank">
              Order ID: {props.entry.orderId}
            </Link>
          )}
          <Authorized
            settings={settings}
            loading={loading}
            feature="Duplicate"
            authorized={
              <a
                href=""
                className="icon-text"
                onClick={(e) => {
                  e.preventDefault();
                  props.setEntryIdToDuplicate &&
                    props.setEntryIdToDuplicate(props.entry.id);
                }}
              >
                <Icon
                  icon="copy"
                  color={theme.colorPrimary}
                  width="15px"
                  height="15px"
                />
                Duplicate {assetsConfig.labels.entry.singular}
              </a>
            }
          />
        </div>
      </div>

      <div className="info ml-auto min-w-[60px]">
        {props.entry.totalLineAmount && (
          <h2 className="ml-auto">${props.entry.totalLineAmount}</h2>
        )}
      </div>
    </EntryCardContainer>
  );
};

export default EntrantEntrySearchCard;

export interface EntrantEntrySearchResult {
  id: number;
  orderId?: number;
  title?: string;
  brand?: string;
  programId?: number;
  reviewStatusId: number;
  coverImage?: string;
  totalLineAmount?: number;
  hierarchy?: string[];
  createdDate: string;
  updatedDate: string;
}

interface EntrantEntrySearchCardProps {
  entry: EntrantEntrySearchResult;
  setEntryIdToDuplicate: Dispatch<SetStateAction<number | null>>;
}
