import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchSettingsSuccess } from "../components/Settings/actions";
import { SettingsState } from "../components/Settings/settingsReducer";
import { urlSettings } from "../endpoints";

export const useSettings = () => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState<SettingsState>({
    isDemo: false,
    featureFlags: {},
  });
  const [loading, setLoading] = useState(true); // Add loading state here

  const setFallback = () => {
    setSettings({
      isDemo: false,
      featureFlags: {},
    });
    setLoading(false); // Set loading to false even on fallback
  };

  async function getSettingsInfo() {
    try {
      const response = await axios.get<SettingsState>(`${urlSettings}`);
      if (response.status === 200) {
        return response.data;
      } else {
        setFallback();
      }
    } catch (error: any) {
      setFallback();
    }
  }

  useEffect(() => {
    const getSettingsAsync = async () => {
      let results = await getSettingsInfo();
      if (results) {
        setSettings(results);
        setLoading(false); // Set loading to false when settings are fetched
      } else {
        setFallback();
      }
    };
    getSettingsAsync();
  }, []);

  useEffect(() => {
    if (Object.keys(settings.featureFlags).length !== 0) {
      // Dispatch action only when featureFlags are populated
      dispatch(fetchSettingsSuccess(settings));
    }
  }, [settings, dispatch]);

  return { settings, loading, setSettings }; // Return loading state
};
