import { useState, useEffect } from "react";
import ProgramCard from "../../components/ProgramCard/ProgramCard";
import UserMenu from "../Admin/User/UserMenu";
import styled, { useTheme } from "styled-components";
import { PatternBG } from "../../globalStyles";
import { NavTabList } from "../../components/NavTabs/NavTabs";
import { useParams } from "react-router-dom";
import { ProgramDisplay } from "../Admin/Program/ProgramInterfaces";
import { getProgramDisplays } from "../Admin/Program/manageProgramDisplays";
import PrimaryLogo from "../../components/PrimaryLogo/PrimaryLogo";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";

export const PageContainer = styled.div`
	width: 100%;
	${PatternBG};
`;

export const ProgramCardsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 4.375rem;
	column-gap: 1.25rem;
	flex-wrap: wrap;
	padding: 0 3rem;
	margin: 6.09375rem auto;
	max-width: 1800px;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, minmax(502px, 1fr));
	/* grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); */
`;

const AwardsDropdown = styled.div`
	display: flex;
	align-items: center;
	padding-right: 2rem;
	margin-left: 5rem;
	border-right: 1px solid ${({ theme }) => theme.colorBorder};
	cursor: pointer;

	h1 {
		color: ${({ theme }) => theme.colorCopyLightLight};
		margin-right: 2rem;
	}
`;

const EntrantPrograms = (props: { metaDescription?: string }) => {
	const theme = useTheme();
	const params = useParams<{ programId: string }>();
	const [programDisplay, setProgramDisplay] = useState<null | ProgramDisplay[]>(
		null
	);

	useEffect(() => {
		getProgramDisplays().then((res) => {
			if (res.status === 200) {
				// console.log("Program Displays Response: ", res);
				const programDisplayArr: ProgramDisplay[] = res.data.flatMap(
					(programDisplay: ProgramDisplay) => {
						if (programDisplay.programDepthLevel === 0) {
							return programDisplay;
						} else return [];
					}
				);
				setProgramDisplay(programDisplayArr);
				// console.log("programDisplayArr", programDisplayArr);
			}
		});
	}, []);

	return (
		<PageContainer>
			<BrowserHeader title={props.metaDescription || "Enter Now"} />
			<UserMenu />
			<NavTabList>
				<PrimaryLogo
					className="mb-[66px]"
					color={theme.colorLogoLightLight}
					width="254px"
				/>
			</NavTabList>
			<ProgramCardsContainer>
				{/* ROOT LEVEL PROGRAMS */}
				{programDisplay &&
					!params.hasOwnProperty("programId") &&
					programDisplay.map((programDisplay: ProgramDisplay, i: number) => {
						return (
							<ProgramCard
								{...programDisplay}
								className="program-card"
								key={i}
								isAdmin={false}
							/>
						);
					})}
			</ProgramCardsContainer>
		</PageContainer>
	);
};

export default EntrantPrograms;
