import { MediaItem, MediaType } from "../../MediaLibrary/mediaLibrary.model.d";
import { AwardModel } from "../Awards/Awards";
import { SeasonModel } from "../Seasons/Seasons";
import { CompanyCredit, IndividualCredit } from "../Credits/CreditInterfaces";
import enumToDropdownOptions from "../../../utils/enumToDropdownOptions";
import { CompanyModel } from "../../Company/company.model";
import { DropdownOptions } from "../../../components/FormFields/DropdownField";

export interface ProgramModel {
	id?: number;
	name: string;
	description: string;
	titleNameOverride?: string;
	brandNameOverride?: string;
	titleDescriptionOverride?: string;
	brandDescriptionOverride?: string;
	brandOptions?: string;
	image?: string;
	imageData?: File;
	welcomeVideo?: string;
	welcomeVideoData?: File;
	imageDisplaysForEntrants: boolean;
	imageDisplaysForJurors: boolean;
	imageDisplaysForWinnersGallery: boolean;
	isCampaignEligible: boolean;
	isMixedCampaignEligible: boolean;
	isEntryLevel: boolean;
	isExecution: boolean;
	entriesOpenDate?: number;
	entriesClosed?: boolean;
	deadlines: ProgramDeadline[];
	season?: SeasonModel;
	seasonId?: number;
	award?: AwardModel;
	awardId?: number;
	parentId?: number;
	hasChildren: boolean;
	children?: ProgramModel[];
	childLevelDisplayLabel: string;
	depth?: number;
	ancestry?: Ancestor[];
	rootProgramId: number;
	physicalComponentAllowed: boolean;
	physicalComponentEntrantHelpText?: string;
	isCampaignOnly: boolean;
	fields?: (
		| ProgramMediaField
		| ProgramTextField
		| ProgramDateField
		| ProgramListField
		| ProgramCreditField
		| ProgramLinkField
		| ProgramPhysicalComponentField
	)[];
	inheritedFields?: (
		| ProgramMediaField
		| ProgramTextField
		| ProgramDateField
		| ProgramListField
		| ProgramCreditField
		| ProgramLinkField
		| ProgramPhysicalComponentField
	)[];
	createdDate?: string;
	createdBy?: string;
	modifiedDate?: string;
	modifiedBy?: string;
}

export interface Ancestor {
	id: number;
	name: string;
	childLevelDisplayLabel?: string;
	currentLevelDisplayLabel?: string;
	description?: string;
}

export interface ProgramDisplay {
	id?: number;
	programId?: number;
	programName?: string;
	programDescription?: string;
	programHasChildren?: boolean;
	programIsEntryLevel?: boolean;
	programEntriesClosed?: boolean;
	programEntriesOpenDate?: number;
	programDepthLevel?: number;
	programImage?: string;
	programWelcomeVideo?: string;
	programImageDisplaysForEntrants?: boolean;
	isActive?: boolean;
	specialNote?: string;
	entryKit?: string;
	entryKitData?: File;
	bestPractices?: string;
	bestPracticesData?: File;
	displayDates?: DisplayDate[];
	showDisplayDates?: boolean;
	showCountdown?: boolean;
	sortOrder: number;
	createdDate?: string;
	createdBy?: string;
	modifiedDate?: string;
	modifiedBy?: string;
	entryKitOverrideText?: string;
	bestPracticesOverrideText?: string;
	enterButtonOverrideText?: string;
}

export interface ProgramCardModel {
	ancestry: Ancestor[];
	welcomeVideo?: string;
	image?: string;
	imageDisplaysForEntrants?: boolean;
	subprogramCards: SubprogramCardModel[];
}

export interface SubprogramCardModel {
	id: number;
	name: string;
	entriesClosed: boolean;
	entriesOpenDate: number;
	description: string;
	image?: string;
	welcomeVideo?: string;
	imageDisplaysForEntrants: boolean;
	isEntryLevel: boolean;
	hasChildren: boolean;
}

export interface DisplayDate {
	id?: number;
	programDisplayId?: number;
	label: string;
	date: number;
}

export interface ProgramDeadline {
	id?: number;
	programId?: number;
	name: string;
	date: number;
	price: number;
	campaignMultiplier?: number;
}

export interface Adjustment {
	id: number;
	adjustmentDate: number;
	expirationDate: number;
	programId: number;
	companyId: number;
}

export interface FieldTemplate {
	id?: number;
	name: string;
	title: string;
	adminHelpText: string;
	entrantHelpText: string;
	jurorHelpText: string;
	displayForJurors: boolean;
	displayForWinnersGallery: boolean;
	isActive: boolean;
	fieldWeight?: number;
	createdDate?: string;
	createdBy?: string;
	modifiedDate?: string;
	modifiedBy?: string;
}

export interface ProgramField {
	templateId?: number;
	programId?: number;
	fieldWeight?: number;
	isInheritedField?: boolean;
}

export interface EntryField {
	classType: string;
	id: number;
	entryId: number;
	programFieldId: number;
}

// #region MediaField
export interface MediaFieldTemplate extends FieldTemplate {
	mediaType: MediaType;
	acceptedDocTypes?: string;
	maxQuantity: number;
	minQuantity: number;
}
export interface ProgramMediaField extends ProgramField, MediaFieldTemplate {}

export interface EntryMediaField extends EntryField {
	programField: ProgramMediaField;
	mediaItems: EntryMediaJoin[];
}

export interface EntryMediaJoin {
	id?: number;
	fieldId: number;
	mediaId: string;
	mediaItem: MediaItem;
	weight: number;
}
// #endregion MediaField

// #region TextField
export enum TextType {
	Character,
	Sentence,
	Paragraph,
}

export const textTypeOptions = enumToDropdownOptions(TextType);

export interface TextFieldTemplate extends FieldTemplate {
	textType: TextType;
	maxLength: number;
	formatText: boolean;
	isRequired: boolean;
}

export interface ProgramTextField extends ProgramField, TextFieldTemplate {}

export interface EntryTextField extends EntryField {
	programField: ProgramTextField;
	text: string;
}
// #endregion TextField

// #region ListField
export enum ListType {
	Radio,
	CheckBox,
	SingleDropDown,
	MultiDropDown,
}

export const listTypeOptions = enumToDropdownOptions(ListType);

export interface ListFieldTemplate extends FieldTemplate {
	listType: ListType;
	maxQuantity: number;
	minQuantity: number;
	listOptions: string;
}

export interface ProgramListField extends ProgramField, ListFieldTemplate {}

export interface EntryListField extends EntryField {
	programField: ProgramListField;
	selectedOption: string[];
}
// #endregion ListField

// #region CreditField
export enum CreditType {
	Company,
	Individual,
}

export interface CreditFieldTemplate extends FieldTemplate {
	creditType: CreditType;
	requiredCreditClassification?: string;
	isUnlocked: boolean;
	//   isRequired: boolean;
	maxCredits: number;
	minCredits: number;
	displayNotApplicable?: boolean;
}

export interface ProgramCreditField extends ProgramField, CreditFieldTemplate {}

export interface EntryCompanyCreditField extends EntryField {
	programField: ProgramCreditField;
	notApplicableAttestation: boolean;
	credits: CompanyCreditJoin[];
}

export interface CompanyCreditJoin {
	id?: number;
	fieldId: number;
	creditId: number;
	credit: CompanyCredit;
	weight?: number;
}

export interface IndividualCreditJoin {
	id?: number;
	fieldId: number;
	creditId: number;
	credit: IndividualCredit;
	weight?: number;
}

export interface EntryIndividualCreditField extends EntryField {
	programField: ProgramCreditField;
	notApplicableAttestation: boolean;
	displayNotApplicable: boolean;
	credits: IndividualCreditJoin[];
}
// #endregion CreditField

// #region DateField
export enum DateType {
	Single,
	Range,
}

export interface DateFieldTemplate extends FieldTemplate {
	dateType: DateType;
	displayTime: boolean;
	minDate?: string;
	maxDate?: string;
	minDateTime?: number;
	maxDateTime?: number;
	isRequired: boolean;
}

export interface ProgramDateField extends ProgramField, DateFieldTemplate {}

export interface EntryDateField extends EntryField {
	programField: ProgramDateField;
	startDate?: string;
	endDate?: string;
	startDateTime?: number;
	endDateTime?: number;
}

// #endregion DateField

// #region LinkField
export interface LinkFieldTemplate extends FieldTemplate {
	minLinks: number;
	maxLinks: number;
}

export interface ProgramLinkField extends ProgramField, LinkFieldTemplate {}

export interface EntryLinkField extends EntryField {
	programField: ProgramLinkField;
	links: LinkSet[];
}

export interface LinkSet {
	id?: number;
	entryLinkFieldId?: number;
	link: string;
	username?: string;
	password?: string;
}

// #endregion LinkField

// #region PhysicalComponentField
export interface PhysicalComponentFieldTemplate extends FieldTemplate {
	isRequired: boolean;
}

export interface ProgramPhysicalComponentField
	extends ProgramField,
		PhysicalComponentFieldTemplate {}

export interface EntryPhysicalComponentField extends EntryField {
	programField: ProgramPhysicalComponentField;
	physicalItemDescription: string;
	hasPhysicalComponent: boolean;
}
// #endregion PhysicalComponentField

// #region Execution
export interface Execution {
	mediaFields: any;
	fields?: EntryField[];
}
// #endregion Execution

export const CompanyTypeData: string[] = [
	"Other",
	"Advertiser / Brand",
	"Advertising Agency",
	"Advertising Club",
	"Agency",
	"Agency Network",
	"Animation Company",
	"Arts Organization",
	"Association",
	"Brand",
	"Casting Agency",
	"Client",
	"Creative Agency",
	"Design Company",
	"Digital Agency",
	"Distribution Company",
	"Dispensary",
	"Editorial Company",
	"Entrant Company",
	"Film Studio",
	"Game Publisher",
	"Holding Company",
	"Interactive Agency",
	"League",
	"Licensee",
	"Marketing Company",
	"Media Agency",
	"Music Company",
	"Music Publisher",
	"Music/Sound Company",
	"Network",
	"Non-Profit",
	"Not for Profit",
	"Photography Studio",
	"Post Production Company",
	"Production Company",
	"Production Studio",
	"Public Relations Agency",
	"Publication",
	"Publishing Company",
	"Radio Network",
	"Radio Station",
	"Record Label",
	"School",
	"Social Agency",
	"Sound Design Company",
	"Streaming Platform",
	"Studio",
	"Talent Agency",
	"Team",
	"Television Network",
	"Television Station",
	"Vendor",
	"Visual Effects Company",
];

export const CompanyTypeOptions: DropdownOptions[] = CompanyTypeData.map(
	(companyType) => ({
		value: companyType,
	})
);

export const IndividualTypeData: string[] = [
	"Additional Credits",
	"Other",
	"Account Executive",
	"Administrative Assistant",
	"Animator",
	"Art Director",
	"Artist",
	"Associate Creative Coordinator",
	"Associate Creative Director",
	"Awards Manager",
	"Awards Producer",
	"Awards Show Coordinator",
	"Chief Creative Officer",
	"Chief Executive Officer",
	"Cinematographer",
	"Composer",
	"Copywriter",
	"Creative Assistant",
	"Creative Coordinator",
	"Creative Director",
	"Creative Services Manager",
	"Designer",
	"Developer",
	"Director",
	"Editor",
	"Educational Instructor",
	"Executive Assistant",
	"Executive Creative Director",
	"Executive Director",
	"Executive Producer",
	"General Manager",
	"Global Chief Creative Officer",
	"Group Creative Director",
	"Managing Director",
	"Marketing",
	"Music Supervisor",
	"Office Manager",
	"Personal Assistant",
	"Photographer",
	"President",
	"Producer",
	"Production Assistant",
	"Production Manager",
	"Project Manager",
	"Public Relations",
	"Public Relations Director",
	"Secretary",
	"Songwriter",
	"Sound Designer",
	"Student",
	"Traffic Manager",
	"Vice President",
];
