import { useEffect, useState } from "react";
import { DropdownOptions } from "../../../../components/FormFields/DropdownField";
import { HierarchySelection } from "../../../../components/HierarchyDropDownModal/HierarchyDropDown";
import MultiDropdown from "../../../../components/MultiDropdown/MultiDropdown";
import HierarchyMultiselect from "./HierarchyMultiselect";
import { useFormikContext } from "formik";

const MultiSelectRow = (props: MultiSelectRowProps) => {
	const { setFieldValue } = useFormikContext<any>();
	const [selectedRootProgram, setSelectedRootProgram] =
		useState<HierarchySelection[]>();

	return (
		<>
			{/* root level programs */}
			<div className="flex flex-col gap-[.5rem]">
				<span>IN</span>
				<MultiDropdown
					placeholder={"Program"}
					options={props.rootLevelPrograms}
					onChange={(selectedList) => {
						// option is removed in multiselect
						if (selectedList.length === 0) {
							setSelectedRootProgram(undefined);
							setFieldValue(props.name, []);
						}
						// 1 or more options are multiselected
						else {
							const rootPrograms = selectedList.map((selected) => ({
								id: selected.value,
								name: String(selected.label),
								isExecution: false,
							}));

							setSelectedRootProgram(rootPrograms);
							if (rootPrograms.length === 1) {
								setFieldValue(props.name, rootPrograms);
							} else {
								setFieldValue(props.name, [rootPrograms]);
							}
						}
					}}
				/>
			</div>

			{selectedRootProgram &&
				selectedRootProgram.length === 1 &&
				!selectedRootProgram[0].isExecution && (
					<HierarchyMultiselect
						parentId={selectedRootProgram[0].id}
						name={props.name}
						index={1}
					/>
				)}
		</>
	);
};

export default MultiSelectRow;

interface MultiSelectRowProps {
	rootLevelPrograms: DropdownOptions[];
	name: string;
}
