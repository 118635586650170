import {
	LibraryContainer,
	SortContainer,
} from "../../../components/Library/Library";
import styled, { useTheme, css } from "styled-components";
import UserMenu from "../User/UserMenu";
import { PatternBG } from "../../../globalStyles";
import DropdownField from "../../../components/FormFields/DropdownField";
import SearchBar from "../../../components/SearchBar/SearchBar";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import MediaUpload, { FileMetaData } from "./MediaUpload";
import {
	MediaSortType,
	filterMediaByActive,
	filterMediaByFileName,
	filterMediaByType,
	filterMediaByUploadDate,
	handleMediaSort,
	mediaSortOptions,
} from "../../MediaLibrary/MediaSort";
import {
	MediaItem,
	MediaType,
	Tag,
} from "../../MediaLibrary/mediaLibrary.model.d";
import {
	getPlaceholderFromFile,
	getMediaWithInfo,
	getLinkedEntries,
	deleteMediaItem,
	EditMediaItem,
	getPlaceholder,
} from "../../MediaLibrary/manageMediaLibrary";
import { filterByTags } from "../../MediaLibrary/MediaSort";
import { getThumbnailSrc } from "../../MediaLibrary/manageMediaLibrary";
import Loading from "../../../components/Loading/Loading";
import EditMediaModal from "./EditMediaModal";
import { useParams, useHistory, useLocation } from "react-router-dom";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import { getCompany } from "../../Company/manageCompany";
import TagSelectionModal from "../../../components/Tags/TagSelectionModal";
import MediaLightBox from "../../Judging/EntryDetail/MediaLightbox";
import useTaskHandler, {
	BottomBar,
	PENDING,
	SelectableContainer,
	StyledSelectableContainer,
	TASKS_COMPLETED,
	TaskHandlerReturnType,
	TaskState,
} from "../../../hooks/useTaskHandler";
import Icon from "../../../components/Icon/Icon";
import Button from "../../../components/Button/Button";
import TagFilter from "./TagFilter";
import { UserPageContainer } from "../../../components/UserPageTemplate/UserPageTemplate";
import { set } from "date-fns";
import config from "../../../config";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import Image from "../../../components/Image/Image";
import assetsConfig from "../../../assetsConfig";

// https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/
export const ImgGridCSS = css`
	--grid-layout-gap: 2rem;
	--grid-column-count: 4;
	--grid-item--min-width: 340px;

	--gap-count: calc(var(--grid-column-count) - 1);
	--total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
	--grid-item--max-width: calc(
		(100% - var(--total-gap-width)) / var(--grid-column-count)
	);

	display: grid;
	grid-template-columns: repeat(
		auto-fill,
		minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
	);
	grid-gap: var(--grid-layout-gap);
`;

const MediaContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
`;

const ImgGrid = styled.div`
	position: relative;
	height: 100%;
	padding: 2rem;
	background: ${({ theme }) => theme.colorBackgroundLightLight};

	${ImgGridCSS};

	.img-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colorBoxShadow};
		cursor: pointer;

		.fileName {
			padding: 1rem;
			background: ${({ theme }) => theme.colorBackgroundLight};
			height: 78px;

			p {
				text-overflow: ellipsis;
				overflow: hidden;
				word-break: break-all;

				// clamp text to 2 lines
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
		}

		img {
			flex: 1;
			width: 100%;
			object-fit: cover;
			aspect-ratio: 16 / 9;
		}
	}
`;

const StyledFilePlaceholder = styled.div`
	position: relative;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};

	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100px;
		height: 100px;
		transform: translate(-50%, -50%);
		z-index: 50;
	}
`;

export const NoResultsText = styled.h3`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: ${({ theme }) => theme.colorCopyLight};
`;

const FilePlaceholder = (props: FileMetaData) => {
	return (
		<StyledFilePlaceholder>
			<img
				className="spinner"
				src={config.assets.loading.secondary}
				alt="loading..."
			/>
			<div className="img-container opacity-30" key={props.id}>
				<img src={getPlaceholderFromFile(props.type)} alt={props.fileName} />
				<div className="fileName">
					<p>{props.fileName}</p>
				</div>
			</div>
		</StyledFilePlaceholder>
	);
};

const defaultFilter = {
	mediaType: null,
	uploadDate: null,
	tags: null,
	fileName: null,
	isActive: null,
};

const MediaLibrary = ({ metaDescription }: { metaDescription?: string }) => {
	const theme = useTheme();
	const history = useHistory();
	const location = useLocation();
	const { companyId } = useParams<{
		companyId?: string;
	}>();
	const parsedCompanyId = companyId
		? parseInt(companyId)
		: Number(getCompany() || "0");
	const [mediaLibrary, setMediaLibrary] = useState<MediaItem[]>([]); // media from DB
	const [filteredMedia, setFilteredMedia] = useState<MediaItem[]>([]);
	const [isFetchingMedia, setIsFetchingMedia] = useState(false);
	const [mediaModal, setMediaModal] = useState<null | MediaItem>(null);

	// upload states
	const [isUploading, setIsUploading] = useState(false);
	const [uploadFileMetadata, setUploadFileMetadata] = useState<FileMetaData[]>(
		[]
	);

	// multi-select functionality
	const [showTagModal, setShowTagModal] = useState(false);

	// filter/sort states
	const [filter, setFilter] = useState<MediaFilter>(defaultFilter);
	const [sortType, setSortType] = useState<MediaSortType | undefined>(
		undefined
	);

	const formikProps = useFormik({
		initialValues: {
			mediaTypeFilter: "All",
		},
		onSubmit: async (value) => {},
		validationSchema: Yup.object({}),
		enableReinitialize: true,
	});

	const getCompanyMedia = () => {
		setIsFetchingMedia(true);
		getMediaWithInfo(parsedCompanyId)
			.then((response) => {
				if (response.status === 200 && response.data) {
					setMediaLibrary(response.data);
				}
				setIsUploading(false);
				setIsFetchingMedia(false);
			})
			.catch((error) => {
				setIsFetchingMedia(false);
				setMediaLibrary([]);
			});
	};

	const onFileUploadError = () => {
		setIsUploading(false);
		setIsFetchingMedia(false);
	};

	const getCompanyMediaNoLoad = () => {
		getMediaWithInfo(parsedCompanyId)
			.then((response) => {
				if (response.status === 200 && response.data) {
					setMediaLibrary(response.data);
				}

				setIsUploading(false);
				setIsFetchingMedia(false);
			})
			.catch((error) => {
				setIsFetchingMedia(false);
				setMediaLibrary([]);
			});
	};

	const handleFilter = () => {
		let mediaLibraryCopy = [...mediaLibrary];

		if (
			filter.mediaType !== null &&
			filter.mediaType !== "" &&
			filter.mediaType !== "All"
		) {
			mediaLibraryCopy = filterMediaByType(
				mediaLibraryCopy,
				parseInt(filter.mediaType)
			);
		}

		if (filter.uploadDate !== null) {
			mediaLibraryCopy = filterMediaByUploadDate(
				mediaLibraryCopy,
				filter.uploadDate
			);
		}

		if (filter.fileName !== null && filter.fileName !== "") {
			mediaLibraryCopy = filterMediaByFileName(
				mediaLibraryCopy,
				filter.fileName
			);
		}

		if (filter.tags && filter.tags.length > 0) {
			mediaLibraryCopy = filterByTags(mediaLibraryCopy, filter.tags);
		}

		if (filter.isActive) {
			mediaLibraryCopy = filterMediaByActive(mediaLibraryCopy);
		}

		if (sortType) {
			mediaLibraryCopy = handleMediaSort(sortType, mediaLibraryCopy);
		}

		setFilteredMedia(mediaLibraryCopy);
	};

	const handleEditMedia = useCallback((media: MediaItem) => {
		return new Promise<MediaItem>((resolve, reject) => {
			EditMediaItem(media)
				.then(() => {
					resolve(media);
				})
				.catch((e) => reject(`Error Editing Media Item: ${e.message}`));
		});
	}, []);

	const deleteMedia = useCallback((media: MediaItem): Promise<MediaItem> => {
		return new Promise((resolve, reject) => {
			getLinkedEntries(parsedCompanyId, media.id!)
				.then((res) => {
					if (res.status === 200) {
						deleteMediaItem(media, res.data)
							.then(() => {
								resolve(media);
							})
							.catch((e) => reject(`Error Deleting Media Item: ${e.message}`));
					}
				})
				.catch((e) => reject(`Error Deleting Media Item: ${e.message}`));
		});
	}, []);

	/*
  state.pending: Processing items array
  state.completed: Completed items array
  state.errored: Errored items array (includes item and error)
  state.next: currently processing item
  state.prev: last processed item
  */
	const {
		reset,
		loadItems,
		onSubmit,
		handleSelectItem,
		...state
	}: TaskHandlerReturnType<MediaItem> = useTaskHandler<MediaItem>();

	// Add deletion tasks when 'Delete Selected' is clicked
	const handleDeleteSelected = () => {
		onSubmit(deleteMedia);
	};

	const handleTagSelected = (tags: Tag[], selectedMedia: MediaItem[]) => {
		const newArray = selectedMedia.map((media) => {
			return { ...media, tags: tags };
		});
		onSubmit(handleEditMedia, newArray);
	};

	const handleToggleActiveSelected = (
		isActive: boolean,
		selectedMedia: MediaItem[]
	) => {
		const newArray = selectedMedia.map((media) => {
			return { ...media, isActive: isActive };
		});
		onSubmit(handleEditMedia, newArray);
	};

	const commonTags = useMemo(() => {
		if (state.items.length === 0) {
			return [];
		}

		const firstItemTags = state.items[0].tags || [];

		for (let i = 1; i < state.items.length; i++) {
			const mediaItem = state.items[i];

			// Compare tags arrays. If they differ, return empty array.
			if (
				!firstItemTags.every(
					(tag) =>
						mediaItem.tags &&
						mediaItem.tags.findIndex((x) => x.id === tag.id) > -1
				)
			) {
				return [];
			}
		}

		// If we made it through the loop without returning, all tags are the same.
		return firstItemTags;
	}, [state.items]);

	const allActive = useMemo(() => {
		if (state.items.length === 0) {
			return false;
		}
		return state.items.every((x) => x.isActive);
	}, [state.items]);

	useEffect(() => {
		if (state.status === PENDING) {
			setIsUploading(true);
		}
		if (state.status === TASKS_COMPLETED) {
			// If there are no tasks left, refresh the media list
			getCompanyMediaNoLoad();
			setIsUploading(false);
		}
	}, [state.status]);

	useEffect(() => {
		if (parsedCompanyId) {
			getCompanyMedia();
		}
	}, [parsedCompanyId]);

	// sort media by create date when media is first fetched
	useEffect(() => {
		handleFilter();
	}, [mediaLibrary]);

	useEffect(() => {
		handleFilter();
	}, [filter, sortType]);

	const handleClear = () => {
		setFilter({ ...defaultFilter });
		setSortType(undefined);
		formikProps.setFieldValue("mediaSort", "");
		formikProps.setFieldValue("mediaTypeFilter", "");
	};

	return (
		<FormikProvider value={formikProps}>
			<BrowserHeader title={metaDescription || `Media Library`} />
			{mediaModal && (
				<EditMediaModal
					media={mediaModal}
					onHide={() => setMediaModal(null)}
					refreshMedia={getCompanyMedia}
				/>
			)}

			<div className="w-full h-full flex flex-col">
				{/* show user menu for non-admin page */}
				{!companyId && <UserMenu title="Media Library" />}

				<UserPageContainer userPage={companyId ? false : true}>
					<LibraryContainer>
						{/* <h1>Media Library</h1> */}
						{showTagModal && state.items.length && (
							<TagSelectionModal
								closeModal={(tags, cancelled) => {
									if (!cancelled) {
										handleTagSelected(tags, state.items);
									}
									setShowTagModal(false);
								}}
								companyId={parsedCompanyId}
								inputTags={commonTags}
								title="Add Tags to Uploaded Media"
							/>
						)}

						<MediaUpload
							isUploading={setIsUploading}
							uploadFileMetadata={setUploadFileMetadata}
							onUploadSuccess={getCompanyMedia}
							onUploadError={onFileUploadError}
							companyId={parsedCompanyId}
						/>

						<MediaContainer>
							<SortContainer>
								<DropdownField
									className="w-80"
									name="mediaTypeFilter"
									placeholder="Search by Media Type"
									options={[
										{ label: "All", value: "All" },
										{ label: "Audio", value: MediaType.Audio },
										{ label: "Document", value: MediaType.Document },
										{ label: "Image", value: MediaType.Image },
										{ label: "Video", value: MediaType.Video },
									]}
									onChange={(e) =>
										setFilter({ ...filter, mediaType: e.target.value })
									}
								/>
								<DropdownField
									className="w-80"
									name="mediaSort"
									placeholder="Sort By"
									options={mediaSortOptions}
									onChange={(e) => {
										setSortType(
											MediaSortType[
												e.currentTarget.value as keyof typeof MediaSortType
											]
										);
									}}
								/>
								<SearchBar
									className="w-96 max-w-[330px]"
									searchPlaceholder="Search by File Name"
									onChange={(e) =>
										setFilter({ ...filter, fileName: e.target.value })
									}
									value={filter.fileName || ""}
									searchIconBG={theme.colorBackgroundLightLight}
									showBorder={false}
								/>

								<TagFilter
									className="w-96 max-w-[330px]"
									onSearch={(tags) => setFilter({ ...filter, tags: tags })}
									tags={filter.tags || []}
								/>

								<ToggleSwitchField
									className="w-64"
									id="isActive"
									name="isActive"
									checked={filter.isActive ? true : false}
									label={"Show Only Active Media"}
									small
									onClick={() =>
										setFilter({
											...filter,
											isActive: filter.isActive ? null : true,
										})
									}
								/>

								<Button className="button-light w-54" onClick={handleClear}>
									Clear
								</Button>
							</SortContainer>

							<p className="m-[2rem] mb-0">
								{assetsConfig.copy.mediaLibraryInstructions}
							</p>

							<ImgGrid>
								{isFetchingMedia ? (
									<Loading fullScreen={false} showLogo={false} />
								) : filteredMedia?.length === 0 ? (
									<NoResultsText>
										No Results Found. Please try a different search criteria or
										upload your media above.
									</NoResultsText>
								) : (
									<>
										{isUploading &&
											uploadFileMetadata.map((file) => (
												<FilePlaceholder key={file.id} {...file} />
											))}
										{filteredMedia.map((mediaObj) => {
											return (
												<SelectableContainer
													state={state}
													className="img-container"
													item={mediaObj}
													key={mediaObj.id}
													onClick={(event) => {
														if (event.shiftKey) {
															handleSelectItem(mediaObj);
														} else {
															setMediaModal(mediaObj);
														}
													}}
												>
													<Image
														key={mediaObj.id}
														src={getThumbnailSrc(mediaObj)}
														placeholderSrc={getPlaceholder(mediaObj.type)}
														alt={mediaObj.fileName}
														refetchOnError
														lazy
													/>
													<div className="fileName">
														<p>{mediaObj.fileName}</p>
													</div>
												</SelectableContainer>
											);
										})}
									</>
								)}
							</ImgGrid>
						</MediaContainer>
					</LibraryContainer>
				</UserPageContainer>

				<BottomBar show={state.items && state.items.length ? true : false}>
					<p className={`bar-text`}>{`${state.items.length} File${
						state.items.length > 1 ? "s" : ""
					} Selected`}</p>
					<ToggleSwitchField
						id="allActive"
						className="mx-[1.5rem]"
						name="allActive"
						disabled={state.status === PENDING}
						checked={allActive}
						label={allActive ? "Active" : "Inactive"}
						small
						onClick={() => handleToggleActiveSelected(!allActive, state.items)}
					/>
					<Button
						className="button-transparent flex-col w-[175px] max-w-[175px]"
						onClick={() =>
							state.status === PENDING ? {} : handleDeleteSelected()
						}
						icon="trash"
						iconSize="20px"
						iconColor={theme.colorPrimary}
					>
						Delete
					</Button>
					<Button
						className="button-transparent flex-col w-[175px] max-w-[175px]"
						onClick={() =>
							state.status === PENDING ? {} : setShowTagModal(true)
						}
						icon="tag"
						iconSize="20px"
						iconColor={theme.colorPrimary}
					>
						Tag
					</Button>
					<Button
						className="button-transparent flex-col w-[175px] max-w-[175px]"
						onClick={() => loadItems([])}
						icon="deselect"
						iconSize="20px"
						iconColor={theme.colorPrimary}
					>
						Deselect All
					</Button>
				</BottomBar>
			</div>
		</FormikProvider>
	);
};

export default MediaLibrary;

interface MediaFilter {
	mediaType: null | string;
	uploadDate: null | Date;
	tags: null | string[];
	fileName: null | string;
	isActive: null | boolean;
}
