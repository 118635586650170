import { FormikProvider, useFormik } from "formik";
import Icon from "../../../components/Icon/Icon";
import Modal, { ModalCard } from "../../../components/Modal/Modal";
import {
	MediaCreditEntryInfoModel,
	MediaItem,
	MediaType,
} from "../../MediaLibrary/mediaLibrary.model.d";
import * as Yup from "yup";
import styled, { useTheme } from "styled-components";
import {
	RenderMediaPreview,
	EditMediaItem,
	deleteMediaItem,
	getLinkedEntries,
} from "../../MediaLibrary/manageMediaLibrary";
import TextField from "../../../components/FormFields/TextField";
import { KBtoMB } from "../../../utils/KBtoMB";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import Button from "../../../components/Button/Button";

import { useContext, useEffect, useState } from "react";
import MediaLightBox from "../../Judging/EntryDetail/MediaLightbox";
import TagSelection from "../../../components/Tags/TagSelection";
import {
	ButtonContainer,
	EditModalContainer,
	EntryDetailList,
	InnerContainer,
	PageType,
} from "../../../components/Library/Library";
import { getClaims } from "../../../components/Auth/handleJWT";
import useDownloadMedia from "../../../utils/useDownloadMedia";
import { DownloadMediaButton } from "../../../components/DownloadMedia/DownloadMedia";
import { Link } from "react-router-dom";
import { useAlert } from "../../../components/Alert/Alerts";
import { getLocalTime } from "../../../utils/timeUtilities";
import { SpinnerSrc } from "../../../components/LocalFileUpload.tsx/LocalFileUpload";

const MediaDetailsGrid = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
	gap: 1rem;
`;

const EditMediaModal = (props: EditMediaModalProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const { downloadFile, isDownloading } = useDownloadMedia();
	const [showLightbox, setShowLightbox] = useState(false);

	const [isFetchingLinkedEntries, setIsFetchingLinkedEntries] = useState(false);
	const [linkedEntries, setLinkedEntries] = useState<
		MediaCreditEntryInfoModel[]
	>([]);
	const formikProps = useFormik({
		initialValues: props.media,
		onSubmit: async (value) => {},
		validationSchema: Yup.object({}),
		enableReinitialize: true,
	});

	useEffect(() => {
		if (props.media.companyId && props.media.id) {
			setIsFetchingLinkedEntries(true);
			getLinkedEntries(props.media.companyId, props.media.id).then(
				(response) => {
					if (response.status === 200 && response.data) {
						setLinkedEntries(response.data);
						setIsFetchingLinkedEntries(false);
					}
				}
			);
		}
	}, [props.media.companyId, props.media.id]);
	const media = formikProps.values;

	const handleEditMedia = () => {
		if (media) {
			EditMediaItem(media)
				.then(() => {
					addNewAlert({
						type: "success",
						message: "Successfully modified media",
					});
					props.onHide();
					props.refreshMedia();
				})
				.catch(() =>
					addNewAlert({
						type: "error",
						message: "Failed to edit media. Please try again later",
					})
				);
		} else {
			addNewAlert({
				type: "error",
				message: "Failed to edit media. Please try again later",
			});
		}
	};

	const handleDeleteMedia = () => {
		if (media && !isFetchingLinkedEntries) {
			deleteMediaItem(media, linkedEntries)
				.then(() => {
					addNewAlert({
						type: "success",
						message: "Successfully deleted media",
					});
					props.onHide();
					props.refreshMedia();
				})
				.catch((e) => {
					addNewAlert({
						type: "error",
						message: e.message,
					});
				});
		}
	};

	const claims = getClaims();
	const isAdmin = claims.some(
		(claim) => claim.name === "role" && claim.value === "admin"
	);

	return (
		<FormikProvider value={formikProps}>
			{media && (
				<Modal show={media !== null}>
					<ModalCard
						title="Edit Media"
						headerIcons={
							<Icon
								icon="close"
								color={theme.colorPrimary}
								onClick={() => props.onHide()}
								width="35px"
								height="35px"
							/>
						}
						iconColor={theme.colorPrimary}
					>
						<EditModalContainer>
							<InnerContainer>
								<RenderMediaPreview
									theme={theme}
									mediaItem={media}
									height="375px"
									onClickImg={() => setShowLightbox(true)}
								/>

								{/* <img
                className="w-full"
                src={getThumbnailSrc(media, ThumbnailSize.X_LARGE)}
                alt={media.fileName}
              /> */}
								<MediaLightBox
									show={showLightbox}
									onClickHide={() => setShowLightbox(false)}
									selectedMediaPreview={media}
									hideCarousel
									isZoomEnabled
								/>

								<div className="flex items-center justify-between">
									<h3>Media Details</h3>
									<ToggleSwitchField
										id="isActive"
										name="isActive"
										checked={media.isActive}
										label={media.isActive ? "Active" : "Inactive"}
										small
									/>
								</div>

								<MediaDetailsGrid>
									<b>File name:</b>
									<TextField
										name="fileName"
										value={media.fileName}
										hiddenlabel
									/>
									<b>File type: </b>
									{MediaType[media.type]}
									{media.createdDate && (
										<>
											<b>Uploaded On: </b>
											{getLocalTime(media.createdDate, true)}
										</>
									)}

									<b>File size: </b>
									{KBtoMB(Number(media.size))}
									{media.dimensions && (
										<>
											<b>Dimensions: </b>
											{media.dimensions}
										</>
									)}
								</MediaDetailsGrid>
								<TagSelection
									companyId={media.companyId}
									selectedTags={media.tags || []}
									setSelectedTags={(outputTags) => {
										formikProps.setFieldValue("tags", outputTags);
									}}
								/>
								<Link
									className="text-colorActivation !font-bold"
									to={
										isAdmin
											? "/admin/edit-entry/tag-library/" + props.media.companyId
											: "/tags"
									}
									target="_blank"
								>
									Manage Tags
								</Link>
								{/* <TagFilter onSearch={() => {}} placeholder={"Add Tags"} /> */}

								<EntryDetailList
									linkedEntries={linkedEntries}
									isFetchingLinkedEntries={isFetchingLinkedEntries}
									pageType={PageType.Media}
								/>
							</InnerContainer>
							<ButtonContainer>
								<Button
									className="button-light"
									icon="trash"
									iconSize="15px"
									iconColor={theme.colorActivation}
									onClick={handleDeleteMedia}
								>
									Delete
								</Button>
								<DownloadMediaButton
									fileName={media.fileName}
									path={media.path}
									component={(isDownloading) => (
										<Button
											className="button-gold flex [&>span]:flex [&>span]:items-center [&>span]:gap-[.5rem]"
											disabled={isDownloading}
										>
											{isDownloading ? (
												<img
													src={SpinnerSrc}
													className="w-[20px]"
													alt="loading..."
												/>
											) : (
												<Icon
													icon="download"
													color={theme.colorPrimary}
													width="12px"
													height="12px"
												/>
											)}{" "}
											Download
										</Button>
									)}
								/>
								<Button
									className="button-gold"
									icon="close"
									iconSize="18px"
									iconColor={theme.colorPrimary}
									onClick={() => props.onHide()}
								>
									Cancel
								</Button>
								<Button icon="check" onClick={handleEditMedia}>
									Save
								</Button>
							</ButtonContainer>
						</EditModalContainer>
					</ModalCard>
				</Modal>
			)}
		</FormikProvider>
	);
};

export default EditMediaModal;

interface EditMediaModalProps {
	media: MediaItem;
	onHide(): void;
	refreshMedia(): void;
}
