import { FormikProvider, useFormik } from "formik";
import { useEffect, useState, useContext, Fragment } from "react";
import { useParams } from "react-router-dom";
import {
  CardBody,
  CardContainer,
  CardHeader,
} from "../../../components/DashboardCard/DashboardCard";
import Loading from "../../../components/Loading/Loading";
import * as Yup from "yup";
import styled, { useTheme } from "styled-components";
import StyledForm from "../../../components/Form/Form";
import TextField from "../../../components/FormFields/TextField";
import Button from "../../../components/Button/Button";
import DropdownField, {
  DropdownOptions,
} from "../../../components/FormFields/DropdownField";
import { CountryOptions } from "../../../data/CountryData";
import {
  StickyCardBody,
  StickyContainer,
} from "../../../components/StickyCard/StickyCard";
import { EntryReviewContainer, EntryField } from "../../Entries/EntryForm";

import { NemoUser } from "../../../hooks/useCurrentUser";
import { JobTitleOptions } from "../../../data/JobTitleData";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import { CompanyModel } from "../../Company/company.model";
import axios from "axios";
import { urlManageCompanies } from "../../../endpoints";
import { CompanyTypeOptions } from "../Program/ProgramInterfaces";
import {
  getAgencyNetworks,
  getCompanyRequestUsers,
  getHoldingCompanies,
} from "../../Company/manageCompany";
import Table from "../../../components/Table/Table";
import Placeholder from "../../../assets/placeholders/placeholder.png";
import AdjustmentFields from "./AdjustmentFields";
import EntryGrid from "../../../components/EntryGrid/EntryGrid";
import { CollapsibleContainer } from "../../../components/Collapsible/Collapsible";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../components/Alert/Alerts";
import { getLocalTime } from "../../../utils/timeUtilities";
import assetsConfig from "../../../assetsConfig";
import { NewUserModal } from "../../../components/NewUserModal/NewUserModal";
import Authorized from "../../../components/Auth/Authorized";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { adminEnable } from "../User/managerUser";

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colorActivation};
`;

const CompanyInfo = styled.div`
  padding: 2.25rem;
  /* border: solid 2px ${({ theme }) => theme.colorBorderLight}; */
  background: ${({ theme }) => theme.colorBackgroundLightLight};
`;

const EditCompany = () => {
  const { addNewAlert } = useAlert();
  const theme = useTheme();
  const { companyId } = useParams<{ companyId: string }>();
  const [companyUsers, setCompanyUsers] = useState<NemoUser[]>([]);
  const [company, setCompany] = useState<CompanyModel>({
    id: 0,
    address1: "",
    address2: "",
    city: "",
    companyType: "",
    entryContactEmail: "",
    entryContactJobTitle: "",
    entryContactName: "",
    entryContactPhone: "",
    financeContactEmail: "",
    financeContactJobTitle: "",
    financeContactName: "",
    financeContactPhone: "",
    name: "",
    postalCode: "",
    socialMedia1: "",
    socialMedia2: "",
    socialMedia3: "",
    socialMedia4: "",
    state: "",
    country: "",
    invoiceContactName: "",
    invoiceEmail: "",
    invoicePhone: "",
    logo: "",
    overridePaymentMethod: false,
    createdDate: "",
    createdBy: "",
    updatedDate: "",
    modifiedBy: "",
    adjustments: [],
    agencyNetworkId: undefined,
    holdingCompanyId: undefined,
  });
  const [isCompanyCollapsed, setisCompanyCollapsed] = useState(false);
  const [isMetadataCollapsed, setIsMetadataCollapsed] = useState(true);
  const [isUserdataCollapsed, setIsUserdataCollapsed] = useState(true);
  const [isEntryDataCollapsed, setIsEntryDataCollapsed] = useState(true);
  const [isPaymentSettingsCollapsed, setIsPaymentSettingsCollapsed] =
    useState(true);

  const [showModal, setShowModal] = useState(false);

  const [agencyNetworksOptions, setAgencyNetworksDropdownOptions] = useState<
    DropdownOptions[]
  >([]);
  const [holdingCompaniesOptions, setHoldingCompaniesDropdownOptions] =
    useState<DropdownOptions[]>([]);
  useEffect(() => {
    getAgencyNetworks().then((data) => {
      var dropdownOptions = data!.map((r) => ({ value: r.id, label: r.name }));
      setAgencyNetworksDropdownOptions(dropdownOptions);
    });
  }, []);

  useEffect(() => {
    getHoldingCompanies().then((data) => {
      var dropdownOptions = data!.map((r) => ({ value: r.id, label: r.name }));
      setHoldingCompaniesDropdownOptions(dropdownOptions);
    });
  }, []);

  async function UpdateCompany(updatedCompany: CompanyModel) {
    // console.log(updatedCompany)

    axios
      .put(`${urlManageCompanies}/${updatedCompany.id}`, {
        ...updatedCompany,
      })
      .then((res) => {
        if (res.status === 204) {
          addNewAlert({ type: "success", message: "Successfully Updated" });
          setCompany(updatedCompany);
        }
      })
      .catch((err) => {
        addNewAlert({
          type: "error",
          message: "Server error. Please try again later",
        });
      });
  }

  async function getCompany(companyId: number) {
    const response = axios.get<CompanyModel>(
      `${urlManageCompanies}/getCompany/${companyId}`
    );
    return response;
  }

  async function getCompanyUsers(companyId: number) {
    try {
      const response = await axios.get<NemoUser[]>(
        `${urlManageCompanies}/getCompanyUsers/${companyId}`
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      addNewAlert({
        type: "error",
        message: "Server error. Please try again later",
      });
    }
  }

  useEffect(() => {
    getCompany(Number(companyId))
      .then((response) => {
        if (response.status === 200) {
          setCompany(response.data);
        }
      })
      .catch((error) => {
        // console.log("getOrder ERROR", error);
        // setErrors((old) => [...old, error]);
      });

    getCompanyUsers(Number(companyId)).then((response) => {
      setCompanyUsers(response!);
    });
  }, []);

  const fieldRequired = "This field is required";
  const formikProps = useFormik({
    initialValues: company,
    enableReinitialize: true,
    onSubmit: async (value) => {},
    validationSchema: Yup.object({
      name: Yup.string().required(fieldRequired),
      companyType: Yup.string().nullable().required(fieldRequired),
      city: Yup.string()
        .nullable()
        .when([], {
          is: () => settings.featureFlags["Company Address"],
          then: (schema) => schema.required(fieldRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
      country: Yup.string()
        .nullable()
        .when([], {
          is: () => settings.featureFlags["Company Address"],
          then: (schema) => schema.required(fieldRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
      state: Yup.string()
        .nullable()
        .when("country", {
          is: (value: string) =>
            value === "United States" ||
            (value === "Canada" && settings.featureFlags["Company Address"]),
          then: Yup.string().required(fieldRequired),
        }),
      address1: Yup.string()
        .nullable()
        .when([], {
          is: () => settings.featureFlags["Company Address"],
          then: (schema) => schema.required(fieldRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
      postalCode: Yup.string()
        .nullable()
        .when("country", {
          is: (value: string) =>
            value === "United States" ||
            (value === "Canada" && settings.featureFlags["Company Address"]),
          then: Yup.string().required(fieldRequired),
        }),
      agencyNetworkId: Yup.number()
        .nullable()
        .min(1, fieldRequired)
        .when([], {
          is: () => settings.featureFlags["Company Agency Network"],
          then: (schema) => schema.required(fieldRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
      holdingCompanyId: Yup.number()
        .nullable()
        .min(1, fieldRequired)
        .when([], {
          is: () => settings.featureFlags[`Company Holding Company`],
          then: (schema) => schema.required(fieldRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
      // phone: Yup.string().required(fieldRequired),
      // adjustments: Yup.array().of(
      //   Yup.object({
      //     programId: Yup.string().required("Program is required"),
      //     adjustmentDate: Yup.date().required("Adjustment date is required"),
      //     expirationDate: Yup.date()
      //       .required("Expiration date is required")
      //       .test(
      //         "is-future-date",
      //         "Expiration date must be in the future",
      //         function (value) {
      //           // this refers to the expirationDate field value
      //           if (!value) {
      //             return false;
      //           }
      //           return isAfter(value!, new Date());
      //         }
      //       ),
      //   })
      // ),
    }),
  });
  const handleSubmit = () => {
    formikProps.validateForm().then((res) => {
      //console.log(res)
      if (Object.values(res).length === 0) {
        UpdateCompany(formikProps.values);
      } else {
        addNewAlert({
          type: "error",
          message: "There are errors with the above fields",
        });
      }
    });
  };
  //console.log(formikProps.values.adjustments?.length)

  //function for increaing duration dynamically
  const increaseByFive = (n: number) => {
    let result = 0.15; // start with initial value of 0.15
    for (let i = 0; i < n; i++) {
      result += 0.03; // increase by 0.03 each time
    }
    return result;
  };

  const { settings, loading } = useSelector(
    (state: RootState) => state.settings
  );

  return (
    (formikProps && formikProps.values.id && (
      <CardContainer>
        <BrowserHeader
          title={`${assetsConfig.labels.company.singular}: [${formikProps.values.id}] ${formikProps.values.name}`}
        />
        <CardHeader>
          <h2 className="card-title">
            Update {assetsConfig.labels.company.singular}
          </h2>
        </CardHeader>
        <CardBody>
          <FormikProvider value={formikProps}>
            <NewUserModal
              onClickHide={() => {
                setShowModal(false);
                getCompanyUsers(Number(companyId)).then((response) => {
                  setCompanyUsers(response!);
                });
              }}
              show={showModal}
              companyId={formikProps.values.id}
            />
            <StickyContainer className="mb-[2rem] !relative !top-0 !min-h-min">
              <CollapsibleContainer
                className="mb-[2rem]"
                title={`${assetsConfig.labels.company.singular} Information`}
              >
                <StickyCardBody className="!p-0">
                  <CompanyInfo>
                    <div className="flex gap-[3.5rem]">
                      <StyledForm
                        className="twoColumn mb-[1rem] !gap-y-[3rem]"
                        compact
                      >
                        <TextField
                          name="name"
                          placeholder={`${assetsConfig.labels.company.singular} Name*`}
                          value={formikProps.values.name}
                        />

                        <DropdownField
                          name="companyType"
                          placeholder={`${assetsConfig.labels.company.singular} Type*`}
                          options={CompanyTypeOptions}
                        />
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Company Agency Network"
                          authorized={
                            <DropdownField
                              name="agencyNetworkId"
                              placeholder={`${assetsConfig.agencyNetworkCompanyName}`}
                              options={agencyNetworksOptions}
                            />
                          }
                        />
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Company Holding Company"
                          authorized={
                            <DropdownField
                              name="holdingCompanyId"
                              placeholder={`${assetsConfig.holdingCompanyName}`}
                              options={holdingCompaniesOptions}
                            />
                          }
                        />
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Company Address"
                          authorized={
                            <>
                              <TextField
                                name="address1"
                                placeholder={`${assetsConfig.labels.company.singular} Address 1*`}
                                value={formikProps.values.address1}
                              />

                              <TextField
                                name="address2"
                                placeholder={`${assetsConfig.labels.company.singular} Address 2*`}
                                value={formikProps.values.address2!}
                              />

                              <TextField
                                name="city"
                                placeholder="City*"
                                value={formikProps.values.city}
                              />

                              <TextField
                                name="state"
                                placeholder="State"
                                value={formikProps.values.state}
                              />

                              <TextField
                                name="postalCode"
                                placeholder="Postal Code*"
                                value={formikProps.values.postalCode}
                              />
                              <DropdownField
                                name="country"
                                placeholder="Country*"
                                options={CountryOptions}
                              />
                            </>
                          }
                        />
                        <TextField
                          name="entryContactName"
                          placeholder={`${assetsConfig.labels.entry.singular} Contact Name`}
                          value={formikProps.values.entryContactName}
                        />
                        <TextField
                          name="entryContactEmail"
                          placeholder={`${assetsConfig.labels.entry.singular} Contact Email`}
                          value={formikProps.values.entryContactEmail}
                        />
                        <DropdownField
                          name="entryContactJobTitle"
                          placeholder={`${assetsConfig.labels.entry.singular} Contact Job Title* `}
                          options={JobTitleOptions}
                        />
                        <TextField
                          name="entryContactPhone"
                          placeholder={`${assetsConfig.labels.entry.singular} Contact Phone`}
                          value={formikProps.values.entryContactPhone}
                        />
                        <TextField
                          name="financeContactName"
                          placeholder="Finance Contact Name"
                          value={formikProps.values.financeContactName}
                        />
                        <TextField
                          name="financeContactEmail"
                          placeholder="Finance Contact Email"
                          value={formikProps.values.financeContactEmail}
                        />
                        <DropdownField
                          name="financeContactJobTitle"
                          placeholder="Finance Contact Job Title* "
                          options={JobTitleOptions}
                        />
                        <TextField
                          name="financeContactPhone"
                          placeholder="Finance Contact Phone"
                          value={formikProps.values.financeContactPhone}
                        />
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Company Social Fields"
                          authorized={
                            <>
                              <TextField
                                name="socialMedia1"
                                placeholder="LinkedIn"
                                value={formikProps.values.socialMedia1}
                              />
                              <TextField
                                name="socialMedia2"
                                placeholder="Twitter"
                                value={formikProps.values.socialMedia2}
                              />
                              <TextField
                                name="socialMedia3"
                                placeholder="Instagram"
                                value={formikProps.values.socialMedia3}
                              />
                              <TextField
                                name="socialMedia4"
                                placeholder="Facebook"
                                value={formikProps.values.socialMedia4}
                              />
                            </>
                          }
                        />
                        <div className="flex flex-col gap-[2rem] flex-1">
                          <AdjustmentFields
                            companyId={formikProps.values.id!}
                          />
                        </div>
                      </StyledForm>
                    </div>

                    <div className="max-w-[650px] grid grid-cols-0 justify-end gap-[1rem] mt-[1rem] ml-auto">
                      <Button
                        // onClick={() => {
                        //   console.log(formikProps.values);
                        //   formikProps.submitForm();
                        // }}
                        onClick={() => handleSubmit()}
                        disabled={formikProps.isSubmitting}
                      >
                        Update {assetsConfig.labels.company.singular}
                      </Button>
                    </div>
                  </CompanyInfo>
                </StickyCardBody>
              </CollapsibleContainer>

              <CollapsibleContainer
                className="mb-[2rem]"
                title={`${assetsConfig.labels.company.singular} Metadata`}
              >
                <StickyCardBody className="!p-0">
                  <EntryReviewContainer className="p-[2.25rem]">
                    <EntryField
                      label="Date Created"
                      value={
                        formikProps.values.createdDate
                          ? getLocalTime(formikProps.values.createdDate, true)
                          : ""
                      }
                      isRequired={false}
                    />
                    <EntryField
                      label="Date Last Modified"
                      value={
                        formikProps.values.updatedDate
                          ? getLocalTime(formikProps.values.updatedDate, true)
                          : ""
                      }
                      isRequired={false}
                    />
                    <EntryField
                      label="Last Modified User"
                      value={formikProps.values.modifiedByName}
                      hardLink={`/edit-user/${formikProps.values.modifiedBy}`}
                      isRequired={false}
                    />
                    <EntryField
                      label={`${assetsConfig.labels.company.singular} Id`}
                      value={formikProps.values.id}
                      isRequired={false}
                    />
                    <EntryField
                      label="Media Library"
                      value={formikProps.values.id}
                      isRequired={false}
                      hardLink={`/admin/edit-entry/media-library/${formikProps.values.id}`}
                      linkText={`/admin/edit-entry/media-library/${formikProps.values.id}`}
                    />
                    <EntryField
                      label="Individual Credits"
                      value={formikProps.values.id}
                      isRequired={false}
                      hardLink={`/admin/edit-entry/credit-library/individual/${formikProps.values.id}`}
                      linkText={`/admin/edit-entry/credit-library/individual/${formikProps.values.id}`}
                    />

                    <EntryField
                      label={`${assetsConfig.labels.company.singular} Credits`}
                      value={formikProps.values.id}
                      isRequired={false}
                      hardLink={`/admin/edit-entry/credit-library/company/${formikProps.values.id}`}
                      linkText={`/admin/edit-entry/credit-library/company/${formikProps.values.id}`}
                    />

                    <EntryField
                      label={`${assetsConfig.labels.company.singular} Tags`}
                      value={formikProps.values.id}
                      isRequired={false}
                      hardLink={`/admin/edit-entry/tag-library/${formikProps.values.id}`}
                      linkText={`/admin/edit-entry/tag-library/${formikProps.values.id}`}
                    />
                  </EntryReviewContainer>
                </StickyCardBody>
              </CollapsibleContainer>

              <CollapsibleContainer
                className="mb-[2rem]"
                title={`${assetsConfig.labels.company.singular} Users`}
              >
                <Button
                  className="my-[1rem] ml-auto mr-[1rem]"
                  type="button"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Add User to {assetsConfig.labels.company.singular}
                </Button>
                <StickyCardBody className="!p-0">
                  <Table
                    isActive={companyUsers.length > 0}
                    dualScroll
                    columnLabels={[
                      "Profile",
                      "User Id",
                      "User Name",
                      "Job Title",
                      "Email",
                      "Phone",
                      "Enabled",
                    ]}
                  >
                    {companyUsers.map((companyUser: NemoUser, index) => {
                      const entry = companyUser;

                      return (
                        <Fragment key={entry.id}>
                          <div className="cell">
                            <img
                              src={
                                (companyUser.profileUrl &&
                                  companyUser.profileUrl!) ||
                                Placeholder
                              }
                              alt={`${assetsConfig.labels.entry.singular} Cover Image`}
                            />
                          </div>
                          <div className="cell">
                            <p>
                              <StyledLink
                                href={`/edit-user/${companyUser.id}`}
                                target="_blank"
                              >
                                {companyUser.id}
                              </StyledLink>
                            </p>
                          </div>
                          <div className="cell">
                            <p>
                              <StyledLink
                                href={`/edit-user/${companyUser.id}`}
                                target="_blank"
                              >
                                {companyUser.fullName}
                              </StyledLink>
                            </p>
                          </div>
                          <div className="cell">
                            <p>{companyUser.jobTitle}</p>
                          </div>
                          <div className="cell">
                            <p>{companyUser.email}</p>
                          </div>
                          <div className="cell">
                            <p>{companyUser.phone}</p>
                          </div>
                          <div className="cell">
                            <CheckboxField
                              checked={companyUser.isEnabled}
                              name="isEnabled"
                              placeholder=""
                              onChange={() => {
                                adminEnable(
                                  companyUser.id,
                                  !companyUser.isEnabled
                                );
                                setCompanyUsers((prev) => {
                                  return prev.map((user) =>
                                    user.id === companyUser.id
                                      ? { ...user, isEnabled: !user.isEnabled }
                                      : user
                                  );
                                });
                              }}
                            />
                          </div>
                        </Fragment>
                      );
                    })}
                  </Table>
                </StickyCardBody>
              </CollapsibleContainer>

              <CollapsibleContainer
                className="mb-[2rem]"
                title={`${assetsConfig.labels.company.singular} ${assetsConfig.labels.entry.plural}`}
              >
                <StickyCardBody className="!p-[1rem]">
                  <EntryGrid companyId={companyId} />
                </StickyCardBody>
              </CollapsibleContainer>

              <CollapsibleContainer
                className="mb-[2rem]"
                title="Payment Method Settings"
              >
                <StickyCardBody>
                  <CheckboxField
                    checked={formikProps.values.overridePaymentMethod}
                    name="overridePaymentMethod"
                    placeholder="Override Payment Method Restrictions"
                  />
                  <Button
                    className="mt-[1rem] ml-auto"
                    onClick={() => handleSubmit()}
                    disabled={formikProps.isSubmitting}
                  >
                    Update {assetsConfig.labels.company.singular}
                  </Button>
                </StickyCardBody>
              </CollapsibleContainer>
            </StickyContainer>
          </FormikProvider>
        </CardBody>
      </CardContainer>
    )) || (
      <>
        <Loading />
      </>
    )
  );
};

export default EditCompany;
