import { FieldArray, useFormikContext } from "formik";
import {
	CreditType,
	EntryIndividualCreditField,
	IndividualCreditJoin,
} from "../../Admin/Program/ProgramInterfaces";
import { IndividualCredit } from "../../Admin/Credits/CreditInterfaces";
import { FieldError } from "../../../components/FormFields/FieldTemplate";
import { useEffect, useState, useContext } from "react";
import { ValidateContext } from "../EntryForm";
import { EntryError } from "../DynamicFields";
import { CreditCheckContext } from "../EntryForm";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import { updateEntryCreditField } from "../manageEntry";

import { SuccessMessageWrapper } from "../../../components/FormFields/FieldWrapper";
import { AUTOSAVE_MESSAGE } from "../DynamicFields";
import { CSSTransition } from "react-transition-group";
import {
	FieldSuccess,
	SUCCESS_MESSAGE_DURATION,
} from "../../../components/FormFields/FieldTemplate";
import Icon from "../../../components/Icon/Icon";
import { useTheme } from "styled-components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import DraggableCredits from "../../../components/Draggable/DraggableCredits";
import { useAlert } from "../../../components/Alert/Alerts";

const EntryIndividualCreditFieldForm = (
	props: EntryIndividualCreditFieldProps
) => {
	const theme = useTheme();
	const { width } = useWindowDimensions();
	const isMobile = width <= Number(theme.lg.replaceAll("px", ""));

	const creditFieldIndex = props.index;
	const { values, setFieldValue } = useFormikContext<any>();
	const [droppedCredits, setDroppedCredits] = useState<IndividualCreditJoin[]>(
		[]
	);
	const { resetCreditCheck, setResetCreditCheck } =
		useContext(CreditCheckContext);
	const isReadOnly = props.readOnly && !props.field.programField.isUnlocked;
	const { addNewAlert } = useAlert();
	const validate = useContext(ValidateContext);
	const [success, setSuccess] = useState<string | undefined>();

	const displayNotApplicable =
		props.field.programField.displayNotApplicable ||
		props.field.programField.displayNotApplicable == null;

	const minCredits =
		props.field.programField.minCredits &&
		props.field.programField.minCredits !== 0
			? props.field.programField.minCredits
			: undefined;
	const maxCredits =
		props.field.programField.maxCredits &&
		props.field.programField.maxCredits !== 0
			? props.field.programField.maxCredits
			: undefined;

	const noDropppedCredits =
		!droppedCredits || (droppedCredits && droppedCredits.length === 0);
	const isMaxDroppped =
		!noDropppedCredits && maxCredits && droppedCredits.length === maxCredits;

	// check if max credits have already been dropped
	const isDropLimit = () => {
		if (isMaxDroppped) {
			addNewAlert({
				type: "error",
				message: `This field only accepts ${props.field.programField.maxCredits} credit(s). Please remove existing credits to add more.`,
			});

			return true;
		} else return false;
	};

	const error = () => {
		if (
			(minCredits && !props.field.notApplicableAttestation) ||
			!displayNotApplicable
		) {
			if (minCredits && props.field.credits.length < minCredits) {
				var errorMessage =
					"This field is required. Please add applicable credits";

				if (displayNotApplicable) {
					errorMessage = `${errorMessage} or check the "N/A" box`;
				}
				return `${errorMessage}.`;
			}
		}
		return undefined;
	};

	const asterisk = minCredits ? "*" : "";

	const updateCreditsForAdmin = (credits: IndividualCreditJoin[]) => {
		let newCreditJoin: any = {};
		if (credits.length > 0) {
			// add the weights of each credit (after they're removed/added)
			const creditsWithWeight = credits.map((credit, i) => ({
				...credit,
				weight: i,
			}));

			newCreditJoin = {
				...values["creditFields"][creditFieldIndex],
				credits: creditsWithWeight,
			};
		} else {
			newCreditJoin = {
				...values["creditFields"][creditFieldIndex],
				credits: [],
			};
		}

		return updateEntryCreditField(newCreditJoin)
			.then((resp) => {
				if (resp.status === 200) {
					setDroppedCredits([...resp.data.credits]);
					setSuccess(AUTOSAVE_MESSAGE);
				} else {
					addNewAlert({
						type: "error",
						message: "Failed to save.",
					});
				}
			})
			.catch((error: any) => {
				addNewAlert({
					type: "error",
					message: "Failed to save.",
				});
			});
	};

	useEffect(() => {
		props.field.credits &&
			props.field.credits.length > 0 &&
			setDroppedCredits(
				props.field.credits.sort((a, b) => a.weight! - b.weight!)
			);
	}, []);

	// update formik credits when a credit is dropped/swapped
	useEffect(() => {
		const creditFields = values["creditFields"];

		if (droppedCredits.length > 0) {
			// add the weights of each credit (after they're removed/added)
			const creditsWithWeight = droppedCredits.map((credit, i) => ({
				...credit,
				weight: i,
			}));

			// replace creditFields (formik value)
			creditFields.splice(props.index, 1, {
				...values["creditFields"][creditFieldIndex],
				credits: creditsWithWeight,
			});

			setFieldValue("creditFields", creditFields);
		} else {
			// delete credits from creditFields
			creditFields.splice(props.index, 1, {
				...values["creditFields"][creditFieldIndex],
				credits: [],
			});

			setFieldValue("creditFields", creditFields);
		}
	}, [droppedCredits]);

	// remove dropped credits if N/A is checked
	useEffect(() => {
		if (props.field.notApplicableAttestation) {
			setDroppedCredits([]);
		}
	}, [props.field.notApplicableAttestation]);

	// hide success message after short delay
	useEffect(() => {
		setTimeout(() => {
			setSuccess(undefined);
		}, SUCCESS_MESSAGE_DURATION);
	}, [success]);

	return (
		<div id={`wrapper-div-${props.name}`} className="col-w-100">
			<h3 className="subtitle font-semibold">
				{props.field.programField.title}
				{asterisk}
			</h3>
			<p className="whitespace-pre-wrap">
				{props.field.programField.entrantHelpText}
			</p>
			{minCredits || maxCredits ? (
				<p className="italic">
					{minCredits && `Minimum: ${minCredits}`}
					{minCredits && maxCredits && " || "}
					{maxCredits && `Maximum: ${maxCredits}`}
				</p>
			) : (
				<></>
			)}
			<FieldArray name={`${props.name}.credits`}>
				{({ insert, remove, push }) => (
					<div>
						<SuccessMessageWrapper>
							{(success, setSuccess) => (
								<DraggableCredits
									name={props.name}
									indCreditArr={droppedCredits.map(
										(creditJoin) => creditJoin.credit
									)}
									creditType={CreditType.Individual}
									allowedCreditClassification={
										props.field.programField.requiredCreditClassification
									}
									min={props.field.programField.minCredits}
									max={props.field.programField.maxCredits}
									onAdd={(newCredits) => {
										const creditJoins = (newCredits as IndividualCredit[]).map(
											(credit, i) => {
												return {
													fieldId: props.field.id,
													id: 0,
													creditId: credit.id!,
													credit: credit,
												};
											}
										);

										creditJoins.forEach((credit, i) => {
											push(credit);
										});

										setResetCreditCheck(true);

										return updateCreditsForAdmin([
											...droppedCredits,
											...creditJoins,
										]).then(() => setSuccess(AUTOSAVE_MESSAGE));
									}}
									onRemove={(creditId) => {
										let index = -1;

										const filtered = droppedCredits.filter(
											(droppedCreditObj, i) => {
												const creditToRemove =
													creditId === droppedCreditObj.creditId;

												if (creditToRemove) index = i;
												return !creditToRemove;
											}
										);

										if (index !== -1) {
											return updateCreditsForAdmin(filtered).then(() => {
												remove(index);
												setSuccess(AUTOSAVE_MESSAGE);
											});
										} else return Promise.reject();
									}}
									onReorder={(reorderedCredits) => {
										const creditJoins = (
											reorderedCredits as IndividualCredit[]
										).map((credit, i) => {
											return {
												fieldId: props.field.id,
												id: 0,
												creditId: credit.id!,
												credit: credit,
											};
										});

										return updateCreditsForAdmin(creditJoins).then(() =>
											setSuccess(AUTOSAVE_MESSAGE)
										);
									}}
									disabled={isReadOnly || props.field.notApplicableAttestation}
									success={success}
									error={error()}
									hideErrMessage
									placeholder={
										isMobile
											? "No Credits Selected"
											: "Drag & Drop Credit From Credit Library on the Right"
									}
									dynamicHeight
								/>
							)}
						</SuccessMessageWrapper>

						<div className="relative mt-[.5rem]">
							{minCredits && displayNotApplicable && (
								<CheckboxField
									name={`${props.name}.notApplicableAttestation`}
									placeholder="N/A"
									checked={props.field.notApplicableAttestation}
									disabled={isReadOnly}
									onChange={(isApplicable) => {
										updateEntryCreditField({
											...props.field,
											notApplicableAttestation: isApplicable,
											...(isApplicable && { credits: [] }), // clear credits if n/a is checked
										})
											.then((resp) => {
												if (resp.status === 200) {
													setSuccess(AUTOSAVE_MESSAGE);
												} else {
													addNewAlert({
														type: "error",
														message: "Failed to save.",
													});
												}
											})
											.catch((error: any) => {
												addNewAlert({
													type: "error",
													message: "Failed to save.",
												});
											});
									}}
								/>
							)}
							{error() && success === undefined && (
								<p className="text-colorDanger mt-[.5rem] absolute">
									{error()}
								</p>
							)}

							<CSSTransition
								in={success !== undefined}
								timeout={SUCCESS_MESSAGE_DURATION}
								unmountOnExit
								classNames="success-message"
							>
								<FieldSuccess>
									<Icon
										icon="check"
										color={theme.colorSuccess}
										width="1rem"
										height="1rem"
									/>
									{success && <span>{success}</span>}
								</FieldSuccess>
							</CSSTransition>
						</div>
					</div>
				)}
			</FieldArray>
		</div>
	);
};

export default EntryIndividualCreditFieldForm;

interface EntryIndividualCreditFieldProps {
	field: EntryIndividualCreditField;
	name: string;
	index: number;
	readOnly?: boolean;
	isAdmin?: boolean;
}
