import { useTheme, css } from "styled-components";
import React, {
	useState,
	useEffect,
	useContext,
	useRef,
	useCallback,
} from "react";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import Button from "../../../../components/Button/Button";
import { IndividualCredit } from "../CreditInterfaces";
import { CompanyCredit } from "../CreditInterfaces";
import styled from "styled-components";
import { CreditType } from "../../Program/ProgramInterfaces";
import {
	CreditCheckContext,
	ToggleMobileCreditLibContext,
} from "../../../Entries/EntryForm";
import DropdownField from "../../../../components/FormFields/DropdownField";
import TagFilter from "../../Media/TagFilter";
import { filterByTags } from "../../../MediaLibrary/MediaSort";
import { Tag } from "../../../MediaLibrary/mediaLibrary.model";
import { creditSortOptions } from "./SortCreditLibrary";
import {
	CreditFilter,
	FilterCondition,
	filterCredits,
} from "./FilterCreditLibrary";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { CSSTransition } from "react-transition-group";
import { MobileLibrary } from "../../Media/NewMediaLibrarySticky";
import StickyCard, {
	StickyCardHeader,
} from "../../../../components/StickyCard/StickyCard";
import {
	NavTab,
	NavTabList,
	NavTabPanel,
	NavTabs,
} from "../../../../components/NavTabs/NavTabs";
import AddCreditModal from "../AddCreditModal";
import { useParams } from "react-router-dom";
import { getClaims } from "../../../../components/Auth/handleJWT";
import Icon from "../../../../components/Icon/Icon";

import { DraggableList } from "../../Media/NewMediaLibrarySticky";
import useListHeight from "../../../../hooks/useListHeight";
import DraggableCredits, {
	DragCredit,
	DragCreditType,
	companyCreditCardHeight,
	indCreditCardHeight,
} from "../../../../components/Draggable/DraggableCredits";
import VirtualizeList from "../../../../components/VirtualizeList/VirtualizeList";
import { useAlert } from "../../../../components/Alert/Alerts";

const CreditTabPanel = styled(NavTabPanel)<{
	isMobile: boolean;
}>`
	padding: 0 !important;
	max-height: calc(100vh - 95px);

	${(p) =>
		p.isMobile === false &&
		css`
			max-height: calc(100vh - 95px);

			@media only screen and (min-height: 350px) {
				max-height: calc(100vh - 125px);
			}

			@media only screen and (min-height: 400px) {
				// remove page padding bottom
				max-height: calc(100vh - 150px);
			}

			@media only screen and (min-height: 450px) {
				// remove page padding bottom
				max-height: calc(100vh - 225px);
			}

			@media only screen and (min-height: 500px) {
				// remove page padding bottom
				max-height: calc(100vh - 250px);
			}

			@media only screen and (min-height: 550px) {
				// remove offset top
				max-height: calc(100vh - 95px - 147.43px - 2.5rem);
			}

			@media only screen and (min-height: 600px) {
				// 100vh, top offset, top offset, header, search bar & tab, page padding bottom
				max-height: calc(100vh - 2.75rem - 55.391px - 95px - 147.43px - 2.5rem);
			}
		`}
`;

const CreditList = (props: CreditListProps) => {
	const { usedHeight, listHeight } = useListHeight();
	const { resetCreditCheck, setResetCreditCheck } =
		useContext(CreditCheckContext);
	const { creditSelect, setCreditSelect } = useContext(
		ToggleMobileCreditLibContext
	);
	const { addNewAlert } = useAlert();

	const isCompanyCredit = props.creditType === CreditType.Company;
	const [filteredCredits, setFilteredCredits] = useState<
		(CompanyCredit | IndividualCredit)[]
	>(props.creditArr);
	const searchVal = props.creditSearchVal;

	const [filter, setFilter] = useState<CreditFilter>({
		jobTitle: searchVal,
		companyType: searchVal,
		dateAdded: null,
		tags: searchVal ? [searchVal] : [],
		creditName: searchVal,
		onlyShowActive: false,
		dateSort: null,
	});

	if (props.creditType === CreditType.Company) {
		props.creditArr.forEach((credit) => {
			(credit as CompanyCredit).companyType =
				(credit as CompanyCredit).companyType === "Other"
					? (credit as CompanyCredit).otherCompanyType!
					: (credit as CompanyCredit).companyType!;
		});
	} else {
		props.creditArr.forEach((credit) => {
			(credit as IndividualCredit).jobTitle =
				(credit as IndividualCredit).jobTitle === "Other"
					? (credit as IndividualCredit).otherJobTitle!
					: (credit as IndividualCredit).jobTitle!;
		});
	}

	const resetCheckedCredits = () => {
		setFilteredCredits(
			filteredCredits.map((credit) => {
				return {
					...credit,
					isCheckedForDrag: false,
				};
			})
		);
	};

	const getCheckedCredits = () => {
		const checkedCredits = filteredCredits.filter(
			(creditObj: CompanyCredit | IndividualCredit) => {
				return creditObj.isCheckedForDrag;
			}
		);

		return checkedCredits;
	};

	const checkedCredits = getCheckedCredits();

	const handlecreditSelect = useCallback(
		(creditSelect) => {
			if (creditSelect !== null) {
				creditSelect(checkedCredits)
					.then((res: any) => {
						addNewAlert({
							type: "success",
							message: res,
						});
						setCreditSelect(null);
					})
					.catch((err: any) =>
						addNewAlert({
							type: "error",
							message: err,
						})
					);
			}
		},
		[creditSelect, checkedCredits]
	);

	useEffect(() => {
		let creditsCopy = filterCredits(
			props.creditArr,
			filter,
			isCompanyCredit,
			FilterCondition.Or
		);
		setFilteredCredits(creditsCopy);
	}, [filter]);

	useEffect(() => {
		setFilteredCredits(props.creditArr);
	}, [props.creditArr]);

	useEffect(() => {
		if (resetCreditCheck) {
			resetCheckedCredits();
			setResetCreditCheck(false);
		}
	}, [resetCreditCheck]);

	useEffect(() => {
		setFilter({
			jobTitle: searchVal,
			companyType: searchVal,
			dateAdded: null,
			tags: searchVal ? [searchVal] : [],
			creditName: searchVal,
			onlyShowActive: false,
			dateSort: null,
		});
	}, [props.creditSearchVal]);

	// on credit select
	useEffect(() => {
		props.setCheckedCredits(checkedCredits.length);
	}, [checkedCredits]);

	// on confirm credit select
	useEffect(() => {
		if (props.confirmCounter > 0) {
			props.onConfirm();
			handlecreditSelect(creditSelect);
		}
	}, [props.confirmCounter]);

	return (
		<div className="p-[2rem] overflow-hidden flex-1">
			<div ref={usedHeight}>
				<DropdownField
					className="mb-[1rem]"
					name="dateSort"
					placeholder="Sort By"
					options={creditSortOptions}
					onChange={(e) =>
						setFilter({
							...filter,
							dateSort: e.target.value === "" ? null : Number(e.target.value),
						})
					}
					value={filter.dateSort}
				/>
				<p className="w-fit ml-auto mb-[0.625rem]">Multiselect</p>
			</div>

			<DraggableList height={listHeight}>
				{props.isMobile ? (
					<>
						{(filteredCredits as DragCreditType[]).map((item, index) => (
							<DragCredit
								key={item.id}
								credit={item}
								creditType={props.creditType}
								onClick={() =>
									setFilteredCredits(
										filteredCredits.map((filteredCredit, filterIndex) => {
											return index === filterIndex
												? {
														...item,
														isCheckedForDrag: !item.isCheckedForDrag,
												  }
												: filteredCredit;
										})
									)
								}
								checked={item.isCheckedForDrag}
								allowMultiSelect
								isMobile
							/>
						))}
					</>
				) : (
					<DraggableCredits
						{...(props.creditType === CreditType.Company
							? {
									companyCreditArr: filteredCredits as CompanyCredit[],
							  }
							: {
									indCreditArr: filteredCredits as IndividualCredit[],
							  })}
						creditType={props.creditType}
						name="credit-library"
						clone
						sortable={false}
						drop={false}
						allowMultiSelect={props.allowMultiselect}
						hideShadow
					/>
				)}
			</DraggableList>
			{/* <VirtualizeList
				list={filteredCredits as DragCreditType[]}
				height={listHeight}
				itemHeight={
					props.creditType === CreditType.Company
						? companyCreditCardHeight
						: indCreditCardHeight
				}
			>
				{(virtualList) =>
					props.isMobile ? (
						<>
							{virtualList.map((item, index) => (
								<DragCredit
									key={item.id}
									credit={item}
									creditType={props.creditType}
									onClick={() =>
										setFilteredCredits(
											filteredCredits.map((filteredCredit, filterIndex) => {
												return index === filterIndex
													? {
															...item,
															isCheckedForDrag: !item.isCheckedForDrag,
													  }
													: filteredCredit;
											})
										)
									}
									checked={item.isCheckedForDrag}
									allowMultiSelect
									isMobile
								/>
							))}
						</>
					) : (
						<DraggableCredits
							{...(props.creditType === CreditType.Company
								? {
										companyCreditArr: virtualList as CompanyCredit[],
								  }
								: {
										indCreditArr: virtualList as IndividualCredit[],
								  })}
							creditType={props.creditType}
							name="credit-library"
							clone
							sortable={false}
							drop={false}
							allowMultiSelect={props.allowMultiselect}
						/>
					)
				}
			</VirtualizeList> */}
		</div>
	);
};

const NewCreditLibrarySticky = (props: CreditLibraryStickyProps) => {
	const theme = useTheme();
	const claims = getClaims();
	const isAdmin = claims.some(
		(claim) => claim.name === "role" && claim.value === "admin"
	);
	const { companyId } = useParams<{
		companyId?: string;
	}>();
	const parsedCompanyId = companyId
		? parseInt(companyId)
		: props.companyId
		? props.companyId
		: Number(claims.find((claim) => claim.name === "company")?.value || "0");

	const { creditSelect, setCreditSelect } = useContext(
		ToggleMobileCreditLibContext
	);
	const { setResetCreditCheck } = useContext(CreditCheckContext);
	const nodeRef = useRef(null);
	const [creditTabIndex, setCreditTabIndex] = useState(0);
	const [showModal, setShowModal] = useState<false | CreditType>(false);
	const [searchVal, setSearchVal] = useState("");

	const { width, height } = useWindowDimensions();
	const isMobile = width <= Number(theme.lg.replaceAll("px", "")); // replaces drag/drop with multiselect

	const [confirmCounter, setConfirmCounter] = useState(0);
	const [checkedCredits, setCheckedCredits] = useState(0);

	const creditLibrary = (
		<>
			{parsedCompanyId &&
				props.setCompanyCreditsArray &&
				props.setIndividualCreditsArray && (
					<AddCreditModal
						companyId={parsedCompanyId}
						show={showModal !== false}
						onClickHide={() => setShowModal(false)}
						setCompanyCreditsArray={props.setCompanyCreditsArray}
						setIndividualCreditsArray={props.setIndividualCreditsArray}
						defaultCreditTab={showModal || 0}
					/>
				)}

			<StickyCard
				className={`entryPreviewMd ${props.className ? props.className : ""} ${
					isMobile ? "!max-h-full" : ""
				}`}
				iconColor={theme.colorCopyLightLight}
				{...(isAdmin && {
					headerIcons: (
						<Button
							className="w-[290px] button-gold !text-colorCopyLightLight"
							onClick={() =>
								window.open(
									`/admin/edit-entry/credit-library/${
										creditTabIndex === 0 ? "company" : "individual"
									}/${props.companyId}`
								)
							}
							icon="credit-db"
							iconColor={theme.colorPrimary}
							iconSize="18px"
						>
							{creditTabIndex === 0 ? "Company" : "Individual"} Credit Library
						</Button>
					),
				})}
			>
				<StickyCardHeader className="!p-[1.5rem_2rem]">
					{"Credit Library"}

					<div className="flex gap-[1rem] flex-wrap sm:flex-nowrap">
						{isAdmin && props.companyId && (
							<Button
								className="button-gold !text-colorCopyLightLight !p-[1rem_1.5rem]"
								icon="credit-db"
								iconColor={theme.colorPrimary}
								onClick={() =>
									window.open(
										`/admin/edit-entry/credit-library/${
											creditTabIndex === 0 ? "company" : "individual"
										}/${props.companyId}`
									)
								}
								iconSize="18px"
							>
								{creditTabIndex === 0 ? "Company" : "Individual"} Credits
							</Button>
						)}

						<Button
							className="w-[175px] ml-auto"
							icon="plus"
							iconColor={theme.colorCopyLightLight}
							onClick={() =>
								setShowModal(
									creditTabIndex === 0
										? CreditType.Company
										: CreditType.Individual
								)
							}
						>
							Add Credit
						</Button>
					</div>

					{isMobile && (
						<Icon
							icon="close"
							color={theme.colorPrimary}
							width="30px"
							height="30px"
							onClick={() => {
								setCreditSelect(null);
								setResetCreditCheck(true);
							}}
						/>
					)}
				</StickyCardHeader>

				<NavTabs
					className="credit-db"
					selectedIndex={creditTabIndex}
					onSelect={(index) => setCreditTabIndex(index)}
				>
					<NavTabList>
						<div className="bg-transparent mb-[1rem]">
							{/* <SearchBar className="w-full" hasFilter dropdownField="filterMedia" dropdownLabel="File Type" dropdownOptions={["png", "jpg", "mp4"]} /> */}
							<SearchBar
								className="w-full"
								label="Search Credits"
								onKeyUp={(e) => setSearchVal(e.currentTarget.value)}
							/>
						</div>
						<NavTab>Company Credits</NavTab>
						<NavTab>Individual Credits</NavTab>
					</NavTabList>
					<CreditTabPanel isMobile={isMobile}>
						<CreditList
							creditType={CreditType.Company}
							creditArr={props.companyCreditArr}
							allowMultiselect={props.allowMultiselect}
							creditSearchVal={searchVal}
							isMobile={isMobile}
							confirmCounter={confirmCounter}
							onConfirm={() => setConfirmCounter(0)}
							setCheckedCredits={setCheckedCredits}
						/>
					</CreditTabPanel>
					<CreditTabPanel isMobile={isMobile}>
						<CreditList
							creditType={CreditType.Individual}
							creditArr={props.indCreditArr}
							allowMultiselect={props.allowMultiselect}
							creditSearchVal={searchVal}
							isMobile={isMobile}
							confirmCounter={confirmCounter}
							onConfirm={() => setConfirmCounter(0)}
							setCheckedCredits={setCheckedCredits}
						/>
					</CreditTabPanel>
				</NavTabs>
				{isMobile && (
					<Button
						className="w-full mt-[1rem]"
						icon="check"
						onClick={() => setConfirmCounter((prev) => prev + 1)}
						disabled={checkedCredits === 0}
					>
						Ok
					</Button>
				)}
			</StickyCard>
		</>
	);

	return isMobile ? (
		<CSSTransition
			classNames="mobile-library"
			in={creditSelect !== null}
			timeout={150}
			nodeRef={nodeRef}
			unmountOnExit
		>
			<MobileLibrary ref={nodeRef}>{creditLibrary}</MobileLibrary>
		</CSSTransition>
	) : (
		creditLibrary
	);
};

export default NewCreditLibrarySticky;

interface CreditLibraryStickyProps {
	className?: string;
	companyId?: number;
	// creditArr: CompanyCredit[] | IndividualCredit[];
	companyCreditArr: CompanyCredit[];
	indCreditArr: IndividualCredit[];
	setCompanyCreditsArray?: React.Dispatch<
		React.SetStateAction<CompanyCredit[]>
	>;
	setIndividualCreditsArray?: React.Dispatch<
		React.SetStateAction<IndividualCredit[]>
	>;
	allowMultiselect?: boolean;
}

interface CreditListProps {
	creditType: CreditType;
	creditArr: CompanyCredit[] | IndividualCredit[];
	allowMultiselect?: boolean;
	creditSearchVal: string;
	isMobile?: boolean;
	confirmCounter: number;
	onConfirm(): void;
	setCheckedCredits(credits: number): void;
}
