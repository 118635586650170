import styled, { useTheme } from "styled-components";
import EntrantEntrySearchCard, {
  EntrantEntrySearchResult,
} from "../../../components/EntrantEntrySearchCard/EntrantEntrySearchCard";
import { useState, useEffect } from "react";
import {
  entrantEntrySearch,
  entrantEntryExport,
} from "../../Entries/manageEntry";
import Loading from "../../../components/Loading/Loading";
import DropdownField, {
  DropdownOptions,
} from "../../../components/FormFields/DropdownField";
import SearchBar from "../../../components/SearchBar/SearchBar";
import TextField from "../../../components/FormFields/TextField";
import { FormikProvider, useFormik } from "formik";
import {
  EntrySearchSort,
  entryStatus,
  sortOrderOptions,
} from "./PrevEntriesSortFilter";
import { getRootProgramOptions } from "../../Admin/Program/manageProgram";
import Button from "../../../components/Button/Button";
import config from "../../../config";
import HierarchyDropDownModal from "../../../components/HierarchyDropDownModal/HierarchyDropDownModal";
import { EntryAction } from "../../../components/HierarchyDropDownModal/HierarchyDropDown";
import assetsConfig from "../../../assetsConfig";
import lowerCase from "../../../utils/lowerCase";
import { useSettings } from "../../../hooks/UseSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getLocalTime } from "../../../utils/timeUtilities";
import Authorized from "../../../components/Auth/Authorized";

export const LoaderPlaceholder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: ${({ theme }) => theme.colorBackgroundLight};
`;

const SortContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 2rem;

  @media only screen and (min-width: ${({ theme }) => theme.sm}) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  & > * {
    flex: 1;
  }
`;

const PrevEntriesTab = () => {
  const theme = useTheme();
  const { settings, loading } = useSelector(
    (state: RootState) => state.settings
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entries, setEntries] = useState<EntrantEntrySearchResult[]>([]);
  const [programNameList, setProgramNameList] = useState<DropdownOptions[]>([]);
  const [csvLoading, setCsvLoading] = useState(false);

  const initialSearch = {
    program: undefined,
    entryReviewStatus: undefined,
    entryTitle: undefined,
    entryBrand: undefined,
    entryId: undefined,
    orderId: null,
    sortByCreatedDate: null,
    sortByUpdatedDate: null,
  };

  const formikProps = useFormik({
    // filter and sort values
    initialValues: initialSearch,
    enableReinitialize: true,
    onSubmit: async () => {
      await handleSearch();
    },
  });

  const getScrubbedRequest = (): EntrantEntrySearchRequest => {
    let result = {
      id: formikProps.values.entryId
        ? Number(formikProps.values.entryId)
        : undefined,
      order: formikProps.values.orderId
        ? formikProps.values.orderId
        : undefined,
      title: formikProps.values.entryTitle
        ? formikProps.values.entryTitle
        : undefined,
      brand: formikProps.values.entryBrand
        ? formikProps.values.entryBrand
        : undefined,
      reviewStatus:
        formikProps.values.entryReviewStatus !== null &&
        formikProps.values.entryReviewStatus !== undefined
          ? [formikProps.values.entryReviewStatus]
          : undefined,
      rootPrograms: formikProps.values.program
        ? [formikProps.values.program]
        : undefined,
    };

    return result;
  };

  const sortField =
    formikProps.values.sortByCreatedDate === 0 ||
    formikProps.values.sortByCreatedDate === 1
      ? formikProps.values.sortByCreatedDate
      : formikProps.values.sortByUpdatedDate === 0 ||
        formikProps.values.sortByUpdatedDate === 1
      ? formikProps.values.sortByUpdatedDate
      : null;

  const sortByReviewStatus = (
    sortField: number | null,
    res: EntrantEntrySearchResult[]
  ) => {
    // sort by review status then updated date on initial render
    if (sortField !== 0 && sortField !== 1) {
      return res.sort((a, b) => {
        // First, compare by reviewStatusId
        if (a.reviewStatusId !== b.reviewStatusId) {
          return b.reviewStatusId - a.reviewStatusId;
        }

        // If reviewStatusId is the same, then sort by date
        return (
          new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime()
        );
      });
    } else return res;
  };

  async function handleSearch() {
    var newRequest = getScrubbedRequest();
    setIsLoading(true);

    await entrantEntrySearch(newRequest, sortField)
      .then((response) => {
        if (response.status == 200) {
          setEntries(sortByReviewStatus(sortField, response.data));
          setIsLoading(false);
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        return error;
      });
  }

  async function handleExport() {
    var newRequest = getScrubbedRequest();

    setCsvLoading(true);

    await entrantEntryExport(newRequest, sortField)
      .then((exportResponse) => {
        if (exportResponse.status == 200) {
          const temp = window.URL.createObjectURL(
            new Blob([exportResponse.data])
          );
          const link = document.createElement("a");

          link.href = temp;
          link.setAttribute(
            "download",
            `Entry Search Export ${getLocalTime(new Date())}.csv`
          );
          document.body.appendChild(link);
          link.click();

          setCsvLoading(false);
        }
      })
      .catch((error: any) => {
        setCsvLoading(false);
        return error;
      });
  }

  const [entryIdToDuplicate, setEntryIdToDuplicate] = useState<null | number>(
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRootProgramOptions(true);
        if (res && res.data) {
          setProgramNameList(res.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call fetchData when the component mounts
    fetchData();

    // do a search on page load
    handleSearch();
  }, []);

  return (
    <div>
      <HierarchyDropDownModal
        entryId={entryIdToDuplicate}
        setEntryId={setEntryIdToDuplicate}
        action={EntryAction.Duplicate}
      />
      <h2>{assetsConfig.labels.entry.singular} Search</h2>
      <p className="mb-[3rem] mt-[1rem]">
        Your {lowerCase(assetsConfig.labels.entry.plural)} from current and
        previous seasons appear here.
      </p>
      <div className="relative">
        <FormikProvider value={formikProps}>
          <SortContainer>
            <DropdownField
              name="sortByCreatedDate"
              options={sortOrderOptions}
              placeholder="Date Created"
              value={formikProps.values.sortByCreatedDate}
              onChange={(e) => {
                if (e.target.value !== "") {
                  formikProps.setFieldValue("sortByUpdatedDate", "");
                }
              }}
            />
            {!settings.isDemo && (
              <DropdownField
                name="program"
                options={
                  programNameList.length > 0
                    ? programNameList
                    : [{ value: "Loading Programs..." }]
                }
                placeholder="Program"
                readOnly={programNameList.length === 0}
              />
            )}

            <DropdownField
              name="entryReviewStatus"
              options={entryStatus}
              placeholder={
                assetsConfig.labels.entry.singular + " Review Status"
              }
            />
            <TextField
              name="entryTitle"
              placeholder={assetsConfig.labels.entry.singular + " Title"}
            />

            <TextField
              name="entryBrand"
              placeholder={assetsConfig.labels.entry.singular + " Brand"}
            />

            <div className="flex gap-[1rem] flex-wrap">
              <TextField
                className="flex-1 min-w-[100px]"
                name="entryId"
                placeholder={assetsConfig.labels.entry.singular + " ID #"}
              />
              <Authorized
                settings={settings}
                loading={loading}
                feature="Commerce"
                authorized={
                  <TextField
                    className="flex-1 min-w-[100px]"
                    name="orderId"
                    placeholder="Order ID #"
                  />
                }
              />
            </div>

            {settings.isDemo && (
              <DropdownField
                name="sortByUpdatedDate"
                options={sortOrderOptions}
                placeholder="Date Updated"
                value={formikProps.values.sortByUpdatedDate}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    formikProps.setFieldValue("sortByCreatedDate", "");
                  }
                }}
              />
            )}
          </SortContainer>
          <div className="flex my-[1rem] gap-[1rem] justify-end flex-wrap">
            <Button
              className="button-light w-[150px]"
              type="button"
              onClick={() => {
                formikProps.setValues(initialSearch);
                setEntries([]);
              }}
            >
              Reset Search
            </Button>
            <Button
              className="button-light w-[150px]"
              disabled={entries.length < 1 || csvLoading}
              onClick={() => handleExport()}
            >
              <div className="flex items-center gap-[.5rem]">
                Export
                {csvLoading && (
                  <img
                    className="w-[16px]"
                    src={config.assets.loading.primary}
                  />
                )}
              </div>
            </Button>
            <Button
              className="w-[150px]"
              disabled={isLoading}
              type="submit"
              onClick={() => {
                formikProps.submitForm();
              }}
            >
              Search
            </Button>
          </div>
        </FormikProvider>

        {isLoading ? (
          <LoaderPlaceholder>
            <Loading
              text={"Loading " + assetsConfig.labels.entry.plural}
              fullScreen={false}
              showLogo={false}
            />
          </LoaderPlaceholder>
        ) : (
          <div className="flex flex-col gap-[1rem]">
            {entries &&
              entries.map((entry) => (
                <EntrantEntrySearchCard
                  key={entry.id}
                  entry={entry}
                  setEntryIdToDuplicate={setEntryIdToDuplicate}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export interface EntrantEntrySearchRequest {
  id?: number;
  order?: string;
  brand?: string;
  title?: string;
  reviewStatus?: number[];
  rootPrograms?: number[];
}

export default PrevEntriesTab;
