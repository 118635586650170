import { useEffect, useState, useContext } from "react";
import TextField from "../../../components/FormFields/TextField";
import {
	EntryTextField,
	TextType,
} from "../../Admin/Program/ProgramInterfaces";
import { fieldRequired } from "../../../components/FieldModal/FieldModal";
import { ValidateContext } from "../EntryForm";
import {
	EntryError,
	DynamicEntryField,
	AUTOSAVE_MESSAGE,
} from "../DynamicFields";
import { updateEntryField } from "../manageEntry";
import { SUCCESS_MESSAGE_DURATION } from "../../../components/FormFields/FieldTemplate";
import { SuccessMessageWrapper } from "../../../components/FormFields/FieldWrapper";
import RichTextField from "../../../components/FormFields/RichTextField";
import { useFormikContext } from "formik";

const EntryTextFieldForm = (props: EntryTextFieldProps) => {
	const validate = useContext(ValidateContext);
	const error =
		validate && !props.field.text && props.field.programField.isRequired
			? fieldRequired
			: undefined;

	const asterisk = props.field.programField.isRequired ? "*" : "";

	return (
		<div className="col-w-100" id={`Wrapper-div-${props.name}`}>
			{!props.isAdmin && (
				<>
					<h3 className="subtitle font-semibold">
						{props.field.programField.title}
						{asterisk}
					</h3>
					<p className="whitespace-pre-wrap">
						{props.field.programField.entrantHelpText}
					</p>
				</>
			)}

			<SuccessMessageWrapper>
				{(success, setSuccess) =>
					props.field.programField.textType === TextType.Paragraph &&
					props.field.programField.formatText ? (
						<RichTextField
							name={`${props.name}.text`}
							success={success}
							error={error}
							readOnly={props.readOnly}
							onBlur={(html) =>
								props.saveOnBlur &&
								updateEntryField({ ...props.field, text: html }).then(() =>
									setSuccess(AUTOSAVE_MESSAGE)
								)
							}
							maxLength={props.field.programField.maxLength}
							height="200px"
							value={props.field.text}
						/>
					) : (
						<TextField
							name={`${props.name}.text`}
							component={
								props.field.programField.textType === TextType.Paragraph
									? "textarea"
									: undefined
							}
							value={props.field.text}
							hiddenlabel
							success={success}
							error={error}
							readOnly={props.readOnly}
							onBlur={() =>
								props.saveOnBlur &&
								updateEntryField(props.field).then(() =>
									setSuccess(AUTOSAVE_MESSAGE)
								)
							}
							maxLength={props.field.programField.maxLength}
						/>
					)
				}
			</SuccessMessageWrapper>
		</div>
	);
};

export default EntryTextFieldForm;

interface EntryTextFieldProps extends DynamicEntryField {
	field: EntryTextField;
	name: string;
	saveOnBlur?: boolean;
}
