import styled, { useTheme, css } from "styled-components";
import { Fragment, useEffect, useState } from "react";
import TextField from "../../../components/FormFields/TextField";
import { useFormik, FormikProvider } from "formik";
import Button from "../../../components/Button/Button";
import Table, { TablePlaceholder } from "../../../components/Table/Table";
import {
  PaymentStatus,
  PaymentStatusOptions,
  TransactionType,
  TransactionTypeOptions,
} from "../../Checkout/OrderInterfaces";
import axios from "axios";
import { urlManageCompanies } from "../../../endpoints";
import Pagination from "../../../utils/Pagination";
import { AdminPageControls, SearchFieldsContainer } from "./AdminSearch";
import StyledForm from "../../../components/Form/Form";
import { CompanyTypeOptions } from "../Program/ProgramInterfaces";
import { CountryOptions } from "../../../data/CountryData";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import { CSVLink } from "react-csv";
import {
  getStoredValue,
  setStoredValue,
} from "../../../components/LocalStorageStorage/LocalStorageStore";
import MultiDropdown from "../../../components/MultiDropdown/MultiDropdown";
import Loading from "../../../components/Loading/Loading";
import { SortDirection } from "../../../components/Table/Table";
import config from "../../../config";
import assetsConfig from "../../../assetsConfig";
import { getLocalTime } from "../../../utils/timeUtilities";
import Authorized from "../../../components/Auth/Authorized";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { NewCompanyModal } from "../../../components/NewCompanyModal/NewCompanyModal";

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colorActivation};
`;

const CsvExport = styled(CSVLink)<{ disabled?: boolean }>`
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
    `};
`;

const initialSearch = {
  id: undefined,
  name: undefined,
  companyType: undefined,
  city: undefined,
  country: undefined,
  userFullName: undefined,
  userEmail: undefined,
  overridePaymentMethod: false,
  hasActiveAdjustment: false,
};

const getScrubbedRequest = (
  request: CompanySearchRequest
): CompanySearchRequest => {
  return {
    id: request.id ? Number(request.id) : undefined,
    name: request.name ? request.name : undefined,
    companyType: request.companyType ? request.companyType : undefined,
    city: request.city ? request.city : undefined,
    country: request.country ? request.country : undefined,
    userFullName: request.userFullName ? request.userFullName : undefined,
    userEmail: request.userEmail ? request.userEmail : undefined,
    overridePaymentMethod: request.overridePaymentMethod
      ? request.overridePaymentMethod
      : undefined,
    hasActiveAdjustment: request.hasActiveAdjustment
      ? request.hasActiveAdjustment
      : undefined,
  };
};

const CompanySearchTab = () => {
  const theme = useTheme();
  const [searchVal, setSearchVal] = useState<CompanySearchRequest>(
    getStoredValue("companySearch") || initialSearch
  );
  const { settings, loading } = useSelector(
    (state: RootState) => state.settings
  );
  const [searchResult, setSearchResult] = useState<CompanySearchResult[]>([]);
  const [isActive, setActive] = useState(false);
  const [page, setPage] = useState(1);
  const [totalAmountOfPages, settotalAmountOfPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const [csvCompanies, setCsvCompanies] = useState<any[]>([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSort, setActiveSort] = useState<string | null>(null);
  const [activeSortDirection, setActiveSortDirection] = useState<SortDirection>(
    SortDirection.Asc
  );

  const [showModal, setShowModal] = useState(false);

  async function PostSearch(request: CompanySearchRequest) {
    try {
      const newRequest = getScrubbedRequest(request);
      setErrors([]);
      setActive(false);
      const response = await axios.post(
        `${urlManageCompanies}/search`,
        newRequest,
        {
          params: { page, recordsPerPage },
        }
      );
      // console.log("Results: ", response);

      if (response.status === 200) {
        setSearchResult(response.data);
        const total = parseInt(response.headers["totalamountofrecords"]);
        setTotalRecords(total);
        settotalAmountOfPages(Math.ceil(total / recordsPerPage));
        setActive(true);
        setIsLoading(false);
      }

      setCsvLoading(true);

      const getCompaniesCSV = await axios.post(
        `${urlManageCompanies}/search/export`,
        newRequest
      );

      if (getCompaniesCSV.status === 200) {
        setCsvLoading(false); // enable export button after csv entries are retrieved
        setCsvCompanies(getCompaniesCSV.data);
      }
    } catch (error: any) {
      // console.log(error);
      //setErrors(error.response.data);
      setIsLoading(false);
    }
  }

  const fieldRequired = "This field is required";
  const formikProps = useFormik({
    initialValues: searchVal,
    onSubmit: async (value) => {
      setStoredValue("companySearch", getScrubbedRequest(value));
      setPage(1);
      await PostSearch(value);
    },
  });

  const searchCompanies = async (
    request: CompanySearchRequest,
    activeSort?: number,
    sortDirection?: SortDirection
  ) => {
    const newRequest = getScrubbedRequest(request);
    const sortField =
      activeSort !== undefined && activeSort !== null ? activeSort : undefined;

    const response = await axios.post(
      `${urlManageCompanies}/search`,
      newRequest,
      {
        params: { page, recordsPerPage, sortField, sortDirection },
      }
    );

    if (response.status === 200) {
      setSearchResult([]); // This cleans the results and forces a React re-render in cases of sorting.
      setSearchResult(response.data);
      const total = parseInt(response.headers["totalamountofrecords"]);
      setTotalRecords(total);
      settotalAmountOfPages(Math.ceil(total / recordsPerPage));
    }
  };

  useEffect(() => {
    if (searchResult.length > 1) {
      searchCompanies(
        formikProps.values,
        activeSort !== null
          ? CompanySearchSortOptions[
              activeSort as keyof typeof CompanySearchSortOptions
            ]
          : undefined,
        activeSort !== null ? activeSortDirection : undefined
      );
    }
  }, [page, recordsPerPage]);

  useEffect(() => {
    if (activeSort !== null) {
      searchCompanies(
        formikProps.values,
        CompanySearchSortOptions[
          activeSort as keyof typeof CompanySearchSortOptions
        ],
        activeSortDirection
      );
    }
  }, [activeSort, activeSortDirection]);

  return (
    <>
      <NewCompanyModal
        onClickHide={() => setShowModal(false)}
        show={showModal}
      />
      <FormikProvider value={formikProps}>
        <SearchFieldsContainer col={3}>
          <TextField
            name="id"
            placeholder={assetsConfig.labels.company.singular + " Id"}
            value={formikProps.values.id}
          />
          <TextField
            name="name"
            placeholder={assetsConfig.labels.company.singular + " Name"}
            value={formikProps.values.name}
          />
          <MultiDropdown
            placeholder={assetsConfig.labels.company.singular + " Type"}
            options={CompanyTypeOptions}
            selectedValues={(formikProps.values.companyType || []).map(
              (label) => ({
                label,
                value: label, // if your CompanyTypeOptions use the company type as the value as well
              })
            )}
            onChange={(selectedList) => {
              formikProps.setFieldValue(
                "companyType",
                selectedList.map((x) => x.label)
              );
            }}
          />
          <Authorized
            settings={settings}
            loading={loading}
            feature="Company Address"
            authorized={
              <MultiDropdown
                placeholder={assetsConfig.labels.company.singular + " Country"}
                options={CountryOptions}
                selectedValues={(formikProps.values.country || []).map((label) => ({
                  label,
                  value: label, // if your CountryOptions use the country name as the value as well
                }))}
                onChange={(selectedList) => {
                  formikProps.setFieldValue(
                    "country",
                    selectedList.map((x) => x.label)
                  );
                }}
              />
          }
          />
          <TextField
            name="userFullName"
            placeholder="User Name"
            value={formikProps.values.userFullName}
          />
          <TextField
            name="userEmail"
            placeholder="User Email"
            value={formikProps.values.userEmail}
          />
          <Authorized
            settings={settings}
            loading={loading}
            feature="Commerce"
            authorized={
              <CheckboxField
                className="self-center"
                name="overridePaymentMethod"
                placeholder="Has Payment Method Override"
                checked={formikProps.values.overridePaymentMethod}
              />
            }
          />
          <Authorized
            settings={settings}
            loading={loading}
            feature="Commerce"
            authorized={
              <CheckboxField
                className="self-center"
                name="hasActiveAdjustment"
                placeholder="Has Active Adjustment"
                checked={formikProps.values.hasActiveAdjustment}
              />
            }
          />
        </SearchFieldsContainer>

        <div className="flex my-[1rem] gap-[1rem] justify-end flex-wrap">
          <Button
            className="button-light w-[150px]"
            type="button"
            onClick={() => {
              formikProps.setValues(initialSearch);
              setSearchVal(initialSearch);
              setSearchResult([]);
              setActive(false);
              setStoredValue("companySearch", initialSearch);
              setActiveSort(null);
              setActiveSortDirection(SortDirection.Asc);
              setPage(1);
            }}
          >
            Reset Search
          </Button>
          <Button
            className="button-light w-[150px]"
            type="button"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Add {assetsConfig.labels.company.singular}
          </Button>
          <CsvExport
            className="no-underline"
            data={csvCompanies}
            // headers={csvHeaders}
            filename={`Companies Search Export ${getLocalTime(new Date())}.csv`}
            disabled={csvCompanies.length < 1}
          >
            <Button
              className="button-light w-[150px]"
              disabled={csvCompanies.length < 1}
            >
              <div className="flex items-center gap-[.5rem]">
                Export
                {csvLoading && (
                  <img
                    className="w-[16px]"
                    src={config.assets.loading.primary}
                  />
                )}
              </div>
            </Button>
          </CsvExport>
          <Button
            className="w-[150px]"
            type="submit"
            onClick={() => {
              setIsLoading(true);
              formikProps.submitForm();
            }}
          >
            Search
          </Button>
        </div>
      </FormikProvider>

      {/* top pagination controls */}
      {isActive && (
        <AdminPageControls
          totalRecords={totalRecords}
          totalAmountOfPages={totalAmountOfPages}
          currentPage={page}
          setPage={(newPage) => setPage(newPage)}
          setIsLoading={(isLoading) => setIsLoading(isLoading)}
        />
      )}

      {!isLoading &&
        (searchResult && searchResult.length > 0 ? (
          <Table
            isActive={isActive}
            dualScroll
            // generate string[] from enum
            columnLabels={Object.keys(CompanySearchSortOptions)
              .filter((field) => {
                // Check if "Finance" is in the field and the feature flag is true
                if (!settings.featureFlags.Commerce && field.includes("Finance")) {
                  return false; // Exclude the field
                }
                else if(!settings.featureFlags["Company Address"] && (field.includes("Country") || field.includes("City") )) {
                  return false; // Exclude the field
                }
                return true; // Include the field
              })
              .splice(
                Object.keys(CompanySearchSortOptions).length / 2,
                Object.keys(CompanySearchSortOptions).length - 1
              )}
            labelAlias={[
              assetsConfig.labels.company.singular + " Id",
              assetsConfig.labels.company.singular + " Name",
              assetsConfig.labels.company.singular + " Type",
              settings.featureFlags['Company Address'] ? assetsConfig.labels.company.singular + " City" : null,
              settings.featureFlags['Company Address'] ? assetsConfig.labels.company.singular + " Country" : null,
              settings.featureFlags.Commerce ? "Finance Contact" : null,
              settings.featureFlags.Commerce ? "Finance Email" : null,
              assetsConfig.labels.entry.singular + " Contact",
              assetsConfig.labels.entry.singular + " Email",
            ].filter((x): x is string => !!x)}
            onClickSort={(sortLabel, sortDirection) => {
              setActiveSort(sortLabel);
              setActiveSortDirection(sortDirection);
            }}
            sortTriangleSize="14px"
            {...(activeSort !== null && {
              activeSort: {
                label: activeSort,
                direction: activeSortDirection,
              },
            })}
          >
            {searchResult.map((company: CompanySearchResult) => {
              return (
                <Fragment key={company.id}>
                  <div className="cell">
                    <p>
                      <StyledLink
                        href={`/edit-company/${company.id}`}
                        target="_blank"
                      >
                        {company.id}
                      </StyledLink>
                    </p>
                  </div>
                  <div className="cell">
                    <p>
                      <StyledLink
                        href={`/edit-company/${company.id}`}
                        target="_blank"
                      >
                        {company.name}
                      </StyledLink>
                    </p>
                  </div>
                  <div className="cell">
                    <p>{company.companyType}</p>
                  </div>
                <Authorized
                  settings={settings}
                  loading={loading}
                  feature="Company Address"
                  authorized={
                    <>
                      <div className="cell">
                        <p>{company.city}</p>
                      </div>
                      <div className="cell">
                        <p>{company.country}</p>
                      </div>
                    </>
                  }
                  />
                  <Authorized
                    settings={settings}
                    loading={loading}
                    feature="Commerce"
                    authorized={
                      <div className="cell">
                        <p>{company.financeContactName}</p>
                      </div>
                    }
                  />
                  <Authorized
                    settings={settings}
                    loading={loading}
                    feature="Commerce"
                    authorized={
                      <div className="cell">
                        <p>{company.financeContactEmail}</p>
                      </div>
                    }
                  />
                  <div className="cell">
                    <p>{company.entryContactName}</p>
                  </div>
                  <div className="cell">
                    <p>{company.entryContactEmail}</p>
                  </div>
                </Fragment>
              );
            })}
          </Table>
        ) : (
          <TablePlaceholder active={isActive}>
            Please add a search to see result table.
          </TablePlaceholder>
        ))}

      {isLoading && (
        <TablePlaceholder active={false}>
          <Loading fullScreen={false} showLogo={false} />
        </TablePlaceholder>
      )}

      {/* bottom pagination controls */}
      {isActive && (
        <AdminPageControls
          totalRecords={totalRecords}
          totalAmountOfPages={totalAmountOfPages}
          currentPage={page}
          setPage={(newPage) => setPage(newPage)}
          setIsLoading={(isLoading) => setIsLoading(isLoading)}
        />
      )}
    </>
  );
};

export default CompanySearchTab;

interface CompanySearchRequest {
  id?: number;
  name?: string;
  companyType?: string[];
  city?: string;
  country?: string[];
  userFullName?: string;
  userEmail?: string;
  overridePaymentMethod?: boolean;
  hasActiveAdjustment?: boolean;
}

interface CompanySearchResult {
  id: number;
  name: string;
  companyType: string;
  city: string;
  country: string;
  financeContactName: string;
  financeContactEmail: string;
  entryContactName: string;
  entryContactEmail: string;
}

function useNavigate() {
  throw new Error("Function not implemented.");
}

enum CompanySearchSortOptions {
  "Company Id",
  "Company Name",
  "Company Type",
  "Company City",
  "Company Country",
  "Finance Contact",
  "Finance Email",
  "Entry Contact",
  "Entry Email",
}
