import styled, { useTheme } from "styled-components";
import { Link } from "react-router-dom";
import Loading from "../Loading/Loading";
import { getClaims } from "../Auth/handleJWT";
import assetsConfig, { defaultBoxShadow } from "../../assetsConfig";
import lowerCase from "../../utils/lowerCase";

// various components shared by the media and credit library

export enum PageType {
	Media,
	Credits,
}

export const EntryDetailCard = styled.div`
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	align-items: center;
	justify-content: space-between;
	padding-bottom: 0.5rem;
	padding-right: 1rem;
	border-bottom: 1px solid ${({ theme }) => theme.colorBorderLight};

	/* .hierarchy {
    grid-column: span 2 / span 2;
  } */
`;

export const EntryDetailList = (props: {
	linkedEntries: any[];
	isFetchingLinkedEntries: boolean;
	pageType: PageType;
}) => {
	const claims = getClaims();
	const isAdmin = claims.some(
		(claim) => claim.name === "role" && claim.value === "admin"
	);

	const isAdminRoute = window.location.pathname.includes("admin"); // check if user is currently in admin route
	const adminRedirectPath = isAdmin && isAdminRoute ? "admin/" : "";

	return (
		<div className="flex flex-col gap-[1rem]">
			<h3>{assetsConfig.labels.entry.singular} Details</h3>
			{props.isFetchingLinkedEntries ? (
				<Loading fullScreen={false} showLogo={false} />
			) : props.linkedEntries.length === 0 ? (
				<p>
					There are no {lowerCase(assetsConfig.labels.entry.plural)} linked to
					this {props.pageType === PageType.Media ? "media" : "credit"}.
				</p>
			) : (
				props.linkedEntries.map((entry) => (
					<>
						<EntryDetailCard key={entry.id}>
							<Link
								className="text-left"
								to={`/${adminRedirectPath}edit-entry?entryId=${entry.id}`}
								target="_blank"
							>
								{entry.title}
							</Link>
							<div className="ml-[1rem]">
								<div className="hierarchy">
									{/* {entry.hierarchy} */}
									{entry.hierarchy &&
										entry.hierarchy
											.split(">")
											.map((level: string, index: number) => (
												<p key={index}>{level}</p>
											))}
								</div>
							</div>
						</EntryDetailCard>
					</>
				))
			)}
		</div>
	);
};

export const EditModalContainer = styled.div`
	overflow-y: scroll;
`;

export const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 3rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
	width: 100%;
	padding: 2rem 3rem;
	background: ${({ theme }) => theme.colorBackgroundLight};

	button {
		flex: 1;
	}
`;

export const LibraryContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
`;

export const LibraryInnerContainer = styled.div`
	padding: 2rem;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
`;

export const CreditListContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 2px;
	column-gap: 0;
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
	background: ${({ theme }) => theme.colorBackgroundLight};

	.children {
		padding: 1rem 0;
		border-bottom: 2px solid ${({ theme }) => theme.colorBorderLight};
	}
`;

export const CreditFieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;
	& > * {
		font-size: ${({ theme }) => theme.xxSmallSize};
		line-height: ${({ theme }) => theme.xxSmallSize};
		word-break: break-word;
	}
`;

export const SortContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	padding: 2rem;
	background: ${({ theme }) => theme.colorBackgroundLight};
	box-shadow: box-shadow: ${defaultBoxShadow};
`;
