const baseURL = process.env.REACT_APP_API_URL;
export const urlCompanies = `${baseURL}/companies`;
export const urlMedia = `${baseURL}/media`;
export const urlTags = `${baseURL}/tags`;
export const urlCredits = `${baseURL}/credits`;
export const urlManageCompanies = `${baseURL}/manage/companies`;
export const urlUsers = `${baseURL}/users`;
export const urlProgramDisplays = `${baseURL}/ProgramDisplays`;
export const urlNotification = `${baseURL}/notification`;

/* Admin Endpoints */
export const urlPrograms = `${baseURL}/admin/programs`;
export const urlSeasons = `${baseURL}/admin/seasons`;
export const urlAwards = `${baseURL}/admin/awards`;
export const urlFieldTemplates = `${baseURL}/admin/fieldtemplates`;
export const urlAdminProgramDisplays = `${baseURL}/admin/programdisplays`;
export const urlUserAdmin = `${baseURL}/admin/users`;
export const urlJurorAdmin = `${baseURL}/admin/jurors`;
export const urlSettingsAdmin = `${baseURL}/admin/settings`;
export const urlSettings = `${baseURL}/settings`;
export const urlJuryAdmin = `${baseURL}/admin/jury`;
export const urlJudgingAdmin = `${baseURL}/admin/JudgingAdmin`;

/* Authorize Endpoints */
export const urlAuthorizeTest = `${baseURL}/Order/charge-card`;
export const urlAuthorize = `${baseURL}/Order/create-order`;
export const urlOrder = `${baseURL}/Order`;
export const urlGetOrders = `${baseURL}/Order/getSortedOrders/`;
export const urlGetOrderCount = `${baseURL}/Order/getOrderCount/`;

/* Entry Endpoints */
export const urlEntries = `${baseURL}/Entries`;

/* Reset Password Endpoints */
export const urlResetPassword = `${baseURL}/Users/resetPassword`;

/* Report Endpoints */
export const urlReports = `${baseURL}/admin/reports`;
