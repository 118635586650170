import styled, { useTheme } from "styled-components";
import Button from "../Button/Button";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import parse from "html-react-parser";
import { roundTime } from "../../utils/getCountdownTimer";
import { ProgramDisplay } from "../../views/Admin/Program/ProgramInterfaces";
import { getCountdownTimer } from "../../utils/getCountdownTimer";
import { ProgramModel } from "../../views/Admin/Program/ProgramInterfaces";
import { DynamicTransitionDuration } from "../../globalStyles";
import { DisplayDate } from "../../views/Admin/Program/ProgramInterfaces";
import { OrderCounter } from "../../views/Admin/User/UserMenu";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../Icon/Icon";
import { useSettings } from "../../hooks/UseSettings";
import assetsConfig from "../../assetsConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getLocalTime, unixToJS } from "../../utils/timeUtilities";
import { getResizedVideoSrcAsync } from "../../views/MediaLibrary/manageMediaLibrary";

const cardSpacing = "2.4375rem";

export const ProgramCardContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	/* max-height: 1080px; */
	height: 100%;
	min-height: 400px;
	width: 100%;
	background: ${({ theme }) => theme.colorBackgroundLight};
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
	max-width: 90vw;
	margin: 0 auto;

	.program-img {
		width: 100%;
		object-fit: cover;
		aspect-ratio: 16 / 9;
	}

	@media only screen and (min-width: ${({ theme }) => theme.sm}) {
		/* max-width: 502px; */
		max-width: 1004px;
	}
`;

export const ProgramHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.296875rem ${cardSpacing};
	background: ${({ theme }) => theme.colorBackgroundDarkDark};
	border-bottom: 2px solid ${({ theme }) => theme.colorBorder};

	h1,
	p {
		color: ${({ theme }) => theme.colorCopyLightLight};
	}

	h1 {
		flex: 1;
		margin-right: 2rem;
		font-family: ${({ theme }) => theme.fontBrand};
		font-weight: ${({ theme }) => theme.fontDefaultWeight};
	}

	p {
		font-style: italic;
	}
`;

export const ProgramDescription = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 1rem;
	padding: ${cardSpacing};
	color: ${({ theme }) => theme.colorCopyLightLight};
	background: ${({ theme }) => theme.colorBackgroundDarkDark};
	transform-origin: top center;
	transition: all ${DynamicTransitionDuration};

	.program-description {
		margin-bottom: ${cardSpacing};
		white-space: pre-line;
		line-height: 23px;
	}

	.special-note {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		margin-top: auto;
		b {
			color: ${({ theme }) => theme.colorCopyLight};
		}
	}
`;

export const InnerProgramDeadlines = styled.div`
	display: grid;
	grid-gap: 0.75rem;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	margin: 0 ${cardSpacing};
	padding: 1rem 2rem;
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
`;

export const ProgramDeadlines = styled.div`
	position: relative;
	z-index: 10;
	/* margin-bottom: 2.4375rem; */

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50%;
		background: ${({ theme }) => theme.colorBackgroundDarkDark};
		z-index: -1;
	}

	.deadline {
		color: ${({ theme }) => theme.colorCopyLight};
	}

	.deadline.normal {
		color: ${({ theme }) => theme.colorActivation};
		font-weight: ${({ theme }) => theme.fontSemiBold};
	}
`;

const DownloadButtons = styled.div`
	display: flex;
	justify-content: center;
	gap: 1rem;
	margin: ${cardSpacing};
`;

export default function ProgramCard(props: ProgramCardProps) {
	const { settings } = useSelector((state: RootState) => state.settings);
	const theme = useTheme();
	const currentDate = new Date(Date.now());
	const [isDisabled, setIsDisabled] = useState(true);
	const history = useHistory();
	const [welcomeVideo, setWelcomeVideo] = useState<string | null>(null);

	// find closest display date
	const closestDisplayDate =
		(props.displayDates &&
			props.displayDates.find((displayDate) =>
				unixToJS(displayDate.date)
					? roundTime(unixToJS(displayDate.date)!)
					: false
			)) ||
		false;

	useEffect(() => {
		const now = new Date(Date.now());

		if (
			props.isActive &&
			!props.programEntriesClosed &&
			now >= new Date(props.programEntriesOpenDate!)
		) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [props]);

	useEffect(() => {
		const fetchResizedVideo = async () => {
			if (props.programWelcomeVideo) {
				const videoSrc = await getResizedVideoSrcAsync(
					props.programWelcomeVideo
				);
				setWelcomeVideo(videoSrc);
			} else setWelcomeVideo(null);
		};
		fetchResizedVideo();
	}, [props.programWelcomeVideo]);

	return (
		<ProgramCardContainer
			className={`${props.className} ${props.isActive ? "active" : ""}`}
		>
			<ProgramHeader>
				<div className="flex items-center">
					<h1 className="!mr-[1rem]">{props.programName!}</h1>
					{props.isAdmin && props.sortOrder && (
						<OrderCounter>{props.sortOrder}</OrderCounter>
					)}
				</div>

				{closestDisplayDate && props.showCountdown && (
					<div className="flex items-center">
						<Icon
							icon="clock"
							color={theme.colorPrimary}
							className="mr-[.5rem]"
						/>

						{closestDisplayDate.date && unixToJS(closestDisplayDate.date) && (
							<p>{roundTime(unixToJS(closestDisplayDate.date)!)} left</p>
						)}
					</div>
				)}
			</ProgramHeader>
			<ProgramDescription>
				{/* <img src={props.logo} /> */}
				{welcomeVideo && (
					<video
						className="welcome-video"
						controls
						key={welcomeVideo + props.id}
					>
						<source src={welcomeVideo} type="video/mp4" />
						<source src={welcomeVideo} type="video/quicktime" />
						Your browser does not support the video tag.
					</video>
				)}
				{props.programImage && props.programImageDisplaysForEntrants && (
					<img className="program-img" src={props.programImage} alt="Program" />
				)}

				<div className="program-description">
					{parse(props.programDescription! || "")}
				</div>
				{props.specialNote && (
					<div className="special-note">
						<b>Special Note: </b>
						{parse(props.specialNote)}
					</div>
				)}
			</ProgramDescription>

			{props.isActive ? (
				props.showDisplayDates &&
				props.displayDates && (
					<ProgramDeadlines>
						<InnerProgramDeadlines>
							{props.displayDates.map((date, i) => {
								const dateTime = unixToJS(date.date) ?? date.date;

								const dateObj = new Date(dateTime);

								return (
									<p
										className={`deadline ${
											currentDate.getTime() > dateObj.getTime()
												? "greyedOut"
												: "normal"
										}`}
										key={i}
									>
										{date.label}: {getLocalTime(dateTime, true)}
									</p>
								);
							})}
						</InnerProgramDeadlines>
					</ProgramDeadlines>
				)
			) : (
				<ProgramDeadlines>
					<InnerProgramDeadlines>
						<p className="deadline normal">Program Closed</p>
					</InnerProgramDeadlines>
				</ProgramDeadlines>
			)}

			{(props.entryKit || props.bestPractices) && (
				<DownloadButtons>
					{props.entryKit && (
						<Button
							className="button-light flex-1"
							iconColor={theme.colorPrimary}
							icon="download"
							download={props.entryKit}
						>
							{props.entryKitOverrideText || "Entry Kit"}
						</Button>
					)}
					{props.bestPractices && (
						<Button
							className="button-light flex-1"
							iconColor={theme.colorPrimary}
							icon="download"
							download={props.bestPractices}
						>
							{props.bestPracticesOverrideText || "Best Practices"}
						</Button>
					)}
				</DownloadButtons>
			)}
			{(props.isAdmin && (
				<Button
					className="mt-auto"
					icon="edit"
					iconColor={theme.colorCopyLightLight}
					to={`/program/program-display/${props.id}`}
				>
					Edit {props.programName!}
				</Button>
			)) ||
				(props.programIsEntryLevel && (
					<Button
						className="mt-auto font-semibold"
						disabled={isDisabled}
						onClick={() => {
							history.push(`/edit-entry/?programId=${props.programId}`, {
								from: history.location.pathname,
							});
						}}
						icon="plus"
						iconColor={theme.colorCopyLightLight}
						{...(props.programHasChildren && {
							iconRight: true,
							icon: "caret",
							iconRotation: "270deg",
						})}
					>
						{settings.isDemo ? (
							<>Create {assetsConfig.labels.entry.singular}</>
						) : (
							<>
								{props.enterButtonOverrideText
									? props.enterButtonOverrideText
									: `${props.programHasChildren ? "Enter" : "Select"} ${
											props.programName
									  }`}
							</>
						)}
					</Button>
				)) || (
					<Button
						className="mt-auto font-semibold"
						disabled={isDisabled}
						to={`/programs/${props.programId}`}
						icon="plus"
						iconColor={theme.colorCopyLightLight}
						{...(props.programHasChildren && {
							iconRight: true,
							icon: "caret",
							iconRotation: "270deg",
						})}
					>
						{settings.isDemo ? (
							<>Create {assetsConfig.labels.entry.singular}</>
						) : (
							<>
								{props.programHasChildren ? "Enter" : "Select"}{" "}
								{props.programName}
							</>
						)}
					</Button>
				)}
		</ProgramCardContainer>
	);
}

export interface ProgramCardProps extends ProgramDisplay {
	className?: string;
	isAdmin: boolean;
}
