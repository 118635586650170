import Button from "../../components/Button/Button";
import DropdownField, {
  DropdownOptions,
} from "../../components/FormFields/DropdownField";
import TextField from "../../components/FormFields/TextField";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import StyledForm from "../../components/Form/Form";
import CheckboxField from "../../components/FormFields/CheckboxField";
import {
  UserProfileHeader,
  UserProfileBody,
} from "../../components/UserPageTemplate/UserPageTemplate";
import CompanyIcon from "../../assets/placeholders/placeholder.png";
import { useCompany } from "../../hooks/UseCompany";
import {
  getAgencyNetworks,
  getHoldingCompanies,
  updateCompany,
  updateCompanyLogo,
} from "./manageCompany";
import { USStateOptions, CanadaStateOptions } from "../../data/StateData";
import { CountryOptions } from "../../data/CountryData";
import { JobTitleOptions } from "../../data/JobTitleData";
import { CompanyTypeOptions } from "../Admin/Program/ProgramInterfaces";

import styled from "styled-components";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import config from "../../config";
import assetsConfig from "../../assetsConfig";
import { useAlert } from "../../components/Alert/Alerts";
import Authorized from "../../components/Auth/Authorized";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const LoadingSpinnder = styled.img`
  width: 15%;
  display: inline-flex;
`;

export default function CompanyUpdate(props: CompanyUpdateProps) {
  const { user } = useCurrentUser();
  const { company, setCompany } = useCompany();
  const [companyLogo, setCompanyLogo] = useState(CompanyIcon);
  const emptyValue = "--------";
  const [firstValidation, setFirstValidation] = useState(false);
  const { addNewAlert } = useAlert();

  useLayoutEffect(() => {
    if (Boolean(company.logo)) {
      setCompanyLogo(company.logo!);
    }
    return;
  }, [company]);

  let fieldRequired = "This field is required";

  const [agencyNetworksOptions, setAgencyNetworksDropdownOptions] = useState<
    DropdownOptions[]
  >([]);
  const [holdingCompaniesOptions, setHoldingCompaniesDropdownOptions] =
    useState<DropdownOptions[]>([]);

  const formikProps = useFormik({
    initialValues: {
      ...company,
      financeEmailCheck:
        company.financeContactEmail === user.email &&
        company.financeContactName === user.fullName &&
        company.financeContactPhone === user.phone &&
        company.financeContactJobTitle === user.jobTitle,
      entryEmailCheck:
        company.entryContactEmail === user.email &&
        company.entryContactName === user.fullName &&
        company.entryContactPhone === user.phone &&
        company.entryContactJobTitle === user.jobTitle,
    },
    enableReinitialize: true,
    onSubmit: async (value) => {
      if (formikProps.values.financeEmailCheck) {
        value.financeContactEmail = user.email;
        value.financeContactName = user.fullName;
        value.financeContactPhone = user.phone;
        value.financeContactJobTitle = user.jobTitle;
      }

      if (formikProps.values.entryEmailCheck) {
        value.entryContactEmail = user.email;
        value.entryContactName = user.fullName;
        value.entryContactPhone = user.phone;
        value.entryContactJobTitle = user.jobTitle;
      }
      await updateCompany(value)
        .then((res) => {
          // console.log("updateCompany response", res);
          addNewAlert({
            type: "success",
            message: "Successfully updated information",
          });
          setCompany(value);
        })
        .catch((e) => {
          addNewAlert({
            type: "error",
            message: "Failed to update",
          });
        });

      // TODO: Handle Company Update logic
    },
    validationSchema: Yup.object({
      name: Yup.string().required(fieldRequired),
      companyType: Yup.string().required(fieldRequired),
      city: Yup.string()
        .nullable()
        .when([], {
          is: () => settings.featureFlags["Company Address"],
          then: (schema) => schema.required(fieldRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
      country: Yup.string()
        .nullable()
        .when([], {
          is: () => settings.featureFlags["Company Address"],
          then: (schema) => schema.required(fieldRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
      state: Yup.string().when("country", {
        is: (value: string) =>
          value === "United States" ||
          (value === "Canada" && settings.featureFlags["Company Address"]),
        then: Yup.string().required(fieldRequired),
      }),
      address1: Yup.string()
        .nullable()
        .when([], {
          is: () => settings.featureFlags["Company Address"],
          then: (schema) => schema.required(fieldRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
      postalCode: Yup.string().when("country", {
        is: (value: string) =>
          value === "United States" ||
          (value === "Canada" && settings.featureFlags["Company Address"]),
        then: Yup.string().required(fieldRequired),
      }),
      // phone: Yup.string().required(fieldRequired),
      financeContactEmail: Yup.string().when("financeEmailCheck", {
        is: (value: any) => value === false || value === undefined,
        then: Yup.string().required(fieldRequired),
      }),
      financeContactName: Yup.string().when("financeEmailCheck", {
        is: (value: any) => value === false || value === undefined,
        then: Yup.string().required(fieldRequired),
      }),
      financeContactJobTitle: Yup.string().when("financeEmailCheck", {
        is: (value: any) => value === false || value === undefined,
        then: Yup.string().required(fieldRequired),
      }),
      financeContactPhone: Yup.string().when("financeEmailCheck", {
        is: (value: any) => value === false || value === undefined,
        then: Yup.string().required(fieldRequired),
      }),
      entryContactEmail: Yup.string().when("entryEmailCheck", {
        is: (value: any) => value === false || value === undefined,
        then: Yup.string().required(fieldRequired),
      }),
      entryContactName: Yup.string().when("entryEmailCheck", {
        is: (value: any) => value === false || value === undefined,
        then: Yup.string().required(fieldRequired),
      }),
      entryContactJobTitle: Yup.string().when("entryEmailCheck", {
        is: (value: any) => value === false || value === undefined,
        then: Yup.string().required(fieldRequired),
      }),
      entryContactPhone: Yup.string().when("entryEmailCheck", {
        is: (value: any) => value === false || value === undefined,
        then: Yup.string().required(fieldRequired),
      }),
      agencyNetworkId: Yup.number()
        .min(1, fieldRequired)
        .when([], {
          is: () => settings.featureFlags["Company Agency Network"],
          then: (schema) => schema.required(fieldRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
      holdingCompanyId: Yup.number()
        .min(1, fieldRequired)
        .when([], {
          is: () => settings.featureFlags[`Company Holding Company`],
          then: (schema) => schema.required(fieldRequired),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
    validateOnBlur: false,
    validateOnChange: firstValidation,
  });

  const checkForErrors = () => {
    if (Object.values(formikProps.errors).length > 0) {
      addNewAlert({
        type: "error",
        message: "Please review the fields above for errors.",
      });
    }
  };

  useEffect(() => {
    getAgencyNetworks().then((data) => {
      var dropdownOptions = data!.map((r) => ({ value: r.id, label: r.name }));
      setAgencyNetworksDropdownOptions(dropdownOptions);
    });
  }, []);

  useEffect(() => {
    getHoldingCompanies().then((data) => {
      var dropdownOptions = data!.map((r) => ({ value: r.id, label: r.name }));
      setHoldingCompaniesDropdownOptions(dropdownOptions);
    });
  }, []);

  const { settings, loading } = useSelector(
    (state: RootState) => state.settings
  );

  return (
    <div>
      <UserProfileHeader
        img={companyLogo}
        heading={formikProps.values.name}
        subheading={`${formikProps.values.city?.toUpperCase()}, ${formikProps.values.state?.toUpperCase()}`}
        setValue={setCompanyLogo}
        updateImage={updateCompanyLogo}
      />
      <UserProfileBody>
        <FormikProvider value={formikProps} key="companyUpdate">
          <StyledForm className="twoColumn">
            <div className="col-w-100">
              <h3 className="subtitle">Company Information</h3>
              <p
                className="sectionText"
                dangerouslySetInnerHTML={{
                  __html: assetsConfig.copy.companyEntryCreationInformation,
                }}
              ></p>
            </div>
            <TextField name="name" placeholder="Company Name*" />
            <DropdownField
              name="companyType"
              placeholder="Company Type*"
              options={CompanyTypeOptions}
            />
            <Authorized
              settings={settings}
              loading={loading}
              feature="Company Agency Network"
              authorized={
                <DropdownField
                  name="agencyNetworkId"
                  placeholder={`${assetsConfig.agencyNetworkCompanyName}`}
                  options={agencyNetworksOptions}
                />
              }
            />
            <Authorized
              settings={settings}
              loading={loading}
              feature="Company Holding Company"
              authorized={
                <DropdownField
                  name="holdingCompanyId"
                  placeholder={`${assetsConfig.holdingCompanyName}`}
                  options={holdingCompaniesOptions}
                />
              }
            />
            <Authorized
              settings={settings}
              loading={loading}
              feature="Company Address"
              authorized={
                <>
                  <DropdownField
                    name="country"
                    placeholder="Country*"
                    options={CountryOptions}
                  />
                  <TextField name="address1" placeholder="Company Address 1*" />
                  <TextField name="address2" placeholder="Company Address 2" />
                  <TextField name="city" placeholder="Company City*" />
                  {formikProps.values.country === "United States" ||
                  formikProps.values.country === "Canada" ? (
                    <DropdownField
                      name="state"
                      placeholder="State/Province/Region*"
                      options={
                        formikProps.values.country === "United States"
                          ? USStateOptions
                          : CanadaStateOptions
                      }
                    />
                  ) : (
                    <TextField
                      name="state"
                      placeholder="State/Province/Region"
                    />
                  )}

                  <TextField
                    name="postalCode"
                    placeholder={
                      formikProps.values.country === "United States" ||
                      formikProps.values.country === "Canada"
                        ? "Zip/Postal Code* "
                        : "Zip/Postal Code"
                    }
                  />
                </>
              }
            />
            <Authorized
              settings={settings}
              loading={loading}
              feature="Company Social Fields"
              authorized={
                <>
                  <TextField name="socialMedia1" placeholder="LinkedIn" />
                  <TextField name="socialMedia2" placeholder="Twitter" />
                  <TextField name="socialMedia3" placeholder="Instagram" />
                  <TextField name="socialMedia4" placeholder="Facebook" />
                </>
              }
            />
            <div className="mt-[1rem]">
              <h3 className="subtitle">Company Point of Contact</h3>
              <p className="sectionText">
                {assetsConfig.copy.companyPointOfContactMessage}
              </p>
            </div>

            <div className="col-w-100">
              <CheckboxField
                name="entryEmailCheck"
                placeholder="I will be the designated entry point of contact"
              />

              {!formikProps.values.entryEmailCheck && (
                <div className="flex flex-col gap-[3rem] mt-[3rem]">
                  <TextField
                    name="entryContactName"
                    placeholder="Entry Contact Name*"
                    className={`${
                      formikProps.values.entryEmailCheck
                        ? "scale-down"
                        : "expand-down"
                    }`}
                  />
                  <TextField
                    name="entryContactEmail"
                    placeholder="Entry Contact Email*"
                    className={`${
                      formikProps.values.entryEmailCheck
                        ? "scale-down"
                        : "expand-down"
                    }`}
                  />
                  <DropdownField
                    name="entryContactJobTitle"
                    placeholder="Entry Contact Job Title*"
                    className={`${
                      formikProps.values.entryEmailCheck
                        ? "scale-down"
                        : "expand-down"
                    }`}
                    options={JobTitleOptions}
                  />
                  <TextField
                    name="entryContactPhone"
                    placeholder="Entry Contact Phone Number*"
                    className={`${
                      formikProps.values.entryEmailCheck
                        ? "scale-down"
                        : "expand-down"
                    } mb-[1.5rem]`}
                  />
                </div>
              )}
              <CheckboxField
                className="mt-[1.5rem]"
                name="financeEmailCheck"
                placeholder="I will be the designated finance point of contact"
              />

              {!formikProps.values.financeEmailCheck && (
                <div className="flex flex-col gap-[3rem] mt-[3rem]">
                  <TextField
                    name="financeContactName"
                    placeholder="Finance Contact Name*"
                    className={`${
                      formikProps.values.financeEmailCheck
                        ? "scale-down"
                        : "expand-down"
                    }`}
                  />
                  <TextField
                    name="financeContactEmail"
                    placeholder="Finance Contact Email*"
                    className={`${
                      formikProps.values.financeEmailCheck
                        ? "scale-down"
                        : "expand-down"
                    }`}
                  />
                  <DropdownField
                    name="financeContactJobTitle"
                    placeholder="Finance Job Title*"
                    className={`${
                      formikProps.values.financeEmailCheck
                        ? "scale-down"
                        : "expand-down"
                    }`}
                    options={JobTitleOptions}
                  />
                  <TextField
                    name="financeContactPhone"
                    placeholder="Finance Contact Phone Number*"
                    className={`${
                      formikProps.values.financeEmailCheck
                        ? "scale-down"
                        : "expand-down"
                    }`}
                  />
                </div>
              )}
            </div>

            {!props.disabled && (
              // <div className="flex justify-end mt-[3rem] gap-[1rem]">
              //   <Button
              //     className="w-[250px] button-light"
              //     onClick={() => {
              //       formikProps.resetForm();
              //       addNewAlert({
              //         type: "success",
              //         message: "Form reset successful",
              //       });
              //     }}
              //     icon="close"
              //   >
              //     Discard Changes
              //   </Button>
              <Button
                className="mb-1 w-[200px] ml-auto"
                onClick={() => setFirstValidation(true)}
                type="submit"
                disabled={formikProps.isSubmitting}
              >
                {formikProps.isSubmitting ? (
                  <LoadingSpinnder src={config.assets.loading.primary} />
                ) : (
                  "Update Company"
                )}
              </Button>
              // </div>
            )}
          </StyledForm>
        </FormikProvider>
      </UserProfileBody>
    </div>
  );
}

interface CompanyUpdateProps {
  disabled?: boolean;
  readOnly?: boolean;
}
