import React, { useEffect, useRef, useState, RefObject } from "react";
import styled, { useTheme } from "styled-components";
import { Document, Page } from "react-pdf";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Instance } from "simple-peer";
import Image from "../../../../components/Image/Image";
import {
	VIDEO_EXTENSIONS,
	isVideoAvailable,
} from "../../../../components/MediaEnlargeModal/MediaEnlargeModal";
import {
	getPdfThumbnail,
	getThumbnailSrc,
	ThumbnailSize,
} from "../../../MediaLibrary/manageMediaLibrary";
import VideoPlaceholder from "../../../../assets/placeholders/video-landscape.png";
import AudioPlaceholder from "../../../../assets/placeholders/audio-landscape.png";
import ImagePlaceholder from "../../../../assets/placeholders/image-landscape.png";
import DocumentPlaceholder from "../../../../assets/placeholders/document-landscape.png";
import { useLiveToolsSettingsHub } from "../../../../hooks/useLiveToolsSettingsHub";
import { LiveToolsMediaType, LiveToolsSettings } from "../JudgingInterfaces";
import { voteButtonColor } from "../../../Judging/GalleryCard";
import Settings from "../../Settings/Settings";
import { useSettings } from "../../../../hooks/UseSettings";
import { DarkPatternBG } from "../../../../globalStyles";
import { PageContainer } from "../../../EntrantProgram/EntrantProgram";
import { BsPhoneLandscape } from "react-icons/bs";
import DocumentImage from "../../../../assets/placeholders/document-landscape.png";
import Icon from "../../../../components/Icon/Icon";
import { ZoomOnHover } from "../../../Judging/EntryDetail/MediaLightbox";
import WaveSurfer from "wavesurfer.js";
import ClipboardText from "../../../../components/ClipboardText/ClipboardText";
import { LinkSet } from "../../Program/ProgramInterfaces";
import { urlJudgingAdmin } from "../../../../endpoints";
import axios from "axios";
import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";
import parse from "html-react-parser";

interface ContainerProps {
	filePath: string;
}

const Container = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
`;

const BackgroundImage = styled.div<ContainerProps>`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 15px;
	margin: -15px;
	background-image: url(${({ filePath }) => filePath});
	background-position: center;
	background-size: cover;
	filter: blur(10px) brightness(0.5);
`;

export const StyledScoreIndicator = styled.div<{ background: string }>`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	height: fit-content;
	aspect-ratio: 1 / 1;
	padding: 1rem;
	font-weight: ${({ theme }) => theme.fontSemiBold};
	cursor: default;
	background: ${(p) => p.background};

	&:hover {
		background: ${(p) => p.background};
	}
	border-radius: 50%;
	color: ${({ theme }) => theme.colorCopyLightLight};
	text-align: center;
`;

const showAward = (settings: LiveToolsSettings) => {
	if (settings.roundStructure) {
		if (
			settings.roundStructure.name === "Grand" ||
			settings.roundStructure.name === "Winner"
		) {
			return settings.revealWinner;
		}
	}
	return true;
};

const ScoreIndicator = (props: {
	background: string;
	children: React.ReactNode;
}) => {
	const ref = useRef<any>(null);

	useEffect(() => {
		if (ref.current) {
			const width = ref.current.offsetWidth;
			const halfWidth = `-${width / 2}px`;
			ref.current.style.top = halfWidth;
			ref.current.style.right = halfWidth;
		}
	}, [ref]);

	return (
		<StyledScoreIndicator ref={ref} background={props.background}>
			{props.children}
		</StyledScoreIndicator>
	);
};

const imgSize = 25;

const BlackRectangle = styled.div`
	position: absolute;
	top: 0;
	left: ${imgSize / 2 + "%"};
	width: ${100 - imgSize / 2 + "%"};
	height: 100%;
	background-color: ${({ theme }) => theme.colorBackgroundDarkDark};
	z-index: -1;
`;

const ImgContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	/* width: ${imgSize + "%"};
  height: 66%; */
`;

const OverlaidImage = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
	max-height: 60vh;
	aspect-ratio: 16 / 9;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 1rem;
	justify-content: center;
	margin: 0 2rem;
	color: ${({ theme }) => theme.colorCopyLightLight};
`;

const InnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 85%;
	height: 50%;
	font-size: 1rem;

	@media only screen and (max-width: ${({ theme }) => theme.lg}) {
		${BlackRectangle} {
			left: 25%;
			width: 75%;
		}

		/* ${ImgContainer} {
      width: 50%;
      height: 66%;
    } */
		${TextContainer} {
			margin: 0 1rem;
		}
	}
	@media only screen and (min-width: 2560px) {
		font-size: 1.5rem;
	}
`;

const Brand = styled.p`
	font-size: 1.25em;
`;

const Title = styled.h1`
	font-size: 2em;
	margin-bottom: 1rem;
`;

const Batch = styled.p`
	font-size: 1.25em;
	text-align: right;
	color: ${({ theme }) => theme.colorCopyLight};

	@media only screen and (min-width: 2560px) {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
`;

const AwardImage = styled.img`
	position: absolute;
	top: 50%;
	right: 0;
	height: 50%;
	transform: translateY(-50%);
`;

const CenteredContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

const StyledTermContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 80%;
	max-width: 1200px;
	height: auto;
	background-color: ${({ theme }) => theme.colorBackgroundLightLight};
	padding: 2rem;
	border-radius: 10px;
`;

const StyledTermTitle = styled.h2`
	font-size: 2rem;
	font-weight: bold;
	color: ${({ theme }) => theme.colorCopyDarkDark};
	margin: 0;
`;

const StyledTermDefinition = styled.div`
	font-size: 1.6rem;
	color: ${({ theme }) => theme.colorCopyDarkDark};
	text-align: left;
	max-width: 80%;
`;

export const DarkPageContainer = styled(PageContainer)`
	${DarkPatternBG};
`;

const StyledTermLine = styled.div`
	width: 100%;
	height: 2px;
	background-color: ${({ theme }) => theme.colorBackgroundDarkDark};
	margin: 2rem 0;
`;

const StyledTitleSlideContainer = styled.div`
	width: 100%;
	background-color: ${({ theme }) => theme.colorCopyLightLight};
`;

export const StyledTitleSlideScoreIndicator = styled.div<{
	background: string;
}>`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-size: 1.25em;
	width: 6rem;
	height: 6rem;
	background: ${(p) => p.background};
	color: ${({ theme }) => theme.colorCopyLightLight};

	${(p) =>
		p.background === "transparent" &&
		`
  border: 1px solid ${p.theme.colorBorderLight}
  `}

	${(p) =>
		p.background &&
		(p.background === p.theme.colorAwardGold ||
			p.background === p.theme.colorAwardSilver ||
			p.background === p.theme.colorAwardBronze) &&
		`
      color: ${p.theme.colorCopyDarkDark};
    `}
`;

const StyledTitleSlideIconText = styled.p`
	text-align: center;
	font-size: 1em;
	margin-top: auto;
	color: ${({ theme }) => theme.colorCopyLightLight};
`;
const StyledTitleSlideBorder = styled.div`
	border-bottom: 2px solid ${({ theme }) => theme.colorPrimary};
`;
const StyledTitleSlideIconContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 80%;
	min-height: 10rem;
	padding: 0 3rem;
	background-color: ${({ theme }) => theme.colorBackgroundDarkDark};

	svg {
		margin: 0 auto;
		g {
			opacity: 1;
		}
	}
`;

const StyledTitleSlideIdContainer = styled.div`
	text-align: right;
	font-size: 1.25rem;
	color: ${({ theme }) => theme.colorCopyLight};

	@media only screen and (min-width: 2560px) {
		font-size: 1.5em;
	}
`;

const StyledTitleSlideBatchContainer = styled.div`
	text-align: right;
	color: ${({ theme }) => theme.colorCopyLight};
	padding: 2.5rem;
`;
const StyledLinkContainer = styled.div`
	a {
		color: ${({ theme }) => theme.colorCopyLightLight};
	}

	a:link {
		text-decoration: none;
	}
`;

const StyledLinkUserContainer = styled.div`
	color: ${({ theme }) => theme.colorCopyLightLight};
	gap: 2rem;
	font-size: 1.25rem;
	padding-top: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledDefinitionSlideContainer = styled.div`
	position: relative;
	top: 0;
	/* width: 900px; */
	max-width: 80vw;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
`;

const StyledDefinitionSlideText = styled.div`
	padding: 2rem;
	font-size: 1.5rem;
	white-space: pre-wrap;
	overflow-y: auto;

	* {
		font-size: 1.5rem;
	}
`;

const StyledDefinitionSlideTitle = styled.div`
	background-color: ${({ theme }) => theme.colorBackgroundDarkDark};
	width: 100%;
	padding: 2.5rem 2rem;
	color: ${({ theme }) => theme.colorCopyLightLight};

	h2 {
		font-size: 2rem;
	}
`;

export const MediaViewer = () => {
	const theme = useTheme();
	let media = <></>;
	const liveSettings = useLiveToolsSettingsHub();
	const mediaRef = useRef<HTMLAudioElement | HTMLVideoElement | null>(null);
	const { settings } = useSettings();
	const [isMuted, setIsMuted] = useState(true);
	const [currentTime, setCurrentTime] = useState(0);
	const [maxTime, setMaxTime] = useState(0);
	const [websitePreview, setWebsitePreview] = useState<string>(DocumentImage);
	const waveformRef = useRef<HTMLDivElement | null>(null);
	const [waveSurfer, setWaveSurfer] = useState<WaveSurfer | null>(null);
	const isMounted = useRef(false);
	const [pauseSlide, setPauseSlide] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(true);

	liveSettings.debug && console.log("Media Item: ", liveSettings.mediaState);

	useEffect(() => {
		axios
			.get(`${urlJudgingAdmin}/getPauseSlideByJuryId/${liveSettings.juryId}`)
			.then((response) => {
				setPauseSlide(response.data.pauseSlide);
				setIsLoading(false);
			})
			.catch((err) => {
				// Handle errors
				setError("Failed to fetch PauseSlide path");
				console.error(err);
				setIsLoading(false);
			});
	}, [liveSettings]);

	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (mediaRef.current) {
			if (liveSettings.mediaState.isPlaying) {
				mediaRef.current!.currentTime = liveSettings.mediaState.timestamp || 0;
				mediaRef.current.play();
			} else {
				mediaRef.current!.currentTime = liveSettings.mediaState.timestamp || 0;
				mediaRef.current.pause();
			}
		}
	}, [liveSettings.mediaState.isPlaying, liveSettings.mediaState.timestamp]);

	useEffect(() => {
		if (mediaRef.current) {
			mediaRef.current!.volume = liveSettings.mediaState.volume || 0.0;
		}
	}, [liveSettings.mediaState.volume]);

	useEffect(() => {
		if (mediaRef.current) {
			mediaRef.current!.volume = liveSettings.mediaState.volume || 0.0;
		}
	}, [liveSettings.mediaState.mediaType]);

	useEffect(() => {
		const getOGImage = async (url: string) => {
			const results = await liveSettings.fetchOgImage(url);
			setWebsitePreview(results || DocumentImage);
		};
		if (
			liveSettings.mediaState.mediaType === LiveToolsMediaType.Link &&
			liveSettings.mediaState.filePath
		) {
			getOGImage(liveSettings.mediaState.filePath);
		} else {
			setWebsitePreview(DocumentImage);
		}
	}, [liveSettings.mediaState.mediaType, liveSettings.mediaState.filePath]);

	useEffect(() => {
		if (
			waveformRef.current &&
			liveSettings.mediaState.mediaType === LiveToolsMediaType.Audio
		) {
			const ws = WaveSurfer.create({
				container: waveformRef.current,
				waveColor: theme.colorCopyLight,
				progressColor: theme.colorBackgroundLightLight,
				cursorColor: theme.colorBackgroundLightLight,
				barWidth: 5,
				barHeight: 1,
				barGap: 2,
				normalize: true,
				cursorWidth: 5,
			});

			ws.load(
				process.env.REACT_APP_S3_HOST! + liveSettings.mediaState.filePath
			);

			ws.on("ready", () => {
				setMaxTime(ws.getDuration());
				setWaveSurfer(ws);

				if (waveformRef.current) {
					waveformRef.current.setAttribute("tabindex", "-1");
					waveformRef.current.focus();
				}
			});

			ws.on("seek", () => {
				setCurrentTime(ws.getCurrentTime());
			});

			return () => {
				ws.destroy();
			};
		}
	}, [
		liveSettings.mediaState.mediaType,
		liveSettings.mediaState.filePath,
		theme,
	]);

	useEffect(() => {
		if (
			waveSurfer &&
			!waveSurfer.isDestroyed &&
			liveSettings.mediaState.mediaType === LiveToolsMediaType.Audio
		) {
			if (liveSettings.mediaState.isPlaying) {
				waveSurfer.play();
			} else {
				waveSurfer.pause();
			}
		}
	}, [liveSettings.mediaState.isPlaying, waveSurfer]);

	useEffect(() => {
		if (
			waveSurfer &&
			liveSettings.mediaState.mediaType == LiveToolsMediaType.Audio
		) {
			waveSurfer.setVolume(liveSettings.mediaState.volume || 0.0);
		}
	}, [liveSettings.mediaState.volume, waveSurfer]);

	useEffect(() => {
		if (
			waveSurfer &&
			!waveSurfer.isDestroyed &&
			typeof liveSettings.mediaState.timestamp === "number" &&
			maxTime > 0
		) {
			const percentage = Math.min(
				Math.max(liveSettings.mediaState.timestamp / maxTime, 0),
				1
			);
			if (waveSurfer !== null) {
				waveSurfer.seekTo(percentage);
			}
		}
	}, [liveSettings.mediaState.timestamp, maxTime, waveSurfer]);

	const formatTime = (time: number) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		return `${minutes}:${seconds.toString().padStart(2, "0")}`;
	};
	const RenderLinkLoginInfo = (props: LinkField) => {
		const theme = useTheme();
		if (props.links && props.links.length > 0) {
			return (
				<div className="flex flex-col gap-[.5rem]">
					{props.links.map((linkSet) => {
						return (
							<div className="flex flex-col">
								<div className="flex items-center gap-x-[2rem] flex-wrap">
									{linkSet.username && (
										<ClipboardText
											displayText={linkSet.username}
											copyText={linkSet.username}
											toolTip="Copy username"
											copiedToolTip="Username copied!"
											icon={
												<Icon
													icon="user"
													width="15px"
													height="15px"
													color={theme.colorCopyLight}
												/>
											}
										/>
									)}
									{linkSet.password && (
										<ClipboardText
											displayText={linkSet.password}
											copyText={linkSet.password}
											toolTip="Copy password"
											copiedToolTip="Password copied!"
											icon={
												<Icon
													icon="lock"
													width="17px"
													height="17px"
													color={theme.colorCopyLight}
												/>
											}
										/>
									)}
								</div>
							</div>
						);
					})}
				</div>
			);
		} else return <></>;
	};
	switch (liveSettings.mediaState.mediaType) {
		case LiveToolsMediaType.Audio:
			media = (
				<div className="h-full relative overflow-hidden">
					<Image
						className="h-full max-h-full object-contain mx-auto brightness-[0.3]"
						src={getThumbnailSrc(
							liveSettings.mediaState.coverImage!,
							ThumbnailSize.MEDIUM
						)}
						alt="Audio Placeholder"
						lazy
					/>
					<div
						ref={waveformRef}
						className="w-full absolute top-[40%] left-[1px]"
					></div>
				</div>
			);
			break;
		case LiveToolsMediaType.Video:
			media = isVideoAvailable(liveSettings.mediaState.filePath || "") ? (
				<video
					muted={isMuted}
					autoPlay={false}
					className="w-full h-full"
					ref={mediaRef as React.RefObject<HTMLVideoElement>}
					onPlay={(event) => {
						if (mediaRef.current) {
							mediaRef.current.volume = liveSettings.mediaState.volume || 0.0;
							setIsMuted(false);
						}
					}}
					onPause={(event) => {
						if (mediaRef.current) {
							setIsMuted(true);
						}
					}}
				>
					{VIDEO_EXTENSIONS.map((extension) => (
						<source
							src={
								process.env.REACT_APP_S3_RESIZED_HOST! +
								liveSettings.mediaState.filePath
							}
							type={`video/${extension}`}
						/>
					))}
					Your browser does not support the video tag.
				</video>
			) : (
				<div className="flex justify-center items-center h-full">
					An error occurred with the video url. The video preview is
					unavailable.
				</div>
			);

			break;
		case LiveToolsMediaType.Image:
			media = (
				<div className="h-full overflow-hidden">
					<ZoomOnHover
						id="zoom-img"
						image={
							<Image
								className="h-full max-h-full object-contain mx-auto"
								src={getThumbnailSrc(
									liveSettings.mediaState.filePath!,
									ThumbnailSize.X_LARGE
								)}
								alt="Media Image"
								lazy
							/>
						}
						isZoom={
							liveSettings.mediaState.xLocation !== undefined &&
							liveSettings.mediaState.yLocation !== undefined
						}
						position={
							liveSettings.mediaState.xLocation !== undefined &&
							liveSettings.mediaState.yLocation !== undefined
								? {
										x: liveSettings.mediaState.xLocation,
										y: liveSettings.mediaState.yLocation,
								  }
								: undefined
						}
						isZoomEnabled={true}
						// imgSrc={mediaSrc || ImagePlaceholder}
					/>
				</div>
			);
			break;
		case LiveToolsMediaType.Link:
			media = (
				<StyledLinkContainer>
					<a
						href={liveSettings.mediaState.filePath ?? ""}
						target="_blank"
						rel="noopener noreferrer"
						className="text-colorCopyLightLight text-center"
					>
						<div className="w-full flex justify-center items-center">
							<img
								src={websitePreview || DocumentImage}
								className="m-x-auto max-w-[350px] max-h-[350px] object-scale-down"
								alt="Website Preview"
							/>
						</div>

						<a
							className="flex justify-center items-center gap-[.5rem] mt-[1rem] text-[1.5rem] !underline"
							href={
								liveSettings.mediaState.filePath
									? liveSettings.mediaState.filePath
									: "#"
							}
							target="_blank"
							rel="noreferrer"
						>
							Open Link in New Tab
							<Icon
								className="inline-block"
								icon="link"
								color={theme.colorCopyLightLight}
								width="20px"
								height="20px"
							/>
						</a>
					</a>
					{liveSettings.mediaState.brand && // using brand to store link username
						liveSettings.mediaState.title && // using title to store link password
						liveSettings.mediaState.filePath && (
							<StyledLinkUserContainer>
								<RenderLinkLoginInfo
									links={[
										{
											link: liveSettings.mediaState.filePath,
											username: liveSettings.mediaState.brand,
											password: liveSettings.mediaState.title,
										},
									]}
								/>
							</StyledLinkUserContainer>
						)}
				</StyledLinkContainer>
			);
			break;
		case LiveToolsMediaType.EntryTitleSlide:
			media = (
				<Container>
					{/* <BackgroundImage
            filePath={getThumbnailSrc(
              liveSettings.mediaState?.filePath || "",
              ThumbnailSize.X_LARGE
            )}
          /> */}
					<InnerContainer className="flex flex-col">
						<div className="grid grid-cols-2 w-[80%]">
							<ImgContainer>
								<OverlaidImage
									src={getThumbnailSrc(
										liveSettings.mediaState?.filePath || "",
										ThumbnailSize.X_LARGE
									)}
								/>
							</ImgContainer>
							<StyledTitleSlideContainer>
								<div className="p-[1.5rem]">
									<Brand>{liveSettings.mediaState.brand}</Brand>
									<Title className="playfair-500 pt-[1rem]">
										{liveSettings.mediaState.title}
									</Title>
									<StyledTitleSlideIdContainer>
										{liveSettings.mediaState.entryId}
									</StyledTitleSlideIdContainer>
								</div>
								<StyledTitleSlideBorder />
								<StyledTitleSlideBatchContainer className="p-[2.5rem] text-right">
									{liveSettings.mediaState.text &&
										liveSettings.mediaState.text
											.split(">")
											.map((level, index) => (
												<Batch key={index}>{level}</Batch>
											))}
									{/* <Batch>{liveSettings.mediaState.text}</Batch> */}
								</StyledTitleSlideBatchContainer>
							</StyledTitleSlideContainer>
						</div>
						<StyledTitleSlideIconContainer>
							<div className="grid grid-cols-4 gap-x-[1rem] gap-y-[.5rem] items-center justify-space-between">
								<Icon
									icon="eye"
									color={theme.colorCopyLightLight}
									width="45px"
									height="45px"
								/>
								<Icon
									icon="video-play"
									color={theme.colorCopyLightLight}
									width="32px"
									height="32px"
								/>
								<Icon
									icon="audio-play"
									color={theme.colorCopyLightLight}
									width="40px"
									height="40px"
								/>
								<Icon
									icon="doc-play"
									color={theme.colorCopyLightLight}
									width="35px"
									height="35px"
								/>
								<StyledTitleSlideIconText>
									{liveSettings.mediaState.imageCount || 0}
								</StyledTitleSlideIconText>
								<StyledTitleSlideIconText>
									{liveSettings.mediaState.videoCount || 0}
								</StyledTitleSlideIconText>
								<StyledTitleSlideIconText>
									{liveSettings.mediaState.audioCount || 0}
								</StyledTitleSlideIconText>
								<StyledTitleSlideIconText>
									{liveSettings.mediaState.documentCount || 0}
								</StyledTitleSlideIconText>
							</div>
							{liveSettings.mediaState.awardLevel &&
								showAward(liveSettings.settings) &&
								liveSettings.mediaState.awardLevel !== "Unvoted" && (
									<StyledTitleSlideScoreIndicator
										background={voteButtonColor(
											liveSettings.mediaState.awardLevel,
											theme
										)}
									>
										<span>{liveSettings.mediaState.awardLevel}</span>
									</StyledTitleSlideScoreIndicator>
								)}
						</StyledTitleSlideIconContainer>
						{/* <ImgContainer>
              <OverlaidImage
                src={getThumbnailSrc(
                  liveSettings.mediaState?.filePath || "",
                  ThumbnailSize.X_LARGE
                )}
              />
            </ImgContainer>

            <BlackRectangle></BlackRectangle>
            <TextContainer>
              <Brand>{liveSettings.mediaState.brand}</Brand>
              <Title className="playfair-500">
                {liveSettings.mediaState.title}
              </Title>
              <Batch>{liveSettings.mediaState.text}</Batch>
            </TextContainer>
            {liveSettings.mediaState.awardLevel && (
              <ScoreIndicator
                background={voteButtonColor(
                  liveSettings.mediaState.awardLevel,
                  theme
                )}
              >
                <span>{liveSettings.mediaState.awardLevel}</span>
              </ScoreIndicator>
            )} */}
					</InnerContainer>
				</Container>
			);
			break;
		case LiveToolsMediaType.DefinitionSlide:
			media = (
				<DarkPageContainer>
					<CenteredContainer>
						{/* <ModalCard title={liveSettings.mediaState.title}>
              <div className="p-[2rem]">
                <p className="">{liveSettings.mediaState.text}</p>
              </div>
            </ModalCard> */}
						<StyledDefinitionSlideContainer>
							<StyledDefinitionSlideTitle>
								<h2>{liveSettings.mediaState.title}</h2>
							</StyledDefinitionSlideTitle>
							{liveSettings.mediaState.text && (
								<StyledDefinitionSlideText>
									{parse(liveSettings.mediaState.text)}
								</StyledDefinitionSlideText>
							)}
						</StyledDefinitionSlideContainer>
						{/* <StyledTermContainer>
              <StyledTermTitle>{liveSettings.mediaState.title}</StyledTermTitle>
              <StyledTermLine />
              <StyledTermDefinition
                className="whitespace-pre-wrap"
                dangerouslySetInnerHTML={{
                  __html: [liveSettings.mediaState.text].join(""),
                }}
              />
            </StyledTermContainer> */}
					</CenteredContainer>
				</DarkPageContainer>
			);
			break;
		case LiveToolsMediaType.Document:
			media = (
				<a
					href={
						(process.env.REACT_APP_S3_HOST ?? "") +
						(liveSettings.mediaState.filePath ?? "")
					}
					target="_blank"
					rel="noopener noreferrer"
					className="text-colorCopyLightLight text-center"
				>
					<Image
						key={liveSettings.mediaState.id}
						src={getPdfThumbnail(liveSettings.mediaState.filePath!)}
						placeholder={DocumentPlaceholder}
						alt={liveSettings.mediaState.title || "Document Preview"}
						lazy
					/>
					<span className="flex justify-center items-center gap-[.5rem] mt-[1rem] text-[1.5rem]">
						Open Document in a New Tab{" "}
						<Icon
							className="inline-block"
							icon="link"
							color={theme.colorCopyLightLight}
							width="20px"
							height="20px"
						/>
					</span>
				</a>
			);
			break;
		default:
			if (isLoading) {
				media = <p>Loading...</p>;
			} else if (pauseSlide) {
				media = (
					<img
						src={process.env.REACT_APP_S3_HOST + pauseSlide}
						alt="Paused"
						className="h-full max-h-full object-contain mx-auto"
					/>
				);
			} else {
				media = (
					<img
						src={process.env.REACT_APP_S3_HOST + settings.pauseSlidePath!}
						alt="Paused"
						className="h-full max-h-full object-contain mx-auto"
					/>
				);
			}
	}

	return (
		<DarkPageContainer
			key={liveSettings.mediaState.filePath}
			className="flex justify-center items-center w-[100vw] h-[100vh]"
		>
			<BrowserHeader title={`Media Viewer: ${liveSettings.juryCard.name}`} />
			{media}
		</DarkPageContainer>
	);
};

interface LinkField {
	links: LinkSet[];
}
export default MediaViewer;
