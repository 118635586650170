import React, { cloneElement, useEffect, useRef, useState } from "react";
import { DraggableList } from "../../views/Admin/Media/NewMediaLibrarySticky";
import { DragItem } from "../Draggable/Draggable";

// HOC that render items as they come into scroll view
const VirtualizeList = <T extends DragItem>(props: VirtualizeListProps<T>) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const [virtualList, setVirtualList] = useState(props.list);

	const virtualize = (scrollTop: number) => {
		const displayedItems = Math.ceil(
			(props.height + scrollTop) / props.itemHeight
		);
		setVirtualList(props.list.slice(0, displayedItems + 2));
	};

	const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
		virtualize(e.currentTarget.scrollTop);
	};

	useEffect(() => {
		if (ref.current) {
			virtualize(ref.current.scrollTop);
		}
	}, [props.list, props.itemHeight, props.height]);

	return (
		<DraggableList
			ref={ref}
			height={props.height}
			onScroll={(e) => handleScroll(e)}
		>
			{props.children(virtualList)}
		</DraggableList>
	);
};

export default VirtualizeList;

interface VirtualizeListProps<T> {
	height: number;
	itemHeight: number;
	list: T[];
	children(list: T[]): React.ReactNode;
}
