import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { getProgramChildrenByParentId } from "../../../Checkout/manageCheckout";
import { HierarchySelection } from "../../../../components/HierarchyDropDownModal/HierarchyDropDown";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import MultiDropdown from "../../../../components/MultiDropdown/MultiDropdown";
import axios from "axios";
import { urlPrograms } from "../../../../endpoints";
import { DropdownOptions } from "../../../../components/FormFields/DropdownField";
import { HierarchySearch } from "./AdvancedSearchFields";
import { NonFormikToggleSwitch } from "../../../../components/FormFields/ToggleSwitchField";

export function getNestedValue(obj: any, path: string): any {
	// Convert array notation to dot notation and split the path
	const keys = path
		.replace(/\[(\d+)\]/g, ".$1") // Convert [0] to .0
		.split("."); // Split by dot notation

	// Traverse the object based on the path
	return keys.reduce((acc, key) => {
		// Convert key to number if it's a valid array index
		const parsedKey = isNaN(Number(key)) ? key : Number(key);

		return acc ? acc[parsedKey] : undefined;
	}, obj);
}

const HierarchyMultiselect = (props: HierarchyDropDownProps) => {
	const { values, setFieldValue } = useFormikContext<HierarchySearch>();
	const [options, setOptions] = useState<SearchHierarchySelection[]>([]);
	const [selection, setSelection] = useState<SearchHierarchySelection>();
	const [enableNegative, setEnableNegative] = useState<boolean>(false);

	const searchArr = getNestedValue(values, props.name);

	useEffect(() => {
		setOptions([]);
		setSelection(undefined);

		if (props.parentId !== null) {
			getProgramChildrenByParentId(props.parentId).then((response) => {
				if (response.status === 200) {
					if (response.data.length === 1) {
						setSelection(response.data[0]);
					} else {
						setOptions(response.data);
					}
				}
			});
		}
	}, [props.parentId]);

	useEffect(() => {
		const copy = [...searchArr];

		// add/remove negative key when toggle switch is clicked
		const currentLevel = copy[props.index];
		if (copy && currentLevel) {
			if (Array.isArray(currentLevel)) {
				const addedNegatives = currentLevel.map((level) => ({
					...level,
					isNegative: enableNegative,
				}));
				copy.splice(props.index, 1, addedNegatives);
			} else {
				const addedNegative = { ...currentLevel, isNegative: enableNegative };
				copy.splice(props.index, 1, addedNegative);
			}
		}

		// if negative is enabled for current level, remove child level search terms
		if (enableNegative) {
			if (copy && copy[props.index + 1]) {
				copy.splice(props.index + 1);
			}
		}

		setFieldValue(props.name, copy);
	}, [enableNegative]);

	return (
		<>
			{/* current level multiselect */}
			{options && options.length > 1 && (
				<div className="flex flex-col gap-[.5rem]">
					<NonFormikToggleSwitch
						id={`negative.hierarchySearch.${props.name}.${props.index}`}
						checked={enableNegative}
						onClick={() => setEnableNegative(!enableNegative)}
						label={"AND NOT IN"}
						small
					/>
					<MultiDropdown
						placeholder={options[0].currentLevelDisplayLabel || "Program"}
						options={options.map((option) => {
							return { label: option.name, value: String(option.id) };
						})}
						onChange={(selectedList) => {
							// const positiveArr = [...searchObj.positive];
							const copy = [...searchArr];
							// only 1 option is multiselected
							if (selectedList.length === 1) {
								const selected = options.find(
									(x) => selectedList[0].value == x.id.toString()
								);

								console.log(selected);
								if (selected && copy) {
									const newSelected = {
										...selected,
										isNegative: enableNegative,
									};
									setSelection(newSelected);

									copy.splice(props.index, 1, newSelected);
									copy.splice(props.index + 1);
									setFieldValue(props.name, copy);
								}

								// if (selected) {
								// 	const removedDuplicates = [
								// 		...new Set([...positiveArr, selected.id]),
								// 	];
								// 	setFieldValue(props.name, {
								// 		...searchObj,
								// 		positive: removedDuplicates,
								// 	});
								// }
							}
							// ALL options are removed in multiselect
							else if (selectedList.length === 0) {
								setSelection(undefined);
								if (copy && copy[props.index]) {
									const sliced = copy.slice(0, props.index);
									setFieldValue(props.name, sliced);
								}

								// setFieldValue(props.name, {
								// 	...searchObj,
								// 	positive: positiveArr,
								// });
							}
							// 2 or more options are multiselected
							else {
								setSelection(undefined);
								if (copy && copy[props.index]) {
									const selectedArr: SearchHierarchySelection[] =
										selectedList.flatMap((selectedItem) => {
											const selected = options.find(
												(x) => selectedItem.value == x.id.toString()
											);
											if (selected !== undefined) {
												return { ...selected, isNegative: enableNegative };
											} else return [];
										});
									copy.splice(props.index, copy.length, selectedArr);
									setFieldValue(props.name, copy);
								}

								// const selectedArr = selectedList.reduce<number[]>(
								// 	(arr, selectedItem) => {
								// 		const selected = options.find(
								// 			(x) => selectedItem.value == x.id.toString()
								// 		);
								// 		if (selected !== undefined) {
								// 			arr.push(selected.id);
								// 		}

								// 		return arr;
								// 	},
								// 	[]
								// );

								// const removedDuplicates = [
								// 		...new Set([...positiveArr, selected.id]),
								// 	];

								// setFieldValue(props.name, {
								// 	...searchObj,
								// 	positive: [...positiveArr, ...selectedArr],
								// });
							}
						}}
					/>
				</div>
			)}

			{/* child level multiselect */}
			{!enableNegative && selection && !selection.isExecution && (
				<HierarchyMultiselect
					name={props.name}
					parentId={selection.id}
					index={props.index + 1}
				/>
			)}
		</>
	);
};

export default HierarchyMultiselect;

interface HierarchyDropDownProps {
	parentId: number | null;
	name: string;
	index: number;
}

export interface SearchHierarchySelection extends HierarchySelection {
	isNegative?: boolean;
}
