import { useState, useEffect, useContext } from "react";
import { getClaims, getToken } from "../components/Auth/handleJWT";
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from "@microsoft/signalr";
import { claim } from "../components/Auth/auth.models";

export type Notification = {
  id: string;
  type: string;
  action: NotificationAction;
  data: any;
};

export enum NotificationAction {
  Notification,
  Add,
  Delete,
  Update,
}

export type UseNotificationHubReturnType = {
  connection: HubConnection | null;
  hubNotifications: Array<Notification>;
  sendNotification: (groupName: string, notification: Notification) => void;
  joinGroup: (groupName: string, connection: HubConnection) => void;
  leaveGroup: (groupName: string, connection: HubConnection) => void;
  isConnected: boolean;
};

export function useNotificationHub(
  claims: claim[]
): UseNotificationHubReturnType {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [hubNotifications, setHubNotifications] = useState<Array<Notification>>(
    []
  );
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const userId = claims.find((claim) => claim.name === "userId")?.value;

  useEffect(() => {
    if (userId) {
      const createConnection = async () => {
        const hubConnection = new HubConnectionBuilder()
          .withUrl(`${process.env.REACT_APP_BACKEND_URL}/notificationHub`, {
            accessTokenFactory: () => getToken() || "",
            transport: HttpTransportType.WebSockets,
          })
          .withAutomaticReconnect([
            0, 2000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
            10000,
          ])
          .build();

        // hubConnection.on("ReceiveNotification", (notification: Notification) => {
        //   console.log("Got a Notification", notification);

        //   setNotifications((prev) => [...prev, notification]);
        // });

        hubConnection.onreconnected(() => setIsConnected(true));
        hubConnection.onclose(() => setIsConnected(false));

        try {
          await hubConnection.start();
          setIsConnected(true);
        } catch (error) {
          console.error("Error starting SignalR connection: ", error);
        }

        setConnection(hubConnection);
      };

      createConnection();

      // Cleanup on component unmount
      return () => {
        if (connection) {
          connection.stop();
        }
      };
    }
  }, [userId]);

  const sendNotification = async (
    groupName: string,
    notification: Notification
  ) => {
    if (connection) {
      try {
        await connection.invoke(
          "SendNotificationToGroup",
          groupName,
          notification
        );
      } catch (error) {
        console.error("Error sending notification: ", error);
      }
    }
  };

  const joinGroup = async (groupName: string, connection: HubConnection) => {
    if (connection) {
      try {
        await connection.invoke("JoinNotificationGroup", groupName);
      } catch (error) {
        console.error("Error joining group: ", error);
      }
    }
  };

  const leaveGroup = async (groupName: string, connection: HubConnection) => {
    if (connection) {
      try {
        await connection.invoke("LeaveNotificationGroup", groupName);
      } catch (error) {
        console.error("Error leaving group: ", error);
      }
    }
  };

  return {
    connection,
    hubNotifications: hubNotifications,
    sendNotification,
    joinGroup,
    leaveGroup,
    isConnected,
  };
}
