import TextField from "./TextField";
import { useState, useEffect } from "react";
import styled, { css, useTheme } from "styled-components";
import { useFormikContext } from "formik";
import Icon from "../Icon/Icon";
import * as Yup from "yup";
import { fieldRequired } from "../FieldModal/FieldModal";
import { defaultBoxShadow } from "../../assetsConfig";

const StyledHoverMenuValidation = styled.div<{
  show: boolean;
  popUpBottom?: boolean;
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  //min-width: 350px;
  /* min-width: 300px; */
  width: 300px;
  left: 105%;
  top: 0px;

  @media (max-width: 1160px) {
    top: 70px;
    left: 5%;
  }

  ${(p) =>
    p.popUpBottom &&
    css`
      top: 70px;
      left: 5%;
    `}

  /* right: 10%; */
  background: ${({ theme }) => theme.colorBackgroundLightLight};
  z-index: 999;
  box-shadow: ${defaultBoxShadow};

  ${(p) =>
    !p.show &&
    css`
      display: none;
    `}

  &:before {
    content: "";
    position: absolute;

    width: 100%;
    height: 100%;

    background: transparent;
  }
  /* caret left */
  &:after {
    content: "";
    position: absolute;
    right: 304px;
    /* top: 5px; */
    bottom: 100%;
    width: 10px;
    height: 20px;
    transform: rotate(270deg);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${({ theme }) => theme.colorBackgroundLightLight};

    @media (max-width: 1160px) {
      right: 270px;
      top: none;
      transform: rotate(360deg);
    }
    @media (min-width: 1160px) {
      top: 5px;
    }
  }

  &.hover-menu-enter {
    opacity: 0;
  }
  &.hover-menu-enter-active {
    opacity: 1;
    transition: all 150ms;
  }
  &.hover-menu-exit {
    opacity: 1;
  }
  &.hover-menu-exit-active {
    opacity: 0;
    transition: opacity 150ms;
  }
  .validation_styles {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .validation_container {
    margin: 20px;
  }
  h6 {
    color: ${({ theme }) => theme.colorCopyLight};
    margin-bottom: 1rem;
    font-weight: bold;
  }
  span {
    color: ${({ theme }) => theme.colorCopyLight};
  }
`;

export const PasswordValidation = {
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})/,
      "Password doesn't meet the requirements"
    )
    .min(8, "At least 8 characters required")
    .required(fieldRequired),
  passwordConfirm: Yup.string()
    // .min(8, "At least 8 characters required")
    .required(fieldRequired)
    .oneOf([Yup.ref("password")], "Password doesn't match."),
};

const PasswordValidateField = (props: PasswordFieldProps) => {
  const { values } = useFormikContext<any>();
  const [textBoxFocused, setTextBoxFocused] = useState(false);
  const theme = useTheme();
  const [hoverMenu, setHoverMenu] = useState(false);
  const [isMinLength, setMinLength] = useState(false);
  const [isUppercase, setUppercase] = useState(false);
  const [isLowerCase, setLowercase] = useState(false);
  const [isSpecial, setSpecial] = useState(false);
  const [isNumber, setNumber] = useState(false);

  useEffect(() => {
    const value = props.value || values[props.name];

    if (value !== undefined) {
      if (value.length >= 8) {
        setMinLength(true);
      } else {
        setMinLength(false);
      }

      if (/[!@#$%^&*.]+/.test(value)) {
        setSpecial(true);
      } else {
        setSpecial(false);
      }

      if (/[A-Z]+/.test(value)) {
        setUppercase(true);
      } else {
        setUppercase(false);
      }
      if (/[a-z]+/.test(value)) {
        setLowercase(true);
      } else {
        setLowercase(false);
      }

      if (/[0-9]+/.test(value)) {
        setNumber(true);
      } else {
        setNumber(false);
      }
    }
  }, [values[props.name], props.value]);

  // triggers when textfield blurs or focuses
  useEffect(() => {
    // only show popup on focused
    if (textBoxFocused) {
      setHoverMenu(true);
    } else {
      setHoverMenu(false);
    }
  }, [textBoxFocused]);

  return (
    <div className="relative">
      <StyledHoverMenuValidation
        show={hoverMenu}
        popUpBottom={props.popUpBottom ? true : false}
      >
        <div className="validation_container">
          <div>
            <h6 className="tracking-[.05rem]">REQUIREMENTS</h6>
          </div>
          <div className="validation_styles">
            <Icon
              className="check"
              icon="check"
              color={isMinLength ? "green" : theme.colorCopyLight}
            ></Icon>
            <p>Minimum of 8 characters</p>
          </div>

          <div className="validation_styles">
            <Icon
              className="check"
              icon="check"
              color={isUppercase ? "green" : theme.colorCopyLight}
            ></Icon>
            <p>Contains an uppercase letter</p>
          </div>

          <div className="validation_styles">
            <Icon
              className="check"
              icon="check"
              color={isLowerCase ? "green" : theme.colorCopyLight}
            ></Icon>
            <p>Contains a lowercase letter</p>
          </div>

          <div className="validation_styles">
            <Icon
              className="check"
              icon="check"
              color={isNumber ? "green" : theme.colorCopyLight}
            ></Icon>
            <p>
              Contains a number <span>(0-9)</span>{" "}
            </p>
          </div>
          <div className="validation_styles">
            <Icon
              className="check"
              icon="check"
              color={isSpecial ? "green" : theme.colorCopyLight}
            ></Icon>
            <p>
              Contains a special character <span>(!@#$%^&*.)</span>
            </p>
          </div>
        </div>
      </StyledHoverMenuValidation>

      <TextField
        type="password"
        name={props.name}
        id={`passwordValidateField-${props.name}`}
        placeholder={props.placeholder}
        // onFocus={() => setTextBoxFocused(true)}
        // onBlur={() => setTextBoxFocused(false)}
        setTextBoxFocused={setTextBoxFocused}
      />
    </div>
  );
};

export default PasswordValidateField;

interface PasswordFieldProps {
  name: string;
  placeholder: string;
  value?: string;
  popUpBottom?: boolean;
}
