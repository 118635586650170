import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DragCard } from "../DragAccordion/DragMediaAccordion";
import { useTheme } from "styled-components";
import {
	EntryMediaField,
	EntryTextField,
	EntryDateField,
	EntryListField,
	EntryLinkField,
	EntryIndividualCreditField,
	EntryCompanyCreditField,
	EntryPhysicalComponentField,
	ProgramModel,
} from "../../views/Admin/Program/ProgramInterfaces";
import Icon from "../Icon/Icon";

export const ItemTypes = {
	CARD: "card",
};

export const ExecutionDragCard: FC<CardProps> = ({
	id,
	index,
	title,
	executionFields,
	moveExecutionForm,
	onClickDelete,
	disabled,
}) => {
	const theme = useTheme();
	const ref = useRef<HTMLDivElement>(null);
	const [{ handlerId }, drop] = useDrop<
		DragItem,
		void,
		{ handlerId: Identifier | null }
	>({
		accept: ItemTypes.CARD,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveExecutionForm(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.CARD,
		item: () => {
			return { id, index };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = isDragging ? 0 : 1;
	drag(drop(ref));

	// retrieve field values
	const datePublic = executionFields.find((field) =>
		field.hasOwnProperty("startDate")
	);
	const coverImg = executionFields.find((field) =>
		field.hasOwnProperty("mediaItems")
	);

	const scrollToElement = (elem: number) => {
		const targetElement = document.getElementById(elem.toString());
		const offsetHeight =
			document.getElementById("entryDetailPanel")?.offsetHeight || 0;
		if (targetElement) {
			const elementRect = targetElement.getBoundingClientRect();

			window.scrollTo({
				top: elementRect.top + window.scrollY - offsetHeight,
				behavior: "smooth",
			});
		}
	};

	return (
		// <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
		// 	{text}
		// 	{executionTitle}
		// </div>

		<DragCard
			className="drag-card"
			ref={ref}
			style={{ opacity }}
			data-handler-id={handlerId}
			onDragStart={(e) => disabled && e.preventDefault()}
			disabled={disabled}
		>
			<div className="credit-info">
				<div className="flex items-center">
					<Icon
						icon="drag-arrows"
						color={theme.colorPrimary}
						className="flex items-center cursor-pointer mr-[1.875rem]"
						readonly={disabled}
					/>
					{/* <img
    					className="w-[85px] h-[50px] object-cover mr-[1.875rem]"
    					src={(coverImg as EntryMediaField)?.mediaItems[0]?.path || ""}
    					alt="cover Image"
    				/> */}
					<div className="flex flex-col">
						<p className="font-semibold !w-[300px]">{title}</p>
						{/* <p className="!w-[300px]">
    						<span className="italic">Public Date: </span>
    						{datePublic
    							? getLocalTime((datePublic as EntryDateField).startDate)
    							: "Not Selected"}
    					</p> */}
					</div>
				</div>

				<div className="flex items-center">
					<Icon
						icon="edit"
						color={theme.colorPrimary}
						className="flex items-center mr-[1.875rem]"
						onClick={() => {
							scrollToElement(id);
						}}
						width="20px"
						height="20px"
						readonly={disabled}
					/>

					<Icon
						icon="close"
						color={theme.colorPrimary}
						className="flex items-center"
						onClick={onClickDelete}
						width="30px"
						height="30px"
						readonly={disabled}
					/>
				</div>
			</div>
		</DragCard>
	);
};

export default ExecutionDragCard;

interface CardProps {
	index: number;
	id: number;
	title: string;
	executionFields: (
		| EntryMediaField
		| EntryTextField
		| EntryDateField
		| EntryListField
		| EntryLinkField
		| EntryPhysicalComponentField
	)[];
	moveExecutionForm: (dragIndex: number, hoverIndex: number) => void;
	onClickDelete: () => void;
	disabled?: boolean;
}

export interface DragItem {
	index: number;
	id: string;
	type: string;
}
