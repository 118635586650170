import { combineReducers } from "redux";
import alertReducer from "../components/Alert/alertReducer";
import settingsReducer from "../components/Settings/settingsReducer";
import notificationsReducer from "../components/Notification/notificationReducer";

const rootReducer = combineReducers({
	alerts: alertReducer,
	settings: settingsReducer,
	notifications: notificationsReducer,
});

export default rootReducer;
