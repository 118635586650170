import styled, { css, useTheme } from "styled-components";
import { UserNotification } from "./types";
import { Circle } from "../ProgressCircle/ProgressCircle";
import { useHistory } from "react-router-dom";
import { getLocalTime } from "../../utils/timeUtilities";
import addEllipsisAfterLetterCount from "../../utils/addEllipsisAfterLetterCount";
import VirtualizeList from "../VirtualizeList/VirtualizeList";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { updateNotification } from "./actions";
import axios from "axios";
import { urlNotification } from "../../endpoints";

export const NotificationListContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-height: 60vh;
	overflow-y: auto;
	padding: 1rem;
	width: 500px;
	max-width: 75vw;
`;

const StyledNotificationItem = styled.div<{
	notificationStyle: NotificationStyle;
}>`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 1rem;
	cursor: pointer;
	transition: all 150ms ease;

	.notification-title {
		font-weight: ${({ theme }) => theme.fontSemiBold};
	}

	.notification-message {
		white-space: pre-wrap;
	}

	.notification-meta {
		font-style: italic;

		font-size: ${({ theme }) => theme.xSmallSize};
	}

	.notification-link {
		text-decoration: underline;
		cursor: pointer;

		font-size: ${({ theme }) => theme.xSmallSize};
	}

	@media only screen and (min-width: ${({ theme }) => theme.md}) {
		.notification-meta,
		.notification-link {
			white-space: nowrap;
		}
	}

	${(p) =>
		p.notificationStyle === NotificationStyle.Light
			? css`
					color: ${({ theme }) => theme.colorCopyDarkDark};

					&:hover {
						background: ${({ theme }) => theme.colorBackgroundLight};
					}

					.notification-meta {
						color: ${({ theme }) => theme.colorCopyDark};
					}
			  `
			: p.notificationStyle === NotificationStyle.Dark
			? css`
					color: ${({ theme }) => theme.colorCopyLightLight};

					&:hover {
						background: ${({ theme }) => theme.colorBackgroundDark};
					}

					.notification-meta {
						color: ${({ theme }) => theme.colorCopyLight};
					}
			  `
			: ""}
`;

function capitalizeFirstLetterOfEachWord(str: string) {
	return str
		.split(" ") // Split the string by spaces
		.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter
		)
		.join(" "); // Join the words back into a single string
}

async function markNotificationAsRead(id: number) {
	try {
		await axios.patch(`${urlNotification}/${id}/read`);
	} catch (error: any) {
		// Handle error
	}
}

const NotificationList = (props: NotificationListProps) => {
	const { notificationArr, height, itemHeight, notificationStyle } = props;

	return notificationArr && notificationArr.length > 0 ? (
		<VirtualizeList
			list={notificationArr}
			height={height}
			itemHeight={itemHeight}
		>
			{(virtualList) =>
				virtualList.map((notification) => {
					return (
						<NotificationItem
							notification={notification}
							notificationStyle={notificationStyle}
						/>
					);
				})
			}
		</VirtualizeList>
	) : (
		<p
			className={
				notificationStyle === NotificationStyle.Dark
					? "text-colorCopyLightLight"
					: ""
			}
		>
			No notifications available
		</p>
	);
};

export const NotificationItem = (props: NotificationItemProps) => {
	const theme = useTheme();
	const history = useHistory();
	const { notification } = props;

	const handleReadNotification = () => markNotificationAsRead(notification.id);
	return (
		<StyledNotificationItem
			notificationStyle={props.notificationStyle}
			onClick={() => {
				handleReadNotification();
				window.open(notification.link);
				// history.push(notification.link);
			}}
		>
			<div className="flex justify-between">
				<span className="notification-title">
					{addEllipsisAfterLetterCount(
						capitalizeFirstLetterOfEachWord(notification.title),
						60
					)}
				</span>
				{!notification.isRead && (
					<Circle
						className="cursor-pointer !border-[7px]"
						color={theme.colorPrimary}
						size="7px"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							handleReadNotification();
						}}
					/>
				)}
			</div>
			{notification.message && (
				<span className="notification-message">
					{addEllipsisAfterLetterCount(notification.message, 50)}
				</span>
			)}
			<div className="flex gap-[.5rem] flex-wrap justify-between">
				<span className="notification-meta">
					{notification.createdDate &&
						`Sent ${getLocalTime(notification.createdDate, false)}`}
					{notification.senderName &&
						` by ${addEllipsisAfterLetterCount(notification.senderName, 40)}`}
				</span>

				<span
					className="notification-link"
					onClick={(e) => {
						// Prevent triggering the parent click
						e.stopPropagation();
						handleReadNotification();

						window.open(notification.link);
					}}
				>
					{notification.linkText}
				</span>
			</div>
		</StyledNotificationItem>
	);
};

const Notifications = (props: NotificationsProps) => {
	const { notifications } = useSelector(
		(state: RootState) => state.notifications
	);

	return (
		<NotificationListContainer>
			<NotificationList {...props} notificationArr={notifications} />
		</NotificationListContainer>
	);
};

interface NotificationsProps {
	height: number;
	itemHeight: number;
	notificationStyle: NotificationStyle;
}

interface NotificationListProps extends NotificationsProps {
	notificationArr: UserNotification[];
}
interface NotificationItemProps {
	notification: UserNotification;
	notificationStyle: NotificationStyle;
}

export enum NotificationStyle {
	Light,
	Dark,
}

export default Notifications;
