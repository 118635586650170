import assetsConfig, {
  lightTheme,
  darkTheme,
  defaultColors,
  lightThemeColors,
  gradients,
  hoverColors,
} from "./assetsConfig";
import { GlobalStyles } from "./globalStyles";
import { ThemeProvider } from "styled-components";
import Sidebar from "./components/Sidebar/Sidebar";
import { createContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import Register from "./views/Register/Register";
import routes from "./route-config";
import Login from "./views/Login/Login";
import configureValidations from "./Validations";
import { claim } from "./components/Auth/auth.models";
import { useEffect, useState } from "react";
import AuthenticationContext from "./components/Auth/AuthenticationContext";
import { getClaims, refreshTokenIfNeeded } from "./components/Auth/handleJWT";
import confugureInterceptor from "./utils/httpInterceptors";
import Authorized from "./components/Auth/Authorized";
import User from "./views/Admin/User/User";
import DashboardBody from "./components/DashboardBody/DashboardBody";
import CreditLibrary from "./views/Admin/Credits/CreditLibrary";
import MediaLibrary from "./views/Admin/Media/MediaLibrary";
import Checkout from "./views/Checkout/Checkout";
import EntrantPrograms from "./views/EntrantProgram/EntrantProgram";
import Payment from "./views/Checkout/Payment";
import RedirectToLandingPage from "./utils/RedirectToRoot";
import Logout from "./utils/Logout";
import { TransactionType } from "./views/Checkout/OrderInterfaces";
import EditEntry from "./views/Entries/EditEntry";
import EntrantSubprograms from "./views/EntrantProgram/EntrantSubprogram";
import ResetPassword from "./views/Login/ResetPassword";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import JudgingGallery from "./views/Judging/JudgingGallery";
import JudgeEntrySets from "./views/Judging/JudgeEntrySets";
import * as FullStory from "@fullstory/browser";
import Scoreboard from "./views/Judging/Scoreboard/Scoreboard";
import { LoadingOverlay } from "./components/Loading/Loading";
import MediaViewer from "./views/Admin/Judging/LiveTools/MediaViewer";
import { NewModal, NewModalProps } from "./components/Modal/Modal";
import { CreditType } from "./views/Admin/Program/ProgramInterfaces";
import { ENTRY_FORM_ROUTES } from "./views/Entries/EntryForm";
import TagLibrary from "./views/TagLibrary/TagLibrary";
import config from "./config";
// import TestComponents from "./views/TestComponents/TestComponents";
// import TestTimezones from "./views/TestComponents/TestTimezones";
import EntryDetailPage from "./views/Judging/EntryDetail/EntryDetail";
import JudgingRedirect from "./views/Admin/Judging/JudgingRedirect";
import { RootState } from "./store";
import FontLoader from "./utils/FontLoader";
import Alerts from "./components/Alert/Alerts";
import useHelpWidget from "./hooks/useHelpWidget";
import { useSettings } from "./hooks/UseSettings";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNotifications } from "./hooks/useNotifications";

FullStory.init({ orgId: "o-1JY9MT-na1" });

configureValidations();
confugureInterceptor();

export const LoadingContext = createContext<{
  loadingMessage: string | null;
  setLoadingMessage(value: string | null): void;
}>({
  loadingMessage: null,
  setLoadingMessage: (value) => {},
});

export const ModalContext = createContext<{
  modal: React.ReactNode | null;
  setModal(value: React.ReactNode | null): void;
}>({
  modal: null,
  setModal: (value) => {},
});

function App() {
  useHelpWidget();
  const { settings, loading } = useSelector(
    (state: RootState) => state.settings
  );
  const { settings: localSettings } = useSettings();

  const [claims, setClaims] = useState<claim[]>(getClaims());
  const [hasInitialized, setHasInitialized] = useState(false);

  useNotifications(claims);

  useEffect(() => {
    const emailClaim = claims.find((claim) => claim.name === "email");

    if (emailClaim) {
      const email = emailClaim.value;

      FullStory.identify(email, {
        displayName: email,
        email: email,
      });
    } else {
      FullStory.anonymize();
    }
  }, [claims]);

  useEffect(() => {
    async function initialize() {
      const updatedClaims = await refreshTokenIfNeeded();
      if (updatedClaims) {
        setClaims(updatedClaims);
      }
      setHasInitialized(true);
    }
    if (!hasInitialized) {
      setTimeout(() => {
        initialize();
      }, 500);
    }
  }, [hasInitialized]);

  // useEffect(() => {
  //   const prefetchImage = (url: string) => {
  //     const img = new Image();
  //     img.src = url;
  //   };

  //   prefetchImage(config.assets.logos.local);
  //   prefetchImage(config.assets.loading.primary);
  // }, []);

  const [theme, setTheme] = useState("light");
  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };
  const [modal, setModal] = useState<NewModalProps | null>(null);
  const [loadingMessage, setLoadingMessage] = useState<null | string>(null);

  useEffect(() => {
    if (Object.values(localSettings).length === 0) {
      setLoadingMessage("Retrieving Application Settings");
    } else {
      setLoadingMessage(null);
    }
  }, [localSettings]);

  // inject theme into CSS variables
  useEffect(() => {
    const themeVariables = {
      ...defaultColors,
      ...lightThemeColors,
      ...gradients,
      ...hoverColors,
    };

    const root = document.documentElement;

    Object.keys(themeVariables).forEach((color) => {
      root.style.setProperty(`--${color}`, (themeVariables as ITheme)[color]);
    });
  }, [theme]);

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <FontLoader
        fontURLs={[
          assetsConfig.colors.fontBrandSrc,
          assetsConfig.colors.fontHeadingSrc,
          assetsConfig.colors.fontCopySrc,
        ]}
      >
        <Alerts />

        <LoadingOverlay
          show={loadingMessage !== null}
          message={loadingMessage}
        />
        <GlobalStyles />
        {loading ? (
          <></>
        ) : (
          <DndProvider backend={HTML5Backend}>
            <BrowserRouter>
              <AuthenticationContext.Provider
                value={{ claims, update: setClaims }}
              >
                <ModalContext.Provider value={{ modal, setModal }}>
                  {modal !== null && <NewModal {...modal} />}
                  <LoadingContext.Provider
                    value={{ loadingMessage, setLoadingMessage }}
                  >
                    <Switch>
                      {/*               <Route exact path="/test-components">
		<Button
		  className="absolute bottom-[2rem] left-[2rem]"
		  onClick={() => toggleTheme()}
		>
		  Toggle Theme
		</Button>
		<TestComponents />
	  </Route>{" "} */}
                      {/* <Route exact path="/icon-display">
		<IconDisplay />
	  </Route> */}
                      {/* <Route exact path="/date">
									<TestDatePickerPage />
								</Route> */}

                      <Route exact path="/logout">
                        <Logout />
                      </Route>
                      <Route exact path="/login">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={
                            claims.find(
                              (c) => c.name === "role" && c.value === "admin"
                            ) ? (
                              <Redirect to={"/library/entries"} />
                            ) : (
                              <Redirect to="/" />
                            )
                          }
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/ResetPassword">
                        <ResetPassword />
                      </Route>
                      <Route exact path="/">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={
                            <EntrantPrograms metaDescription="Home" />
                          }
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/register">
                        <Register />
                      </Route>
                      <Route exact path="/judge/:juryId/:entrySetId">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Judging"
                          authorized={<JudgingGallery />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route
                        exact
                        path="/judge/:juryId/:entrySetId/:batchId/:entryId/:voteId"
                      >
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Judging"
                          authorized={<EntryDetailPage />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/media-viewer/:juryId">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Judging"
                          authorized={<MediaViewer />}
                          notAuthorized={<Redirect to="/" />}
                        />
                      </Route>

                      <Route exact path="/company">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<Redirect to="/" />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route path="/programs/:programId">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<EntrantSubprograms />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/programs">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<EntrantPrograms />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/judge/:juryId">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Judging"
                          authorized={<JudgeEntrySets />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/user-info">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<User tabIndex={0} />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/company-info">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<User tabIndex={1} />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/new-entry">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<EntrantPrograms />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      {/* {isAdmin ? (
									<Route
										exact
										path="/edit-entry"
										render={(props) => {
											<Authorized
												authorized={
													<Redirect
														to={`/admin/edit-entry${props.location.search}`}
													/>
												}
												notAuthorized={<Login />}
											/>;
										}}
									/>
								) : (
									<Route path={`/edit-entry/:tab?`}>
										<Authorized
											authorized={<EditEntry />}
											notAuthorized={<Login />}
										/>
									</Route>
								)} */}

                      <Route path={`/edit-entry/:tab?`}>
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<EditEntry />}
                          notAuthorized={<Login />}
                        />
                      </Route>

                      <Route exact path="/payment/Credit">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Commerce"
                          authorized={
                            <Payment transactionType={TransactionType.Credit} />
                          }
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/payment/Check">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Commerce"
                          authorized={
                            <Payment transactionType={TransactionType.Check} />
                          }
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/payment/Bank">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Commerce"
                          authorized={
                            <Payment transactionType={TransactionType.Bank} />
                          }
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/entries/draft-entries">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<Checkout tab="draft-entries" />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/entries/cart">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<Checkout tab="cart" />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/entries/orders">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Commerce"
                          authorized={<Checkout tab="orders" />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/entries/entries-search">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<Checkout tab="entries-search" />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/entries">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<Checkout />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/scoreboard/:juryId">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Judging"
                          authorized={<Scoreboard />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/credits/company">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={
                            <CreditLibrary creditType={CreditType.Company} />
                          }
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/credits/individual">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={
                            <CreditLibrary creditType={CreditType.Individual} />
                          }
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/media">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<MediaLibrary />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/tags">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          authorized={<TagLibrary />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Route exact path="/judgingredirect/:entryId">
                        <Authorized
                          settings={settings}
                          loading={loading}
                          feature="Judging"
                          authorized={<JudgingRedirect />}
                          notAuthorized={<Login />}
                        />
                      </Route>
                      <Authorized
                        settings={settings}
                        loading={loading}
                        role="admin"
                        authorized={
                          <div className="flex w-full">
                            <Sidebar />
                            <Switch>
                              {routes.map((route) => (
                                <Route
                                  key={route.path}
                                  path={route.path}
                                  exact={route.exact}
                                >
                                  <Authorized
                                    settings={settings}
                                    loading={loading}
                                    role={
                                      route.isSysAdmin
                                        ? "sysadmin"
                                        : route.isAdmin
                                        ? "admin"
                                        : ""
                                    }
                                    feature={route.feature}
                                    authorized={
                                      <DashboardBody id="dashboard-body">
                                        <route.component />
                                      </DashboardBody>
                                    }
                                  />
                                </Route>
                              ))}
                            </Switch>
                          </div>
                        }
                        notAuthorized={<RedirectToLandingPage />}
                      />
                    </Switch>
                  </LoadingContext.Provider>
                </ModalContext.Provider>
              </AuthenticationContext.Provider>
            </BrowserRouter>
          </DndProvider>
        )}
      </FontLoader>
    </ThemeProvider>
  );
}

export default App;

interface ITheme {
  [key: string]: string;
}
