import axios from "axios";
import { getClaims } from "../../components/Auth/handleJWT";
import { urlAwards, urlEntries, urlUserAdmin } from "../../endpoints";
import { PaymentStatus } from "../Checkout/OrderInterfaces";
import {
  EntryDetailsModel,
  EntryNote,
  EntryNoteType,
  RequestEmail,
} from "../Judging/EntryDetail/EntryDetail";
import { Audit, EntryModel, ExecutionModel } from "./EntryInterfacesComplete";
import { EntrantEntrySearchResult } from "../../components/EntrantEntrySearchCard/EntrantEntrySearchCard";
import { EntrantEntrySearchRequest } from "../Checkout/PreviousEntry/PrevEntriesTab";
import {
  EntryCompanyCreditField,
  EntryDateField,
  EntryIndividualCreditField,
  EntryLinkField,
  EntryListField,
  EntryMediaField,
  EntryPhysicalComponentField,
  EntryTextField,
} from "../Admin/Program/ProgramInterfaces";
import { EntrySearchSort } from "../Checkout/PreviousEntry/PrevEntriesSortFilter";
import { getTimezoneOffset } from "../../utils/timeUtilities";

function getCompany() {
  const claims = getClaims();
  return claims.find((claim) => claim.name === "company")?.value;
}

export async function getNewEntryByProgramId(programId: string) {
  try {
    const response = await axios.get<EntryModel>(
      `${urlEntries}/new?programId=${programId}`
    );
    return response;
  } catch (error: any) {
    // console.log(error);
    return error;
  }
}

export async function createExecution(entryId: number, programId: number) {
  try {
    const response = await axios.get<ExecutionModel>(
      `${urlEntries}/createExecution?entryId=${entryId}&programId=${programId}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getEntryById(entryId: number) {
  try {
    const response = await axios.get<EntryModel>(`${urlEntries}/${entryId}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getEntryDetailsForJuror(entryId: number, juryId: number) {
  try {
    const response = await axios.get<EntryDetailsModel>(
      `${urlEntries}/getEntryDetailsForJuror?entryId=${entryId}&juryId=${juryId}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getEntryNotes(entryId: number) {
  try {
    const response = await axios.get<Array<EntryNote>>(
      `${urlEntries}/getEntryNotes?entryId=${entryId}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function createEntryNote(entryId: number, note: EntryNote) {
  try {
    const response = await axios.post<EntryNote>(
      `${urlEntries}/createEntryNote?entryId=${entryId}`,
      note
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateEntryNote(
  entryNoteId: number,
  updatedNote: Partial<EntryNote>
) {
  try {
    const response = await axios.put(
      `${urlEntries}/updateEntryNote`,
      updatedNote
    );
    return response.data;
  } catch (error: any) {
    console.error("Error updating entry note:", error);
    throw error;
  }
}

export async function deleteEntryNote(entryNoteId: number) {
  try {
    const response = await axios.delete(
      `${urlEntries}/deleteEntryNote?entryNoteId=${entryNoteId}`
    );
    return response.data;
  } catch (error: any) {
    console.error("Error deleting entry note:", error);
    throw error;
  }
}

export async function getEntryNotesByType(
  entryId: number,
  entryNoteType: EntryNoteType
) {
  try {
    const response = await axios.get<Array<EntryNote>>(
      `${urlEntries}/getEntryNotesByType?entryId=${entryId}&entryNoteType=${entryNoteType}`
    );
    return response.data;
  } catch (error: any) {
    console.error("Error fetching entry notes by type:", error);
    throw error;
  }
}

export async function sendHelpEmailRequest(helpRequestMessage: RequestEmail) {
  try {
    const response = await axios.post(
      `${urlEntries}/sendHelpRequestEmail`,
      helpRequestMessage
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateEntry(entryId: number, entryData: EntryModel) {
  return await axios.put(`${urlEntries}/updateEntry/${entryId}`, entryData);
}

export async function markEntryAsChild(
  childEntryId: number,
  parentEntryId: number
) {
  try {
    const response = await axios.put(
      `${urlEntries}/markAsChild/${childEntryId}/${parentEntryId}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function removeParentRelationship(childEntryId: number) {
  try {
    const response = await axios.delete(
      `${urlEntries}/removeParentRelationship/${childEntryId}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function deleteExecution(executionId: number, companyId: number) {
  try {
    const response = await axios.delete(
      `${urlEntries}/deleteExecution/${executionId}?companyId=${companyId}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function addRevisionNote(entryId: number, revisionNote: string) {
  const data = {
    entryId: entryId,
    revisionNote: revisionNote,
  };

  return await axios.post<Audit>(`${urlEntries}/addRevisionNote`, data);
}

export async function updateHideMediaForWinnersGallery(
  mediaId: string,
  hideForWinnersGallery: boolean
) {
  const data = {
    id: mediaId,
    hideForWinnersGallery: hideForWinnersGallery,
  };

  return await axios.post(
    `${urlEntries}/updateHideMediaForWinnersGallery`,
    data
  );
}

export async function approveFlagMedia(
  entryId: number,
  mediaId: string,
  approve: boolean
) {
  const data = {
    entryId: entryId,
    mediaId: mediaId,
    approve: approve,
  };

  return await axios.post(`${urlEntries}/approveFlagMedia`, data);
}

export async function getDraftEntries() {
  const response = axios.get<EntryModel[]>(
    `${urlEntries}/getEntriesByPaymentStatus?companyId=${getCompany()}&paymentStatusId=${
      PaymentStatus.Incomplete
    }`
  );
  return response;
}

export async function entrantEntrySearch(
  filter: EntrantEntrySearchRequest,
  sortField: EntrySearchSort | null
) {
  const sort = sortField !== null ? sortField : 0;
  const direction = sortField === null ? 0 : sortField === 1 ? 0 : 1;

  const response = await axios.post<EntrantEntrySearchResult[]>(
    `${urlEntries}/entrantEntrySearch?sortField=${sort}&sortDirection=${direction}`,
    filter,
    {}
  );

  return response;
}

export async function entrantEntryExport(
  filter: EntrantEntrySearchRequest,
  sortDirection: number | null
) {
  let direction = sortDirection == 1 ? 0 : 1;

  // Get the timezone offset and append it to the query parameters
  const timezoneOffset = getTimezoneOffset();

  const response = await axios.post(
    `${urlEntries}/entrantEntrySearch/export?sortField=0&sortDirection=${direction}&timezoneOffset=${timezoneOffset}`,
    filter,
    { responseType: "blob" }
  );
  return response;
}

export async function changePaymentStatus(
  entryId: number,
  newStatusId: number
) {
  const response = axios.get(
    `${urlEntries}/setPaymentStatus/${entryId}?paymentStatusId=${newStatusId}`
  );
  return response;
}

export async function changeReviewStatus(entryId: number, newStatusId: number) {
  const response = axios.get(
    `${urlEntries}/setReviewStatus/${entryId}?reviewStatusId=${newStatusId}`
  );
  return response;
}

export async function getAwardableAwards() {
  const response = axios.get(`${urlAwards}/getAwardableAwards`);
  return response;
}

export async function updateEntryProperty(request: UpdatePropertyRequest) {
  const response = axios.put(`${urlEntries}/property`, request);
  return response;
}

export async function updateExecutionProperty(request: UpdatePropertyRequest) {
  const response = axios.put(`${urlEntries}/execution/property`, request);
  return response;
}

export async function updateEntryField(request: any) {
  const formData = new FormData();
  formData.append("jsonField", JSON.stringify(request) || "");

  const response = axios.put(`${urlEntries}/field`, formData);
  return response;
}

export async function updateEntryCreditField(request: any) {
  const formData = new FormData();
  formData.append("jsonField", JSON.stringify(request) || "");
  const response = axios.put(`${urlEntries}/creditField`, formData);
  return response;
}

export async function setIsCampaign(entryId: number, isCampaign: boolean) {
  const response = axios.get(
    `${urlEntries}/setIsCampaign/${entryId}?isCampaign=${isCampaign}`
  );
  return response;
}

export async function markNotificationsAsRead(entryId: number) {
  const response = axios.put(
    `${urlEntries}/markNotificationsAsRead/${entryId}`
  );
  return response;
}

export async function changeExecutionProgram(
  entryId: number,
  companyId: number,
  newExecutionProgramId: number,
  executions: ExecutionModel[]
) {
  const request = {
    entryId: entryId,
    newExecutionProgramId: newExecutionProgramId,
    executions: executions,
  };
  return await axios.post<ExecutionModel[]>(
    `${urlEntries}/changeExecutionProgram?companyId=${companyId}`,
    request
  );
}

type CampaignFieldType =
  | EntryMediaField
  | EntryTextField
  | EntryDateField
  | EntryListField
  | EntryLinkField
  | EntryPhysicalComponentField;

type CreditFieldType = EntryCompanyCreditField | EntryIndividualCreditField;

type EntryFieldType = CampaignFieldType | CreditFieldType;

export function sortEntryFields(
  fields: CampaignFieldType[]
): CampaignFieldType[];
export function sortEntryFields(fields: CreditFieldType[]): CreditFieldType[];

export function sortEntryFields(fields: EntryFieldType[]): EntryFieldType[] {
  const sortFields = (a: EntryFieldType, b: EntryFieldType) => {
    const fieldWeightA =
      a.programField?.fieldWeight !== undefined
        ? a.programField.fieldWeight
        : Number.MAX_SAFE_INTEGER;
    const fieldWeightB =
      b.programField?.fieldWeight !== undefined
        ? b.programField.fieldWeight
        : Number.MAX_SAFE_INTEGER;

    if (fieldWeightA !== fieldWeightB) {
      // Sort by fieldWeight in descending order (larger weights first)
      return fieldWeightB - fieldWeightA;
    }

    // For fields without fieldWeight, sort by createdDate in ascending order
    const createdDateA = a.programField?.createdDate
      ? new Date(a.programField.createdDate).getTime()
      : Infinity;
    const createdDateB = b.programField?.createdDate
      ? new Date(b.programField.createdDate).getTime()
      : Infinity;

    return createdDateA - createdDateB;
  };

  return fields.sort(sortFields);
}

export interface UpdatePropertyRequest {
  id: number;
  propertyName: string;
  propertyValue: any;
}
