import axios from "axios";
import { getClaims } from "../../../components/Auth/handleJWT";
import { urlCredits } from "../../../endpoints";
import { MediaCreditEntryInfoModel } from "../../MediaLibrary/mediaLibrary.model";
import { CompanyCredit, IndividualCredit } from "./CreditInterfaces";
import assetsConfig from "../../../assetsConfig";
import lowerCase from "../../../utils/lowerCase";

function getCompany() {
	const claims = getClaims();
	return claims.find((claim) => claim.name === "company")?.value;
}

export async function getActiveCompanyCredits(companyId: number) {
	return await axios.get<CompanyCredit[]>(
		`${urlCredits}/GetActiveCompanyCredits?companyId=${companyId}`
	);
}

export async function getCompanyCredits(companyId: number) {
	return await axios.get<CompanyCredit[]>(
		`${urlCredits}/GetCompanyCredits?companyId=${companyId}`
	);
}

export async function getActiveIndividualCredits(companyId: number) {
	return await axios.get<IndividualCredit[]>(
		`${urlCredits}/GetActiveIndividualCredits?companyId=${companyId}`
	);
}

export async function getIndividualCredits(companyId: number) {
	return await axios.get<IndividualCredit[]>(
		`${urlCredits}/GetIndividualCredits?companyId=${companyId}`
	);
}

export async function updateCompanyCredit(
	creditId: number,
	creditData: CompanyCredit
) {
	await axios
		.put(
			`${urlCredits}/PutCompanyCredit?id=${creditId}&companyId=${creditData.companyId}`,
			creditData
		)
		.then((response) => {
			if (response.status == 200) {
				return response;
			}
		})
		.catch((error: any) => {
			return error;
		});
}

export async function updateIndividualCredit(
	creditId: number,
	creditData: IndividualCredit
) {
	await axios
		.put(
			`${urlCredits}/PutIndividualCredit?id=${creditId}&companyId=${creditData.companyId}`,
			creditData
		)
		.then((response) => {
			if (response.status == 200) {
				return response;
			}
		})
		.catch((error: any) => {
			return error;
		});
}

export async function deleteCompanyCredit(
	value: CompanyCredit,
	linkedEntries: MediaCreditEntryInfoModel[]
) {
	if (!linkedEntries || linkedEntries.length > 0) {
		throw new Error(
			`${
				assetsConfig.labels.company.singular
			} Credits linked to active ${lowerCase(
				assetsConfig.labels.entry.plural
			)} can't be deleted.`
		);
	}

	return await axios.delete(
		`${urlCredits}/DeleteCompanyCredit/${value.id}${
			"?companyId=" + value.companyId
		}`
	);
}

export async function getLinkedEntriesByCompanyCreditId(
	companyId: number,
	companyCreditId: number
) {
	return await axios.get<MediaCreditEntryInfoModel[]>(
		`${urlCredits}/GetLinkedEntriesByCompanyCreditId/${companyCreditId}?companyId=${companyId}`
	);
}

export async function deleteIndividualCredit(
	value: IndividualCredit,
	linkedEntries: MediaCreditEntryInfoModel[]
) {
	if (!linkedEntries || linkedEntries.length > 0) {
		throw new Error(
			`Individual Credits linked to active ${lowerCase(
				assetsConfig.labels.entry.plural
			)} can't be deleted.`
		);
	}

	return await axios.delete(
		`${urlCredits}/DeleteIndividualCredit/${value.id}${
			"?companyId=" + value.companyId
		}`
	);
}

export async function getLinkedEntriesByIndividualCreditId(
	companyId: number,
	individualCreditId: number
) {
	return await axios.get<MediaCreditEntryInfoModel[]>(
		`${urlCredits}/GetLinkedEntriesByIndividualCreditId/${individualCreditId}?companyId=${companyId}`
	);
}

export async function addIndividualCredit(creditData: IndividualCredit) {
	return await axios.post(
		`${urlCredits}/PostIndividualCredit?companyId=${creditData.companyId}`,
		creditData
	);
}

export async function addCompanyCredit(creditData: CompanyCredit) {
	return await axios.post(
		`${urlCredits}/PostCompanyCredit?companyId=${creditData.companyId}`,
		creditData
	);
}

export function downloadCompanyCreditSampleCsvFile() {
	return downloadFile(
		`${urlCredits}/DownloadCompanyCreditsCsv`,
		"company_credits_template.csv"
	);
}

export function downloadIndividualCreditSampleCsvFile() {
	return downloadFile(
		`${urlCredits}/DownloadIndividualCreditsCsv`,
		"individual_credit_template.csv"
	);
}

function downloadFile(url: string, fileName: string) {
	axios
		.get(url, {
			responseType: "blob",
		})
		.then((response) => {
			const temp = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");

			link.href = temp;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();
		})
		.catch((error: any) => {
			return error;
		});
}

export const uploadCompanyCreditCsvFile = (companyId: number, file: File) => {
	return uploadFile(
		`${urlCredits}/UploadCompanyCreditsCsvFile?companyId=${companyId}`,
		file
	);
};

export const uploadIndividualCreditCsvFile = (
	companyId: number,
	file: File
) => {
	return uploadFile(
		`${urlCredits}/UploadIndividualCreditsCsvFile?companyId=${companyId}`,
		file
	);
};

function uploadFile(url: string, file: File) {
	let formData = new FormData();

	formData.append("file", file);

	return axios.post(url, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
}
