import styled, { useTheme } from "styled-components";
import Icon from "../../../components/Icon/Icon";
import Modal, { ModalCard } from "../../../components/Modal/Modal";
import TextField from "../../../components/FormFields/TextField";
import assetsConfig from "../../../assetsConfig";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import Button from "../../../components/Button/Button";
import { EntryNote } from "../../Judging/EntryDetail/EntryDetail";
import { getLocalTime } from "../../../utils/timeUtilities";
import { NemoUser } from "../../../hooks/useCurrentUser";
import { css } from "styled-components";
import { useAlert } from "../../../components/Alert/Alerts";
import { AdminEntryModel } from "../../Entries/EditEntry";
import { useEffect, useState } from "react";
import EditMenu from "../../../components/EditMenu/EditMenu";
import ReviewNoteBubble from "./ReviewNoteBubble";

const EditReviewNote = (props: EditReviewNoteProps) => {
  const formikProps = useFormik({
    initialValues: {
      editReviewNote: props.note.note,
    },
    onSubmit: async () => {},
    enableReinitialize: true,
  });
  return (
    <FormikProvider value={formikProps}>
      <TextField
        className="flex-1"
        name="editReviewNote"
        placeholder="Edit Review Note"
        component="textarea"
        height="118px"
        value={formikProps.values.editReviewNote}
        onChange={(e) => {
          props.setEditNote({ ...props.note, note: e.target.value });
        }}
      />
    </FormikProvider>
  );
};

export default EditReviewNote;

interface EditReviewNoteProps {
  note: EntryNote;
  setEditNote: React.Dispatch<React.SetStateAction<EntryNote | null>>;
}
