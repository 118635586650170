import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { defaultBoxShadow } from "../../assetsConfig";

const VerticalDots = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  height: fit-content;

  span {
    min-width: 4px;
    min-height: 4px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colorCopyLight};
  }
`;

const ActionMenu = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  top: 0;
  padding: 1rem;
  background: ${({ theme }) => theme.colorBackgroundLightLight};
  box-shadow: ${defaultBoxShadow};
  display: none;

  ${(p) =>
    p.show &&
    css`
      display: flex;
    `}
`;

const MenuIcon = (props: EditMenuProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showActions, setShowActions] = useState(false);

  const handleClickOutside = (event: any) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event?.target as Node)
    ) {
      setShowActions(false);
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  // 	console.log(showActions);
  // }, [showActions]);

  return (
    <div className="relative" ref={containerRef}>
      <div
        className={`flex justify-center items-center h-[35px] w-[30px] ${
          props.disabled ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        onClick={() => !props.disabled && setShowActions(true)}
      >
        <VerticalDots
          className={`${
            props.disabled ? "!cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          <span></span>
          <span></span>
          <span></span>
        </VerticalDots>
      </div>

      <ActionMenu show={showActions}>
        {props.options.map((option) => (
          <span
            className="cursor-pointer"
            onClick={() => {
              setShowActions(false);
              option.action();
            }}
          >
            {option.label}
          </span>
        ))}
      </ActionMenu>
    </div>
  );
};

const EditMenu = (props: EditMenuProps) => {
  return <MenuIcon {...props}></MenuIcon>;
};

export default EditMenu;

interface EditMenuProps {
  options: Array<{
    label: string;
    action(): void;
  }>;
  disabled?: boolean;
}
