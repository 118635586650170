import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { FormikProvider, useFormik } from "formik";
import Modal, { ModalCard } from "../../../components/Modal/Modal";
import Icon from "../../../components/Icon/Icon";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import Button from "../../../components/Button/Button";
import axios from "axios";
import {
	IStoredSearch,
	setStoredValue,
} from "../../../components/LocalStorageStorage/LocalStorageStore";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const TabModalCard = styled(ModalCard)`
	overflow-y: auto;
	div[role="tabpanel"] {
		overflow: auto;
	}
`;

const EntryExportColumnsModal = (props: EntryExportColumnsModalProps) => {
    const theme = useTheme();
    const [exportGroups, setExportGroups] = useState<EntryExportGroup[]>([]);
    const [selectedColumns, setSelectedColumns] = useState<EntryExportColumn[]>(props.selectedExportColumns || []);
    
    const { settings } = useSelector((state: RootState) => state.settings);

    useEffect(() => {
        loadEntryExportColumns();
    }, []);

    async function loadEntryExportColumns() {
        try {
            const response = await axios.get(props.getExportGroupsEndpoint);

            if (response.status === 200) {
                console.log("export groups", response.data);
                setExportGroups(response.data);
                
                const allColumns = response.data.flatMap((group: EntryExportGroup) => group.columns);
                
                // Filter columns based on feature flags
                const filteredColumns = filterColumnsByFeatureFlags(allColumns);
                
                // Store the filtered columns in local storage if needed
                if (props.localStorageKey) {
                    setStoredValue(props.localStorageKey, filteredColumns);
                }

                // Filter the selected columns to only include those that are visible
                const filteredSelectedColumns = selectedColumns.filter(selectedItem =>
                    filteredColumns.find(column => column.column === selectedItem.column)
                );

                // Set selected columns to the filtered ones
                setSelectedColumns(filteredSelectedColumns);
            }
        } catch (error: any) {
            console.error("Error loading export columns:", error);
        }
    }

    // Function to filter columns based on feature flags
    const filterColumnsByFeatureFlags = (columns: EntryExportColumn[]) => {
		return columns.filter((item: EntryExportColumn) => {
			// Exclude specific items when the feature flags are disabled
			if (!settings.featureFlags["Company Address"] &&
				(
					item.displayName === "Author Country" ||
					item.displayName === "Author Address 1" ||
					item.displayName === "Author Address 2" ||
					item.displayName === "Author City" ||
					item.displayName === "Author State" ||
					item.displayName === "Author Postal Code"
				)) {
				return false;
			}
			
			if (!settings.featureFlags["Company Agency Network"] && 
				item.displayName === "Special 1") {
				return false;
			}
			
			if (!settings.featureFlags["Company Holding Company"] && 
				item.displayName === "Special 2") {
				return false;
			}

			if (!settings.featureFlags["Entry Parent/Child"] && 
				item.displayName === "Child/Parent Entries") {
				return false;
			}
	
			return true; // Include all other items
		});
	};

    // Effect to handle changes in feature flags
    useEffect(() => {
        if (exportGroups.length > 0) {
            const allColumns = exportGroups.flatMap((group) => group.columns);
            const filteredColumns = filterColumnsByFeatureFlags(allColumns);
            
            // Filter the selected columns to only include those that are visible
            const filteredSelectedColumns = selectedColumns.filter(selectedItem =>
                filteredColumns.find(column => column.column === selectedItem.column)
            );

            setSelectedColumns(filteredSelectedColumns);
        }
    }, [settings.featureFlags, exportGroups]);

	function toggleColumn(item: EntryExportColumn) {
		setSelectedColumns((current) => {
			return selectedColumns.findIndex((s) => s.column == item.column) > -1
				? selectedColumns.filter((c) => c.column != item.column)
				: [...selectedColumns, item];
		});
	}

	const formikProps = useFormik({
		initialValues: {},
		onSubmit: async (value) => {},
	});

	return (
		<FormikProvider value={formikProps}>
			<Modal show={props.show}>
				<TabModalCard
					title="Export Columns"
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							onClick={props.onClickHide}
							width="35px"
							height="35px"
						/>
					}
					iconColor={theme.colorPrimary}
				>
					<div className="p-4">
						{exportGroups &&
							exportGroups.map((group, groupIndex) => (
								<div key={groupIndex}>
									<h3 className="mb-1">{group.group}</h3>
									<div className="grid grid-cols-3 mb-10">
										{group.columns.filter(item => {
                                            // Filter out columns based on the feature flags
                                            return filterColumnsByFeatureFlags([item]).length > 0;
                                        }).map((item, index) => {
											const checkBoxLabel =
												item.displayName === "Transaction Type"
													? "Payment Method"
													: item.displayName;
											return (
												<CheckboxField
													key={index}
													className="checkbox-field m-3"
													name={`multiSelect-${item.column}`}
													checked={
														selectedColumns.length > 0 &&
														selectedColumns.findIndex(
															(c) => c.column == item.column
														) > -1
													}
													onChange={() => toggleColumn(item)}
													placeholder={checkBoxLabel}
													nonFormik
												/>
											);
										})}
									</div>
								</div>
							))}
						<div className="flex justify-between">
							<div className="flex justify-normal space-x-6">
								<Button
									onClick={() => {
										const allColumns = exportGroups.flatMap(
											(group) => group.columns
										);
										setSelectedColumns(allColumns);
									}}
								>
									Select all
								</Button>
								<Button onClick={() => setSelectedColumns([])}>
									Clear selection
								</Button>
							</div>
							<div>
								<Button
									onClick={() => {
										props.onSelectionChange(selectedColumns);
										props.onClickHide();
									}}
									disabled={selectedColumns.length < 1}
								>
									Export
								</Button>
							</div>
						</div>
					</div>
				</TabModalCard>
			</Modal>
		</FormikProvider>
	);
};

export default EntryExportColumnsModal;

export interface EntryExportColumn {
	column: string;
	displayName: string;
	group: string;
}

export interface EntryExportGroup {
	group: string;
	columns: EntryExportColumn[];
}

export interface EntryExportColumnsModalProps {
	show: boolean;
	onClickHide(): void;
	selectedExportColumns: EntryExportColumn[];
	onSelectionChange(columns: EntryExportColumn[]): void;
	localStorageKey: IStoredSearch;
	getExportGroupsEndpoint: string;
}
