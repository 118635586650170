import FieldModal from "../../../../components/FieldModal/FieldModal";
import RadioField, {
	ListContainer,
} from "../../../../components/FormFields/RadioField";
import { DateFieldTemplate, ProgramDateField } from "../ProgramInterfaces";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import ToggleSwitchField from "../../../../components/FormFields/ToggleSwitchField";
import { fieldTemplateValidation } from "../../../../components/FieldModal/FieldModal";
import { fieldRequired } from "../../../../components/FieldModal/FieldModal";
import { DateType } from "../ProgramInterfaces";
import { newFieldInitialVal } from "../../../../components/FieldModal/FieldModal";
import { useEffect } from "react";
import DateTimeField from "../../../../components/FormFields/DateTimeField";
import DateField from "../../../../components/FormFields/DateField";

const DateFieldForm = (props: CustomFieldProps) => {
	const formikProps = useFormik({
		initialValues: {
			// populate existing field values for update form OR use new values for new field form
			...(props.formData ? { ...props.formData } : { ...newFieldInitialVal }),
			dateType: props.formData ? props.formData.dateType : DateType.Single,
			displayTime: props.formData ? props.formData.displayTime : false,
			minDate:
				props.formData && props.formData.minDate
					? props.formData.minDate
					: undefined,
			maxDate:
				props.formData && props.formData.maxDate
					? props.formData.maxDate
					: undefined,
			minDateTime:
				props.formData && props.formData.minDateTime
					? props.formData.minDateTime
					: undefined,
			maxDateTime:
				props.formData && props.formData.maxDateTime
					? props.formData.maxDateTime
					: undefined,
			isRequired: props.formData ? props.formData.isRequired : false,
		},
		onSubmit: async (value) => {},
		validationSchema: Yup.object({
			...fieldTemplateValidation,
			dateType: Yup.string().required(fieldRequired),
			minDate: Yup.string().when("displayTime", {
				is: false,
				then: Yup.string().typeError(fieldRequired).required(fieldRequired),
			}),
			maxDate: Yup.string()
				.when("displayTime", {
					is: false,
					then: Yup.string().typeError(fieldRequired).required(fieldRequired),
				})
				.test(
					"minMax-date",
					"End Date cannot be before Start Date",
					(value, context) => {
						const min = context.parent.minDate;
						const max = value;
						if (!min || !max) return true; // If dates are invalid, skip this test
						return max >= min;
					}
				)
				.test(
					"same-date",
					"End Date cannot be the same as the Start Date",
					(value, context) => {
						const min = context.parent.minDate;
						const max = value;
						if (!min || !max) return true; // If dates are invalid, skip this test
						return max != min;
					}
				),
			minDateTime: Yup.string().when("displayTime", {
				is: true,
				then: Yup.string().typeError(fieldRequired).required(fieldRequired),
			}),
			maxDateTime: Yup.string()
				.when("displayTime", {
					is: true,
					then: Yup.string().typeError(fieldRequired).required(fieldRequired),
				})
				.test(
					"minMax-dateTime",
					"End Date Time cannot be before Start Date Time",
					(value, context) => {
						const min = context.parent.minDateTime;
						const max = value;
						if (!min || !max) return true; // If dates are invalid, skip this test
						return max >= min;
					}
				)
				.test(
					"same-dateTime",
					"End Date Time cannot be the same as the Start Date Time",
					(value, context) => {
						const min = context.parent.minDateTime;
						const max = value;
						if (!min || !max) return true; // If dates are invalid, skip this test
						return max != min;
					}
				),
		}),
		validateOnChange: false,
		validateOnBlur: false,
	});

	return (
		<FormikProvider value={formikProps}>
			<FieldModal
				{...(props.formData && {
					formData: props.formData,
				})}
				formTitle="Date Field Template"
				hideModal={() => props.hideModal()}
				formType="date"
				isUpdateForm={props.formData ? true : false}
			>
				<div>
					<p className="mb-[1rem]">
						Will this display as a single date or a date range for entrants
					</p>
					<ListContainer name="dateType">
						<RadioField
							name="dateType"
							value={DateType.Single}
							label="Single Date"
							checked={formikProps.values.dateType === DateType.Single}
							{...(props.formData &&
								props.formData.dateType !== undefined && {
									disabled: true,
								})}
						/>
						<RadioField
							name="dateType"
							value={DateType.Range}
							label="Date Range"
							checked={formikProps.values.dateType === DateType.Range}
							// error={formikProps.errors["dateType"]}
							{...(props.formData &&
								props.formData.dateType !== undefined && {
									disabled: true,
								})}
							// disabled={true}
						/>
					</ListContainer>
				</div>
				<div>
					<p className="mb-[1rem]">Display Time?</p>
					<ToggleSwitchField
						id="displayTime"
						name="displayTime"
						checked={formikProps.values.displayTime}
					/>
				</div>

				{formikProps.values.displayTime ? (
					<>
						<DateTimeField
							name="minDateTime"
							value={formikProps.values.minDateTime}
							placeholder="Start Date Time"
						/>
						<DateTimeField
							name="maxDateTime"
							value={formikProps.values.maxDateTime}
							placeholder="End Date Time"
						/>
					</>
				) : (
					<>
						<DateField
							name="minDate"
							value={formikProps.values.minDate}
							placeholder="Start Date"
						/>

						<DateField
							name="maxDate"
							value={formikProps.values.maxDate}
							placeholder="End Date"
						/>
					</>
				)}

				<div>
					<p className="mb-[1rem]">Is Required?</p>
					<ToggleSwitchField
						id="isRequired"
						name="isRequired"
						checked={formikProps.values.isRequired}
					/>
				</div>
			</FieldModal>
		</FormikProvider>
	);
};

export default DateFieldForm;

interface CustomFieldProps {
	formData?: DateFieldTemplate | ProgramDateField;
	hideModal: () => void;
}
