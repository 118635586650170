import { useState, useEffect } from "react";
import UserMenu from "../Admin/User/UserMenu";
import styled, { useTheme } from "styled-components";
import { PatternBG } from "../../globalStyles";
import { NavTabList } from "../../components/NavTabs/NavTabs";
import { useHistory, useParams } from "react-router-dom";
import {
	Ancestor,
	ProgramDisplay,
	SubprogramCardModel,
} from "../Admin/Program/ProgramInterfaces";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import {
	getChildProgramDisplays,
	getProgramDisplayById,
	getProgramDisplays,
} from "../Admin/Program/manageProgramDisplays";
import SubProgramCard from "../../components/SubProgramCard/SubProgramCard";
import PrimaryLogo from "../../components/PrimaryLogo/PrimaryLogo";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import ParentProgramCard, {
	ParentProgramCardModel,
} from "../../components/ParentProgramCard/ParentProgramCard";

const PageContainer = styled.div`
	width: 100%;
	${PatternBG};
`;

const ParentProgramContainer = styled.div`
	width: 100%;
	padding: 0 3rem;
	margin: 6.09375rem auto;
	max-width: 1800px;
`;

const ProgramCardsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 4.375rem;
	column-gap: 1.25rem;
	flex-wrap: wrap;
	padding: 0 3rem;
	margin: 6.09375rem auto;
	max-width: 1800px;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, minmax(502px, 1fr));
	/* grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); */
`;

const AwardsDropdown = styled.div`
	display: flex;
	align-items: center;
	padding-right: 2rem;
	margin-left: 5rem;
	border-right: 1px solid ${({ theme }) => theme.colorBorder};
	cursor: pointer;

	h1 {
		color: ${({ theme }) => theme.colorCopyLightLight};
		margin-right: 2rem;
	}
`;

const EntrantSubprograms = () => {
	const theme = useTheme();
	const params = useParams<{ programId: string }>();
	const [subPrograms, setSubprograms] = useState<null | SubprogramCardModel[]>(
		null
	);
	const history = useHistory();
	const [breadcrumbs, setBreadcrumbs] = useState([
		{ label: "Programs", value: 0 },
	]);
	let breadcrumbArray = [{ label: "Programs", value: 0 }];
	const [parentProgramDisplay, setParentProgramDisplay] =
		useState<ParentProgramCardModel | null>(null);

	useEffect(() => {
		if (params && params.hasOwnProperty("programId")) {
			const programId = parseInt(params.programId);

			getChildProgramDisplays(programId).then((res) => {
				if (res.data != null || undefined) {
					const ancestry = res.data.ancestry;

					setSubprograms(res.data.subprogramCards);
					ancestry.map((ancestor: Ancestor) => {
						breadcrumbArray.push({
							label: ancestor.name,
							value: ancestor.id,
						});
					});
					window.scrollTo(0, 0);
					setBreadcrumbs(breadcrumbArray);

					// set parent program display
					setParentProgramDisplay({
						programId: ancestry[ancestry.length - 1].id,
						name: ancestry[ancestry.length - 1].name,
						description: ancestry[ancestry.length - 1].description || "",
						welcomeVideo: res.data.welcomeVideo,
						image: res.data.image,
						imageDisplaysForEntrants: res.data.imageDisplaysForEntrants,
					});
				} else {
					// console.log("enter program");
				}
			});
		}
	}, [params]);

	const handleClickBreadcrumb = (
		breadcrumb: {
			label: string;
			value?: string | number;
		},
		index: number
	) => {
		breadcrumb.value === 0 && history.push("/");
		breadcrumb.value !== 0 && history.push(String(breadcrumb.value));
	};

	return (
		<PageContainer>
			<BrowserHeader
				title={`Enter ${breadcrumbs[breadcrumbs.length - 1].label}`}
			/>
			<UserMenu />
			<NavTabList>
				<div className="flex items-center mb-[66px] flex-wrap gap-y-[2rem] gap-x-[5rem]">
					<PrimaryLogo color={theme.colorLogoLightLight} width="254px" />
					<Breadcrumbs navLinks={breadcrumbs} onClick={handleClickBreadcrumb} />
				</div>
			</NavTabList>

			{parentProgramDisplay && (
				<ParentProgramContainer>
					<ParentProgramCard {...parentProgramDisplay} />
				</ParentProgramContainer>
			)}
			<ProgramCardsContainer>
				{/* SUBPROGRAMS */}
				{subPrograms &&
					params.hasOwnProperty("programId") &&
					subPrograms.map((program, i: number) => {
						return <SubProgramCard key={i} {...program} />;
					})}
			</ProgramCardsContainer>
		</PageContainer>
	);
};

export default EntrantSubprograms;
