// import { EntryModel } from "../Entries/EntryInterfaces";
import { Stream } from "stream";
import { EntryCardProps } from "../../components/CheckoutEntryCard/CheckoutEntryCard";
import { EntryModel } from "../Entries/EntryInterfacesComplete";
import { DropdownOptions } from "../../components/FormFields/DropdownField";

export interface orderLineItem {
	id: number;
	entryId: number;
	entry: EntryModel[];
	title: string;
	isCampaign: boolean;
	totalAmount: number;
}

export interface OrderModel {
	id?: number;
	CompanyId?: number;
	statusId: number;
	transactionType: TransactionType;
	cardInfo?: CreditCard;
	paymentDate?: string;
	orderAmount: number;
	bankName?: string;
	bankCity?: string;
	checkNumber?: string;
	checkStatus?: CheckStatus;
	fullName: string;
	email: string;
	phone: string;
	companyId?: number;
	companyName: string;
	companyCountry: string;
	companyCity: string;
	companyState: string;
	companyAddress1: string;
	companyAddress2?: string;
	companyPostalCode: string;
	notes?: string;
	invoicePDF?: string;
	hasPhysicalComponent?: boolean;
	lineItems: OrderLineItem[];
	createdDate?: string;
	createdBy?: string;
	updatedDate?: string;
	modifiedBy?: string;
	modifiedByName?: string;
}

export interface OrderModelPdf {
	OrderId: number;
	PdfFile?: Promise<Blob> | null;
	IsError?: boolean;
	AuthCode?: string;
	AuthMessage?: string;
}

export enum TransactionType {
	Credit,
	Check,
	Bank,
	Submission,
	Unassigned,
}

export const TransactionTypeOptions: DropdownOptions[] = [
	{ value: TransactionType.Credit, label: "Credit" },
	{ value: TransactionType.Check, label: "Check" },
	{ value: TransactionType.Bank, label: "Bank" },
	{ value: TransactionType.Submission, label: "Submission" },
];

export enum CheckStatus {
	NotReceived,
	Received,
	Deposited,
	Cleared,
	Declined,
}

export const CheckStatusOptions: DropdownOptions[] = [
	{ value: CheckStatus.NotReceived, label: "Not Received" },
	{ value: CheckStatus.Received, label: "Received" },
	{ value: CheckStatus.Deposited, label: "Deposited" },
	{ value: CheckStatus.Cleared, label: "Cleared" },
	{ value: CheckStatus.Declined, label: "Declined" },
];

export interface CreditCard {
	cardNumber: string;
	expirationDate: string;
	cardCode: string;
}

export interface OrderLineItem {
	id?: number;
	entryId: number;
	title?: string;
	totalLineAmount?: number;
	entry: EntryCardProps;
	programHasPhysicalComponent?: boolean;
}

export enum PaymentStatus {
	Incomplete,
	ReadyForCheckout,
	PaymentPending,
	Paid,
	Deleted,
	Abandoned,
	Refunded,
}

export const PaymentStatusOptions: DropdownOptions[] = [
	{ value: PaymentStatus.Incomplete, label: "Incomplete" },
	{ value: PaymentStatus.ReadyForCheckout, label: "Ready For Checkout" },
	{ value: PaymentStatus.PaymentPending, label: "Payment Pending" },
	{ value: PaymentStatus.Paid, label: "Paid" },
	{ value: PaymentStatus.Deleted, label: "Deleted" },
	{ value: PaymentStatus.Abandoned, label: "Abandoned" },
	{ value: PaymentStatus.Refunded, label: "Refunded" },
];

export enum ReviewStatus {
	Draft,
	ReadyForReview,
	OnHold,
	Failed,
	Updated,
	Passed,
	Withdrawn,
	InJudging,
	Completed,
	Released,
}

export const ReviewStatusOptions: DropdownOptions[] = [
	{ value: ReviewStatus.Draft, label: "Draft" },
	{ value: ReviewStatus.ReadyForReview, label: "Ready For Review" },
	{ value: ReviewStatus.OnHold, label: "On Hold" },
	{ value: ReviewStatus.Failed, label: "Failed" },
	{ value: ReviewStatus.Updated, label: "Updated" },
	{ value: ReviewStatus.Passed, label: "Passed" },
	{ value: ReviewStatus.Withdrawn, label: "Withdrawn" },
	{ value: ReviewStatus.InJudging, label: "In Judging" },
	{ value: ReviewStatus.Completed, label: "Completed" },
	{ value: ReviewStatus.Released, label: "Released" },
];
