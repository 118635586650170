import { useFormik } from "formik";
import FileUpload from "../../../../components/FormFields/FileUpload";
import * as Yup from "yup";
import { MediaType } from "../../../MediaLibrary/mediaLibrary.model.d";
import { useEffect, useState } from "react";
import { updateJuryWelcomeVideo } from "../manageJudging";
import { CardBody } from "../../../../components/DashboardCard/DashboardCard";
import Button from "../../../../components/Button/Button";
import { useTheme } from "styled-components";
import { useAlert } from "../../../../components/Alert/Alerts";

const WelcomeVideoTab = (props: { juryId: string; welcomeVideo?: string }) => {
	const { addNewAlert } = useAlert();
	const [isUploading, setIsUploading] = useState(false);

	const formikProps = useFormik<{
		welcomeVideo?: string;
		welcomeVideoData?: File;
	}>({
		initialValues: {
			welcomeVideo: props.welcomeVideo,
		},
		onSubmit: async () => {},
		enableReinitialize: true,
		validationSchema: Yup.object({}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	const uploadWelcomeVideo = () => {
		setIsUploading(true);
		const formData = new FormData();
		formData.append("FormFile", formikProps.values.welcomeVideoData || "");
		formData.append(
			"FileName",
			formikProps.values.welcomeVideoData
				? formikProps.values.welcomeVideoData.name
				: ""
		);

		updateJuryWelcomeVideo(formData, Number(props.juryId))
			.then((res) => {
				if (res.status === 200) {
					addNewAlert({
						type: "success",
						message: "Sucessfully updated welcome video",
					});
				}
				setIsUploading(false);
			})
			.catch((err) => {
				addNewAlert({
					type: "error",
					message: err.message,
				});
				setIsUploading(false);
			});
	};

	return (
		<CardBody className="flex flex-col gap-[1rem]">
			<h2>Judging Welcome Video</h2>

			<FileUpload
				height="173px"
				id="fileUpload-welcomeVideo"
				onChange={(files) => {
					if (!files || files.length === 0) {
						return;
					}
					formikProps.setFieldValue("welcomeVideoData", files[0].file);
				}}
				{...(formikProps.values.welcomeVideo && {
					value: {
						fileName: "Welcome Video",
						fileType: "video",
						src: formikProps.values.welcomeVideo,
						initial: true,
					},
					disabled: false,
				})}
				remove={() => {
					formikProps.setFieldValue("welcomeVideo", undefined);
					formikProps.setFieldValue("welcomeVideoData", undefined);
				}}
				allowedMediaType={MediaType.Video}
			/>
			<Button
				className="!w-fit ml-auto"
				icon="check"
				onClick={uploadWelcomeVideo}
				disabled={isUploading}
			>
				Save Changes
			</Button>
		</CardBody>
	);
};

export default WelcomeVideoTab;
