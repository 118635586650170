import Dashboard from "./views/Admin/Dashboard/Dashboard";
import Entries from "./views/Entries/Entries";
import Global from "./components/Global/Global";
import Judging from "./views/Admin/Judging/Judging";
import Login from "./views/Login/Login";
import Programs from "./views/Admin/Program/Programs";
import Reports from "./components/Reports/Reports";
import RedirectToRoot from "./utils/RedirectToRoot";
import Seasons from "./views/Admin/Seasons/Seasons";
import Awards from "./views/Admin/Awards/Awards";
import ProgramRootAdminNav from "./views/Admin/Program/ProgramRootAdminNav";
import FieldTemplates from "./views/Admin/Program/FieldTemplates";
import AdminSearch from "./views/Admin/Search/AdminSearch";
import NewProgram from "./views/Admin/Program/NewProgram";
import ProgramConfig from "./views/Admin/Program/ProgramConfig";
import ProgramHierarchyNav from "./views/Admin/Program/ProgramHierarchyNav";
import NewProgramDisplay from "./views/Admin/Program/NewProgramDisplay";
import ProgramDisplayForm from "./views/Admin/Program/ProgramDisplayForm";
import EditOrder from "./views/Admin/Orders/EditOrder";
import React from "react";
import EditUser from "./views/Admin/User/EditUser";
import EditCompany from "./views/Admin/Company/EditCompany";
import JudgingConfigLanding from "./views/Admin/Judging/JudgingConfig/JudgingConfigLanding";
import JudgingConfigJury from "./views/Admin/Judging/JudgingConfig/JudgingConfigJury";
import JudgingConfigProgram from "./views/Admin/Judging/JudgingConfig/JudgingConfigProgram";
import Settings from "./views/Admin/Settings/Settings";
import JudgingAdminLanding from "./views/Admin/Judging/Administration/JudgingAdminLanding";
import JudgingAdminProgram from "./views/Admin/Judging/Administration/JudgingAdminProgram";
import JudgingAdminJury from "./views/Admin/Judging/Administration/JudgingAdminJury";
import ManageGroup from "./views/Admin/Judging/Administration/ManageGroup";
import JudgingJurorView from "./views/Admin/Judging/Administration/JudgingJurorView";
import EntrySetAdministration from "./views/Admin/Judging/Administration/EntrySetAdministration";
import LiveToolsLanding from "./views/Admin/Judging/LiveTools/LiveToolsLanding";
import MediaViewer from "./views/Admin/Judging/LiveTools/MediaViewer";
import MediaLibrary from "./views/Admin/Media/MediaLibrary";
import CreditLibrary from "./views/Admin/Credits/CreditLibrary";
import { CreditType } from "./views/Admin/Program/ProgramInterfaces";
import EditEntry, { AdminEditPageType } from "./views/Entries/EditEntry";
import CompanyMediaLibrary from "./views/Admin/Entries/CompanyMediaLibrary";
import CompanyCreditLibrary from "./views/Admin/Entries/CompanyCreditLibrary";
import PhysicalEntryPDF from "./views/TestComponents/PhysicalEntryPDF";
import JudgingReports from "./views/Admin/Judging/Reports/JudgingReports";
import CompanyTagLibrary from "./views/Admin/Tags/CompanyTagLibrary";
import Review from "./views/Admin/Review/Review";
import ProgramAwardWeights from "./views/Admin/Program/ProgramAwardWeights";

const routes: Array<{
  path: string;
  component: any;
  isAdmin?: boolean;
  isSysAdmin?: boolean;
  exact?: boolean;
  feature?: string;
}> = [
  { path: "/program/new", component: NewProgram, isSysAdmin: true },
  { path: "/program/season", component: Seasons, isSysAdmin: true },
  { path: "/program/award", component: Awards, isSysAdmin: true },
  {
    path: "/program/configure/:id",
    component: ProgramConfig,
    isSysAdmin: true,
  },
  {
    path: "/program/program-setup/:id",
    component: ProgramHierarchyNav,
    isSysAdmin: true,
  },
  {
    path: "/program/program-setup",
    component: ProgramRootAdminNav,
    isSysAdmin: true,
    exact: true,
  },
  {
    path: "/program/program-display/:id",
    component: ProgramDisplayForm,
    isSysAdmin: true,
  },
  {
    path: "/program/new-program-display",
    component: NewProgramDisplay,
    isSysAdmin: true,
    exact: true,
  },
  {
    path: "/program/program-display",
    component: Programs,
    isSysAdmin: true,
    exact: true,
  },
  {
    path: "/program/field-templates",
    component: FieldTemplates,
    isSysAdmin: true,
    exact: true,
  },
  {
    path: "/program/release-targets",
    component: ProgramAwardWeights,
    isSysAdmin: true,
    exact: true,
  },
  {
    path: "/settings/features",
    component: () => <Settings tab="Features" />,
    isSysAdmin: true,
    exact: true,
  },
  {
    path: "/settings/pause-slide",
    component: () => <Settings tab="pause-slide" />,
    isSysAdmin: true,
    exact: true,
  },
  {
    path: "/library/entries",
    component: () => <AdminSearch tab="entries" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/library/orders",
    component: () => <AdminSearch tab="orders" />,
    isAdmin: true,
    exact: true,
    feature: "Commerce",
  },
  {
    path: "/library/users",
    component: () => <AdminSearch tab="users" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/library/companies",
    component: () => <AdminSearch tab="companies" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/library/company-credits",
    component: () => <AdminSearch tab="company-credits" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/library/individual-credits",
    component: () => <AdminSearch tab="individual-credits" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/library/jurors",
    component: () => <AdminSearch tab="jurors" />,
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/library/media",
    component: () => <AdminSearch tab="media" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/library/media/:companyId",
    component: () => <MediaLibrary />,
    isAdmin: true,
    exact: true,
  },

  {
    path: "/reports/entry-creation-checkout",
    component: () => <Reports tab="entry-creation-checkout" />,
    isAdmin: true,
    exact: true,
    feature: "Commerce",
  },
  {
    path: "/reports/entry-review",
    component: () => <Reports tab="entry-review" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/reports/order-upload",
    component: () => <Reports tab="order-upload" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/reports/customer-upload",
    component: () => <Reports tab="customer-upload" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/reports/deadline-upload",
    component: () => <Reports tab="deadline-upload" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/library/credits/company/:companyId",
    component: () => <CreditLibrary creditType={CreditType.Company} />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/library/credits/individual/:companyId",
    component: () => <CreditLibrary creditType={CreditType.Individual} />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/edit-order/:orderId",
    component: EditOrder,
    isAdmin: true,
    feature: "Commerce",
  },
  {
    path: "/edit-user/:userId",
    component: EditUser,
    isAdmin: true,
  },
  {
    path: "/edit-company/:companyId",
    component: EditCompany,
    isAdmin: true,
  },
  { path: "/entries", component: Entries, exact: true },
  { path: "/reports", component: Reports, isAdmin: true },
  //   judging config routes
  {
    path: "/judging/config",
    component: JudgingConfigLanding,
    isSysAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/judging/config/:programId",
    component: JudgingConfigProgram,
    isSysAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/judging/config/:programId/:juryId",
    component: JudgingConfigJury,
    isSysAdmin: true,
    exact: true,
    feature: "Judging",
  },
  // judging admin routes
  {
    path: "/judging/admin",
    component: JudgingAdminLanding,
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/judging/admin/:programId",
    component: JudgingAdminProgram,
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/judging/admin/:programId/:juryId/jurorView",
    component: JudgingJurorView,
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/judging/admin/:programId/:juryId",
    component: JudgingAdminJury,
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/judging/admin/:programId/:juryId/:entrySetId/manageGroups",
    component: ManageGroup,
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/judging/admin/:programId/:juryId/:entrySetId/manageRound/:roundId",
    component: EntrySetAdministration,
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/judging/admin/:programId/:juryId/:entrySetId/manageRound",
    component: EntrySetAdministration,
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  // live tools routes
  {
    path: "/judging/admin/:programId/:juryId/live-tools",
    component: LiveToolsLanding,
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/judging/reports",
    component: JudgingReports,
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  {
    path: "/judging/reports/jury-votes",
    component: () => (
      <JudgingReports tab="jury-votes" metaDescription={"Jury Votes Report"} />
    ),
    isAdmin: true,
    exact: true,
    feature: "Judging",
  },
  // admin entry edit
  {
    path: "/admin/edit-entry",
    component: () => <EditEntry pageType={AdminEditPageType.EditEntry} />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/admin/edit-entry/media",
    component: () => <EditEntry pageType={AdminEditPageType.Media} />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/admin/edit-entry/credits",
    component: () => <EditEntry pageType={AdminEditPageType.Credits} />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/admin/edit-entry/media-library/:companyId",
    component: () => <CompanyMediaLibrary />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/admin/edit-entry/credit-library/company/:companyId",
    component: () => <CompanyCreditLibrary creditType={CreditType.Company} />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/admin/edit-entry/credit-library/individual/:companyId",
    component: () => (
      <CompanyCreditLibrary creditType={CreditType.Individual} />
    ),
    isAdmin: true,
    exact: true,
  },
  {
    path: "/admin/edit-entry/tag-library/:companyId",
    component: () => <CompanyTagLibrary />,
    isAdmin: true,
    exact: true,
  },
  // Review
  {
    path: "/review/progress",
    component: () => <Review tab="progress" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/review/assignment",
    component: () => <Review tab="assignment" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/review",
    component: () => <Review tab="assignment" />,
    isAdmin: true,
    exact: true,
  },
  {
    path: "/test/physicalPDF",
    component: () => <PhysicalEntryPDF pageTitle="Physical Entries" />,
    isAdmin: true,
    exact: true,
  },
  { path: "*", component: RedirectToRoot },
];

export default routes;
