import {
  NavTabs,
  NavTab,
  NavTabList,
  NavTabPanel,
} from "../../../components/NavTabs/NavTabs";
import UserMenu from "./UserMenu";
import styled, { useTheme } from "styled-components";
import UserInfo from "./UserInfo";
import CompanyUpdate from "../../Company/CompanyUpdate";
import Authorized from "../../../components/Auth/Authorized";
import PrimaryLogo from "../../../components/PrimaryLogo/PrimaryLogo";
import { useEffect, useState } from "react";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export default function User({ tabIndex = 0 }: { tabIndex: number }) {
  const theme = useTheme();
  const route = useHistory();
  const { settings, loading } = useSelector(
    (state: RootState) => state.settings
  );

  const TabNames = settings.featureFlags["Simplified Registration"]
    ? ["User Info"]
    : ["User Info", "Company Info"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tabIndex]);

  return (
    <div className="w-full">
      <BrowserHeader title={`${TabNames[tabIndex]}`} />
      <UserMenu />
      <Authorized
        settings={settings}
        loading={loading}
        role="manager"
        // Authorized manager
        authorized={
          <NavTabs selectedIndex={tabIndex}>
            <NavTabList>
              <PrimaryLogo
                className="mb-[66px]"
                color={theme.colorLogoLightLight}
                width="254px"
              />
              <NavTab onClick={() => route.push("/user-info")}>
                User Info
              </NavTab>

              {!settings.featureFlags["Simplified Registration"] && (
                <NavTab onClick={() => route.push("/company-info")}>
                  Company Info
                </NavTab>
              )}

              {/* <NavTab>Company Users</NavTab> */}
            </NavTabList>
            <NavTabPanel>
              <UserInfo />
            </NavTabPanel>
            {!settings.featureFlags["Simplified Registration"] && (
              <NavTabPanel>
                <CompanyUpdate disabled={false} />
              </NavTabPanel>
            )}

            {/* <NavTabPanel>
              <CompanyView />
            </NavTabPanel> */}
          </NavTabs>
        }
        //non-manager
        notAuthorized={
          <NavTabs selectedIndex={tabIndex}>
            <NavTabList>
              <PrimaryLogo
                className="mb-[66px]"
                color={theme.colorLogoLightLight}
                width="254px"
              />
              <NavTab onClick={() => route.push("/user-info")}>
                User Info
              </NavTab>
              {/*<NavTab onClick={() => route.push("/company-info")}>
                Company Info
              </NavTab>*/}
            </NavTabList>
            <NavTabPanel>
              <UserInfo />
            </NavTabPanel>
            {/* <NavTabPanel>
              <CompanyUpdate disabled={true} readOnly={true} />
            </NavTabPanel> */}
          </NavTabs>
        }
      />
    </div>
  );
}
