import axios from "axios";
import { useState } from "react";
import { urlReports } from "../../endpoints";
import { getTimezoneOffset } from "../../utils/timeUtilities";

export async function getRootProgramCounts(programId: number) {
  try {
    const response = await axios.get<LabelAndCount[]>(
      `${urlReports}/getRootProgramCounts/${programId}`
    );

    if (response.status === 204) {
      // Handle no content scenario
      return [];
    } else if (response.status === 200) {
      // On success, return the data
      return response.data;
    } else {
      // Handle other statuses if needed
      return [];
    }
  } catch (error: any) {
    return error;
  }
}

export async function getEntryProgramReviewStatuses(programId: number) {
  try {
    const response = await axios.get<ReviewStatusesAndTotal[]>(
      `${urlReports}/EntryProgramReviewStatuses/${programId}`
    );

    if (response.status === 204) {
      // Handle no content scenario
      return [];
    } else if (response.status === 200) {
      // On success, return the data
      return response.data;
    } else {
      // Handle other statuses if needed
      return [];
    }
  } catch (error: any) {
    return error;
  }
}

export async function getEntryCreationCheckoutNumbers(programId: number) {
  try {
    const response = await axios.get<EntryCheckout[]>(
      `${urlReports}/EntryCreationCheckoutNumbers/${programId}`
    );

    if (response.status === 204) {
      // Handle no content scenario
      return [];
    } else if (response.status === 200) {
      // On success, return the data
      return response.data;
    } else {
      // Handle other statuses if needed
      return [];
    }
  } catch (error: any) {
    return error;
  }
}

export async function getPaymentStatusesAmount(programId: number) {
  try {
    const response = await axios.get<ReviewStatusesAndAmount[]>(
      `${urlReports}/GetPaymentStatusesAmount/${programId}`
    );

    if (response.status === 204) {
      // Handle no content scenario
      return [];
    } else if (response.status === 200) {
      // On success, return the data
      return response.data;
    } else {
      // Handle other statuses if needed
      return [];
    }
  } catch (error: any) {
    return error;
  }
}

export async function getOrderReports(
  startDate: number,
  endDate: number
): Promise<OrderReport[]> {
  try {
    // Get the timezone offset and append it to the query parameters
    const timezoneOffset = getTimezoneOffset();

    const response = await axios.get<OrderReport[]>(
      `${urlReports}/order-reports`,
      {
        params: {
          incStartDate: startDate,
          incEndDate: endDate,
          timezoneOffset: timezoneOffset,
        },
      }
    );

    if (response.status === 204) {
      // Handle no content scenario
      return [];
    } else if (response.status === 200) {
      // On success, return the data
      return response.data;
    } else {
      // Handle other statuses if needed
      return [];
    }
  } catch (error: any) {
    throw error;
  }
}

export async function getCustomerUploadReports(
  startDate: number,
  endDate: number
): Promise<CustomerUpload[]> {
  try {
    const response = await axios.get<CustomerUpload[]>(
      `${urlReports}/customer-upload`,
      {
        params: {
          incStartDate: startDate,
          incEndDate: endDate,
        },
      }
    );

    if (response.status === 204) {
      // Handle no content scenario
      return [];
    } else if (response.status === 200) {
      // On success, return the data
      return response.data;
    } else {
      // Handle other statuses if needed
      return [];
    }
  } catch (error: any) {
    throw error;
  }
}

export async function getCheckoutAndPaymentStatuses(
  programId: number
): Promise<CheckoutAndPaymentStatuses> {
  try {
    const response = await axios.get<CheckoutAndPaymentStatuses>(
      `${urlReports}/EntryCreationCheckoutNumbersAndPaymentStatuses/${programId}`
    );

    if (response.status === 204) {
      // Handle no content scenario
      return {
        entryCheckout: {
          pending: { entries: 0, amount: 0 },
          completed: { entries: 0, amount: 0 },
        },
        reviewStatusesAndAmount: [],
      };
    } else if (response.status === 200) {
      // On success, return the data
      return response.data;
    } else {
      return {
        entryCheckout: {
          pending: { entries: 0, amount: 0 },
          completed: { entries: 0, amount: 0 },
        },
        reviewStatusesAndAmount: [],
      };
    }
  } catch (error: any) {
    throw error;
  }
}

export const getDeadlineUploadReport = async (
  seasonId: number,
  awardId: number
): Promise<DeadlineUploadReport[]> => {
  try {
    const response = await axios.get<DeadlineUploadReport[]>(
      `${urlReports}/program-deadlines/${seasonId}/${awardId}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "An error occurred while fetching deadline upload counts: ",
      error
    );
    return [];
  }
};

export interface LabelAndCount {
  label: string;
  entries: number;
}

export interface ReviewStatusesAndTotal {
  entryProgram: string;
  reviewStatusCounts: LabelAndCount[];
  total: number;
}

export interface EntriesAndAmount {
  entries: number;
  amount: number;
}

export interface EntryCheckout {
  pending: EntriesAndAmount;
  completed: EntriesAndAmount;
}

export interface LabelCountAndAmount {
  label: string;
  entries: number;
  amount: number;
}

export interface ReviewStatusesAndAmount {
  [key: string]: any;
  entryProgram: string;
  incomplete: EntriesAndAmount;
  readyForCheckout: EntriesAndAmount;
  paymentPending: EntriesAndAmount;
  paid: EntriesAndAmount;
  pending: EntriesAndAmount;
  completed: EntriesAndAmount;
}

export interface CheckoutAndPaymentStatuses {
  entryCheckout: EntryCheckout;
  reviewStatusesAndAmount: ReviewStatusesAndAmount[];
}

export interface OrderReport {
  InvoiceNo: number;
  Customer: string;
  InvoiceDate: Date;
  DueDate: Date;
  Terms: string;
  Location: string;
  Memo: string;
  ItemDescription: string;
  ItemProductService: string;
  ItemQuantity: number;
  ItemRate: number;
  ItemAmount: number;
  ServiceDate?: string | null;
}

export interface CustomerUpload {
  name: string;
  company: string;
  emailAddress: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  earliestInvoice: string;
}

export const headerToDataKeyMap: { [header: string]: string } = {
  InvoiceNo: "invoiceNo",
  Customer: "customer",
  InvoiceDate: "invoiceDate",
  DueDate: "dueDate",
  Terms: "terms",
  Location: "location",
  Memo: "memo",
  ItemDescription: "itemDescription",
  "Item(Product/Service)": "itemProductService",
  ItemQuantity: "itemQuantity",
  ItemRate: "itemRate",
  ItemAmount: "itemAmount",
  ServiceDate: "serviceDate",
};

export const headerToKeyMap: Record<string, keyof CustomerUpload> = {
  Name: "name",
  Company: "company",
  "Email Address": "emailAddress",
  Phone: "phone",
  Street: "street",
  City: "city",
  State: "state",
  ZIP: "zip",
  Country: "country",
  "Earliest Invoice": "earliestInvoice",
};

export const deadlineUploadHeaderToDataKeyMap: Record<
  string,
  keyof DeadlineUploadReport
> = {
  Vertical: "vertical",
  Season: "season",
  "Item(Product/Service)": "item",
  Price: "price",
};

export interface DeadlineUploadReport {
  vertical: string;
  season: string;
  item: string;
  price: number;
}
