import {
  ADD_NOTIFICATION,
  FETCH_NOTIFICATIONS_SUCCESS,
  NotificationActionTypes,
  REMOVE_NOTIFICATION,
  SET_FALLBACK,
  UPDATE_NOTIFICATION,
  UserNotification,
} from "./types";

export const fetchNotificationsSuccess = (
  notificationsArr: UserNotification[]
): NotificationActionTypes => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: notificationsArr,
});

export const setFallback = (): NotificationActionTypes => ({
  type: SET_FALLBACK,
});
export const addNotification = (
  notification: UserNotification
): NotificationActionTypes => {
  return {
    type: ADD_NOTIFICATION,
    payload: notification,
  };
};

export const updateNotification = (
  notification: UserNotification
): NotificationActionTypes => {
  return {
    type: UPDATE_NOTIFICATION,
    payload: notification,
  };
};

export const removeNotification = (id: number): NotificationActionTypes => {
  return {
    type: REMOVE_NOTIFICATION,
    payload: id,
  };
};
