import styled, { useTheme } from "styled-components";
import Button from "../Button/Button";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import parse from "html-react-parser";
import { roundTime } from "../../utils/getCountdownTimer";
import {
	ProgramDisplay,
	SubprogramCardModel,
} from "../../views/Admin/Program/ProgramInterfaces";
import { getCountdownTimer } from "../../utils/getCountdownTimer";
import { ProgramModel } from "../../views/Admin/Program/ProgramInterfaces";
import { DynamicTransitionDuration } from "../../globalStyles";
import { DisplayDate } from "../../views/Admin/Program/ProgramInterfaces";
import {
	ProgramCardContainer,
	ProgramHeader,
	ProgramDescription,
} from "../ProgramCard/ProgramCard";
import { StringLiteral } from "typescript";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getResizedVideoSrcAsync } from "../../views/MediaLibrary/manageMediaLibrary";

const SubProgramCard = (props: SubProgramCardProps) => {
	const [isDisabled, setIsDisabled] = useState(true);
	const history = useHistory();
	const theme = useTheme();
	const [welcomeVideo, setWelcomeVideo] = useState<string | null>(null);

	useEffect(() => {
		const now = new Date(Date.now());

		if (!props.entriesClosed && now >= new Date(props.entriesOpenDate!)) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [props]);

	useEffect(() => {
		const fetchResizedVideo = async () => {
			if (props.welcomeVideo) {
				const videoSrc = await getResizedVideoSrcAsync(props.welcomeVideo);
				setWelcomeVideo(videoSrc);
			} else setWelcomeVideo(null);
		};
		fetchResizedVideo();
	}, [props.welcomeVideo]);

	return (
		<ProgramCardContainer className={`${props.className}`}>
			<ProgramHeader>
				<h1>{props.name}</h1>
			</ProgramHeader>
			<ProgramDescription>
				{welcomeVideo && (
					<video
						className="welcome-video"
						controls
						key={welcomeVideo + props.id}
					>
						<source src={welcomeVideo} type="video/mp4" />
						<source src={welcomeVideo} type="video/quicktime" />
						Your browser does not support the video tag.
					</video>
				)}
				{props.image && props.imageDisplaysForEntrants && (
					<img src={props.image} alt="Program" />
				)}
				<div className="program-description">{parse(props.description!)}</div>
			</ProgramDescription>
			{(props.isEntryLevel && (
				<Button
					className="mt-auto font-semibold"
					disabled={isDisabled}
					onClick={() => {
						history.push(`/edit-entry/?programId=${props.id}`, {
							from: history.location.pathname,
						});
					}}
					icon="plus"
					iconColor={theme.colorCopyLightLight}
					{...(props.hasChildren && {
						iconRight: true,
						icon: "caret",
						iconRotation: "270deg",
					})}
				>
					{props.hasChildren ? "Enter" : "Select"} {props.name}
				</Button>
			)) || (
				<Button
					className="mt-auto font-semibold"
					disabled={isDisabled}
					to={`/programs/${props.id}`}
					icon="plus"
					iconColor={theme.colorCopyLightLight}
					{...(props.hasChildren && {
						iconRight: true,
						icon: "caret",
						iconRotation: "270deg",
					})}
				>
					{props.hasChildren ? "Enter" : "Select"} {props.name}
				</Button>
			)}
		</ProgramCardContainer>
	);
};

export default SubProgramCard;

interface SubProgramCardProps extends SubprogramCardModel {
	className?: string;
}
