import { useContext, useEffect, useRef, useState } from "react";
import {
  getEntryNotesByType,
  createEntryNote,
  updateEntryNote,
  deleteEntryNote,
} from "../views/Entries/manageEntry";
import {
  EntryNote,
  EntryNoteType,
} from "../views/Judging/EntryDetail/EntryDetail";
import {
  useNotificationHub,
  Notification,
  NotificationAction,
} from "./useNotificationHub";
import { NemoUser } from "./useCurrentUser";
import AuthenticationContext from "../components/Auth/AuthenticationContext";

export type UseChatHubReturnType = {
  messages: Array<EntryNote>;
  draftMessage: EntryNote;
  blankReviewNote: EntryNote;
  setDraftMessage: React.Dispatch<React.SetStateAction<EntryNote>>;
  sendDraftMessage: (newEntryNote: EntryNote) => Promise<void>;
  updateMessage: (updatedNote: Partial<EntryNote>) => Promise<void>;
  deleteMessage: (entryNoteId: number) => Promise<void>;
  isConnected: boolean;
  loading: boolean;
  error: string | null;
};

export function useChatHub(
  entryId: number,
  entryNoteType: EntryNoteType | null,
  currentUser: NemoUser
): UseChatHubReturnType {
  const { claims } = useContext(AuthenticationContext);
  const { connection, joinGroup, leaveGroup, isConnected } =
    useNotificationHub(claims);
  const [messages, setMessages] = useState<Array<EntryNote>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const blankReviewNote: EntryNote = {
    entryId: entryId,
    nameCreatedBy: currentUser.fullName,
    id: 0,
    isDeleted: false,
    type: entryNoteType == null ? EntryNoteType.Feedback : entryNoteType,
    note: "",
    isPrivate: true, // private = "draft" note, public = "published" note
  };

  const [draftMessage, setDraftMessage] = useState<EntryNote>(blankReviewNote);
  const messagesRef = useRef(messages); // Create a ref for the messages

  useEffect(() => {
    //console.log("Messages have changed: ", messages);
    // Update the ref whenever messages state changes
    messagesRef.current = messages;
  }, [messages]);

  // Fetch initial messages of specific EntryNoteType
  useEffect(() => {
    if (entryId && entryNoteType != null) {
      setMessages([]);
      setDraftMessage(blankReviewNote);
      const fetchNotes = async () => {
        try {
          const data = await getEntryNotesByType(entryId, entryNoteType);
          setMessages(data);
          setDraftMessage(data.find((x) => x.isPrivate) || blankReviewNote);
          setLoading(false);
        } catch (err) {
          setError("Failed to fetch messages.");
          setLoading(false);
        }
      };

      fetchNotes();
    }
  }, [entryId, entryNoteType]);

  // Join the SignalR group based on entryId and entryNoteType
  useEffect(() => {
    //console.log("entryNoteType", entryNoteType);
    if (connection && entryId && entryNoteType != null) {
      // Convert the enum value (number) to the corresponding string name
      const entryNoteTypeName = EntryNoteType[entryNoteType];
      const groupName = `EntryId-${entryId}-Type-${entryNoteTypeName}`;
      joinGroup(groupName, connection);

      return () => {
        leaveGroup(groupName, connection);
      };
    }
  }, [connection, entryId, entryNoteType]);

  useEffect(() => {
    if (isConnected && connection) {
      // Set up SignalR connection to handle incoming notifications
      connection.on("ReceiveNotification", (notification: Notification) => {
        //console.log("Incoming Notification", notification);

        // Handle "Add" action for new messages
        if (notification.action === NotificationAction.Add) {
          const newMessage = notification.data as EntryNote; // Cast notification data to EntryNote type
          if (newMessage.isPrivate) {
            // If the new message is private, set it as a draft message
            setDraftMessage(newMessage);
          } else {
            // If the new message is not private, add it to the existing message list
            setMessages((prevMessages) => [...prevMessages, newMessage]);
          }
        }
        // Handle "Update" action for existing messages
        else if (notification.action === NotificationAction.Update) {
          const updatedMessage = notification.data as EntryNote; // Cast notification data to EntryNote type
          if (updatedMessage.isPrivate) {
            // If the updated message is private, update the draft message
            setDraftMessage(updatedMessage);
          } else {
            // Access the current messages state from the ref
            const existingMessages = messagesRef.current;
            // Check if the message already exists in the list
            if (!existingMessages.find((x) => x.id === updatedMessage.id)) {
              // If the message does not exist, add it to the list
              setMessages((prevMessages) => [...prevMessages, updatedMessage]);
            } else {
              // If the message exists, update it in the list
              setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                  msg.id === updatedMessage.id ? updatedMessage : msg
                )
              );
            }
          }
        }
        // Handle "Delete" action for removing messages
        else if (notification.action === NotificationAction.Delete) {
          const deletedMessageId = (notification.data as { id: number }).id; // Get the ID of the message to be deleted
          // Remove the message with the matching ID from the list
          setMessages((prevMessages) =>
            prevMessages.filter((msg) => msg.id !== deletedMessageId)
          );
        }
      });
    }
  }, [connection, isConnected]);

  // Listen for incoming notifications and update the message list
  // useEffect(() => {
  //   console.log("Incoming Notification", notifications);

  //   notifications.forEach((notification) => {
  //     if (notification.action === NotificationAction.Add) {
  //       const newMessage = notification.data as EntryNote;
  //       setMessages((prevMessages) => [...prevMessages, newMessage]);
  //     } else if (notification.action === NotificationAction.Update) {
  //       const updatedMessage = notification.data as EntryNote;
  //       setMessages((prevMessages) =>
  //         prevMessages.map((msg) =>
  //           msg.id === updatedMessage.id ? updatedMessage : msg
  //         )
  //       );
  //     } else if (notification.action === NotificationAction.Delete) {
  //       const deletedMessageId = (notification.data as { id: number }).id;
  //       setMessages((prevMessages) =>
  //         prevMessages.filter((msg) => msg.id !== deletedMessageId)
  //       );
  //     }
  //   });
  // }, [notifications]);

  // Function to send a new message
  const sendDraftMessage = async (entryNote: EntryNote) => {
    //console.log("sendDraftMessage: ", entryNote);

    if (entryNote.id === 0) {
      try {
        // Create the note via the API (backend will handle sending the SignalR notification)
        await createEntryNote(entryId, entryNote)
          .then((res) => {
            if (res.data && res.data.isPrivate) {
              setDraftMessage(res.data);
            }
          })
          .catch((error) => {
            console.error("createEntryNote: ", error);
            setError("Failed to save draft message.");
          });
      } catch (err) {
        setError("Failed to send message.");
      }
    } else {
      try {
        // Update the note via the API (backend will handle sending the SignalR notification)
        await updateEntryNote(entryId, entryNote);
      } catch (err) {
        setError("Failed to update message.");
      }
    }
  };

  // Function to update an existing message
  const updateMessage = async (updatedNote: Partial<EntryNote>) => {
    try {
      // Update the note via the API (backend will handle sending the SignalR notification)
      await updateEntryNote(entryId, updatedNote);
    } catch (err) {
      setError("Failed to update message.");
    }
  };

  // Function to delete a message
  const deleteMessage = async (entryNoteId: number) => {
    try {
      // Delete the note via the API (backend will handle sending the SignalR notification)
      await deleteEntryNote(entryNoteId);
    } catch (err) {
      setError("Failed to delete message.");
    }
  };

  return {
    messages,
    draftMessage,
    blankReviewNote,
    setDraftMessage,
    sendDraftMessage,
    updateMessage,
    deleteMessage,
    isConnected,
    loading,
    error,
  };
}
