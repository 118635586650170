export interface UserNotification {
  id: number;
  title: string;
  message?: string;
  link: string;
  linkText: string;
  sender: string;
  senderName: string;
  recipient: string;
  recipientName: string;
  createdDate?: string;
  isRead: boolean;
}

export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const SET_FALLBACK = "SET_FALLBACK";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

// Action types
export interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: UserNotification;
}

export interface UpdateNotificationAction {
  type: typeof UPDATE_NOTIFICATION;
  payload: UserNotification;
}

export interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION;
  payload: number; // Assuming payload is the notification ID
}

export interface FetchNotificationsSuccessAction {
  type: typeof FETCH_NOTIFICATIONS_SUCCESS;
  payload: UserNotification[];
}

export interface SetFallbackNotificationAction {
  type: typeof SET_FALLBACK;
}

export type NotificationActionTypes =
  | FetchNotificationsSuccessAction
  | SetFallbackNotificationAction
  | AddNotificationAction
  | UpdateNotificationAction
  | RemoveNotificationAction;
