import {
	JudgingRoundStatus,
	JuryCardAdmin,
	RoundType,
	VoteOption,
	EntrySetAdminCard,
} from "../JudgingInterfaces";
import Button from "../../../../components/Button/Button";
import { useState } from "react";
import HierarchyNav, {
	HierarchyCard,
} from "../../../../components/HierarchyNav/HierarchyNav";
import Icon from "../../../../components/Icon/Icon";
import styled, { useTheme, css } from "styled-components";
import {
	expandAnimation,
	SubLevel,
} from "../../../../components/HierarchyNav/HierarchyNav";
import DashboardCard from "../../../../components/DashboardCard/DashboardCard";
import ToggleSwitchField from "../../../../components/FormFields/ToggleSwitchField";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import CheckboxField from "../../../../components/FormFields/CheckboxField";
import { useEffect } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { getToken } from "../../../../components/Auth/handleJWT";
import Progressbar from "../../../../components/Progressbar/Progressbar";
import TextField from "../../../../components/FormFields/TextField";
import { useAdminHub } from "../../../../hooks/useAdminHub";
import BrowserHeader from "../../../../components/BrowserHeader/BrowserHeader";
import DateTimeField from "../../../../components/FormFields/DateTimeField";

export const RoundControls = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	padding: 1rem;
`;

const EntrySetControlsContainer = styled.div<{
	show: boolean;
}>`
	display: none;
	position: relative;
	flex-wrap: wrap;
	align-items: center;
	gap: 1rem;
	width: calc(100% - 1.5rem);
	margin-left: 1.5rem;
	padding: 15px 19px;
	background: ${({ theme }) => theme.colorBackgroundLight};
	box-shadow: 0 4px 8px -2px ${({ theme }) => theme.colorBoxShadow};

	${RoundControls} {
		margin-left: auto;
	}
	${(p) => p.show && `display: flex`};
`;

// for the admin jury view
const JuryEntrySetControls = (props: JuryEntrySetControlsProps) => {
	const theme = useTheme();
	const { values, setFieldValue } = useFormikContext<EntrySetAdminCard>();
	const [roundChanging, setRoundChanging] =
		useState<null | JudgingRoundStatus>();

	useEffect(() => {
		setRoundChanging(null);
	}, [values.roundStatus]);

	return (
		<EntrySetControlsContainer
			className={props.className}
			show={props.show || false}
		>
			{props.roundSelection && props.roundSelection}
			<DateTimeField
				name="deadline"
				readOnly={props.disabled}
				placeholder="Deadline Date"
				value={values.deadline}
			/>

			{props.showThreshold && (
				<TextField
					className="threshold"
					name="threshold"
					type="number"
					readOnly={props.disabled}
					value={values.threshold}
					placeholder="Threshold"
				/>
			)}

			{props.showCountAbstainAndNoVotes && (
				<CheckboxField
					className="countAbstainAndNoVotes"
					name="countAbstainAndNoVotes"
					disabled={props.disabled}
					checked={values.countAbstainAndNoVotes}
					placeholder="Count Abstain & No Votes"
					onChange={(value) => {
						props.updateCountAbstainAndNoVotes &&
							props.updateCountAbstainAndNoVotes(
								props.entrySetAdminCard.juryId,
								props.entrySetAdminCard.id,
								props.entrySetAdminCard.activeRoundId,
								value,
								props.entrySetAdminCard
							);
					}}
				/>
			)}

			<RoundControls className="round-controls">
				<Icon
					icon={
						roundChanging === JudgingRoundStatus.Started
							? "play-spinning"
							: "play"
					}
					readonly={
						props.disabled ||
						(roundChanging !== JudgingRoundStatus.Started &&
							roundChanging !== null)
					}
					color={
						values.roundStatus === JudgingRoundStatus.Started && !props.disabled
							? theme.colorActivation
							: theme.colorCopyLight
					}
					onClick={() => {
						// setFieldValue("roundStatus", JudgingRoundStatus.Started);
						setRoundChanging(JudgingRoundStatus.Started);
						if (!props.disabled) {
							props.updateRoundStatus(
								props.entrySetAdminCard.juryId,
								props.entrySetAdminCard.id,
								props.entrySetAdminCard.activeRoundId,
								JudgingRoundStatus.Started
							);
						}
					}}
					width="30px"
					height="30px"
				/>

				<Icon
					icon={
						roundChanging === JudgingRoundStatus.Pause
							? "pause-spinning"
							: "pause-circle"
					}
					readonly={
						props.disabled ||
						(roundChanging !== JudgingRoundStatus.Pause &&
							roundChanging !== null)
					}
					color={
						values.roundStatus === JudgingRoundStatus.Pause && !props.disabled
							? theme.colorActivation
							: theme.colorCopyLight
					}
					onClick={() => {
						// setFieldValue("roundStatus", JudgingRoundStatus.Pause);
						setRoundChanging(JudgingRoundStatus.Pause);
						props.updateRoundStatus(
							props.entrySetAdminCard.juryId,
							props.entrySetAdminCard.id,
							props.entrySetAdminCard.activeRoundId,
							JudgingRoundStatus.Pause
						);
					}}
					width="30px"
					height="30px"
				/>

				<Icon
					icon={
						roundChanging === JudgingRoundStatus.Completed
							? "stop-spinning"
							: "stop"
					}
					readonly={
						props.disabled ||
						(roundChanging !== JudgingRoundStatus.Completed &&
							roundChanging !== null)
					}
					color={
						values.roundStatus === JudgingRoundStatus.Completed &&
						!props.disabled
							? theme.colorActivation
							: theme.colorCopyLight
					}
					onClick={() => {
						// setFieldValue("roundStatus", JudgingRoundStatus.Completed);
						setRoundChanging(JudgingRoundStatus.Completed);
						props.updateRoundStatus(
							props.entrySetAdminCard.juryId,
							props.entrySetAdminCard.id,
							props.entrySetAdminCard.activeRoundId,
							JudgingRoundStatus.Completed
						);
					}}
					width="30px"
					height="30px"
				/>

				{props.progress && (
					<Progressbar
						className="!pr-0 ml-[1rem]"
						background={theme.colorBackgroundLight}
						completionPercent={props.progress}
						currentRound={props.entrySetAdminCard.activeRoundOrder}
						totalRounds={props.entrySetAdminCard.totalRounds}
					/>
				)}
			</RoundControls>

			<CheckboxField
				name="entriesLocked"
				checked={values.entriesLocked}
				disabled={props.disabled}
				onChange={(isLocked) => {
					setFieldValue("entriesLocked", isLocked);
					props.updateEntriesLocked(
						props.entrySetAdminCard.juryId,
						props.entrySetAdminCard.id,
						props.entrySetAdminCard.activeRoundId,
						!values.entriesLocked
					);
				}}
				placeholder="Lock Entries"
			/>
		</EntrySetControlsContainer>
	);
};

const EntrySetCard = (props: EntrySetProps) => {
	const debug = false;
	const history = useHistory();

	const [showControls, setShowControls] = useState(props.showControls);
	const [entrySetAdminCard, setEntrySetAdminCard] = useState<EntrySetAdminCard>(
		props.blankEntrySetCard
	);

	// useEffect for handling entrySetId changes
	useEffect(() => {
		if (props.connection && entrySetAdminCard.id === 0) {
			// Fetch and update the entrySetCard data
			props.connection.on(
				`entrySetCardUpdated-${props.entrySetId}`,
				(card: EntrySetAdminCard) => {
					let newCard = card as EntrySetAdminCard;
					newCard.showControls = showControls;
					setEntrySetAdminCard({
						...newCard,
					});
					debug &&
						console.log(`entrySetCardUpdated-${props.entrySetId}`, newCard);
				}
			);

			props.connection.invoke(
				"joinEntrySetGroup",
				Number(props.entrySetId),
				Number(props.juryId)
			);
		}
	}, [props.connection, props.entrySetId]);

	const formikProps = useFormik({
		initialValues: {
			...entrySetAdminCard,
			showControls: showControls,
		},
		onSubmit: async (value) => {},
		validationSchema: Yup.object({}),
		enableReinitialize: true,
	});

	useEffect(() => {
		if (formikProps.values.deadline != entrySetAdminCard.deadline) {
			debug &&
				console.log("Triggering deadline: ", formikProps.values.deadline);
			props.updateDeadline(
				formikProps.values.juryId,
				formikProps.values.id,
				formikProps.values.activeRoundId,
				formikProps.values.deadline
			);
		}
	}, [formikProps.values.deadline]);

	useEffect(() => {
		debug && console.log("Show: ", formikProps.values.showControls);
		setShowControls(formikProps.values.showControls);
	}, [formikProps.values.showControls]);

	return (
		<FormikProvider value={formikProps}>
			<HierarchyCard
				className="text-center"
				key={`hierarchy.${props.index}`}
				gridColumns={props.gridColumns}
				gridArea={props.gridArea}
			>
				<span className="horizontal-branch" />
				{/* show this button if first round has started */}
				{entrySetAdminCard.activeRoundId > 0 && (
					<Button
						className="button-light"
						to={`/judging/admin/${props.programId}/${entrySetAdminCard.juryId}/${entrySetAdminCard.id}/manageRound`}
					>
						Manage Round
					</Button>
				)}
				{/* show this button if first round HAS NOT started */}
				{entrySetAdminCard.activeRoundId == 0 && (
					<Button
						className="button-light"
						onClick={() =>
							history.push(
								`/judging/admin/${props.programId}/${entrySetAdminCard.juryId}/${entrySetAdminCard.id}/manageGroups`
							)
						}
					>
						Manage Groups
					</Button>
				)}

				<b>{formikProps.values.name}</b>
				<p>
					{formikProps.values.activeRoundOrder} of{" "}
					{formikProps.values.totalRounds}
				</p>
				<p>{formikProps.values.roundType}</p>
				<p>{formikProps.values.activeEntries}</p>
				<p>{formikProps.values.activeJurors}</p>
				<p>
					{formikProps.values.votesPossible
						? Math.round(
								(formikProps.values.votesCast /
									formikProps.values.votesPossible) *
									100
						  ) + "%"
						: "N/A"}
				</p>
				<p className={formikProps.values.roundStatus ? "active" : "inactive"}>
					{formikProps.values.roundStatus ? "Active" : "Inactive"}
				</p>
				<ToggleSwitchField
					className="justify-center"
					name="showControls"
					id={`show-controls-${formikProps.values.id}`}
					checked={formikProps.values.showControls}
					small
				/>
			</HierarchyCard>
			<JuryEntrySetControls
				key={props.index}
				entrySetAdminCard={formikProps.values}
				showCountAbstainAndNoVotes={false}
				show={formikProps.values.showControls}
				showThreshold={false}
				updateRoundStatus={props.updateRoundStatus}
				updateEntriesLocked={props.updateEntriesLocked}
			/>
		</FormikProvider>
	);
};

const JudgingAdminJury = () => {
	const theme = useTheme();
	const history = useHistory();
	const location = useLocation();
	const {
		juryCard,
		refreshdata,
		juryId,
		programId,
		connection,
		blankEntrySetCard,
		updateDeadline,
		updateRoundStatus,
		updateEntriesLocked,
	} = useAdminHub();

	const formikProps = useFormik({
		initialValues: juryCard,
		onSubmit: async (value) => {},
		validationSchema: Yup.object({}),
		enableReinitialize: true,
	});

	return (
		<FormikProvider value={formikProps}>
			<BrowserHeader title={`Jury Admin: ${formikProps.values.name}`} />
			<DashboardCard
				title={`${
					formikProps.values?.name ? formikProps.values.name + " Jury" : ""
				}`}
			>
				{formikProps.values && formikProps.values.entrySetIds.length > 0 && (
					<>
						<HierarchyCard className="!flex gap-[1rem]">
							<Button
								className="button-light"
								onClick={() =>
									window.open(
										`/judging/admin/${programId}/${juryId}/live-tools`,
										"_blank"
									)
								}
							>
								Live Tools
							</Button>
							<Button
								className="button-light"
								onClick={() => window.open(`/scoreboard/${juryId}`, "_blank")}
								//  to={`/scoreboard/${juryId}`}
							>
								Scoreboard
							</Button>
							<Button
								className="button-light"
								onClick={() => window.open(`/media-viewer/${juryId}`, "blank")}
							>
								Media Viewer
							</Button>
							<h2 className="w-[300px] truncate">{formikProps.values.name}</h2>
							<p className="ml-auto">
								{formikProps.values.activeEntries} Entries
							</p>

							<p>
								{Math.round(
									(formikProps.values.votesCast /
										formikProps.values.votesPossible) *
										100
								)}
								% Complete
							</p>
							<Button
								className="button-light"
								onClick={() =>
									window.open(`${location.pathname}/jurorView`, "_blank")
								}
							>
								Juror view
							</Button>
							<Button
								className="button-light"
								onClick={() => refreshdata(Number(juryId))}
							>
								Refresh Data
							</Button>
							<div
								className="flex items-center justify-end cursor-pointer gap-[.5rem]"
								onClick={() => history.goBack()}
							>
								<p>Back</p>
								<Icon
									icon="caret"
									color={theme.colorCopyDarkDark}
									rotation="180deg"
								/>
							</div>
						</HierarchyCard>
						<HierarchyNav
							columns={[
								{ label: null, width: "150px" },
								{ label: "Entry Set Name" },
								{ label: "Round" },
								{ label: "Round Type" },
								{ label: "Entries" },
								{ label: "Jurors" },
								{ label: "Progress" },
								{ label: "Status" },
								{ label: "Show Controls" },
							]}
							isCenterLabel
							noSortTriangle
							disableAnimation
							dashBorder
						>
							{(gridColumns: string, gridArea: string) =>
								formikProps.values.entrySetIds.map((entrySetId, i) => (
									<SubLevel
										key={`$sublevel.${i}`}
										order={i}
										expandAnimation={expandAnimation}
										pushDown={i - 1 >= 0 ? true : false}
										disableAnimation
									>
										<EntrySetCard
											key={`entrySetCard.${i}`}
											fieldName={`entrySets[${i}]`}
											entrySetId={entrySetId}
											showControls={true}
											gridArea={gridArea}
											juryId={juryId}
											programId={programId}
											connection={connection}
											updateDeadline={updateDeadline}
											updateEntriesLocked={updateEntriesLocked}
											updateRoundStatus={updateRoundStatus}
											blankEntrySetCard={blankEntrySetCard}
											gridColumns={gridColumns}
											index={1}
										/>
									</SubLevel>
								))
							}
						</HierarchyNav>
					</>
				)}
			</DashboardCard>
		</FormikProvider>
	);
};

export default JudgingAdminJury;

interface EntrySetProps {
	entrySetId: number;
	fieldName: string;
	showControls: boolean;
	gridColumns: string;
	gridArea: string;
	index: number;
	programId: string;
	juryId: string;
	connection: HubConnection | null;
	blankEntrySetCard: EntrySetAdminCard;
	updateDeadline: (
		juryId: number,
		entrySetId: number,
		activeRoundId: number,
		deadline?: number
	) => void;
	updateRoundStatus: (
		juryId: number,
		entrySetId: number,
		activeRoundId: number,
		status: JudgingRoundStatus
	) => void;
	updateEntriesLocked: (
		juryId: number,
		entrySetId: number,
		activeRoundId: number,
		isLocked: boolean
	) => void;
}

export interface JuryEntrySetControlsProps {
	className?: string;
	show?: boolean;
	entrySetAdminCard: EntrySetAdminCard;
	showThreshold: boolean;
	disabled?: boolean;
	showCountAbstainAndNoVotes: boolean;
	progress?: number;
	updateCountAbstainAndNoVotes?: (
		juryId: number,
		entrySetId: number,
		activeRoundId: number,
		countAbstainAndNoVotes: boolean,
		entrySetCard: EntrySetAdminCard
	) => void;
	updateRoundStatus: (
		juryId: number,
		entrySetId: number,
		activeRoundId: number,
		status: JudgingRoundStatus
	) => void;

	updateEntriesLocked: (
		juryId: number,
		entrySetId: number,
		activeRoundId: number,
		isLocked: boolean
	) => void;
	roundSelection?: React.ReactNode;
}
