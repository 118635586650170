import styled from "styled-components";
import React, { useState, useEffect } from "react";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import {
  CardBody,
  CardContainer,
  CardHeader,
} from "../../../components/DashboardCard/DashboardCard";
import axios from "axios";
import DropdownField, {
  DropdownOptions,
} from "../../../components/FormFields/DropdownField";
import Button from "../../../components/Button/Button";
import { urlPrograms } from "../../../endpoints";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useAlert } from "../../../components/Alert/Alerts";
import config from "../../../config";

interface ProgramTreeDTO {
  id: number;
  isEntryLevel: boolean;
  name: string;
  releaseWeight: number | null;
}

interface DropDownOptionDTO {
  label: string;
  value: number | null;
}

const LoadingSpinner = styled.img`
  width: 11%;
  display: inline-flex;
`;

const ProgramAwardWeights: React.FC = () => {
  const [rootPrograms, setRootPrograms] = useState<ProgramTreeDTO[]>([]);
  const [releaseWeightOptions, setReleaseWeightOptions] = useState<
    DropDownOptionDTO[]
  >([]);
  const [selectedWeights, setSelectedWeights] = useState<{
    [key: number]: number | null;
  }>({});
  const { addNewAlert } = useAlert();

  // Fetch the root programs and release weight options
  useEffect(() => {
    const fetchData = async () => {
      try {
        const rootProgramsResponse = await axios.get<ProgramTreeDTO[]>(
          `${urlPrograms}/getProgramReleaseWeights`
        );
        const programs = rootProgramsResponse.data;

        const releaseWeightOptionsResponse = await axios.get<
          DropDownOptionDTO[]
        >(`${urlPrograms}/getReleaseWeightOptions`);
        const options = [
          { label: "None", value: null },
          ...releaseWeightOptionsResponse.data,
        ];

        setRootPrograms(programs);
        setReleaseWeightOptions(options);

        // Initialize the selectedWeights state with existing release weights
        const initialSelectedWeights: { [key: number]: number | null } = {};
        programs.forEach((program) => {
          initialSelectedWeights[program.id] = program.releaseWeight;
        });
        setSelectedWeights(initialSelectedWeights);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const formikProps = useFormik({
    initialValues: { selectedWeights },
    enableReinitialize: true,
    validationSchema: Yup.object({}),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async () => {
      try {
        debugger;
        const promises = Object.keys(selectedWeights).map((programId) => {
          // Ensure that any selected weight of '0' is treated as null
          const releaseWeight =
            selectedWeights[Number(programId)] === 0
              ? null
              : selectedWeights[Number(programId)];
          return axios.get(`${urlPrograms}/assignProgramReleaseWeights`, {
            params: {
              programId: Number(programId),
              releaseWeight, // Send releaseWeight as null if it's 0
            },
          });
        });

        await Promise.all(promises);
        addNewAlert({
          type: "success",
          message: "Release weights assigned successfully!",
        });
      } catch (error) {
        console.error("Error saving release weights:", error);
        addNewAlert({
          type: "error",
          message: "Error saving release weights.",
        });
      }
    },
  });

  const handleWeightChange = (
    programId: number,
    value: string | number | null
  ) => {
    // Parse the value safely, treating empty strings or invalid values as null
    const parsedValue =
      value === "" || value === null || isNaN(Number(value))
        ? null
        : Number(value);

    formikProps.setFieldValue(`selectedWeights.${programId}`, parsedValue);
    setSelectedWeights((prevWeights) => ({
      ...prevWeights,
      [programId]: parsedValue,
    }));
  };

  return (
    <FormikProvider value={formikProps}>
      <CardContainer>
        <BrowserHeader title="Winners Gallery Release Targets" />
        <CardHeader>
          <h2 className="card-title text-xl font-semibold">
            Winners Gallery Release Targets
          </h2>
        </CardHeader>
        <CardBody>
          <div className="mt-6">
            {rootPrograms.map((program) => (
              <div key={program.id} className="flex items-center mb-4">
                {/* This container has a fixed width to align all dropdowns */}
                <div className="min-w-[250px] text-lg font-medium">
                  {program.name}
                </div>
                <DropdownField
                  name={`program-${program.id}`}
                  options={releaseWeightOptions}
                  value={selectedWeights[program.id] || undefined}
                  placeholder="Select Release Target"
                  onChange={(event) =>
                    handleWeightChange(program.id, Number(event.target.value))
                  }
                  className="w-56 border border-gray-300 rounded-md"
                />
              </div>
            ))}

            <div className="max-w-[650px] grid grid-cols-0 justify-end gap-[1rem] mt-[1rem] ml-auto">
              <Button
                className="w-[250px]"
                icon={formikProps.isSubmitting ? "" : "check"}
                onClick={() => formikProps.submitForm()}
                disabled={formikProps.isSubmitting}
              >
                {formikProps.isSubmitting ? (
                  <LoadingSpinner src={config.assets.loading.primary} />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </CardBody>
      </CardContainer>
    </FormikProvider>
  );
};

export default ProgramAwardWeights;
