import Icon from "../../../../components/Icon/Icon";
import Modal, { ModalCard } from "../../../../components/Modal/Modal";
import {
	MediaItem,
	MediaType,
} from "../../../MediaLibrary/mediaLibrary.model.d";
import {
	useState,
	useEffect,
	Dispatch,
	SetStateAction,
	useRef,
	useCallback,
} from "react";
import styled, { css, useTheme } from "styled-components";
import Image from "../../../../components/Image/Image";
import {
	VIDEO_EXTENSIONS,
	isVideoAvailable,
} from "../../../../components/MediaEnlargeModal/MediaEnlargeModal";
import VideoPlaceholder from "../../../../assets/placeholders/video-landscape.png";
import AudioPlaceholder from "../../../../assets/placeholders/audio-landscape.png";
import ImagePlaceholder from "../../../../assets/placeholders/image-landscape.png";
import DocumentPlaceholder from "../../../../assets/placeholders/document-landscape.png";
import {
	getThumbnailSrc,
	ThumbnailSize,
} from "../../../MediaLibrary/manageMediaLibrary";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack"; // https://github.com/wojtekmaj/react-pdf/issues/97
import { StyleSheet } from "@react-pdf/renderer";
import { LiveToolsMediaItem, LiveToolsMediaType } from "../JudgingInterfaces";
import { DarkPageContainer } from "./MediaViewer";
import {
	ZoomOnHover,
	ZoomPosition,
} from "../../../Judging/EntryDetail/MediaLightbox";
import VolumeControl from "./VolumeControl";
import Button from "../../../../components/Button/Button";
import { CSSTransition } from "react-transition-group";

const StyledIconContainer = styled.div<{ color: string }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	cursor: pointer;

	.label {
		color: ${(p) => p.color};
	}
`;

const MediaControlIcon = (props: {
	onClick(): void;
	icon: string;
	color: string;
	label: string;
}) => {
	return (
		<StyledIconContainer color={props.color} onClick={() => props.onClick()}>
			<Icon icon={props.icon} color={props.color} width="35px" height="35px" />
			<p className="label">{props.label}</p>
		</StyledIconContainer>
	);
};

const MediaPreviewContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	width: 100%;
	height: 100%;

	&.media-preview-enter {
		opacity: 0;
	}

	&.media-preview-enter-active {
		opacity: 1;
		transition: opacity 300ms;
	}

	&.media-preview-enter-done {
		opacity: 1;
	}

	&.media-preview-exit {
		opacity: 1;
	}

	&.media-preview-exit-active {
		opacity: 0;

		transition: opacity 300ms;
	}

	&.media-preview-exit-done {
		opacity: 0;
	}
`;

const RenderMediaPreview = (props: RenderMediaPreviewProps) => {
	const theme = useTheme();
	const mediaRef = useRef<HTMLAudioElement | HTMLVideoElement | null>(null);
	const mediaPreviewRef = useRef<HTMLDivElement | null>(null);

	const [media, setMedia] = useState(props.media);
	const prevMediaRef = useRef<JudgingMediaItem | null>(null);
	const [volumeChangeTimeout, setVolumeChangeTimeout] =
		useState<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (mediaRef.current) {
			mediaRef.current!.currentTime = props.liveMedia.timestamp || 0;
		}
	}, [props.liveMedia.timestamp]);

	useEffect(() => {
		if (props.media) {
			setMedia(props.media);
		} else {
			setMedia(null);
		}
		return () => {
			setMedia(null);
		};
	}, [props.media]);

	const handleVolumeChange = (
		newVolume: number,
		props: HandleVolumeChangeProps
	) => {
		if (props.isPresenting) {
			if (volumeChangeTimeout) {
				clearTimeout(volumeChangeTimeout);
			}
			setVolumeChangeTimeout(
				setTimeout(() => {
					props.updateMedia?.({
						...props.liveMedia,
						volume: newVolume,
					});
				}, 200)
			);
		}
	};

	useEffect(() => {
		if (mediaRef.current) {
			mediaRef.current!.volume = props.newVolume || 0.0;
		}
		handleVolumeChange(props.newVolume, props);
	}, [props.newVolume]);

	const mediaPreview = (media: JudgingMediaItem | null) => {
		if (media === null) {
			return <></>;
		} else {
			switch (media.type) {
				case MediaType.Image:
					return (
						<div className="h-full overflow-hidden">
							<ZoomOnHover
								id="zoom-img"
								image={
									<Image
										className="h-full max-h-full object-contain mx-auto"
										src={getThumbnailSrc(media.path, ThumbnailSize.X_LARGE)}
										alt={media.fileName}
										lazy
									/>
								}
								isZoom={props.isZoom}
								getPosition={(position) => props.getZoomPosition(position)}
								isZoomEnabled
							/>
						</div>
					);
				case MediaType.Document:
					return (
						<DarkPageContainer className="flex items-center justify-center flex-1">
							<a
								href={process.env.REACT_APP_S3_HOST + media.path}
								target="_blank"
								rel="noopener noreferrer"
								className="text-center text-colorCopyLightLight"
							>
								<Image
									key={media.id}
									src={getThumbnailSrc(media)}
									placeholderSrc={DocumentPlaceholder}
									alt={media.fileName}
									refetchOnError
									lazy
								/>
								<span className="flex justify-center items-center gap-[.5rem] mt-[1rem] text-[1.5rem]">
									Open Document in a New Tab{" "}
									<Icon
										className="inline-block"
										icon="link"
										color={theme.colorCopyLightLight}
										width="20px"
										height="20px"
									/>
								</span>
							</a>
						</DarkPageContainer>
					);
				case MediaType.Audio:
					return (
						<div className="flex flex-col items-center justify-center w-fit h-full">
							<Image
								className="h-[50%]"
								src={getThumbnailSrc(props.coverImage!, ThumbnailSize.MEDIUM)}
								alt="Entry Cover Image"
								lazy
							/>
							<audio
								className="mt-[1rem] w-full"
								ref={mediaRef as React.RefObject<HTMLAudioElement>}
								onPlay={(event) => {
									if (props.isPresenting && mediaRef.current) {
										props.updateMedia?.({
											...props.liveMedia,
											isPlaying: true,
											timestamp: mediaRef.current?.currentTime,
										});
									}
								}}
								onPause={(event) => {
									if (props.isPresenting && mediaRef.current) {
										props.updateMedia?.({
											...props.liveMedia,
											isPlaying: false,
											timestamp: mediaRef.current?.currentTime,
										});
									}
								}}
								controls
								muted={true}
							>
								<source
									src={process.env.REACT_APP_S3_HOST! + media.path}
									type="audio/mpeg"
								/>
								Your browser does not support the audio element.
							</audio>
						</div>
					);

				case MediaType.Video:
					return isVideoAvailable(
						process.env.REACT_APP_S3_HOST! + media.path
					) ? (
						<video
							className="w-full h-full"
							ref={mediaRef as React.RefObject<HTMLVideoElement>}
							onPlay={(event) => {
								if (props.isPresenting) {
									// event.preventDefault();
									props.updateMedia?.({
										...props.liveMedia,
										isPlaying: true,
										timestamp: mediaRef.current?.currentTime,
									});
								}
							}}
							onPause={(event) => {
								if (props.isPresenting) {
									// event.preventDefault();
									props.updateMedia?.({
										...props.liveMedia,
										isPlaying: false,
										timestamp: mediaRef.current?.currentTime,
									});
								}
							}}
							controls
							muted={true}
						>
							{VIDEO_EXTENSIONS.map((extension) => (
								<source
									src={
										process.env.REACT_APP_S3_RESIZED_HOST! +
										media.videoResizePath
									}
									type={`video/${extension}`}
								/>
							))}
							Your browser does not support the video tag.
						</video>
					) : (
						<div className="flex justify-center items-center w-full h-full">
							An error occurred with the video url. The video preview is
							unavailable.
						</div>
					);
				default:
					return (
						<div className="flex justify-center items-center w-full h-full">
							An error occurred. The media preview is unavailable.
						</div>
					);
			}
		}
	};

	return (
		<DarkPageContainer className="flex items-center justify-center flex-1">
			<CSSTransition
				classNames="media-preview"
				in={media !== prevMediaRef.current || props.isZoom} // fade-in animation when media/zoom changes
				timeout={300}
				nodeRef={mediaPreviewRef}
				unmountOnExit
			>
				<MediaPreviewContainer ref={mediaPreviewRef} key={media?.id}>
					{mediaPreview(media)}
				</MediaPreviewContainer>
			</CSSTransition>
		</DarkPageContainer>
	);
};

const MediaNav = (props: {
	activeMedia: JudgingMediaItem;
	flattenedMediaArr: JudgingMediaItem[];
	setActiveMedia(mediaItem: JudgingMediaItem): void;
}) => {
	const theme = useTheme();
	const [mediaIndex, setMediaIndex] = useState(
		props.flattenedMediaArr.findIndex(
			(media) => media.id === props.activeMedia.id
		)
	);

	const handleMediaNavigation = (direction: "prev" | "next") => {
		const prevOrNextMediaIndex = mediaIndex + (direction === "prev" ? -1 : 1);

		// if media item exists
		if (props.flattenedMediaArr[prevOrNextMediaIndex]) {
			setMediaIndex(prevOrNextMediaIndex);
		}
	};

	useEffect(() => {
		if (props.flattenedMediaArr[mediaIndex]) {
			props.setActiveMedia(props.flattenedMediaArr[mediaIndex]);
		}
	}, [mediaIndex]);

	return (
		<div className="w-full flex flex-col gap-[1rem] mt-auto">
			<Button
				className="button-light"
				icon="caret"
				iconColor={theme.colorCopyDarkDark}
				iconRotation="90deg"
				onClick={() => handleMediaNavigation("prev")}
				disabled={mediaIndex === 0}
			>
				Previous
			</Button>

			<Button
				icon="caret"
				iconRotation="-90deg"
				iconRight
				onClick={() => handleMediaNavigation("next")}
				disabled={mediaIndex === props.flattenedMediaArr.length - 1}
			>
				Next
			</Button>
		</div>
	);
};

const MediaControl = (props: MediaControlProps) => {
	const theme = useTheme();
	const [showModal, setShowModal] = useState(props.show);
	const [media, setMedia] = useState(props.media);
	const [isPresenting, setIsPresenting] = useState(false);
	const [isZoomed, setIsZoomed] = useState(false);
	const [volume, setVolume] = useState(0.0);
	const [currentMediaType, setCurrentMediaType] = useState(
		LiveToolsMediaType.PauseSlide
	);
	const [mediaTitle, setMediaTitle] = useState("Media Preview");
	const isTitleSlideShowing =
		props.mediaViewerState.mediaType === LiveToolsMediaType.EntryTitleSlide &&
		props.mediaViewerState.entryId === props.currentEntryId;

	useEffect(() => {
		switch (props.media?.type) {
			case MediaType.Audio:
				setCurrentMediaType(LiveToolsMediaType.Audio);
				break;
			case MediaType.Document:
				setCurrentMediaType(LiveToolsMediaType.Document);
				break;
			case MediaType.Image:
				setCurrentMediaType(LiveToolsMediaType.Image);
				break;
			case MediaType.Video:
				setCurrentMediaType(LiveToolsMediaType.Video);
				break;
			default:
				setCurrentMediaType(LiveToolsMediaType.PauseSlide);
				break;
		}
		if (props.media) {
			setMedia(props.media);
		} else {
			setMedia(null);
		}
		return () => {
			setMedia(null);
			setCurrentMediaType(LiveToolsMediaType.PauseSlide);
		};
	}, [props.media]);

	useEffect(() => {
		setShowModal(props.show);
	}, [props.show, props.media]);

	useEffect(() => {
		if (!props.mediaViewerState.filePath || !media) {
			setIsPresenting(false);
		} else if (!media.videoResizePath && !media.path) {
			setIsPresenting(false);
		} else {
			const path = media?.videoResizePath ? media.videoResizePath : media.path;
			setIsPresenting(props.mediaViewerState.filePath === path);
		}
	}, [props.mediaViewerState, media]);

	let timeout: any = null;

	const handleZoom = useCallback(
		(position: ZoomPosition | null, mediaViewerState: LiveToolsMediaItem) => {
			const delay = 200; // update x/y zoom position after this delay
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				props.updateMedia({
					...mediaViewerState,
					xLocation: position ? position.x : undefined,
					yLocation: position ? position.y : undefined,
					zoomLevel: undefined,
				});
			}, delay);
		},
		[]
	);

	const showPauseSlide = () => {
		props.updateMedia({
			...props.mediaViewerState,
			filePath: undefined,
			mediaType: LiveToolsMediaType.PauseSlide,
			timestamp: undefined,
			coverImage: undefined,
			awardLevel: undefined,
			brand: undefined,
			title: undefined,
			text: undefined,
			xLocation: undefined,
			yLocation: undefined,
			zoomLevel: undefined,
		});
	};

	// set zoom to false if modal is closed or title slide is shown
	useEffect(() => {
		if (!showModal || isTitleSlideShowing) setIsZoomed(false);
	}, [showModal, isTitleSlideShowing]);

	// add media type to modal label if it doesn't exist
	useEffect(() => {
		if (media && media.title && media.fileName) {
			const hasMediaType = Object.values(MediaType)
				.filter((v) => typeof v !== "number")
				.some((mediaType) => media?.title?.includes(String(mediaType)));
			if (hasMediaType) {
				setMediaTitle(media.title + ": " + media.fileName);
			} else {
				setMediaTitle(
					media.title + " " + MediaType[media.type] + ": " + media.fileName
				);
			}
		}
	}, [media]);

	return (
		<Modal show={showModal}>
			<ModalCard
				className="!min-h-[500px] !w-[80vw] !max-h-[80vh]"
				title={mediaTitle}
				headerIcons={
					<Icon
						icon="close"
						color={theme.colorPrimary}
						onClick={() => {
							props.setActiveMediaPreview(null);
							//   showPauseSlide();
							//   props.setActiveMediaViewerState(null);
						}}
						width="35px"
						height="35px"
					/>
				}
				iconColor={theme.colorPrimary}
			>
				<div className="flex w-full p-[1rem] h-[70vh]">
					{/* <MediaPreviewContainer ref={mediaPreviewRef}> */}
					<RenderMediaPreview
						media={media}
						liveMedia={props.mediaViewerState}
						updateMedia={props.updateMedia}
						isPresenting={isPresenting}
						isZoom={isZoomed}
						getZoomPosition={(position) =>
							handleZoom(position, props.mediaViewerState)
						}
						coverImage={props.coverImage}
						newVolume={volume}
					/>
					{/* </MediaPreviewContainer> */}
					{/* </CSSTransition> */}
					<div className="flex flex-col items-center text-center gap-[1rem] p-[1rem_2rem] w-[185px]">
						<MediaControlIcon
							onClick={() => {
								if (!isPresenting || isTitleSlideShowing) {
									props.updateMedia({
										...props.mediaViewerState,
										filePath: media?.videoResizePath
											? media?.videoResizePath
											: media?.path,
										mediaType: currentMediaType,
										coverImage: props.coverImage,
										awardLevel: undefined,
										brand: undefined,
										title: undefined,
										text: undefined,
										timestamp: 0,
										xLocation: undefined,
										yLocation: undefined,
										zoomLevel: undefined,
										isPlaying: false,
									});
								} else {
									showPauseSlide();
								}
							}}
							icon="present"
							color={
								isPresenting &&
								props.mediaViewerState.mediaType !==
									LiveToolsMediaType.EntryTitleSlide
									? theme.colorPrimary
									: theme.colorCopyLight
							}
							label={
								isPresenting && !isTitleSlideShowing
									? "Stop Presenting Media"
									: "Present Media"
							}
						/>

						{currentMediaType === LiveToolsMediaType.Image &&
							isPresenting &&
							!isTitleSlideShowing && (
								<MediaControlIcon
									onClick={() => {
										setIsZoomed(!isZoomed);

										// reset zoom
										if (isZoomed) handleZoom(null, props.mediaViewerState);
									}}
									icon="zoom"
									color={isZoomed ? theme.colorPrimary : theme.colorCopyLight}
									label={isZoomed ? "Reset Zoom" : "Zoom"}
								/>
							)}

						<MediaControlIcon
							onClick={() => {
								if (isTitleSlideShowing) {
									showPauseSlide();
								} else {
									props.showTitleSlide();
								}
							}}
							icon="help"
							color={
								isTitleSlideShowing ? theme.colorPrimary : theme.colorCopyLight
							}
							label="Title Slide"
						/>

						{(currentMediaType === LiveToolsMediaType.Video ||
							currentMediaType === LiveToolsMediaType.Audio) &&
							isPresenting && (
								<VolumeControl
									volume={props.mediaViewerState.volume || 0.0}
									onVolumeChange={
										(newVolume) => setVolume(newVolume)
										// handleVolumeChange(newVolume, props)
									}
								/>
							)}
						{media && (
							<MediaNav
								activeMedia={media}
								flattenedMediaArr={props.flattenedMediaArr}
								setActiveMedia={(media) => {
									props.setActiveMediaPreview(media);
									//   showPauseSlide();
									setIsZoomed(false);
								}}
							/>
						)}
					</div>
				</div>
			</ModalCard>
		</Modal>
	);
};

export default MediaControl;

interface MediaControlProps {
	media: JudgingMediaItem | null;
	show: boolean;
	setActiveMediaPreview: Dispatch<SetStateAction<JudgingMediaItem | null>>;
	mediaViewerState: LiveToolsMediaItem;
	//   setActiveMediaViewerState: Dispatch<
	//     SetStateAction<LiveToolsMediaItem | null>
	//   >;
	title?: string;
	updateMedia: (mediaState: LiveToolsMediaItem) => void;
	coverImage?: string;
	showTitleSlide(): void;
	flattenedMediaArr: JudgingMediaItem[]; // flattened arr containing all media items in every execution
	currentEntryId: number;
}

interface HandleVolumeChangeProps {
	isPresenting: boolean;
	liveMedia: LiveToolsMediaItem;
	updateMedia?: (media: LiveToolsMediaItem) => void;
}

interface RenderMediaPreviewProps {
	media: JudgingMediaItem | null;
	liveMedia: LiveToolsMediaItem;
	updateMedia: (mediaState: LiveToolsMediaItem) => void;
	isPresenting: boolean;
	isZoom: boolean;
	coverImage?: string;
	getZoomPosition(position: ZoomPosition): void;
	newVolume: number;
}

interface JudgingMediaItem extends MediaItem {
	title?: string;
}
