import { useTheme, css } from "styled-components";
import { Link, NavLink, useLocation } from "react-router-dom";
import { SidebarData, limitedSidebar } from "./SidebarData";
import styled from "styled-components";
import { memo, useEffect, useState } from "react";
import Icon from "../Icon/Icon";
import { DynamicTransitionDuration } from "../../globalStyles";
import { linkDTO, sublinkDTO } from "./Sidebar.model";
import { CSSTransition } from "react-transition-group";
import config from "../../config";
import { useSettings } from "../../hooks/UseSettings";
import Loading from "../Loading/Loading";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Authorized from "../Auth/Authorized";
import Notifications, {
	NotificationItem,
	NotificationListContainer,
	NotificationStyle,
} from "../Notification/Notification";
import { Circle } from "../ProgressCircle/ProgressCircle";

export const SIDEBAR_WIDTH = 269;

const StyledNavbar = styled.div<{ isExpanded: Boolean }>`
	position: relative;
	height: 100%;
	width: ${({ isExpanded }) => (isExpanded ? `${SIDEBAR_WIDTH}px` : "65px")};
	flex-grow: 1;
	flex-shrink: 0;
	transition: width ${DynamicTransitionDuration} ease;
	background: ${({ theme }) => theme.colorBackgroundMedium};
	overflow: ${({ isExpanded }) => (isExpanded ? "hidden" : "visible")};

	.menu_caret {
		position: relative;
		/* position: fixed;
    z-index: 999;
    top: 1rem; */
		width: 20px;
		margin: ${({ isExpanded }) =>
			isExpanded ? "1rem 1rem 0 auto" : "1rem auto"};
		transform: ${({ isExpanded }) =>
			isExpanded ? "rotate(90deg)" : "rotate(270deg)"};

		transition: transform ${DynamicTransitionDuration} ease;
	}
`;

const Logo = styled.img<{ isExpanded: Boolean }>`
	display: block;
	width: 66%;
	margin: auto;
	margin-top: 80px;
	visibility: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
	padding: 0px 0 40px 0;
`;

const ActiveBar = styled.div<{ isExpanded: Boolean }>`
	position: absolute;
	left: ${({ isExpanded }) => (isExpanded ? "37px" : "-45px")};
	bottom: ${({ isExpanded }) => (isExpanded ? "-5px" : "4px")};
	height: ${({ isExpanded }) => (isExpanded ? "5px" : "0px")};
	width: ${({ isExpanded }) => (isExpanded ? "0" : "50px")};
	top: ${({ isExpanded }) => (isExpanded ? "" : "0px")};

	background: ${({ theme }) => theme.colorActivation};
	transition: all ${DynamicTransitionDuration} ease;
`;

const NavLinkContainer = styled.div<{
	isExpanded?: Boolean;
	allowOverflow?: boolean;
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	max-height: 65px;
	overflow: ${({ isExpanded }) => (isExpanded ? "hidden" : "visible")};
	transition: max-height 0.15s ease;

	&.expanded {
		max-height: 999px;

		${ActiveBar} {
			width: 50px;
			height: ${({ isExpanded }) => (isExpanded ? "5px" : "65px")};
		}
	}

	${(p) =>
		p.allowOverflow &&
		css`
			overflow: visible;

			${ActiveBar} {
				height: "5px";
			}
		`};
`;

const StyledNavLink = styled(NavLink)<{ isExpanded: Boolean }>`
	position: relative;
	display: flex;
	justify-content: ${({ isExpanded }) =>
		isExpanded ? "space-between" : "center"};
	align-items: center;
	height: 65px;
	padding: ${({ isExpanded }) =>
		isExpanded ? "20px 26.5px 20px 37px" : "1.25rem"};
	color: ${({ theme }) => theme.colorCopyLight};

	${(p) =>
		p.isExpanded &&
		`
  border-bottom: 5px solid ${p.theme.colorBorderDark};
  `}

	/* svg {
    width: ${({ theme }) => theme.h2Size};
    height: ${({ theme }) => theme.h2Size};
  } */

  .caret {
		transform: rotate(270deg);
		transition: all ${DynamicTransitionDuration} ease;
	}

	&:link {
		text-decoration: none;
	}

	&.link-active {
		color: ${({ theme }) => theme.colorCopyLightLight};

		.caret {
			transform: rotate(360deg);
		}
	}

	&:hover {
		${ActiveBar} {
			width: 50px;
			height: ${({ isExpanded }) => (isExpanded ? "5px" : "65px")};
		}
	}
`;

const StyledSubLinks = styled(NavLink)<{ isCollapsed?: boolean }>`
	padding: ${({ isCollapsed }) =>
		isCollapsed ? "0.6875rem 0 0.6875rem 10px" : "0.6875rem 0 0.6875rem 60px"};
	text-decoration: none;

	&:hover {
		transition: all ${DynamicTransitionDuration} ease-in;
		color: white;
	}

	&.active {
		font-weight: ${({ theme }) => theme.fontSemiBold};
		color: white;
	}
`;

const HoverMenu = styled.div<{ showHoverMenu?: boolean }>`
	position: absolute;
	display: flex;
	right: -180px;
	width: 180px;
	flex-direction: column;
	z-index: 1;
	background-color: ${({ theme }) => theme.colorBackgroundMedium};
	box-shadow: 0 2px 8px 4px ${({ theme }) => theme.colorBoxShadow};
	padding-top: 5px;
	padding-bottom: 5px;

	@-webkit-keyframes fadeInFromNone {
		0% {
			display: none;
			opacity: 0;
		}

		1% {
			display: block;
			opacity: 0;
		}

		100% {
			display: block;
			opacity: 1;
		}
	}

	@-moz-keyframes fadeInFromNone {
		0% {
			display: none;
			opacity: 0;
		}

		1% {
			display: block;
			opacity: 0;
		}

		100% {
			display: block;
			opacity: 1;
		}
	}

	@-o-keyframes fadeInFromNone {
		0% {
			display: none;
			opacity: 0;
		}

		1% {
			display: block;
			opacity: 0;
		}

		100% {
			display: block;
			opacity: 1;
		}
	}

	@keyframes fadeInFromNone {
		0% {
			display: none;
			opacity: 0;
		}

		1% {
			display: block;
			opacity: 0;
		}

		100% {
			display: block;
			opacity: 1;
		}
	}
	-webkit-animation: fadeInFromNone ${DynamicTransitionDuration} ease-out;
	-moz-animation: fadeInFromNone ${DynamicTransitionDuration} ease-out;
	-o-animation: fadeInFromNone ${DynamicTransitionDuration} ease-out;
	animation: fadeInFromNone ${DynamicTransitionDuration} ease-out;
`;

const NotificationHoverMenu = styled(HoverMenu)`
	right: -280px;
	width: 280px;

	@media only screen and (min-width: ${({ theme }) => theme.xs}) {
		right: -400px;
		width: 400px;

		${NotificationListContainer} {
			width: 400px;
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.sm}) {
		right: -500px;
		width: 500px;

		${NotificationListContainer} {
			width: 500px;
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.md}) {
		right: -600px;
		width: 600px;

		${NotificationListContainer} {
			width: 600px;
		}
	}
`;

const initialLinkState = {
	Home: false,
	Program: false,
	Entries: false,
	Judging: false,
	Configuration: false,
	"Global Settings": false,
	Reports: false,
};

const SideBarMenu = memo((props: SidebarMenuProps) => {
	const { link, isExpanded, activeLink, setActiveLink } = props;
	const theme = useTheme();
	const { settings, loading } = useSelector((state: RootState) => ({
		settings: state.settings.settings,
		loading: state.settings.loading,
	}));
	const [showHoverMenu, setShowHoverMenu] = useState<boolean>(false);

	return (
		<NavLinkContainer
			isExpanded={isExpanded}
			allowOverflow={false} // allow overflow for notifications
			className={activeLink[link.title] ? "expanded" : ""}
			onMouseOver={() => setShowHoverMenu(true)}
			onMouseLeave={() => setShowHoverMenu(false)}
		>
			<StyledNavLink
				isExpanded={isExpanded}
				to={link.path}
				activeClassName="link-active"
				className={`${link.className} flex`}
				onClick={(e) => {
					setActiveLink({ ...initialLinkState, [link.title]: true });
				}}
			>
				{isExpanded ? (
					<>
						<p>{link.title}</p>
						{link.sublinks && link.sublinks.length > 0 && (
							<Icon
								icon="caret"
								className="caret"
								color={theme.colorCopyLight}
								width="11px"
								height="11px"
							/>
						)}
					</>
				) : (
					<>
						{link.icon && (
							<Icon
								icon={link.icon}
								className={`link_icon ${
									link.icon === "logout" ? "ml-[5px]" : ""
								}`}
								color={theme.colorCopyLightLight}
								width={`${
									link.icon === "library"
										? "20px"
										: link.icon === "home"
										? "25px"
										: link.icon === "logout"
										? "25px"
										: link.icon === "statue"
										? "25px"
										: link.icon === "entry-review"
										? "25px"
										: link.icon === "search"
										? "25px"
										: link.icon === "bell"
										? "25px"
										: "30px"
								}`}
								height={`${
									link.icon === "library"
										? "20px"
										: link.icon === "home"
										? "25px"
										: link.icon === "logout"
										? "25px"
										: link.icon === "statue"
										? "40px"
										: link.icon === "entry-review"
										? "25px"
										: link.icon === "search"
										? "25px"
										: link.icon === "bell"
										? "25px"
										: "30px"
								}`}
							/>
						)}
					</>
				)}

				<ActiveBar isExpanded={isExpanded} />
			</StyledNavLink>
			{isExpanded ? (
				<>
					{link.sublinks?.map((sublink: sublinkDTO, subindex: number) => {
						return (
							<Authorized
								key={sublink.path}
								settings={settings}
								loading={loading}
								role={sublink.sysadmin ? "sysadmin" : "admin"}
								feature={sublink.feature}
								authorized={
									<StyledSubLinks
										key={subindex}
										activeClassName="active"
										to={sublink.path}
									>
										{sublink.title}
									</StyledSubLinks>
								}
							/>
						);
					})}
				</>
			) : link.sublinks && link.sublinks.length > 0 ? (
				<CSSTransition
					in={showHoverMenu}
					timeout={150}
					unmountOnExit
					classNames="hover-menu"
				>
					<HoverMenu>
						{link.sublinks?.map((sublink: sublinkDTO, subindex: number) => {
							return (
								<Authorized
									key={sublink.path}
									settings={settings}
									loading={loading}
									role={sublink.sysadmin ? "sysadmin" : "admin"}
									feature={sublink.feature}
									authorized={
										<StyledSubLinks
											key={subindex}
											isCollapsed
											activeClassName="active"
											to={sublink.path}
										>
											{sublink.title}
										</StyledSubLinks>
									}
								/>
							);
						})}
					</HoverMenu>
				</CSSTransition>
			) : (
				<></>
			)}
		</NavLinkContainer>
	);
});

const NotificationBell = (props: SidebarMenuProps) => {
	const { link, isExpanded, activeLink, setActiveLink } = props;
	const theme = useTheme();
	const [showHoverMenu, setShowHoverMenu] = useState<boolean>(false);
	const { notifications } = useSelector((state: RootState) => ({
		notifications: state.notifications.notifications,
	}));
	const hasNewNotifications =
		notifications && notifications.some((n) => !n.isRead);

	return (
		<NavLinkContainer
			isExpanded={isExpanded}
			allowOverflow={true} // allow overflow for notifications
			className={activeLink[link.title] ? "expanded" : ""}
			onMouseOver={() => setShowHoverMenu(true)}
			onMouseLeave={() => setShowHoverMenu(false)}
		>
			<StyledNavLink
				isExpanded={isExpanded}
				to={link.path}
				activeClassName="link-active"
				className={`${link.className} flex`}
				onClick={(e) => {
					setActiveLink({ ...initialLinkState, [link.title]: true });
					e.preventDefault();
				}}
			>
				{isExpanded ? (
					<>
						<p>{link.title}</p>
						{hasNewNotifications && (
							<Circle
								className="!border-[4px] mr-auto ml-[7px]"
								color={theme.colorDanger}
								size="4px"
							/>
						)}
						{link.sublinks && link.sublinks.length > 0 && (
							<Icon
								icon="caret"
								className="caret"
								color={theme.colorCopyLight}
								width="11px"
								height="11px"
							/>
						)}
					</>
				) : (
					<>
						{link.icon && (
							<Icon
								icon={link.icon}
								className={`link_icon ${
									link.icon === "logout" ? "ml-[5px]" : ""
								}`}
								color={theme.colorCopyLightLight}
								width="25px"
								height="25px"
							/>
						)}

						{hasNewNotifications && (
							<Circle
								className="absolute right-[10px] top-[23px] !border-[4px]"
								color={theme.colorDanger}
								size="4px"
							/>
						)}
					</>
				)}

				<ActiveBar isExpanded={isExpanded} />
			</StyledNavLink>

			<CSSTransition
				in={showHoverMenu}
				timeout={150}
				unmountOnExit
				classNames="hover-menu"
			>
				<NotificationHoverMenu>
					<Notifications
						height={556}
						itemHeight={117}
						notificationStyle={NotificationStyle.Dark}
					/>
				</NotificationHoverMenu>
			</CSSTransition>
		</NavLinkContainer>
	);
};

const Sidebar = memo(() => {
	const { settings, loading } = useSelector((state: RootState) => ({
		settings: state.settings.settings,
		loading: state.settings.loading,
	}));

	const sidebar = settings.isDemo ? limitedSidebar : SidebarData;
	const theme = useTheme();
	const [activeLink, setActiveLink] = useState<{ [index: string]: boolean }>(
		initialLinkState
	);
	const [isExpanded, setExpanded] = useState<boolean>(false);

	useEffect(() => {
		const messageIcon = document.getElementById("messages-floating-icon");
		if (messageIcon !== null) {
			if (isExpanded) {
				// set the icon position to the right of the sidebar
				(messageIcon as HTMLElement).style.left = String(269 + 75 - 65) + "px";
			} else {
				(messageIcon as HTMLElement).style.left = "75px";
			}
		}
	}, [isExpanded]);

	return (
		<StyledNavbar isExpanded={isExpanded}>
			<div className={`fixed z-[99] ${isExpanded ? "w-[269px]" : "w-[65px]"}`}>
				<Icon
					icon="caret"
					className="menu_caret"
					onClick={() => setExpanded(!isExpanded)}
					color={theme.colorCopyLightLight}
					width="20px"
					height="20px"
				/>
			</div>

			<div className={`fixed z-[98] ${isExpanded ? "w-[269px]" : "w-[65px]"}`}>
				<Link to="/dashboard">
					<Logo
						isExpanded={isExpanded}
						alt="logo"
						src={config.assets.logos.primaryWhite}
					/>
				</Link>

				<div className="relative z-10">
					<Authorized
						settings={settings}
						loading={loading}
						role={sidebar[0].sysadmin ? "sysadmin" : "admin"}
						feature={sidebar[0].feature}
						authorized={
							<NotificationBell
								link={sidebar[0]}
								isExpanded={isExpanded}
								activeLink={activeLink}
								setActiveLink={setActiveLink}
							/>
						}
					/>
					{[...sidebar.slice(1)].map((link, index) => {
						return (
							<Authorized
								key={link.path}
								settings={settings}
								loading={loading}
								role={link.sysadmin ? "sysadmin" : "admin"}
								feature={link.feature}
								authorized={
									<SideBarMenu
										key={index}
										link={link}
										isExpanded={isExpanded}
										activeLink={activeLink}
										setActiveLink={setActiveLink}
									/>
								}
							/>
						);
					})}
				</div>
			</div>
		</StyledNavbar>
	);
});

export default Sidebar;

interface SidebarMenuProps {
	link: linkDTO;
	isExpanded: boolean;
	activeLink: { [index: string]: boolean };
	setActiveLink: React.Dispatch<
		React.SetStateAction<{
			[index: string]: boolean;
		}>
	>;
}
