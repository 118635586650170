import { Ancestor, ProgramModel } from "./ProgramInterfaces";
import Button from "../../../components/Button/Button";
import {
	CardHeader,
	CardBody,
	CardContainer,
} from "../../../components/DashboardCard/DashboardCard";
import styled, { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import Sort from "../../../components/Sort/Sort";
import { SortContainer } from "../../../components/Sort/Sort";
import { getRootPrograms, getRootProgramSetupCards } from "./manageProgram";
import { useHistory } from "react-router-dom";
import { DynamicTransitionDuration } from "../../../globalStyles";
import Breadcrumbs, {
	NavLinkProps,
} from "../../../components/Breadcrumbs/Breadcrumbs";
import Icon from "../../../components/Icon/Icon";
import HierarchyNav, {
	HierarchyCard,
} from "../../../components/HierarchyNav/HierarchyNav";
import { SeasonModel } from "../Seasons/Seasons";
import { AwardModel } from "../Awards/Awards";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { getLocalTime } from "../../../utils/timeUtilities";

export const ProgramHierarchyContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 15px 19px;
	background: ${({ theme }) => theme.colorBackgroundLight};
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
	/* margin-bottom: 30px; */
	max-height: 112px;
	/* max-width: 100%; */
	/* transition: max-height ${DynamicTransitionDuration} ease; */

	.program-title {
		width: 20%;
	}

	p {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

const ExpandLevelContainer = styled.div<{
	childrenCount: number;
	isExpanded: boolean;
}>`
	position: relative;
	${(p) => p.childrenCount && `height: calc(${p.childrenCount} * 112px);`};
`;

const ProgramHierarchy = (props: ProgramSetupCard) => {
	const theme = useTheme();
	const history = useHistory();

	return (
		<>
			<div>
				<Button
					className="button-light !min-w-0 max-w-full"
					iconLeft="edit"
					to={`/program/configure/${props.id}`}
				>
					Edit
				</Button>
			</div>
			<p>{props.name}</p>
			{props.season && <p>{props.season.name}</p>}
			{props.award && <p>{props.award.name}</p>}
			<p>{props.entriesOpenDate && getLocalTime(props.entriesOpenDate)}</p>
			{props.hasChildren && (
				<div
					className="flex justify-end items-center cursor-pointer ml-auto"
					onClick={() => history.push(`/program/program-setup/${props.id}`)}
				>
					<p>{props.childLevelDisplayLabel}</p>
					<Icon
						icon="caret"
						color={theme.colorCopyLight}
						className="ml-[.5rem]"
					/>
				</div>
			)}
		</>
	);
};

export default function ProgramRootAdminNav() {
	const history = useHistory();
	const [rootPrograms, setRootPrograms] = useState<ProgramSetupCard[]>([]);
	const [breadcrumbs] = useState<NavLinkProps[]>([
		{ label: "Programs", value: `/program/program-setup` },
	]);

	useEffect(() => {
		getRootProgramSetupCards()
			.then((res) => {
				// console.log("Get Root Programs", res);
				setRootPrograms(res.data);
			})
			.catch((e) => {
				// console.log("getRootPrograms ERROR", e);
			});
	}, []);
	const theme = useTheme();

	const handleClickBreadcrumb = (
		breadcrumb: {
			label: string;
			value?: string | number;
		},
		index: number
	) => {
		breadcrumb.value?.toString() && history.push(breadcrumb.value?.toString());
	};

	return (
		<CardContainer>
			<BrowserHeader title="Program Setup" />
			<CardHeader>
				<h2 className="card-title">Program Setup</h2>
				<Breadcrumbs navLinks={breadcrumbs} onClick={handleClickBreadcrumb} />
			</CardHeader>
			<CardBody className="flex flex-col overflow-y-auto overflow-x-hidden">
				<div className="flex gap-[1.28125rem] mb-[1rem] w-full justify-end">
					<Button
						iconLeft="plus"
						iconColor={theme.colorCopyLightLight}
						to="/program/new"
					>
						Add Program
					</Button>
				</div>

				<HierarchyNav
					columns={[
						{ label: null },
						{ label: "Program Name" },
						{ label: "Season" },
						{ label: "Award" },
						{ label: "Entires Opened" },
						{ label: null },
					]}
					responsive
					responsiveMinWidth="130px"
				>
					{(gridColumns: string, gridArea: string) =>
						rootPrograms.map((program, index) => {
							return (
								<HierarchyCard
									key={index}
									gridColumns={gridColumns}
									responsive
									gridArea={gridArea}
									responsiveMinWidth="130px"
								>
									<ProgramHierarchy key={program.id!} {...program} />
								</HierarchyCard>
							);
						})
					}
				</HierarchyNav>
			</CardBody>
		</CardContainer>
	);
}
export {};

export interface ProgramSetupCard {
	id: number;
	name: string;
	season?: SeasonModel;
	award?: AwardModel;
	entriesOpenDate?: number;
	childLevelDisplayLabel?: string;
	hasChildren?: boolean;
	ancestry?: Ancestor[];
	children?: ProgramSetupCardChild[];
}

export interface ProgramSetupCardChild {
	id: number;
	name: string;
	entriesOpenDate: number;
	childLevelDisplayLabel: string;
	season?: SeasonModel;
	award?: AwardModel;
	hasChildren?: boolean;
	children?: ProgramSetupCardChild[];
}
