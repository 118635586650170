import { FormikProvider, useFormik } from "formik";
import { useState } from "react";
import styled from "styled-components";
import Button from "../Button/Button";
import ResultsTable from "./ResultsTable";
import { getOrderReports, OrderReport } from "./manageReports";
import config from "../../config";
import DateTimeField from "../FormFields/DateTimeField";
import { unixToMS } from "../../utils/timeUtilities";

const LoadingSpinner = styled.img`
  width: 100px;
`;

const spinnerSrc = config.assets.loading.primary;

const OrderUpload = () => {
  const [orderReports, setOrderReports] = useState<OrderReport[]>();
  const [orderReportLoading, setOrderReportLoading] = useState(false);

  const formikProps = useFormik({
    initialValues: {
      startDateTime: undefined as number | undefined,
      endDateTime: undefined as number | undefined,
    },
    enableReinitialize: false,
    onSubmit: async () => {},
    validateOnBlur: false,
  });

  const generateInformation = async () => {
    setOrderReportLoading(true);
    try {
      const start = formikProps.values.startDateTime;
      const end = formikProps.values.endDateTime;

      if (start !== undefined && end !== undefined) {
        // convert start and end dates from seconds to ms
        const reports = await getOrderReports(unixToMS(start), unixToMS(end));
        setOrderReports(reports);
      }
    } catch (error) {
      console.error("Failed to get order report: ", error);
    }
    setOrderReportLoading(false);
  };

  return (
    <div>
      <FormikProvider value={formikProps}>
        {/* Date picker section */}
        <div className="flex items-center space-x-4 pb-5">
          <div className="flex gap-[1rem] items-center">
            <DateTimeField
              name="startDateTime"
              placeholder="Start Date Time"
              value={formikProps.values.startDateTime}
            />
            <DateTimeField
              name="endDateTime"
              placeholder="End Date Time"
              value={formikProps.values.endDateTime}
            />
          </div>
          <Button onClick={generateInformation}>Generate</Button>
        </div>

        {/* Loading and Results display section */}
        {orderReportLoading ? (
          <div className="pt-10 flex flex-col items-center justify-center">
            <div>Running Order Report...</div>
            <LoadingSpinner src={spinnerSrc} />
          </div>
        ) : orderReports && orderReports.length > 0 ? (
          <ResultsTable data={orderReports} />
        ) : null}
      </FormikProvider>
    </div>
  );
};

export default OrderUpload;
